import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["type", "placeholder", "field", "name", "autoComplete", "validate", "mode", "countrySelected", "zipCase"];
import React, { useState, useEffect } from 'react';
import TextInput from '@magento/venia-ui/lib/components/TextInput';
import { Field } from '../Inputs';
import { hasLengthAtLeast, isRequired } from '@magento/venia-ui/lib/util/formValidators';
import combine from '@magento/venia-ui/lib/util/combineValidators';
export default function MaskedInput(_ref) {
  var _props$isRequired, _props$values, _props$values2;

  var _ref$type = _ref.type,
      type = _ref$type === void 0 ? 'phone' : _ref$type,
      _ref$placeholder = _ref.placeholder,
      placeholder = _ref$placeholder === void 0 ? 'Phone' : _ref$placeholder,
      _ref$field = _ref.field,
      field = _ref$field === void 0 ? '' : _ref$field,
      _ref$name = _ref.name,
      name = _ref$name === void 0 ? '' : _ref$name,
      autoComplete = _ref.autoComplete,
      validate = _ref.validate,
      mode = _ref.mode,
      _ref$countrySelected = _ref.countrySelected,
      countrySelected = _ref$countrySelected === void 0 ? 'US' : _ref$countrySelected,
      _ref$zipCase = _ref.zipCase,
      zipCase = _ref$zipCase === void 0 ? false : _ref$zipCase,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      resetInput = _useState2[0],
      setResetInput = _useState2[1];

  useEffect(function () {
    setResetInput(true);
    setTimeout(function () {
      return setResetInput(false);
    }, 0);
  }, [countrySelected]);
  var formatter = type === 'phone' ? countrySelected === 'US' || countrySelected === 'CA' ? ['+', '1', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] : '' : (countrySelected === null || countrySelected === void 0 ? void 0 : countrySelected.trim()) === 'US' ? [/\d/, /\d/, /\d/, /\d/, /\d/] : [/[A-Z]/i, /\d/, /[A-Z]/i, ' ', /\d/, /[A-Z]/i, /\d/]; // const parser = value => value.replace(/-/g, '');

  return resetInput ? null : React.createElement(Field, {
    id: type,
    label: type
  }, React.createElement(TextInput, _extends({}, props, {
    id: type,
    placeholder: placeholder,
    name: name,
    field: field ? field : type // parser={parser}
    ,
    maintainCursor: true,
    formatter: formatter,
    required: (_props$isRequired = props === null || props === void 0 ? void 0 : props.isRequired) !== null && _props$isRequired !== void 0 ? _props$isRequired : false,
    autoComplete: autoComplete,
    validate: type === 'phone' ? validate : combine([isRequired, [hasLengthAtLeast, zipCase ? zipCase : !(props !== null && props !== void 0 && (_props$values = props.values) !== null && _props$values !== void 0 && _props$values.country) || (props === null || props === void 0 ? void 0 : (_props$values2 = props.values) === null || _props$values2 === void 0 ? void 0 : _props$values2.country) === 'US' ? 5 : 7]]),
    mode: mode
  })));
}