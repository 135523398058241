import React from 'react';

var NavFavoriteIcon = function NavFavoriteIcon() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    width: "24",
    height: "19",
    viewBox: "0 0 24 19"
  }, React.createElement("defs", null, React.createElement("path", {
    id: "vc8p8h1s0a",
    d: "M0 0h23.524v19H0z"
  })), React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("mask", {
    id: "rdl45g3btb",
    fill: "#fff"
  }, React.createElement("use", {
    xlinkHref: "#vc8p8h1s0a"
  })), React.createElement("path", {
    fill: "#FFF",
    stroke: "#FFF",
    strokeWidth: "0.5",
    d: "M20.623 9.771L11.76 17.8 2.901 9.77c-1.083-.982-1.68-2.286-1.68-3.674s.597-2.693 1.68-3.676c.96-.868 2.236-1.347 3.593-1.347 1.358 0 2.634.479 3.592 1.347l1.437 1.304c.133.12.345.12.477 0l1.437-1.303c.959-.87 2.236-1.348 3.593-1.348 1.358 0 2.633.479 3.593 1.347 1.083.983 1.68 2.288 1.68 3.676 0 1.388-.597 2.692-1.68 3.674m.477-7.81C20.012.974 18.567.432 17.03.432s-2.982.542-4.071 1.528L11.76 3.046 10.564 1.96C9.476.974 8.03.432 6.494.432c-1.537 0-2.982.542-4.07 1.528C1.205 3.064.534 4.534.534 6.097c0 1.564.672 3.032 1.89 4.136l9.1 8.245a.358.358 0 00.477 0l9.1-8.245c1.217-1.104 1.888-2.572 1.888-4.136 0-1.563-.67-3.033-1.888-4.137",
    mask: "url(#rdl45g3btb)"
  })));
};

export default NavFavoriteIcon;