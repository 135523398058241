import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useCustomerWishlistSkus } from '../../hooks/useCustomerWishlistSkus/useCustomerWishlistSkus';
import mergeOperations from '@magento/peregrine/lib/util/shallowMerge';
import defaultOperations from './gallery.gql';
import { EXISTS_IN_FAVORITES } from './gallery.gql.ee';
export var useGallery = function useGallery() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var operations = mergeOperations(defaultOperations, props.operations); // useCustomerWishlistSkus();

  var _useQuery = useQuery(operations.getStoreConfigQuery, {
    fetchPolicy: 'cache-and-network'
  }),
      storeConfigData = _useQuery.data;

  var storeConfig = storeConfigData ? storeConfigData.storeConfig : null;
  var listOfProductIds = useMemo(function () {
    var _props$items;

    return props === null || props === void 0 ? void 0 : (_props$items = props.items) === null || _props$items === void 0 ? void 0 : _props$items.map(function (x) {
      var _x$id;

      var strId = x === null || x === void 0 ? void 0 : (_x$id = x.id) === null || _x$id === void 0 ? void 0 : _x$id.toString(); // x.id - id is actually the id coming back from klevu which is a combination of product id and variant id 
      // (configuable product id and simple product id) in the form of: "{product_id}-{variant_id}"
      // get substring until first dash

      return (strId === null || strId === void 0 ? void 0 : strId.substring(0, strId === null || strId === void 0 ? void 0 : strId.indexOf('-'))) || strId;
    });
  }, [props.items]);

  var _useQuery2 = useQuery(EXISTS_IN_FAVORITES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      product_id_list: listOfProductIds
    }
  }),
      favoritesData = _useQuery2.data,
      refetchFavorites = _useQuery2.refetch;

  return {
    storeConfig: storeConfig,
    favoritesData: favoritesData,
    refetchFavorites: refetchFavorites
  };
};