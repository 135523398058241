import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import useInternalLink from '@magento/peregrine/lib/hooks/useInternalLink';
import mergeOperations from '@magento/peregrine/lib/util/shallowMerge';
import DEFAULT_OPERATIONS from './breadcrumbs.gql'; // Just incase the data is unsorted, lets sort it.

var sortCrumbs = function sortCrumbs(a, b) {
  return a.category_level > b.category_level;
}; // Generates the path for the category.


var getPath = function getPath(path, suffix) {
  if (path) {
    return "/".concat(path).concat(suffix || '');
  } // If there is no path this is just a dead link.


  return '#';
};
/**
 * Returns props necessary to render a Breadcrumbs component.
 *
 * @param {object} props
 * @param {object} props.query - the breadcrumb query
 * @param {string} props.categoryId - the id of the category for which to generate breadcrumbs
 * @return {{
 *   currentCategory: string,
 *   currentCategoryPath: string,
 *   isLoading: boolean,
 *   normalizedData: array,
 *   handleClick: function
 * }}
 */


export var useBreadcrumbs = function useBreadcrumbs(props) {
  var categoryId = props.categoryId;
  var operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  var getBreadcrumbsQuery = operations.getBreadcrumbsQuery,
      getStoreConfigQuery = operations.getStoreConfigQuery;

  var _useQuery = useQuery(getBreadcrumbsQuery, {
    variables: {
      category_id: categoryId
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  }),
      data = _useQuery.data,
      loading = _useQuery.loading,
      error = _useQuery.error;

  var _useQuery2 = useQuery(getStoreConfigQuery, {
    fetchPolicy: 'cache-and-network'
  }),
      storeConfigData = _useQuery2.data;

  var categoryUrlSuffix = useMemo(function () {
    if (storeConfigData) {
      return storeConfigData.storeConfig.category_url_suffix;
    }
  }, [storeConfigData]); // When we have breadcrumb data sort and normalize it for easy rendering.

  var normalizedData = useMemo(function () {
    if (!loading && data && data.categories.items.length) {
      var breadcrumbData = data.categories.items[0].breadcrumbs;
      return breadcrumbData && breadcrumbData.map(function (category) {
        return {
          category_level: category.category_level,
          text: category.category_name,
          path: getPath(category.category_url_path, categoryUrlSuffix)
        };
      }).sort(sortCrumbs);
    }
  }, [categoryUrlSuffix, data, loading]);

  var _useInternalLink = useInternalLink('category'),
      setShimmerType = _useInternalLink.setShimmerType;

  return {
    currentCategory: data && data.categories.items.length && data.categories.items[0].name || '',
    currentCategoryPath: data && data.categories.items.length && "".concat(data.categories.items[0].url_path).concat(categoryUrlSuffix || '') || '#',
    isLoading: loading,
    hasError: !!error,
    normalizedData: normalizedData || [],
    handleClick: setShimmerType
  };
};