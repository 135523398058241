import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useEffect, useMemo } from 'react';
import { Form } from 'informed';
import Trigger from '@magento/venia-ui/lib/components/Trigger';
import TextInput from '@magento/venia-ui/lib/components/TextInput';
import Field from '../../../../overrides/venia-ui/components/Field';
import { Modal, Button, CloseIcon } from '../../../';
import classes from './accessRequestModal.module.css';
import { useAccessRequest } from '../hooks/useAccessRequest';
import MaskedInput from '../../../MaskedInput';

var AccessRequestModal = function AccessRequestModal(_ref) {
  var isOpen = _ref.isOpen,
      closeModal = _ref.closeModal;

  var _useAccessRequest = useAccessRequest(),
      handleSubmit = _useAccessRequest.handleSubmit,
      data = _useAccessRequest.data,
      loading = _useAccessRequest.loading,
      error = _useAccessRequest.error;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      success = _useState2[0],
      setSuccess = _useState2[1];

  useEffect(function () {
    if (data !== null && data !== void 0 && data.AccessRequest) setSuccess(true);
    return function () {
      return setSuccess(false);
    };
  }, [data]);
  var content = useMemo(function () {
    return success ? React.createElement("div", {
      className: "flex flex-col justify-center items-center"
    }, React.createElement("h3", {
      className: "lg_mb-[32px] mb-[20px] text-[white]"
    }, "Access request form sent successfully"), React.createElement("h6", {
      className: "lg_mb-[50px] mb-[20px] text-[white] text-center"
    }, "Thank you for submitting your request for account access; your submittal has been sent to your Company Admin who will review and approve it accordingly."), React.createElement(Button, {
      priority: "high",
      "aria-label": "Close Product Registration Modal",
      onClick: closeModal
    }, "Close")) : React.createElement(React.Fragment, null, React.createElement("h3", {
      className: "mb-7 leading-139"
    }, "Access Request"), React.createElement("h6", {
      className: "mb-12 leading-160"
    }, "Please fill out the form below"), React.createElement(Form, {
      onSubmit: handleSubmit,
      className: "grid grid-cols-1 gap-y-6"
    }, React.createElement("div", {
      className: "col-span-1"
    }, React.createElement(Field, {
      id: "jobTitle",
      label: "job"
    }, React.createElement(TextInput, {
      field: "jobTitle",
      id: "jobTitle",
      placeholder: "Job Title *",
      mode: "dark",
      classes: {
        input: classes.input,
        placeholder: classes.inputPlaceholder
      }
    }))), React.createElement("div", {
      className: "col-span-1"
    }, React.createElement(Field, {
      id: "firstName",
      label: "firstName"
    }, React.createElement(TextInput, {
      field: "firstName",
      id: "firstName",
      placeholder: "First Name *",
      mode: "dark",
      classes: {
        input: classes.input,
        placeholder: classes.inputPlaceholder
      }
    }))), React.createElement("div", {
      className: "col-span-1"
    }, React.createElement(Field, {
      id: "lastName",
      label: "lastName"
    }, React.createElement(TextInput, {
      field: "lastName",
      id: "lastName",
      placeholder: "Last Name *",
      mode: "dark",
      classes: {
        input: classes.input,
        placeholder: classes.inputPlaceholder
      }
    }))), React.createElement("div", {
      className: "col-span-1"
    }, React.createElement(Field, {
      id: "email",
      label: "email"
    }, React.createElement(TextInput, {
      field: "email",
      id: "email",
      placeholder: "Email *",
      mode: "dark",
      classes: {
        input: classes.input,
        placeholder: classes.inputPlaceholder
      }
    }))), React.createElement("div", {
      className: "col-span-1"
    }, React.createElement(MaskedInput, {
      field: "phone",
      placeholder: "Phone *",
      classes: {
        input: classes.input,
        placeholder: classes.inputPlaceholder
      },
      mode: "dark",
      type: "phone",
      countrySelected: 'US'
    })), React.createElement("div", {
      className: "flex justify-end mt-12"
    }, React.createElement(Button, {
      classes: {
        content: 'text-h6'
      },
      type: "submit",
      size: "large",
      "aria-label": "Send access request data"
    }, "SEND"))));
  }, [success]);
  return React.createElement(Modal, {
    isOpen: isOpen,
    toggleModal: closeModal,
    size: "full",
    classes: {
      fullModal: 'flex justify-center items-center'
    }
  }, React.createElement(Trigger, {
    action: closeModal,
    classes: {
      root: classes.closeBtn
    },
    "aria-label": "Close Access Request Modal"
  }, React.createElement("span", {
    className: "sr-only"
  }, "Close Access Request Modal"), React.createElement(CloseIcon, null)), React.createElement("div", {
    className: classes.root
  }, content));
};

export default AccessRequestModal;