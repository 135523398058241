import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useStyle } from '@magento/venia-ui/lib/classify';
import Image from './Image/image';
import logo from 'src/assets/hf-logo-2022-horiz-new.svg';
import logoSmall from 'src/assets/logo-small.png';
/**
 * A component that renders a logo in the header.
 *
 * @kind functional component
 *
 * @param {props} props React component props
 *
 * @returns {React.Element} A React component that displays a logo.
 */

var Logo = function Logo(props) {
  var height = props.height,
      width = props.width;
  var classes = useStyle({}, props.classes);

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var _useState = useState(window.innerWidth),
      _useState2 = _slicedToArray(_useState, 2),
      windowWidth = _useState2[0],
      setWindowWidth = _useState2[1];

  useEffect(function () {
    window.addEventListener('resize', handleResize);
    return function () {
      return window.removeEventListener('resize', handleResize);
    };
  }, []);

  var handleResize = function handleResize() {
    setWindowWidth(window.innerWidth);
  };

  var isSmallDevice = windowWidth <= 639;
  var isTabletDevice = windowWidth > 1023 && windowWidth < 1279;
  var title = formatMessage({
    id: 'Hubbardton forge',
    defaultMessage: 'Hubbardton forge'
  });
  return React.createElement(Image, {
    alt: 'Hubbardton forge logo',
    classes: {
      container: classes.container,
      image: classes.logo
    },
    height: height,
    src: isSmallDevice || isTabletDevice ? logoSmall : logo,
    title: title,
    width: width
  });
};
/**
 * Props for the Logo component.
 *
 * @kind props
 *
 * @property {Object} classes An object containing the class names for the Logo component.
 * @property {string} classes.logo Classes for logo
 * @property {number} [height=18] Height of the logo.
 * @property {number} [width=102] Width of the logo.
 */


Logo.propTypes = {
  classes: PropTypes.shape({
    logo: PropTypes.string
  }),
  height: PropTypes.number,
  width: PropTypes.number
};
/*Logo.defaultProps = {
  height: 34,
  width: 308
};*/

export default Logo;