// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../node_modules/postcss-loader/dist/cjs.js!./tile.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navButton-root-sWY {\n}\n\n.navButton-icon-tob {\n    /* composes: root from '../Icon/icon.module.css'; */\n}\n\n.navButton-icon_disabled-V7O {\n}\n\n.navButton-icon_disabled-V7O circle {\n    fill: var(--venia-global-color-worldly-gray);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "navButton-root-sWY " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["root"] + "",
	"icon": "navButton-icon-tob",
	"icon_disabled": "navButton-icon_disabled-V7O navButton-icon-tob navButton-root-sWY " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["root"] + ""
};
export default ___CSS_LOADER_EXPORT___;
