import React from 'react';
import { useMergeCssClasses } from '../../Utils/utils';
import defaultClasses from './modalBody.module.css';

var ModalBody = function ModalBody(_ref) {
  var children = _ref.children,
      propClasses = _ref.classes;
  var classes = useMergeCssClasses(defaultClasses, propClasses);
  return React.createElement("div", {
    className: classes.root
  }, children);
};

export default ModalBody;