import React from 'react';
import ViewByCategoryLinks from './viewByCategoryLinks';
import ViewByRoomSettings from './viewByRoomSettings'; // getting childs of categorys and filter items

var getSubmenuLinks = function getSubmenuLinks(data) {
  return data === null || data === void 0 ? void 0 : data.filter(function (item) {
    return item.children.length > 0 && item.children;
  });
};

var SubemenuItems = function SubemenuItems(_ref) {
  var _getSubmenuLinks;

  var items = _ref.items,
      setSubmenuItems = _ref.setSubmenuItems;
  var subItems = (_getSubmenuLinks = getSubmenuLinks(items)) === null || _getSubmenuLinks === void 0 ? void 0 : _getSubmenuLinks.map(function (item, idx) {
    // if (!item.children) return;
    if (item.name === 'View by Room Setting') {
      return React.createElement(ViewByRoomSettings, {
        setSubmenuItems: setSubmenuItems,
        key: idx,
        item: item
      });
    } else {
      return React.createElement(ViewByCategoryLinks, {
        setSubmenuItems: setSubmenuItems,
        key: idx,
        item: item
      });
    }
  });
  return React.createElement(React.Fragment, null, subItems);
};

export default SubemenuItems;