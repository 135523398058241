import React, { Fragment, Suspense } from 'react';
import { shape, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useCartTrigger } from '@magento/peregrine/lib/talons/Header/useCartTrigger';
import { useStyle } from '@magento/venia-ui/lib/classify';
import defaultClasses from './cartTrigger.module.css';
import { GET_ITEM_COUNT_QUERY } from 'src/graphql/cartTrigger.gql';
import { ShopingIcon } from '../../';
import { getCustomerGroup } from '../../../Utils/utils';
var MiniCart = React.lazy(function () {
  return import('@magento/venia-ui/lib/components/MiniCart');
});

var CartTrigger = function CartTrigger(props) {
  var _getCustomerGroup = getCustomerGroup(),
      isComNonPurchasing = _getCustomerGroup.isComNonPurchasing,
      isComPurchasing = _getCustomerGroup.isComPurchasing,
      isComReps = _getCustomerGroup.isComReps,
      isConsumer = _getCustomerGroup.isConsumer;

  var _useCartTrigger = useCartTrigger({
    queries: {
      getItemCountQuery: GET_ITEM_COUNT_QUERY
    }
  }),
      handleLinkClick = _useCartTrigger.handleLinkClick,
      handleTriggerClick = _useCartTrigger.handleTriggerClick,
      itemCount = _useCartTrigger.itemCount,
      miniCartRef = _useCartTrigger.miniCartRef,
      miniCartIsOpen = _useCartTrigger.miniCartIsOpen,
      hideCartTrigger = _useCartTrigger.hideCartTrigger,
      setMiniCartIsOpen = _useCartTrigger.setMiniCartIsOpen,
      miniCartTriggerRef = _useCartTrigger.miniCartTriggerRef;

  var classes = useStyle(defaultClasses, props.classes);

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var buttonAriaLabel = formatMessage({
    id: 'cartTrigger.ariaLabel',
    defaultMessage: 'Toggle mini cart. You have {count} items in your cart.'
  }, {
    count: itemCount
  });
  var itemCountDisplay = itemCount > 99 ? '99+' : itemCount;
  var triggerClassName = miniCartIsOpen ? classes.triggerContainer_open : classes.triggerContainer;
  var maybeItemCounter = itemCount ? React.createElement("span", {
    className: classes.counter
  }, itemCountDisplay) : null;
  var cartTrigger = // Because this button behaves differently on desktop and mobile
  // we render two buttons that differ only in their click handler
  // and control which one displays via CSS.
  React.createElement(Fragment, null, React.createElement("div", {
    className: triggerClassName,
    ref: miniCartTriggerRef
  }, React.createElement("button", {
    "aria-label": buttonAriaLabel,
    className: classes.trigger,
    onClick: handleTriggerClick // Don't change id here. This id is used to trigger mini cart when adding to cart
    ,
    id: "mini-cart-btn"
  }, React.createElement(ShopingIcon, null), maybeItemCounter)), React.createElement("button", {
    "aria-label": buttonAriaLabel,
    className: classes.link,
    onClick: handleLinkClick
  }, React.createElement(ShopingIcon, null), maybeItemCounter), React.createElement(Suspense, {
    fallback: null
  }, React.createElement(MiniCart, {
    isOpen: miniCartIsOpen,
    setIsOpen: setMiniCartIsOpen,
    ref: miniCartRef
  })));
  return cartTrigger;
};

export default CartTrigger;
CartTrigger.propTypes = {
  classes: shape({
    counter: string,
    link: string,
    openIndicator: string,
    root: string,
    trigger: string,
    triggerContainer: string
  })
};