import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

var _templateObject;

var _excluded = ["children"],
    _excluded2 = ["children"],
    _excluded3 = ["children"],
    _excluded4 = ["children"],
    _excluded5 = ["children", "hideStoreName"];
import React, { useMemo, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
export { default as HeadProvider } from './headProvider';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useUserContext } from '@magento/peregrine/lib/context/user';
Helmet.defaultProps.defer = false;
export var Link = function Link(props) {
  var children = props.children,
      tagProps = _objectWithoutProperties(props, _excluded);

  return React.createElement(Helmet, null, React.createElement("link", tagProps, children));
};
export var Meta = function Meta(props) {
  var children = props.children,
      tagProps = _objectWithoutProperties(props, _excluded2);

  return React.createElement(Helmet, null, React.createElement("meta", tagProps, children));
};
export var Style = function Style(props) {
  var children = props.children,
      tagProps = _objectWithoutProperties(props, _excluded3);

  return React.createElement(Helmet, null, React.createElement("style", tagProps, children));
};
export var Title = function Title(props) {
  var children = props.children,
      tagProps = _objectWithoutProperties(props, _excluded4);

  return React.createElement(Helmet, null, React.createElement("title", tagProps, children));
};
var STORE_NAME_QUERY = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query getStoreName {\n        # eslint-disable-next-line @graphql-eslint/require-id-when-available\n        storeConfig {\n            store_code\n            store_name\n        }\n    }\n"])));
export var StoreTitle = function StoreTitle(props) {
  var children = props.children,
      _props$hideStoreName = props.hideStoreName,
      hideStoreName = _props$hideStoreName === void 0 ? false : _props$hideStoreName,
      tagProps = _objectWithoutProperties(props, _excluded5);

  var _useLocation = useLocation(),
      pathname = _useLocation.pathname;

  var _useQuery = useQuery(STORE_NAME_QUERY),
      storeNameData = _useQuery.data;

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      currentUser = _useUserContext2[0].currentUser;

  var storeName = useMemo(function () {
    return storeNameData ? storeNameData.storeConfig.store_name : STORE_NAME;
  }, [storeNameData]);
  var titleText;

  if (children) {
    if (hideStoreName) {
      titleText = children;
    } else {
      titleText = "".concat(children, " - ").concat(storeName);
    }
  } else {
    titleText = storeName;
  }

  useEffect(function () {
    if (titleText && titleText !== 'Hubbardton Forge') {
      var _hsq = window._hsq = window._hsq || [];

      _hsq.push(['setPath', pathname]);

      _hsq.push(['trackPageView']);

      _hsq.push(['identify', {
        email: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.email) || ''
      }]);
    }
  }, [titleText, pathname, currentUser === null || currentUser === void 0 ? void 0 : currentUser.email]);
  useEffect(function () {
    if (children) {
      window.gtag('event', 'page_view', {
        page: {
          page_title: children,
          page_path: window.location.pathname,
          page_location: window.location.href
        }
      });
      window.gtag('js', new Date());
    }
  }, [children]);
  return React.createElement(Helmet, null, React.createElement("title", tagProps, titleText));
};