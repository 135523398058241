import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["dismiss", "isActive", "classes"];
import React from 'react';
import defaultClasses from './mask.module.css';
import { useMergeCssClasses } from '../../../../Utils/utils';
/**
 * A component that masks content.
 *
 * @param {props} props React component props
 * @param {Object} props.classes - CSS classes to override element styles.
 * @param {callback} props.dismiss Handler for the onClick() event
 * @param {callback} props.isActive True if the mask is in an active state. False otherwise.
 * @returns {React.Element} A React component that will mask content.
 *
 */

var Mask = function Mask(props) {
  var dismiss = props.dismiss,
      isActive = props.isActive,
      propClasses = props.classes,
      restProps = _objectWithoutProperties(props, _excluded);

  var classes = useMergeCssClasses(defaultClasses, propClasses);
  var className = isActive ? classes.root_active : classes.root;
  return React.createElement("button", _extends({
    className: className,
    onClick: dismiss
  }, restProps, {
    "aria-label": "Mask"
  }), React.createElement("span", {
    className: "sr-only"
  }, "Mask button"));
};

export default Mask;