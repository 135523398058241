// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slider-sliderWrapper-aMz {\n}\n\n.slider-carousel-hft {\n    width: 100vw;\n    scroll-behavior: smooth;\n    gap: 10px;\n    padding-bottom: 50px;\n}\n\n.slider-carousel-hft::-webkit-scrollbar {\n    display: block;\n    height: 6px;\n}\n\n.slider-prevBtn-3tc,\n.slider-nextBtn-Ci- {\n    top: 35%;\n    transform: translateY(-35%);\n    visibility: hidden;\n    transition: visibility 0.3s ease-out;\n    opacity: 1;\n    z-index: 10;\n}\n\n.slider-prevBtn-3tc:hover,\n.slider-nextBtn-Ci-:hover {\n    opacity: 0.7;\n}\n.slider-prevBtn-3tc {\n}\n\n.slider-nextBtn-Ci- {\n}\n\n.slider-sliderWrapper-aMz:hover .slider-prevBtn-3tc,\n.slider-sliderWrapper-aMz:hover .slider-nextBtn-Ci- {\n    visibility: visible;\n    transition: visibility 0.3s ease-in;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderWrapper": "slider-sliderWrapper-aMz flex items-center justify-evenly relative",
	"carousel": "slider-carousel-hft flex overflow-x-scroll",
	"prevBtn": "slider-prevBtn-3tc absolute left-4",
	"nextBtn": "slider-nextBtn-Ci- absolute right-4"
};
export default ___CSS_LOADER_EXPORT___;
