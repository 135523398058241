// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../node_modules/postcss-loader/dist/cjs.js!./tokens.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n    box-sizing: border-box;\n}\n\nhtml {\n    /* background-color: rgb(var(--venia-global-color-gray-50)); */\n    /* font-size: 100%; */\n    font-size: 1rem;\n    font-weight: var(--venia-global-fontWeight-normal);\n    line-height: var(--venia-global-lineHeight-100);\n    -moz-osx-font-smoothing: grayscale;\n    -webkit-font-smoothing: antialiased;\n    font-family: var(--venia-global-fontFamily-sansSerif);\n}\n\n/*\n * Lock the window's scroll position while an overlay is active.\n * Has no effect in iOS Safari.\n * https://bugs.webkit.org/show_bug.cgi?id=153852\n *\n * :global(html[data-scroll-lock='true'] body) {\n *   overflow: hidden ;\n * }\n */\n\nbody,\nbutton,\ninput,\nselect,\ntextarea {\n    /* color: rgb(var(--venia-global-color-text)); */\n    font-family: var(--venia-global-fontFamily-sansSerif);\n}\n\nbody {\n    margin: 0;\n    padding: 0;\n    font-size: var(--venia-global-fontSize-base);\n    letter-spacing: 0.0487rem;\n}\n\nh1,\nh2,\nh3,\nh4 {\n    font-family: var(--venia-global-fontFamily-kepler);\n}\n\na {\n    color: currentColor;\n    text-decoration: none;\n}\n\np {\n    margin: 0;\n}\n\ndl,\nol,\nul {\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n}\n\ndd,\ndt {\n    margin: 0;\n}\n\nbutton {\n    background: none;\n    border: 0;\n    cursor: pointer;\n    font-size: 100%;\n    padding: 0;\n    touch-action: manipulation;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    -webkit-appearance: none;\n}\n\nbutton:disabled {\n    cursor: default;\n    touch-action: none;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
