import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Check } from 'react-feather';
import { bool, func, shape, string } from 'prop-types';
import classes from './sortItem.module.css';

var SortItem = function SortItem(_ref) {
  var onClick = _ref.onClick,
      sortItem = _ref.sortItem;
  var handleClick = useCallback(function () {
    onClick(sortItem);
  }, [sortItem, onClick]);
  return React.createElement("button", {
    className: classes.root,
    onClick: handleClick,
    "aria-label": "Sort item"
  }, React.createElement("span", {
    className: classes.content
  }, React.createElement("span", {
    className: classes.text
  }, React.createElement(FormattedMessage, {
    id: sortItem.id,
    defaultMessage: sortItem.text
  }))));
};

SortItem.propTypes = {
  active: bool,
  classes: shape({
    content: string,
    root: string,
    text: string
  }),
  onClick: func
};
export default SortItem;