// TODO: Remove this, we should rely on the Magento component
// Once the component is updated, remove this and write logic to collect the tags from the dom in configAggregator
var tags = {
  residential: [{
    label: 'All',
    value: 'all'
  }, {
    label: 'Bar',
    value: 'bar'
  }, {
    label: 'Bath',
    value: 'bath'
  }, {
    label: 'Bedroom',
    value: 'bedroom'
  }, {
    label: 'Dining Room',
    value: 'dining_room'
  }, {
    label: 'Entryway / Hallway',
    value: 'entryway_hallway'
  }, {
    label: 'Game & Media',
    value: 'game_media'
  }, {
    label: 'Kitchen',
    value: 'kitchen'
  }, {
    label: 'Library/Home Office',
    value: 'library_home_office'
  }, {
    label: 'Living Room',
    value: 'living_room'
  }, {
    label: 'Outdoor Living',
    value: 'outdoor_living'
  }],
  commercial: [{
    label: 'All',
    value: 'all'
  }, {
    label: 'Senior Living',
    value: 'senior_living'
  }, {
    label: 'Education',
    value: 'education'
  }, {
    label: 'Conference Room',
    value: 'conference_room'
  }, {
    label: 'Corridor',
    value: 'corridor'
  }, {
    label: 'Guest Room',
    value: 'guest_room'
  }, {
    label: 'Healthcare',
    value: 'healthcare'
  }, {
    label: 'Hospitality',
    value: 'hospitality'
  }, {
    label: 'Lobby',
    value: 'lobby'
  }, {
    label: 'Lounge/Restaurant',
    value: 'lounge_restaurant'
  }, {
    label: 'Multi-Family',
    value: 'multi_family'
  }]
};
export default tags;