import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useWindowSize } from '@magento/peregrine/lib/hooks/useWindowSize';
import classes from './footerLinksGroup.module.css';
import { DownArrow, Accordion } from '../';

var FooterLinksGroup = function FooterLinksGroup(_ref) {
  var footerData = _ref.footerData;

  var _useWindowSize = useWindowSize(),
      innerWidth = _useWindowSize.innerWidth;

  var isSmallDevice = innerWidth < 640; // TODO closing other accordions on click certain accoirdion

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      activeAccordionId = _useState2[0],
      setActiveAccordionID = _useState2[1];

  var handleClick = function handleClick(accordionId) {
    setActiveAccordionID(function (prevState) {
      if (prevState === accordionId) {
        return null;
      } else if (prevState !== accordionId) {
        return accordionId;
      } else {
        return accordionId;
      }
    });
  };

  var linkGroups = footerData === null || footerData === void 0 ? void 0 : footerData.map(function (item, key) {
    var _item$row;

    var links = item === null || item === void 0 ? void 0 : (_item$row = item.row) === null || _item$row === void 0 ? void 0 : _item$row.map(function (rowItem, rowKey) {
      var _rowItem$url, _rowItem$url2;

      return React.createElement("li", {
        key: item.name + '-' + rowKey,
        className: "list-none"
      }, rowItem !== null && rowItem !== void 0 && (_rowItem$url = rowItem.url) !== null && _rowItem$url !== void 0 && _rowItem$url.startsWith('https') ? React.createElement("a", {
        "aria-label": "Go to ".concat(rowItem.name),
        href: rowItem === null || rowItem === void 0 ? void 0 : rowItem.url,
        className: "".concat(classes.footerLink, " leading-28 cursor-pointer"),
        target: "_blank",
        rel: "noopener"
      }, rowItem === null || rowItem === void 0 ? void 0 : rowItem.name) : rowItem !== null && rowItem !== void 0 && (_rowItem$url2 = rowItem.url) !== null && _rowItem$url2 !== void 0 && _rowItem$url2.includes('resources') ? React.createElement(Link, {
        "aria-label": "Go to ".concat(rowItem.name),
        className: "".concat(classes.footerLink, " leading-28 cursor-pointer"),
        to: {
          pathname: '/resources',
          state: {
            from: window.location.pathname
          }
        }
      }, React.createElement(FormattedMessage, {
        id: rowItem.name,
        defaultMessage: rowItem.name
      })) : React.createElement(Link, {
        className: "".concat(classes.footerLink, " leading-28 cursor-pointer"),
        to: rowItem === null || rowItem === void 0 ? void 0 : rowItem.url,
        "aria-label": "Go to ".concat(rowItem.name)
      }, React.createElement(FormattedMessage, {
        id: rowItem.name,
        defaultMessage: rowItem.name
      })));
    });
    return React.createElement("div", {
      key: 'footer-list-' + key,
      className: "overflow-hidden text-sm",
      id: item === null || item === void 0 ? void 0 : item.id,
      onClick: function onClick() {
        return handleClick(item === null || item === void 0 ? void 0 : item.id);
      }
    }, isSmallDevice ? React.createElement(Accordion, {
      title: item.name,
      activeAccordionId: activeAccordionId,
      id: item === null || item === void 0 ? void 0 : item.id,
      openIcon: React.createElement(DownArrow, null),
      closeIcon: React.createElement(DownArrow, null),
      content: React.createElement("ul", null, links),
      classes: {
        title: classes.accordionTitle,
        closeIcon: classes.closeIcon,
        openIcon: classes.openIcon,
        wrapper: 'py-3'
      }
    }) : React.createElement(React.Fragment, null, React.createElement("div", {
      className: "pb-4 font-bold uppercase flex justify-between",
      style: {
        color: '#3e3e3e'
      }
    }, item.name), React.createElement("ul", null, links)));
  });
  return React.createElement(React.Fragment, null, linkGroups);
};

export default FooterLinksGroup;