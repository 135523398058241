import React from 'react';
import { UNCONSTRAINED_SIZE_KEY } from '@magento/peregrine/lib/talons/Image/useImage';
import { Image } from '../../';
import defaultClasses from './favouritesItem.module.css';
import { generateUrl } from '@magento/peregrine/lib/util/imageUtils';
var IMAGE_WIDTH = 300;
var IMAGE_HEIGHT = 375;
var IMAGE_WIDTHS = new Map().set(640, IMAGE_WIDTH).set(UNCONSTRAINED_SIZE_KEY, 840);

var FavouritesItem = function FavouritesItem(_ref) {
  var _derivedSelectedItem$, _derivedSelectedItem$2, _derivedSelectedItem$3, _derivedSelectedItem$4, _derivedSelectedItem$5, _derivedSelectedItem$6, _derivedSelectedItem$7, _derivedSelectedItem$8, _derivedSelectedItem$9, _item$small_image, _item$small_image2;

  var item = _ref.item,
      _ref$derivedSelectedI = _ref.derivedSelectedItem,
      derivedSelectedItem = _ref$derivedSelectedI === void 0 ? false : _ref$derivedSelectedI;
  var derivedImage = (_derivedSelectedItem$ = derivedSelectedItem === null || derivedSelectedItem === void 0 ? void 0 : (_derivedSelectedItem$2 = derivedSelectedItem.configured_variant) === null || _derivedSelectedItem$2 === void 0 ? void 0 : (_derivedSelectedItem$3 = _derivedSelectedItem$2.media_gallery) === null || _derivedSelectedItem$3 === void 0 ? void 0 : (_derivedSelectedItem$4 = _derivedSelectedItem$3[0]) === null || _derivedSelectedItem$4 === void 0 ? void 0 : _derivedSelectedItem$4.url) !== null && _derivedSelectedItem$ !== void 0 ? _derivedSelectedItem$ : derivedSelectedItem === null || derivedSelectedItem === void 0 ? void 0 : (_derivedSelectedItem$5 = derivedSelectedItem.product) === null || _derivedSelectedItem$5 === void 0 ? void 0 : (_derivedSelectedItem$6 = _derivedSelectedItem$5.media_gallery) === null || _derivedSelectedItem$6 === void 0 ? void 0 : (_derivedSelectedItem$7 = _derivedSelectedItem$6[0]) === null || _derivedSelectedItem$7 === void 0 ? void 0 : _derivedSelectedItem$7.url;
  var skuLabel = (derivedSelectedItem === null || derivedSelectedItem === void 0 ? void 0 : derivedSelectedItem.__typename) != 'SimpleProduct' ? 'Base Item' : 'Configured Item';
  var skuValue = !!derivedSelectedItem ? (_derivedSelectedItem$8 = derivedSelectedItem === null || derivedSelectedItem === void 0 ? void 0 : (_derivedSelectedItem$9 = derivedSelectedItem.configured_variant) === null || _derivedSelectedItem$9 === void 0 ? void 0 : _derivedSelectedItem$9.sku) !== null && _derivedSelectedItem$8 !== void 0 ? _derivedSelectedItem$8 : derivedSelectedItem.product.sku : item.sku;
  var wishlistItemImageUrl = derivedImage ? derivedImage : item !== null && item !== void 0 && (_item$small_image = item.small_image) !== null && _item$small_image !== void 0 && _item$small_image.url ? (_item$small_image2 = item.small_image) === null || _item$small_image2 === void 0 ? void 0 : _item$small_image2.url : item === null || item === void 0 ? void 0 : item.small_image;
  return React.createElement("div", {
    className: "flex items-center md_border-b border-solid border-bronze md_py-10 w-full mt-5 md_mt-0"
  }, React.createElement("div", {
    className: defaultClasses.image
  }, React.createElement(Image, {
    src: generateUrl(wishlistItemImageUrl, 'image-product')(IMAGE_WIDTH) // height={IMAGE_HEIGHT}
    ,
    widths: IMAGE_WIDTHS,
    alt: item === null || item === void 0 ? void 0 : item.name,
    classes: {
      image: 'h-full object-scale-down',
      container: 'h-full'
    }
  })), React.createElement("div", {
    className: "flex flex-col ml-2.5 md_ml-5"
  }, React.createElement("p", {
    className: "text-18 md_text-22 font-bold text-black"
  }, item === null || item === void 0 ? void 0 : item.name), React.createElement("p", {
    className: "text-bronze text-sm font-light"
  }, skuLabel, " #", skuValue)));
};

export default FavouritesItem;