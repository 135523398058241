import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useCreateWishlistForm } from '@magento/peregrine/lib/talons/Wishlist/WishlistDialog/CreateWishlistForm/useCreateWishlistForm';
import Button from '@magento/venia-ui/lib/components/Button';
import TextInput from '@magento/venia-ui/lib/components/TextInput';
import { isRequired } from '@magento/venia-ui/lib/util/formValidators';
import FormError from '@magento/venia-ui/lib/components/FormError';
import defaultClasses from './createWishlistForm.module.css';
import { bool, func, shape, string } from 'prop-types';
import { useMergeCssClasses } from 'src/Utils/utils';
import Field from '@magento/venia-ui/lib/components/Field';
import getCustomerGroupData from '../../../../../../hooks/getCustomerGroupData';

var CreateWishlistForm = function CreateWishlistForm(props) {
  var _getCustomerGroupData = getCustomerGroupData(),
      customer_flow_map_group = _getCustomerGroupData.customer_flow_map_group;

  var classes = useMergeCssClasses(defaultClasses, props.classes);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      toggleCreateBtn = _useState2[0],
      setToggleCreateBtn = _useState2[1];

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var talonProps = useCreateWishlistForm({
    onCancel: props.onCancel,
    onCreateList: props.onCreateList,
    isAddLoading: props.isAddLoading
  });
  var formErrors = talonProps.formErrors,
      handleCancel = talonProps.handleCancel,
      handleSave = talonProps.handleSave,
      isSaveDisabled = talonProps.isSaveDisabled;
  var cancelButtonText = formatMessage({
    id: 'createWishlistForm.cancelButton',
    defaultMessage: 'Cancel'
  });
  var saveButtonText = formatMessage({
    id: 'createWishlistForm.Create',
    defaultMessage: 'CREATE'
  });
  var hasProjects = useMemo(function () {
    return customer_flow_map_group !== 'Consumer' && customer_flow_map_group !== 'HF Employee' && customer_flow_map_group !== 'RES Reps' && customer_flow_map_group !== 'COM Reps';
  }, [customer_flow_map_group]);
  return hasProjects ? React.createElement("div", {
    className: "mt-8"
  }, !toggleCreateBtn ? React.createElement(Button, {
    fullWidth: true,
    priority: "normal",
    onClick: function onClick() {
      return setToggleCreateBtn(!toggleCreateBtn);
    },
    "aria-label": "Create a new wishlist",
    classes: {
      root: classes.btnWishlist
    }
  }, "CREATE A NEW LIST") : React.createElement(React.Fragment, null, React.createElement("div", {
    className: "flex items-center flex-col"
  }, React.createElement(Field, {
    id: "listname",
    label: "listname",
    classes: {
      root: 'w-full'
    }
  }, React.createElement(TextInput, {
    id: classes.listname,
    field: "listname",
    validate: isRequired,
    placeholder: "Enter project name"
  })), React.createElement(Button, {
    disabled: isSaveDisabled,
    onClick: handleSave,
    priority: "low",
    type: "button",
    classes: {
      root: classes.createBtn
    },
    "aria-label": "Enter project name"
  }, saveButtonText)), React.createElement(FormError, {
    classes: {
      root: classes.formErrors
    },
    errors: formErrors
  }))) : React.createElement(React.Fragment, null);
};

export default CreateWishlistForm;
CreateWishlistForm.defaultProps = {
  classes: shape({
    actions: string,
    formErrors: string,
    listname: string,
    radioContents: string,
    radioRoot: string,
    visibility: string
  }),
  onCancel: func.isRequired,
  onCreateList: func.isRequired,
  isAddLoading: bool.isRequired
};