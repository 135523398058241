import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
import _extends from "@babel/runtime/helpers/extends";

var _templateObject;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { array, func, oneOfType, shape, string } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { useStyle } from '@magento/venia-ui/lib/classify';
import { fullPageLoadingIndicator } from '@magento/venia-ui/lib/components/LoadingIndicator';
import Block from '@magento/venia-ui/lib/components/CmsBlock/block';
import ErrorView from '@magento/venia-ui/lib/components/ErrorView';
import defaultClasses from './cmsBlock.module.css';

var CmsBlockGroup = function CmsBlockGroup(props) {
  var identifiers = props.identifiers,
      _props$showError = props.showError,
      showError = _props$showError === void 0 ? true : _props$showError;
  var classes = useStyle(defaultClasses, props.classes);

  var _useQuery = useQuery(GET_CMS_BLOCKS, {
    variables: {
      identifiers: identifiers
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  }),
      loading = _useQuery.loading,
      error = _useQuery.error,
      data = _useQuery.data;

  if (!data) {
    if (loading) {
      return fullPageLoadingIndicator;
    }

    if (error && showError) {
      return React.createElement(ErrorView, {
        message: error.message
      });
    }

    if (error && !showError) {
      return null;
    }
  } // TODO: remove this comment


  var items = data.cmsBlocks.items;

  if (!Array.isArray(items) || !items.length) {
    return React.createElement("div", null, React.createElement(FormattedMessage, {
      id: 'cmsBlock.noBlocks',
      defaultMessage: 'There are no blocks to display'
    }));
  }

  var BlockChild = typeof children === 'function' ? children : Block;
  var blocks = items.map(function (item, index) {
    return React.createElement(BlockChild, _extends({
      key: item.identifier,
      className: classes.block,
      index: index
    }, item));
  });
  return React.createElement("div", {
    className: classes.root
  }, React.createElement("div", {
    className: classes.content
  }, blocks));
};

CmsBlockGroup.propTypes = {
  children: func,
  classes: shape({
    block: string,
    content: string,
    root: string
  }),
  identifiers: oneOfType([string, array])
};
export default CmsBlockGroup;
export var GET_CMS_BLOCKS = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query cmsBlocks($identifiers: [String]!) {\n    cmsBlocks(identifiers: $identifiers) {\n      items {\n        content\n        identifier\n      }\n    }\n  }\n"])));