import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;

import { gql } from '@apollo/client';
export var getCategoriesList = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query categoriesList {\n        resourcesCategory {\n            category_id\n            name\n        }\n    }\n"])));
export var getResourcesAll = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query resourcesAll {\n        resourcesAll {\n            category_id\n            name\n            description\n            customer_group\n            sub_category {\n                items {\n                    customer_group\n                    date\n                    description\n                    file_format\n                    file_name\n                    file_size\n                    image\n                    resources_id\n                    url\n                    customer_price_group\n                }\n                name\n                subcategory_id\n            }\n        }\n    }\n"])));
export var requestResources = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    mutation resourcesRequestSubmit(\n        $company: String!\n        $first_name: String!\n        $last_name: String!\n        $project_name: String!\n        $message: String!\n        $role: String!\n        $email: String!\n        $zip: String!\n        $file_type: String!\n        $base_item: String!\n        $consent_collect_data: String!\n    ) {\n        resourcesRequestSend(\n            company: $company\n            first_name: $first_name\n            last_name: $last_name\n            project_name: $project_name\n            message: $message\n            role: $role\n            email: $email\n            zip: $zip\n            file_type: $file_type\n            base_item: $base_item\n            consent_collect_data: $consent_collect_data\n        )\n    }\n"])));
export var getCategoryResources = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    query categoryResources($categoryId: Int!, $subcategoryId: Int!) {\n        resourcesFiles(category_id: $categoryId, subcategory_id: $subcategoryId) {\n            resurces_files {\n                file_name\n                file_size\n                date\n                resources_id\n                url\n                image\n            }\n            subcategory {\n                name\n                subcategory_id\n            }\n        }\n    }\n"])));
export var getCategoryResourcesBySearch = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    query categoryResourcesSearch($categoryId: Int!, $search: String!) {\n        resourcesFilesSearch(category_id: $categoryId, search: $search) {\n            resurces_files {\n                file_name\n                file_size\n                date\n                resources_id\n                url\n                image\n            }\n            subcategory {\n                name\n                subcategory_id\n            }\n        }\n    }\n"])));
export var downloadFiles = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    query downloadFiles($input: DownloadFilesInput!) {\n        downloadFiles(input: $input)\n    }\n"])));
export var getProductResources = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    query productResources($sku: String) {\n        products(filter: { sku: { eq: $sku } }) {\n            items {\n                sku\n                uid\n                hf_internal_images_block\n                hf_installation_instructions\n            }\n        }\n    }\n"])));
export var getResourcesImages = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    query products($sku: String) {\n        products(filter: { sku: { eq: $sku } }) {\n            items {\n                uid\n                id\n                name\n                media_gallery_entries {\n                    file\n                    label\n                    size\n                    file_type\n                }\n                media_gallery {\n                    url\n                }\n            }\n        }\n    }\n"])));
export var GET_PIMCORE_ASSET_HOST = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    query storeConfigData {\n        storeConfig {\n            id\n            store_code\n            pimcore_assets_host\n        }\n    }\n"])));
export default {
  getCategoriesList: getCategoriesList,
  getCategoryResources: getCategoryResources,
  getCategoryResourcesBySearch: getCategoryResourcesBySearch,
  requestResources: requestResources,
  getResourcesAll: getResourcesAll,
  getProductResources: getProductResources,
  downloadFiles: downloadFiles,
  getResourcesImages: getResourcesImages,
  getPimcoreAssetHost: GET_PIMCORE_ASSET_HOST
};