import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { gql } from '@apollo/client';
export var GET_PRODUCT_FILTERS_BY_CATEGORY = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query getProductFiltersByCategory($categoryIdFilter: FilterEqualTypeInput!) {\n        products(filter: { category_uid: $categoryIdFilter }) {\n            aggregations {\n                label\n                count\n                attribute_code\n                options {\n                    label\n                    value\n                    swatch_data {\n                        value\n                        type\n                    }\n                }\n                position\n            }\n        }\n    }\n"])));
export var GET_CATEGORY_CONTENT = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query getCategoryData($id: String!) {\n        categories(filters: { category_uid: { in: [$id] } }) {\n            # eslint-disable-next-line @graphql-eslint/require-id-when-available\n            items {\n                uid\n                name\n                display_mode\n                description\n                url_key\n                url_path\n                meta_title\n                meta_description\n                meta_keywords\n                children {\n                    uid\n                    name\n                    products {\n                        total_count\n                    }\n                    display_mode\n                    description\n                    url_key\n                    url_path\n                    image\n                }\n            }\n        }\n    }\n"])));
export var GET_CATEGORY_AVAILABLE_SORT_METHODS = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    query getCategoryAvailableSortMethods($categoryIdFilter: FilterEqualTypeInput!) {\n        products(filter: { category_uid: $categoryIdFilter }) {\n            sort_fields {\n                options {\n                    label\n                    value\n                }\n            }\n        }\n    }\n"])));
export default {
  getCategoryContentQuery: GET_CATEGORY_CONTENT,
  getProductFiltersByCategoryQuery: GET_PRODUCT_FILTERS_BY_CATEGORY,
  getCategoryAvailableSortMethodsQuery: GET_CATEGORY_AVAILABLE_SORT_METHODS
};