import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { gql } from '@apollo/client';
import { CartTriggerFragment } from '@magento/peregrine/lib/talons/Header/cartTriggerFragments.gql';
import { MiniCartFragment } from '@magento/peregrine/lib/talons/MiniCart/miniCartFragments.gql';
import { WishlistPageFragment } from '@magento/peregrine/lib/talons/WishlistPage/wishlistFragment.gql';
export var ADD_WISHLIST_ITEM_TO_CART = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    mutation AddWishlistItemToCart(\n        $cartId: String!\n        $cartItem: CartItemInput!\n    ) {\n        addProductsToCart(cartId: $cartId, cartItems: [$cartItem]) {\n            cart {\n                id\n                ...CartTriggerFragment\n                ...MiniCartFragment\n            }\n        }\n    }\n    ", "\n    ", "\n"])), CartTriggerFragment, MiniCartFragment);
export var REMOVE_PRODUCTS_FROM_WISHLIST = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    mutation RemoveProductsFromWishlist(\n        $wishlistId: ID!\n        $wishlistItemsId: [ID!]!\n    ) {\n        removeProductsFromWishlist(\n            wishlistId: $wishlistId\n            wishlistItemsIds: $wishlistItemsId\n        ) {\n            wishlist {\n                id\n                ...WishlistPageFragment\n            }\n        }\n    }\n    ", "\n"])), WishlistPageFragment);
export var ADD_PRODUCT_TO_QUOTE = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    mutation addConfigurableProductsToMpQuote(\n        $sku: String!\n        $quantity: Float!\n        $selected_options: [ID]\n    ) {\n        addConfigurableProductsToMpQuote(\n            input: {\n                cart_items: [\n                    {\n                        data: {\n                            quantity: $quantity\n                            sku: $sku\n                            selected_options: $selected_options\n                        }\n                    }\n                ]\n            }\n        ) {\n            quote {\n                entity_id\n                customer_id\n                customer_email\n                items_qty\n                items {\n                    sku\n                    name\n                    price\n                    product {\n                        sku\n                        name\n                    }\n                    ... on ConfigurableQuoteItem {\n                        configurable_options {\n                            option_label\n                            value_label\n                        }\n                    }\n                }\n            }\n        }\n    }\n"])));
export default {
  addWishlistItemToCartMutation: ADD_WISHLIST_ITEM_TO_CART,
  removeProductsFromWishlistMutation: REMOVE_PRODUCTS_FROM_WISHLIST,
  addProductToQuoteMutation: ADD_PRODUCT_TO_QUOTE
};