// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalHeader-root-kDI {\n}\n\n/* .root svg {\n    height: 18px;\n    width: 18px;\n} */\n.modalHeader-root-kDI svg path {\n    stroke: var(--venia-global-color-white);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "modalHeader-root-kDI flex justify-between bg-bronze text-22 text-white"
};
export default ___CSS_LOADER_EXPORT___;
