import React from 'react';
import { bool } from 'prop-types';

var CloseIconWhite = function CloseIconWhite(_ref) {
  var isWhite = _ref.isWhite;
  return React.createElement("svg", {
    width: "20",
    height: "20",
    viewBox: "0 0 20 20",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("g", {
    stroke: "#FFF",
    "stroke-width": "2",
    fill: "none",
    "fill-rule": "evenodd",
    "stroke-linecap": "square"
  }, React.createElement("path", {
    d: "m2.19 1.69 16.264 16.264M2.19 17.954 18.455 1.691"
  })));
};

export default CloseIconWhite;
CloseIconWhite.propTypes = {
  isWhite: bool
};
CloseIconWhite.defaultProps = {
  isWhite: false
};