import React, { useMemo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'informed';
import { shape, string } from 'prop-types';
import { isRequired, isEmailValid } from '@magento/venia-ui/lib/util/formValidators';
import TextInput from '@magento/venia-ui/lib/components/TextInput';
import combine from '@magento/venia-ui/lib/util/combineValidators';
import Button from '../Common/Button/button';
import SelectField from '../Inputs/SelectField/selectField';
import defaultClasses from './newsletter.module.css';
import { useNewsletter } from './useNewsletter';

var Newsletter = function Newsletter(props) {
  var _selectOptions$subscr;

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var _useNewsletter = useNewsletter(),
      errors = _useNewsletter.errors,
      handleSubmit = _useNewsletter.handleSubmit,
      isBusy = _useNewsletter.isBusy,
      setFormApi = _useNewsletter.setFormApi,
      newsLetterResponse = _useNewsletter.newsLetterResponse,
      selectOptions = _useNewsletter.selectOptions;

  var options = [{
    label: 'What best describes you? *',
    value: ''
  }];
  selectOptions === null || selectOptions === void 0 ? void 0 : (_selectOptions$subscr = selectOptions.subscribeOptions) === null || _selectOptions$subscr === void 0 ? void 0 : _selectOptions$subscr.map(function (item) {
    options.push({
      label: item.option,
      value: item.option
    });
  });
  var initialValues = {
    name: '',
    email: '',
    type: ''
  };
  useEffect(function () {
    var script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js?pre=1';
    document.body.appendChild(script);
    script.addEventListener('load', function () {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: process.env.HUBSPOT_PORTAL_ID,
          formId: '801f8aeb-df2d-4264-bcdf-519a9ab141c3',
          target: '#hubspotNewsletter',
          region: 'na1'
        });
      }
    });
  }, []);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "text-sm sm_text-base font-bold mb-6"
  }, React.createElement(FormattedMessage, {
    id: 'newsletter.subtitle',
    defaultMessage: 'STAY UP TO DATE WITH HUBBARDTON FORGE'
  })), React.createElement("div", {
    id: "hubspotNewsletter"
  }));
};

Newsletter.propTypes = {
  classes: shape({
    modal_active: string,
    root: string,
    title: string,
    form: string,
    buttonsContainer: string
  })
};
export default Newsletter;