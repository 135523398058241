// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gallery-gridCollections-OPR {\n}\n\n.gallery-collectionOneItem-Z9u {\n}\n\n.gallery-collectionTwoItems-Yra {\n}\n\n.gallery-collectionThreeItems-kmo {\n}\n\n@media (min-width: 1024px) {\n    .gallery-collectionOneItem-Z9u {\n        width: calc(25% - 15px) !important;\n    }\n\n    .gallery-collectionTwoItems-Yra {\n        width: calc(50% - 10px) !important;\n    }\n\n    .gallery-collectionThreeItems-kmo {\n        width: calc(75% - 5px) !important;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridCollections": "gallery-gridCollections-OPR grid grid-cols-2 lg_grid-cols-4 gap-5",
	"collectionOneItem": "gallery-collectionOneItem-Z9u grid grid-cols-1 gap-5 w-full m-auto",
	"collectionTwoItems": "gallery-collectionTwoItems-Yra grid grid-cols-2 md_grid-cols-2 gap-5 w-full m-auto",
	"collectionThreeItems": "gallery-collectionThreeItems-kmo grid grid-cols-2 md_grid-cols-3 gap-5 w-full m-auto"
};
export default ___CSS_LOADER_EXPORT___;
