import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { COMPANY_ACCESS, COMPANY_CHECK } from '../accountMenu.gql';
export var useAccessRequest = function useAccessRequest() {
  var _useLazyQuery = useLazyQuery(COMPANY_ACCESS),
      _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
      sendAccessRequest = _useLazyQuery2[0],
      _useLazyQuery2$ = _useLazyQuery2[1],
      loading = _useLazyQuery2$.loading,
      error = _useLazyQuery2$.error,
      data = _useLazyQuery2$.data;

  var handleSubmit = useCallback(function (data) {
    var url = new URL(window.location.href);
    data.epicorCompanyId = +url.searchParams.get('epId');
    sendAccessRequest({
      variables: data
    });
  }, []);
  return {
    handleSubmit: handleSubmit,
    data: data,
    loading: loading,
    error: error
  };
};