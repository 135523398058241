import React from 'react';
import { CloseIcon, Modal, ModalBody } from '../../../components';
import Trigger from '@magento/venia-ui/lib/components/Trigger/trigger';
import classes from '../../../pages/SampleRequest/selectedSamplesList.module.css';
import CmsBlock from '../../Cms/cmsBlock';

var InstallationSupportModal = function InstallationSupportModal(_ref) {
  var isOpen = _ref.isOpen,
      closeModal = _ref.closeModal;
  return React.createElement(Modal, {
    isOpen: isOpen,
    toggleModal: closeModal
  }, React.createElement("div", {
    className: "flex justify-end bg-bronze pr-8 pt-8"
  }, React.createElement(Trigger, {
    action: closeModal,
    classes: {
      root: classes.closeButton
    },
    "aria-label": "Close Installation Support modal"
  }, React.createElement("span", {
    className: "sr-only text-white"
  }, "Close modal"), React.createElement(CloseIcon, {
    isWhite: true
  }))), React.createElement(ModalBody, {
    classes: {
      root: 'px-16 bg-bronze text-white'
    }
  }, React.createElement(CmsBlock, {
    identifiers: "footer_installation_support_modal"
  })));
};

export default InstallationSupportModal;