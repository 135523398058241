// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main-root-YLm {\n    position: relative;\n    /* overflow-x: hidden; */\n}\n\n.main-root_masked-YlY {\n}\n\n.main-page-hXb {\n    margin: 0 auto;\n    /* max-width: var(--venia-global-maxWidth); */\n    min-height: 40rem;\n    overflow-y: initial !important;\n}\n\n.main-page_masked-X6Z {\n}\n\n@media (min-height: 640px) {\n    .main-page-hXb {\n        min-height: 38rem;\n    }\n}\n\n@media (min-height: 768px) {\n    .main-page-hXb {\n        min-height: 40rem;\n    }\n}\n\n@media (min-height: 960px) {\n    .main-page-hXb {\n        min-height: 50rem;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "main-root-YLm",
	"root_masked": "main-root_masked-YlY main-root-YLm",
	"page": "main-page-hXb",
	"page_masked": "main-page_masked-X6Z main-page-hXb"
};
export default ___CSS_LOADER_EXPORT___;
