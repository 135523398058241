import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { Fragment, Suspense, useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { shape, string } from 'prop-types';
import { useAccountTrigger } from '@magento/peregrine/lib/talons/Header/useAccountTrigger';
import { useStyle } from '@magento/venia-ui/lib/classify';
import { useUserContext } from '@magento/peregrine/lib/context/user/';
import Icon from '../../Common/Icon/icon';
var AccountMenu = React.lazy(function () {
  return import('./accountMenu');
});
import AccountIcon from '../../../assets/user-icon.svg';
import AccountLoggedInIcon from '../../../assets/user-logged-in-icon.svg';
import defaultClasses from './accountTrigger.module.css';
import AccessRequestModal from './NotLoggedInMenu/accessRequestModal';
/**
 * The AccountTrigger component is the call to action in the site header
 * that toggles the AccountMenu dropdown.
 *
 * @param {Object} props
 * @param {Object} props.classes - CSS classes to override element styles.
 */

var AccountTrigger = function AccountTrigger(props) {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isAccessRequestModalOpen = _useState2[0],
      setisRequestModalOpen = _useState2[1];

  var _useAccountTrigger = useAccountTrigger(),
      accountMenuIsOpen = _useAccountTrigger.accountMenuIsOpen,
      accountMenuRef = _useAccountTrigger.accountMenuRef,
      accountMenuTriggerRef = _useAccountTrigger.accountMenuTriggerRef,
      setAccountMenuIsOpen = _useAccountTrigger.setAccountMenuIsOpen,
      handleTriggerClick = _useAccountTrigger.handleTriggerClick;

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      isUserSignedIn = _useUserContext2[0].isSignedIn; // useEffect(() => {
  //     props?.setAccountMenuIsOpen(accountMenuIsOpen);
  // }, [accountMenuIsOpen]);


  var classes = useStyle(defaultClasses, props.classes);

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var handleToggleModal = function handleToggleModal() {
    return setisRequestModalOpen(function (prevState) {
      return !prevState;
    });
  };

  return React.createElement("div", {
    className: "grid"
  }, React.createElement("button", {
    "aria-label": formatMessage({
      id: 'accountTrigger.ariaLabel',
      defaultMessage: 'Toggle My Account Menu'
    }),
    className: accountMenuIsOpen ? classes.trigger_active : classes.trigger,
    onClick: handleTriggerClick,
    ref: accountMenuTriggerRef
  }, React.createElement(Icon, {
    src: isUserSignedIn ? AccountLoggedInIcon : AccountIcon,
    alt: "Account icon"
  })), React.createElement(Suspense, {
    fallback: null
  }, React.createElement(AccountMenu, {
    ref: accountMenuRef,
    accountMenuIsOpen: accountMenuIsOpen,
    setAccountMenuIsOpen: setAccountMenuIsOpen,
    toggleModal: handleToggleModal,
    handleTriggerClick: handleTriggerClick
  })), isAccessRequestModalOpen && React.createElement(AccessRequestModal, {
    isOpen: isAccessRequestModalOpen,
    closeModal: handleToggleModal
  }));
};

export default AccountTrigger;
AccountTrigger.propTypes = {
  classes: shape({
    root: string,
    root_open: string,
    trigger: string
  })
};