// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loadingIcon-st0-waA {\n    fill-rule: evenodd;\n    clip-rule: evenodd;\n    fill: #1a1919;\n}\n\n.loadingIcon-mainIcon-se9 {\n    max-width: 8rem;\n    max-height: 8rem;\n}\n\n.loadingIcon-st1-Yv3 {\n    fill: none;\n    stroke: #b5b5b5;\n    stroke-width: 1.27;\n    stroke-miterlimit: 10;\n}\n\n.loadingIcon-st3-Izv {\n    fill: none;\n    stroke: #1a1919;\n    stroke-width: 3.4;\n    stroke-miterlimit: 10;\n}\n\n.loadingIcon-st2-iOe {\n    fill: #1a1919;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"st0": "loadingIcon-st0-waA",
	"mainIcon": "loadingIcon-mainIcon-se9",
	"st1": "loadingIcon-st1-Yv3",
	"st3": "loadingIcon-st3-Izv",
	"st2": "loadingIcon-st2-iOe"
};
export default ___CSS_LOADER_EXPORT___;
