import { applyMiddleware } from 'redux';
import auth from '@magento/peregrine/lib/store/middleware/auth';
import log from '@magento/peregrine/lib/store/middleware/log';
import thunk from '@magento/peregrine/lib/store/middleware/thunk';
var middleware = [thunk, auth];

if (process.env.NODE_ENV !== 'production') {
  middleware.push(log);
}

export default applyMiddleware.apply(void 0, middleware);