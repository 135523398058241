// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".favorites-activeBtn-W-B svg g path {\n    fill: var(--venia-global-color-white);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activeBtn": "favorites-activeBtn-W-B"
};
export default ___CSS_LOADER_EXPORT___;
