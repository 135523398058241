import React from 'react';

var InstallationIcon = function InstallationIcon() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "33",
    height: "61",
    viewBox: "0 0 33 61"
  }, React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "#121111",
    strokeWidth: "2"
  }, React.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M16.312 29.5v-28m0 58v-8M1.61 55.921l5.66-5.657m24.012 5.657l-5.66-5.657"
  }), React.createElement("path", {
    d: "M23.7 39.207c-.256 1.197-.964 2.237-1.959 3.036C20.36 43.35 18.433 44 16.311 44c-2.107 0-4.022-.64-5.406-1.698-.987-.755-1.705-1.726-1.977-2.823z"
  }), React.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M9.568 29.823h13.487l8.155 9.363H1.177z"
  })));
};

export default InstallationIcon;