import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { gql } from '@apollo/client';
export var getCollections = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query collections($pageSize: Int!, $currentPage: Int!, $filter_group: Int) {\n        collections(page_size: $pageSize, curent_page: $currentPage, filter_group: $filter_group) {\n            collection_size\n            collections {\n                collection_url\n                collections_id\n                description\n                image\n                name\n            }\n        }\n    }\n"])));
export var getCmsPageMeta = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query collectionCategory($filters: CategoryFilterInput) {\n        categories(filters: $filters) {\n            items {\n                uid\n                id\n                name\n                meta_title\n                meta_description\n                meta_keywords\n            }\n        }\n    }\n"])));
export default {
  getCollections: getCollections,
  getCmsPageMeta: getCmsPageMeta
};