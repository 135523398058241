import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classes from './middleDialog.module.css';
import getCustomerGroupData from '../../../hooks/getCustomerGroupData';

function MiddleDialog(_ref) {
  var setIsWishlistDialogOpen = _ref.setIsWishlistDialogOpen,
      autoClose = _ref.autoClose;

  var _getCustomerGroupData = getCustomerGroupData(),
      customer_flow_map_group = _getCustomerGroupData.customer_flow_map_group;

  useEffect(function () {
    var timeId = setTimeout(function () {
      return autoClose();
    }, 5000);
    return function () {
      return clearTimeout(timeId);
    };
  }, []);
  return React.createElement("div", {
    className: classes.centered
  }, React.createElement("div", {
    className: "flex flex-col md_flex-row justify-between text-black"
  }, React.createElement("p", {
    className: "text-center text-18 font-bold "
  }, "Added product to Favorites"), React.createElement("div", {
    className: "flex justify-around md_justify-between text-black items-center"
  }, React.createElement(Link, {
    to: '/favorites',
    className: "cursor-pointer relative md_mr-6 md_ml-14 leading-160",
    "aria-label": "Go to favorites page"
  }, "View"), customer_flow_map_group !== 'Consumer' && customer_flow_map_group !== 'HF Employee' && customer_flow_map_group !== 'RES Reps' && customer_flow_map_group !== 'COM Reps' && React.createElement("p", {
    className: "cursor-pointer relative leading-160",
    onClick: function onClick() {
      return setIsWishlistDialogOpen(true);
    }
  }, "Add to projects"))));
}

export default MiddleDialog;