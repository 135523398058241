import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { gql } from '@apollo/client';
export var GET_STORE_CONFIG_DATA = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query GetStoreConfigForBreadcrumbs {\n        # eslint-disable-next-line @graphql-eslint/require-id-when-available\n        storeConfig {\n            store_code\n            category_url_suffix\n        }\n    }\n"])));
export var GET_BREADCRUMBS = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query GetBreadcrumbs($category_id: String!) {\n        categories(filters: { category_uid: { in: [$category_id] } }) {\n            # eslint-disable-next-line @graphql-eslint/require-id-when-available\n            items {\n                breadcrumbs {\n                    category_uid\n                    # We may not need level if `breadcrumbs` is sorted.\n                    category_level\n                    category_name\n                    category_url_path\n                }\n                id\n                uid\n                name\n                url_path\n            }\n        }\n    }\n"], ["\n    query GetBreadcrumbs($category_id: String!) {\n        categories(filters: { category_uid: { in: [$category_id] } }) {\n            # eslint-disable-next-line @graphql-eslint/require-id-when-available\n            items {\n                breadcrumbs {\n                    category_uid\n                    # We may not need level if \\`breadcrumbs\\` is sorted.\n                    category_level\n                    category_name\n                    category_url_path\n                }\n                id\n                uid\n                name\n                url_path\n            }\n        }\n    }\n"])));
export default {
  getBreadcrumbsQuery: GET_BREADCRUMBS,
  getStoreConfigQuery: GET_STORE_CONFIG_DATA
};