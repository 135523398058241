import React from 'react';

var QuotesIcon = function QuotesIcon() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "24",
    height: "23",
    viewBox: "0 0 24 23"
  }, React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("text", {
    fill: "#fff",
    fontFamily: "Montserrat-Medium, Montserrat",
    fontSize: "24",
    fontWeight: "400",
    letterSpacing: "1.222",
    transform: "translate(1 1)"
  }, React.createElement("tspan", {
    x: "6.77",
    y: "23.654"
  }, "\u201C")), React.createElement("path", {
    stroke: "#FFF",
    strokeWidth: "1.25",
    d: "M15.566 1.606A10.038 10.038 0 0012.083 1c-5.66 0-10.29 4.725-10.29 10.5 0 1.615.356 3.19 1.068 4.604L1.437 20.95c-.08.283 0 .606.198.808a.774.774 0 00.554.242c.079 0 .158 0 .237-.04l4.512-1.373A10.08 10.08 0 0012.083 22c4.79 0 8.826-3.352 9.974-7.875.801-2.22.801-10.116-6.49-12.52z"
  })));
};

export default QuotesIcon;