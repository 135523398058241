// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quantity-root-vJQ {\n    /* --stroke: var(--venia-global-color-gray-600); */\n    text-align: center;\n}\n\n.quantity-label-Q3m:not(:focus):not(:active) {\n    position: absolute;\n    overflow: hidden;\n    width: 1px;\n    height: 1px;\n    clip: rect(0 0 0 0);\n    -webkit-clip-path: inset(100%);\n            clip-path: inset(100%);\n    white-space: nowrap;\n}\n\n.quantity-qtyLabel-rGu {\n    text-align: left;\n}\n\n.quantity-button-7ka {\n    align-items: center;\n    display: inline-flex;\n    width: 40px;\n    height: 40px;\n    justify-content: center;\n}\n\n.quantity-button-7ka:hover {\n    --stroke: rgb(var(--venia-global-color-gray-900));\n    border-color: rgb(var(--stroke));\n}\n\n.quantity-button-7ka:disabled,\n.quantity-button-7ka:disabled:hover {\n    --stroke: rgb(var(--venia-global-color-gray-400));\n    border-color: rgb(var(--venia-global-color-gray-400));\n}\n\n.quantity-button-7ka:disabled {\n    cursor: not-allowed;\n    background-color: var(--venia-global-color-urbane-bronze);\n    opacity: 0.4;\n}\n\n.quantity-button-7ka:focus {\n    outline: none;\n}\n\n.quantity-button_decrement-O1- {\n}\n\n.quantity-button_increment-g-- {\n}\n\n.quantity-icon-tpt {\n    align-items: center;\n    display: inline-flex;\n    justify-content: center;\n    touch-action: manipulation;\n}\n\n.quantity-input-7J9 {\n    height: 40px;\n    padding-left: 0;\n}\n\n.quantity-inputWidth-2Uj {\n    width: 6rem;\n}\n\n@media (max-width: 1280px) {\n    .quantity-inputWidth-2Uj {\n        width: 50px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "quantity-root-vJQ flex justify-between",
	"label": "quantity-label-Q3m",
	"qtyLabel": "quantity-qtyLabel-rGu",
	"button": "quantity-button-7ka bg-bronze",
	"button_decrement": "quantity-button_decrement-O1- quantity-button-7ka bg-bronze mr-4 lg_mr-2 xl_mr-4",
	"button_increment": "quantity-button_increment-g-- quantity-button-7ka bg-bronze ml-4 lg_ml-2 xl_ml-4",
	"icon": "quantity-icon-tpt",
	"input": "quantity-input-7J9 border border-solid border-gray text-center py-0 mr-4",
	"inputWidth": "quantity-inputWidth-2Uj"
};
export default ___CSS_LOADER_EXPORT___;
