import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { gql } from '@apollo/client';
export var GET_STORE_CONFIG_DATA = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query GetStoreConfigForMegaMenu {\n    storeConfig {\n      id\n      store_code\n      category_url_suffix\n    }\n  }\n"])));
export var GET_MEGA_MENU = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query getMegaMenu {\n    categoryList {\n      id\n      uid\n      name\n      title_color\n      children {\n        id\n        uid\n        include_in_menu\n        name\n        position\n        url_path\n        image\n        title_color\n        children {\n          id\n          uid\n          include_in_menu\n          name\n          position\n          url_path\n          image\n          title_color\n          children {\n            id\n            uid\n            include_in_menu\n            name\n            position\n            url_path\n            title_color\n            children {\n              id\n              uid\n              include_in_menu\n              name\n              position\n              url_path\n              title_color\n            }\n          }\n        }\n      }\n    }\n  }\n"])));
export default {
  getMegaMenuQuery: GET_MEGA_MENU,
  getStoreConfigQuery: GET_STORE_CONFIG_DATA
};