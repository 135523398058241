// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".productSliderItem-image-KDR {\n    -o-object-fit: scale-down;\n       object-fit: scale-down;\n}\n\n.productSliderItem-skuDimensionsWrapper-tsF {\n    margin-top: 0.25rem;\n    margin-bottom: 0.25rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    font-weight: 300;\n    line-height: 1.71;\n    color: var(--venia-global-color-urbane-bronze);\n}\n\n@media (min-width: 768px) {\n\n    .productSliderItem-skuDimensionsWrapper-tsF {\n        display: block;\n    }\n}\n\n@media (min-width: 1280px) {\n\n    .productSliderItem-skuDimensionsWrapper-tsF {\n        display: flex;\n    }\n\n    .productSliderItem-skuDimensionsWrapper-tsF {\n        justify-content: space-between;\n    }\n}\n\n.productSliderItem-skuDimensionsWrapper-tsF p {\n    line-height: 1.5;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "productSliderItem-image-KDR h-full",
	"skuDimensionsWrapper": "productSliderItem-skuDimensionsWrapper-tsF"
};
export default ___CSS_LOADER_EXPORT___;
