// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sortItem-root-BLo {\n}\n\n.sortItem-content-q-a {\n  align-items: center;\n  display: grid;\n  gap: 0.75rem;\n  grid-auto-flow: column;\n  grid-template-columns: 1fr;\n  height: 2.5rem;\n  padding: 0 0.75rem;\n  width: 100%;\n}\n\n.sortItem-text-mgR {\n  text-align: left;\n  white-space: nowrap;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "sortItem-root-BLo flex items-center w-full",
	"content": "sortItem-content-q-a",
	"text": "sortItem-text-mgR"
};
export default ___CSS_LOADER_EXPORT___;
