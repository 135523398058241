// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subMenuColumn-submenuColumn--y8 {\n}\n\n.subMenuColumn-submenuColumn_active-i6V {\n    /* TODO this is not good, ask Marija for color of text on hoverl */\n    color: #121111;\n}\n\n.subMenuColumn-submenuColumn_active-i6V::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: -34px;\n    height: 100%;\n    width: 10px;\n    background-color: var(--venia-global-color-worldly-gray);\n}\n\n.subMenuColumn-link-ut9 {\n    white-space: nowrap;\n}\n\n.subMenuColumn-link-ut9:hover {\n    font-weight: 600;\n}\n\n.subMenuColumn-submenuChild-qHh {\n    margin-top: 21px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submenuColumn": "subMenuColumn-submenuColumn--y8 relative text-sm py-2 leading-171",
	"submenuColumn_active": "subMenuColumn-submenuColumn_active-i6V subMenuColumn-submenuColumn--y8 relative text-sm py-2 leading-171",
	"link": "subMenuColumn-link-ut9 text-bronze",
	"submenuChild": "subMenuColumn-submenuChild-qHh"
};
export default ___CSS_LOADER_EXPORT___;
