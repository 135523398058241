import _extends from "@babel/runtime/helpers/extends";
import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { Fragment, useState } from 'react';
import { shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { useHeader } from '@magento/peregrine/lib/talons/Header/useHeader';
import { useStyle } from '@magento/venia-ui/lib/classify';
import resourceUrl from '@magento/peregrine/lib/util/makeUrl';
import OnlineIndicator from '@magento/venia-ui/lib/components/Header/onlineIndicator';
import { useWindowSize } from '@magento/peregrine/lib/hooks/useWindowSize';
import { useUserContext } from '@magento/peregrine/lib/context/user';
import { useSelector } from 'react-redux';
import AccountTrigger from './Account/accountTrigger';
import SearchTrigger from './Search/searchTrigger';
import CartTrigger from './Cart/cartTrigger';
import Logo from '../Common/logo';
import MegaMenu from './MegaMenu/megaMenu';
import FavTrigger from './Favourite/favTrigger';
import defaultClasses from './header.module.css';
import NavTrigger from './navTrigger';
import Navigation from '../Navigation/navigation';
import SearchModal from '../Search/searchModal';
import { useKlevuSearch } from '../Search/searchBarKlevuWrapper';
import ResourcesTabs from './ResourcesTabs';
import { getCustomerGroup } from '../../Utils/utils';
import QuoteMiniCart from './QuoteMiniCart';
import { useAppContext } from '@magento/peregrine/lib/context/app';

var Header = function Header(props) {
  var _resourcesAllLinks$;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isSearchOpen = _useState2[0],
      setIsSearchOpen = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isHoveredTopLinks = _useState4[0],
      setIsHoveredTopLinks = _useState4[1];

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      _useUserContext2$ = _useUserContext2[0],
      isUserSignedIn = _useUserContext2$.isSignedIn,
      currentUser = _useUserContext2$.currentUser;

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      accountMenuIsOpen = _useState6[0],
      setAccountMenuIsOpen = _useState6[1];

  var _useHeader = useHeader(),
      handleSearchTriggerClick = _useHeader.handleSearchTriggerClick,
      hasBeenOffline = _useHeader.hasBeenOffline,
      isOnline = _useHeader.isOnline;

  var _useAppContext = useAppContext(),
      _useAppContext2 = _slicedToArray(_useAppContext, 2);

  _objectDestructuringEmpty(_useAppContext2[0]);

  var closeDrawer = _useAppContext2[1].closeDrawer;
  var classes = useStyle(defaultClasses, props.classes);
  var rootClass = classes.closed;

  var _useWindowSize = useWindowSize(),
      innerWidth = _useWindowSize.innerWidth;

  var _getCustomerGroup = getCustomerGroup(),
      isComNonPurchasing = _getCustomerGroup.isComNonPurchasing,
      isComPurchasing = _getCustomerGroup.isComPurchasing,
      isComReps = _getCustomerGroup.isComReps;

  var resourcesAllLinks = useSelector(function (state) {
    return state.resources.resourcesLinks;
  });
  var customerGroupId = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.group_uid) !== 0 ? window.atob(currentUser === null || currentUser === void 0 ? void 0 : currentUser.group_uid) : 0;

  var _useKlevuSearch = useKlevuSearch({
    isSignedIn: isUserSignedIn,
    customerGroupId: customerGroupId
  }),
      searchValue = _useKlevuSearch.searchValue,
      setSearchValue = _useKlevuSearch.setSearchValue,
      onSearchChange = _useKlevuSearch.onSearchChange,
      params = _useKlevuSearch.params,
      products = _useKlevuSearch.products,
      categories = _useKlevuSearch.categories,
      suggestions = _useKlevuSearch.suggestions,
      doSearch = _useKlevuSearch.doSearch,
      trendingProducts = _useKlevuSearch.trendingProducts,
      settings = _useKlevuSearch.settings;

  return React.createElement(Fragment, null, React.createElement("div", {
    className: "".concat(classes.topLinks, " ").concat(isHoveredTopLinks ? classes.topLinksHover : '')
  }, React.createElement("div", {
    className: classes.switchers,
    style: {
      zIndex: accountMenuIsOpen ? '10' : '110'
    }
  }, React.createElement(Link, {
    to: "/po-tracker",
    className: "pr-4 text-white",
    "aria-label": "Go to PO Tracker page"
  }, "PO Tracker"), React.createElement("div", {
    className: classes.resources,
    onMouseOver: function onMouseOver() {
      return setIsHoveredTopLinks(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHoveredTopLinks(false);
    }
  }, React.createElement(Link, {
    to: "/resources?tab=".concat(resourcesAllLinks === null || resourcesAllLinks === void 0 ? void 0 : (_resourcesAllLinks$ = resourcesAllLinks[0]) === null || _resourcesAllLinks$ === void 0 ? void 0 : _resourcesAllLinks$.name),
    className: "pr-4 pl-8 text-white",
    "aria-label": "Go to Resources page"
  }, "Resources"), React.createElement(ResourcesTabs, null)), React.createElement(Link, {
    to: "/outlet",
    className: classes.outletLink,
    "aria-label": "Go to Outlet page"
  }, "Outlet"))), React.createElement("header", {
    className: "".concat(rootClass, " sticky")
  }, React.createElement("div", {
    className: innerWidth > 1024 ? 'container' : 'pl-6'
  }, React.createElement("div", {
    className: classes.toolbar
  }, React.createElement(OnlineIndicator, {
    hasBeenOffline: hasBeenOffline,
    isOnline: isOnline
  }), React.createElement(Link, {
    to: resourceUrl('/'),
    className: classes.logoContainer,
    onClick: function onClick() {
      return closeDrawer();
    },
    "aria-label": "Go to Home page"
  }, React.createElement(Logo, {
    classes: {
      logo: classes.logo,
      container: classes.container
    }
  })), React.createElement(MegaMenu, null), React.createElement("div", {
    className: classes.secondaryActions
  }, React.createElement(SearchTrigger, {
    onClick: function onClick() {
      return setIsSearchOpen(true);
    }
  }), React.createElement(AccountTrigger, {
    setAccountMenuIsOpen: setAccountMenuIsOpen
  }), isUserSignedIn && innerWidth > 768 && React.createElement(FavTrigger, null), (isComNonPurchasing || isComPurchasing || isComReps) && React.createElement(QuoteMiniCart, null), React.createElement(CartTrigger, null)), React.createElement("div", {
    className: classes.primaryActions
  }, React.createElement(NavTrigger, null))), isSearchOpen && React.createElement(SearchModal, _extends({}, params, {
    isOpen: isSearchOpen,
    closeModal: function closeModal() {
      return setIsSearchOpen(false);
    },
    setSearchValue: setSearchValue,
    searchValue: searchValue,
    onSearchChange: onSearchChange,
    products: products,
    suggestions: suggestions,
    categories: categories,
    doSearch: doSearch,
    trendingProducts: trendingProducts,
    settings: settings,
    handleSearchTriggerClick: handleSearchTriggerClick
  })))), React.createElement(Navigation, null));
};

Header.propTypes = {
  classes: shape({
    closed: string,
    logo: string,
    open: string,
    primaryActions: string,
    secondaryActions: string,
    toolbar: string,
    switchers: string,
    switchersContainer: string
  })
};
export default Header;