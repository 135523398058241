import React from 'react';
import defaultClasses from './customCheckbox.module.css';
import { useMergeCssClasses } from '../../../Utils/utils';
import { CheckIcon } from '../../';

function CustomCheckbox(_ref) {
  var checked = _ref.checked,
      onClick = _ref.onClick,
      customClasses = _ref.customClasses,
      _ref$showLabel = _ref.showLabel,
      showLabel = _ref$showLabel === void 0 ? false : _ref$showLabel,
      label = _ref.label;
  var classes = useMergeCssClasses(defaultClasses, customClasses);
  var checkBoxClass = checked ? classes.root_checked : classes.root;
  return React.createElement("div", {
    className: classes.checkboxWrapper
  }, React.createElement("div", {
    className: checkBoxClass,
    onClick: onClick
  }, checked && React.createElement(CheckIcon, null)), showLabel ? React.createElement("h6", {
    className: classes.labelText
  }, label) : null);
}

export default CustomCheckbox;