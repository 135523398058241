import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useMemo } from 'react';
import ContentTypeFactory from '@magento/pagebuilder/lib/factory';
import parseStorageHtml from '@magento/pagebuilder/lib/parseStorageHtml';
import { useUserContext } from '@magento/peregrine/lib/context/user';
/**
 * Page Builder component for rendering Page Builder master storage format in React
 *
 * @param data
 * @returns {*}
 * @constructor
 */

export default function PageBuilder(_ref) {
  var html = _ref.html,
      classes = _ref.classes;

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      isSignedIn = _useUserContext2[0].isSignedIn;

  var data = useMemo(function () {
    return parseStorageHtml(html);
  }, [html]);
  return data.children.map(function (child, i) {
    var _child$children, _child$children$, _child$children2, _child$children2$;

    if ((child === null || child === void 0 ? void 0 : (_child$children = child.children) === null || _child$children === void 0 ? void 0 : (_child$children$ = _child$children[0]) === null || _child$children$ === void 0 ? void 0 : _child$children$.classes) === 'login-btn' && isSignedIn) return React.createElement(React.Fragment, null);else if ((child === null || child === void 0 ? void 0 : (_child$children2 = child.children) === null || _child$children2 === void 0 ? void 0 : (_child$children2$ = _child$children2[0]) === null || _child$children2$ === void 0 ? void 0 : _child$children2$.classes) === 'login-btn' && !isSignedIn) {
      return React.createElement("div", {
        className: "login-btn"
      }, "Login");
    }
    return React.createElement("div", {
      className: classes.root,
      key: i
    }, React.createElement(ContentTypeFactory, {
      data: child
    }));
  });
}