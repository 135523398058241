// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../../node_modules/postcss-loader/dist/cjs.js!../../clickable.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cartTrigger-counter-CXV {\n    border-radius: 50%;\n    height: 14px;\n    width: 14px;\n    font-size: 0.6875rem;\n    line-height: 1.5;\n    bottom: 5px;\n    right: 5px;\n}\n\n.cartTrigger-trigger-bcf {\n    align-content: center;\n    display: flex;\n    height: 3rem;\n    justify-content: center;\n    position: relative;\n    z-index: 1;\n}\n\n.cartTrigger-triggerContainer-dYv {\n    align-items: center;\n    display: grid;\n    /* The full height of the site header minus (box shadow height * 2). */\n    height: calc(5rem - 8px);\n}\n\n.cartTrigger-triggerContainer_open-YdD {\n    box-shadow: 0 4px rgb(var(--venia-brand-color-1-700));\n}\n\n.cartTrigger-triggerContainer_open-YdD::after {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    background-color: var(--venia-global-color-red);\n    width: 42px;\n    height: 4px;\n}\n\n.cartTrigger-link-NdS {\n    display: none;\n}\n\n/*\n * Mobile-specific styles.\n */\n@media (max-width: 960px) {\n    .cartTrigger-triggerContainer-dYv {\n        display: none;\n    }\n\n    .cartTrigger-link-NdS {\n        display: flex;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"counter": "cartTrigger-counter-CXV absolute bg-red flex items-center justify-center",
	"trigger": "cartTrigger-trigger-bcf " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["root"] + "",
	"triggerContainer": "cartTrigger-triggerContainer-dYv",
	"triggerContainer_open": "cartTrigger-triggerContainer_open-YdD cartTrigger-triggerContainer-dYv",
	"link": "cartTrigger-link-NdS cartTrigger-trigger-bcf " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["root"] + ""
};
export default ___CSS_LOADER_EXPORT___;
