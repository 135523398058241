import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { gql } from '@apollo/client';
import { DiscountSummaryFragment } from '@magento/peregrine/lib/talons/CartPage/PriceSummary/discountSummary.gql';
import { GiftCardSummaryFragment } from '@magento/peregrine/lib/talons/CartPage/PriceSummary/queries/giftCardSummary';
import { GiftOptionsSummaryFragment } from '@magento/peregrine/lib/talons/CartPage/PriceSummary/queries/giftOptionsSummary';
import { ShippingSummaryFragment } from '@magento/peregrine/lib/talons/CartPage/PriceSummary/shippingSummary.gql';
import { TaxSummaryFragment } from '@magento/peregrine/lib/talons/CartPage/PriceSummary/taxSummary.gql';
export var GrandTotalFragment = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    fragment GrandTotalFragment on CartPrices {\n        grand_total {\n            currency\n            value\n        }\n    }\n"])));
export var PriceSummaryFragment = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    fragment PriceSummaryFragment on Cart {\n        id\n        # eslint-disable-next-line @graphql-eslint/require-id-when-available\n        items {\n            uid\n            quantity\n        }\n        ...ShippingSummaryFragment\n        prices {\n            ...TaxSummaryFragment\n            ...DiscountSummaryFragment\n            ...GrandTotalFragment\n            subtotal_excluding_tax {\n                currency\n                value\n            }\n            subtotal_including_tax {\n                currency\n                value\n            }\n        }\n        ...GiftCardSummaryFragment\n        ...GiftOptionsSummaryFragment\n    }\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n"])), DiscountSummaryFragment, GiftCardSummaryFragment, GiftOptionsSummaryFragment, GrandTotalFragment, ShippingSummaryFragment, TaxSummaryFragment);