import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSubMenu } from '@magento/peregrine/lib/talons/MegaMenu/useSubMenu';
import { useStyle } from '@magento/venia-ui/lib/classify';
import defaultClasses from './subMenu.module.css';
import SubmenuColumn from './subMenuColum';
import SubmenuItems from './submenuItems';
import Image from '../../../Common/Image/image';
/**
 * The Submenu component displays submenu in mega menu
 *
 * @param {array} props.items - categories to display
 * @param {int} props.mainNavWidth - width of the main nav. It's used for setting min-width of the submenu
 * @param {function} props.onNavigate - function called when clicking on Link
 */

var Submenu = function Submenu(props) {
  var _props$items;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      submenuItems = _useState2[0],
      setSubmenuItems = _useState2[1];

  var items = props.items,
      isFocused = props.isFocused,
      subMenuState = props.subMenuState,
      handleCloseSubMenu = props.handleCloseSubMenu,
      categoryUrlSuffix = props.categoryUrlSuffix,
      onNavigate = props.onNavigate;
  var children = items.children,
      subMenuImage = items.image;
  var classes = useStyle(defaultClasses, props.classes);
  var talonProps = useSubMenu({
    isFocused: isFocused,
    subMenuState: subMenuState,
    handleCloseSubMenu: handleCloseSubMenu
  });

  var handleMouseLeave = function handleMouseLeave() {
    var menu = _toConsumableArray(submenuItems === null || submenuItems === void 0 ? void 0 : submenuItems.map(function (item) {
      return _objectSpread(_objectSpread({}, item), {}, {
        isActive: false
      });
    }));

    if (menu !== null && menu !== void 0 && menu.length) menu[0].isActive = true;
    setSubmenuItems(menu);
  };

  useEffect(function () {
    var newItems = children.map(function (item) {
      if (item.children.length) {
        if (children[0] === item) return _objectSpread(_objectSpread({}, item), {}, {
          isActive: true
        });else return item;
      } else return item;
    });
    setSubmenuItems(newItems);
  }, [children]);
  var isSubMenuActive = talonProps.isSubMenuActive; // changing local state on haver, and set active link for displaying right links

  var handleSubmenuColumnHover = function handleSubmenuColumnHover(categoryId) {
    return setSubmenuItems(function (prevState) {
      return prevState.map(function (item) {
        return item.id === categoryId ? _objectSpread(_objectSpread({}, item), {}, {
          isActive: true
        }) : _objectSpread(_objectSpread({}, item), {}, {
          isActive: false
        });
      });
    });
  };

  var subMenuClassname = isSubMenuActive ? classes.submenu_active : classes.submenu; //  links of categorys and filters ( left part of submenu)

  var subMenusLeftItems = submenuItems === null || submenuItems === void 0 ? void 0 : submenuItems.map(function (category, index) {
    var keyboardProps = index === children.length - 1 ? talonProps.keyboardProps : {};
    return React.createElement(SubmenuColumn, {
      index: index,
      keyboardProps: keyboardProps,
      key: category.id,
      category: category,
      categoryUrlSuffix: categoryUrlSuffix,
      onNavigate: onNavigate,
      isActive: category.isActive,
      handleHoverLink: handleSubmenuColumnHover,
      setSubmenuItems: setSubmenuItems
    });
  });
  var subManyImg = submenuItems === null || submenuItems === void 0 ? void 0 : submenuItems.map(function (category, index) {
    var subImage;

    if (category !== null && category !== void 0 && category.image && category !== null && category !== void 0 && category.isActive) {
      subImage = category.image;
    } else {
      subImage = subMenuImage;
    }

    return subImage && (category === null || category === void 0 ? void 0 : category.isActive) && React.createElement("div", {
      key: index,
      className: classes.imagePlaceholder
    }, React.createElement(Image, {
      src: subImage,
      alt: "Product categories image placeholder"
    }));
  });
  var productDropdownStyle = ((_props$items = props.items) === null || _props$items === void 0 ? void 0 : _props$items.url_path) === 'products' ? classes.submenuProduct : '';
  return React.createElement("div", {
    className: "".concat(subMenuClassname, " ").concat(productDropdownStyle),
    onMouseLeave: function onMouseLeave() {
      return handleMouseLeave();
    }
  }, React.createElement("div", {
    className: classes.submenuItemsWrapper
  }, subMenusLeftItems, subManyImg), React.createElement(SubmenuItems, {
    setSubmenuItems: setSubmenuItems,
    handleMouseLeave: handleMouseLeave,
    items: submenuItems
  }));
};

export default Submenu;
Submenu.propTypes = {
  items: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({
      children: PropTypes.array.isRequired,
      id: PropTypes.number.isRequired,
      include_in_menu: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      path: PropTypes.array.isRequired,
      position: PropTypes.number.isRequired,
      url_path: PropTypes.string.isRequired
    })),
    image: PropTypes.string
  }).isRequired,
  // mainNavWidth: PropTypes.number.isRequired,
  categoryUrlSuffix: PropTypes.string,
  onNavigate: PropTypes.func.isRequired
};