import api from "!../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../../node_modules/postcss-loader/dist/cjs.js!./slider.module.css";

var options = {"injectType":"singletonStyleTag"};

options.insert = "head";
options.singleton = true;

var update = api(content, options);



export default content.locals || {};