import { useQuery } from '@apollo/client';
import { GET_MEGA_MENU } from '../../components/Header/MegaMenu/megaMenu.gql';
/**
 *
 * @param {*} props.operations GraphQL operations used by talons
 */

export var useFilterCategories = function useFilterCategories() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _useQuery = useQuery(GET_MEGA_MENU, {
    variables: {
      id: 'NA=='
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only'
  }),
      loading = _useQuery.loading,
      error = _useQuery.error,
      data = _useQuery.data;

  return {
    loading: loading,
    error: error,
    data: data && data.categoryList[0].children[0]
  };
};