import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useMemo, useReducer } from 'react';
import withLogger from '@magento/peregrine/lib/util/withLogger';

var init = function init(next) {
  return next instanceof Map ? next : new Map();
};

var reducer = function reducer(state, action) {
  var payload = action.payload,
      type = action.type;

  switch (type) {
    case 'clear':
      {
        return init();
      }

    case 'add item':
      {
        var group = payload.group,
            item = payload.item;
        var nextState = new Map(state);
        var nextSet = new Set(state.get(group));
        nextSet.add(item);
        nextState.set(group, nextSet);
        return nextState;
      }

    case 'remove item':
      {
        var _group = payload.group,
            _item = payload.item;

        var _nextState = new Map(state);

        var _nextSet = new Set(state.get(_group));

        _nextSet["delete"](_item); // if removing an item leaves a group empty, delete that group


        if (_nextSet.size) {
          _nextState.set(_group, _nextSet);
        } else {
          _nextState["delete"](_group);
        }

        return _nextState;
      }

    case 'remove group':
      {
        var _group2 = payload.group;

        var _nextState2 = new Map(state);

        _nextState2["delete"](_group2);

        return _nextState2;
      }

    case 'toggle item':
      {
        var _group3 = payload.group,
            _item2 = payload.item;

        var _nextState3 = new Map(state);

        var _nextSet2 = new Set(state.get(_group3));

        if (_nextSet2.has(_item2)) {
          _nextSet2["delete"](_item2);
        } else {
          _nextSet2.add(_item2);
        } // if removing an item leaves a group empty, delete that group


        if (_nextSet2.size) {
          _nextState3.set(_group3, _nextSet2);
        } else {
          _nextState3["delete"](_group3);
        } // console.log(nextState);


        return _nextState3;
      }

    case 'set input range':
      {
        var _group4 = payload.group,
            _item3 = payload.item;

        var _nextState4 = new Map(state);

        var _nextSet3 = new Set(state.get(_group4));

        _nextSet3.add(_item3);

        _nextState4.set(_group4, _nextSet3);

        return _nextState4;
      }

    case 'set items':
      {
        return init(payload);
      }
  }
};

var wrappedReducer = withLogger(reducer);
export var useFilterState = function useFilterState() {
  var _useReducer = useReducer(wrappedReducer, null, init),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      state = _useReducer2[0],
      dispatch = _useReducer2[1];

  var addItem = useCallback(function (payload) {
    dispatch({
      payload: payload,
      type: 'add item'
    });
  }, [dispatch]);
  var clear = useCallback(function (payload) {
    dispatch({
      payload: payload,
      type: 'clear'
    });
  }, [dispatch]);
  var removeItem = useCallback(function (payload) {
    dispatch({
      payload: payload,
      type: 'remove item'
    });
  }, [dispatch]);
  var removeGroup = useCallback(function (payload) {
    dispatch({
      payload: payload,
      type: 'remove group'
    });
  }, [dispatch]);
  var setItems = useCallback(function (payload) {
    dispatch({
      payload: payload,
      type: 'set items'
    });
  }, [dispatch]);
  var toggleItem = useCallback(function (payload) {
    dispatch({
      payload: payload,
      type: 'toggle item'
    });
  }, [dispatch]);
  var setRangeInput = useCallback(function (payload) {
    dispatch({
      payload: payload,
      type: 'set input range'
    });
  }, [dispatch]);
  var api = useMemo(function () {
    return {
      addItem: addItem,
      clear: clear,
      dispatch: dispatch,
      removeItem: removeItem,
      removeGroup: removeGroup,
      setItems: setItems,
      toggleItem: toggleItem,
      setRangeInput: setRangeInput
    };
  }, [addItem, clear, dispatch, removeItem, removeGroup, setItems, toggleItem, setRangeInput]);
  return [state, api];
};