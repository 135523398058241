import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useLayoutEffect, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useStyle } from '@magento/venia-ui/lib/classify';
import { arrayOf, oneOf, shape, string } from 'prop-types';
import resourceUrl from '@magento/peregrine/lib/util/makeUrl';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from '@magento/peregrine/lib/hooks/useWindowSize';
import defaultClasses from './column.module.css';
import CmsVideoSection from '../../components/CmsVideoSection';
var matchMedia = globalThis.matchMedia; // this is width od left margine on load page

/**
 * Page Builder Column component.
 *
 * This component is part of the Page Builder / PWA integration. It can be consumed without Page Builder.
 *
 * @typedef Column
 * @kind functional component
 *
 * @param {props} props React component props
 *
 * @returns {React.Element} A React component that displays a Column.
 */

var Column = function Column(props) {
  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      customMarginLeft = _useState2[0],
      setCustomMarginLeft = _useState2[1];

  var classes = useStyle(defaultClasses, props.classes);

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      bgImageStyle = _useState4[0],
      setBgImageStyle = _useState4[1];

  var columnElement = useRef(null);
  var location = useLocation();
  var appearance = props.appearance,
      backgroundAttachment = props.backgroundAttachment,
      backgroundColor = props.backgroundColor,
      backgroundPosition = props.backgroundPosition,
      _props$backgroundRepe = props.backgroundRepeat,
      backgroundRepeat = _props$backgroundRepe === void 0 ? 'repeat' : _props$backgroundRepe,
      backgroundSize = props.backgroundSize,
      border = props.border,
      borderColor = props.borderColor,
      borderRadius = props.borderRadius,
      borderWidth = props.borderWidth,
      children = props.children,
      _props$cssClasses = props.cssClasses,
      cssClasses = _props$cssClasses === void 0 ? [] : _props$cssClasses,
      desktopImage = props.desktopImage,
      marginBottom = props.marginBottom,
      marginLeft = props.marginLeft,
      marginRight = props.marginRight,
      marginTop = props.marginTop,
      minHeight = props.minHeight,
      mobileImage = props.mobileImage,
      paddingBottom = props.paddingBottom,
      paddingLeft = props.paddingLeft,
      paddingRight = props.paddingRight,
      paddingTop = props.paddingTop,
      textAlign = props.textAlign,
      verticalAlignment = props.verticalAlignment,
      width = props.width;

  var _useWindowSize = useWindowSize(),
      innerWidth = _useWindowSize.innerWidth;

  var image = desktopImage;

  if (mobileImage && matchMedia && matchMedia('(max-width: 768px)').matches) {
    image = mobileImage;
  }

  var flexDirection = 'column';
  var display = 'flex';
  var alignSelf;

  switch (appearance) {
    case 'align-top':
      alignSelf = 'flex-start';
      break;

    case 'align-center':
      alignSelf = 'center';
      break;

    case 'align-bottom':
      alignSelf = 'flex-end';
      break;

    case 'full-height':
    default:
      alignSelf = 'stretch';
      break;
  }

  var justifyContent;

  switch (verticalAlignment) {
    case 'top':
    default:
      justifyContent = 'flex-start';
      break;

    case 'middle':
      justifyContent = 'center';
      break;

    case 'bottom':
      justifyContent = 'flex-end';
      break;
  }

  var dynamicStyles = {
    alignSelf: alignSelf,
    backgroundColor: backgroundColor,
    border: border,
    borderColor: borderColor,
    borderRadius: borderRadius,
    borderWidth: borderWidth,
    display: display,
    flexDirection: flexDirection,
    justifyContent: justifyContent,
    marginBottom: marginBottom,
    marginLeft: marginLeft,
    marginRight: marginRight,
    marginTop: marginTop,
    minHeight: minHeight,
    paddingBottom: paddingBottom,
    paddingLeft: paddingLeft,
    paddingRight: paddingRight,
    paddingTop: paddingTop,
    textAlign: textAlign,
    verticalAlignment: verticalAlignment,
    width: width
  };

  if (image) {
    dynamicStyles.backgroundImage = bgImageStyle;
    dynamicStyles.backgroundSize = backgroundSize;
    dynamicStyles.backgroundPosition = backgroundPosition;
    dynamicStyles.backgroundAttachment = backgroundAttachment;
    dynamicStyles.backgroundRepeat = backgroundRepeat;
  }

  var doesDivIncludeClass = cssClasses.includes('customBlock');

  if (doesDivIncludeClass) {// dynamicStyles.paddingLeft = customMarginLeft && innerWidth > 1024 && customMarginLeft;
  } // Determine the containers width and optimize the image


  useLayoutEffect, useEffect(function () {
    if (image && columnElement.current) {
      if (backgroundSize === 'cover') {
        setBgImageStyle("url(".concat(resourceUrl(image, {
          type: 'image-wysiwyg',
          width: columnElement.current.offsetWidth,
          height: columnElement.current.offsetHeight,
          quality: 85,
          crop: false,
          fit: 'cover'
        }), ")"));
      } else {
        setBgImageStyle("url(".concat(resourceUrl(image, {
          type: 'image-wysiwyg',
          quality: 85
        }), ")"));
      }
    }
  }, [backgroundSize, image, setBgImageStyle]);
  var windowPadding = 64; // this is left padding of container

  var containerWidth = 1536; // width of container

  if (innerWidth < 1536 && innerWidth > 1280) {
    windowPadding = 64;
    containerWidth = 1280;
  } else if (innerWidth < 1280 && innerWidth > 1024) {
    windowPadding = 64;
    containerWidth = 1024;
  }

  var widthOfLeftMargin = useMemo(function () {
    return (innerWidth - containerWidth + windowPadding) / 2;
  }, [innerWidth, containerWidth, windowPadding]);
  var calculateLeftMargine = useCallback(function () {
    return location.pathname !== '/' && setCustomMarginLeft(widthOfLeftMargin);
  }, [innerWidth]);
  useLayoutEffect(function () {
    var events = ['resize', 'load'];
    if (doesDivIncludeClass) events.map(function (item) {
      window.addEventListener(item, calculateLeftMargine);
      return function () {
        return window.removeEventListener(item, calculateLeftMargine);
      };
    });
  }, [innerWidth]);
  useEffect(function () {
    if (location.pathname === '/') {
      calculateLeftMargine();
    }
  }, [location]);
  var findElementIndex = useCallback(function (dataName) {
    return children === null || children === void 0 ? void 0 : children.findIndex(function (child) {
      var _child$props, _child$props$data, _child$props$data$htm, _dataName$split;

      return child === null || child === void 0 ? void 0 : (_child$props = child.props) === null || _child$props === void 0 ? void 0 : (_child$props$data = _child$props.data) === null || _child$props$data === void 0 ? void 0 : (_child$props$data$htm = _child$props$data.html) === null || _child$props$data$htm === void 0 ? void 0 : _child$props$data$htm.includes(dataName === null || dataName === void 0 ? void 0 : (_dataName$split = dataName.split('=')) === null || _dataName$split === void 0 ? void 0 : _dataName$split[0]);
    });
  }, [children]);
  var extractVideoUrl = useMemo(function () {
    var _children$videoElemen, _children$videoElemen2, _children$videoElemen3, _children$videoElemen4;

    var videoElementIndex = findElementIndex('play-video-code');
    var htmlData = children === null || children === void 0 ? void 0 : (_children$videoElemen = children[videoElementIndex]) === null || _children$videoElemen === void 0 ? void 0 : (_children$videoElemen2 = _children$videoElemen.props) === null || _children$videoElemen2 === void 0 ? void 0 : (_children$videoElemen3 = _children$videoElemen2.data) === null || _children$videoElemen3 === void 0 ? void 0 : (_children$videoElemen4 = _children$videoElemen3.html) === null || _children$videoElemen4 === void 0 ? void 0 : _children$videoElemen4.split("'");
    return htmlData === null || htmlData === void 0 ? void 0 : htmlData[1];
  }, [children]);
  return React.createElement("div", {
    style: dynamicStyles,
    ref: columnElement,
    className: [classes.root].concat(_toConsumableArray(cssClasses)).join(' ')
  }, children === null || children === void 0 ? void 0 : children.filter(function (_, i) {
    return i !== findElementIndex('play-video-code');
  }), children !== null && children !== void 0 && children[findElementIndex('play-video-code')] ? React.createElement(CmsVideoSection, {
    video: extractVideoUrl
  }) : null);
};
/**
 * Props for {@link Column}
 *
 * @typedef props
 *
 * @property {String} appearance Converts to CSS align-self sub-property of the flexbox item
 * @property {String} backgroundAttachment CSS background-attachment property
 * @property {String} backgroundColor CSS background-color property
 * @property {String} backgroundPosition CSS background-position property
 * @property {String} backgroundRepeat CSS background-repeat property
 * @property {String} backgroundSize CSS background-size property
 * @property {String} border CSS border property
 * @property {String} borderColor CSS border color property
 * @property {String} borderRadius CSS border radius property
 * @property {String} borderWidth CSS border width property
 * @property {Object} classes An object containing the class names for the Column
 * @property {String} classes.root CSS classes for the root container element
 * @property {Array} cssClasses List of CSS classes to be applied to the component
 * @property {String} desktopImage Background image url to be used for desktop screen width
 * @property {String} marginBottom CSS margin bottom property
 * @property {String} marginLeft CSS margin left property
 * @property {String} marginRight CSS margin right property
 * @property {String} marginTop CSS margin top property
 * @property {String} maxWidth Maximum width of the video
 * @property {String} minHeight - CSS min-height property
 * @property {String} mobileImage Background image url to be used for mobile screen width
 * @property {String} paddingBottom CSS padding bottom property
 * @property {String} paddingLeft CSS padding left property
 * @property {String} paddingRight CSS padding right property
 * @property {String} paddingTop CSS padding top property
 * @property {String} textAlign Horisontal alignment of the contents within the parent container
 * @property {String} verticalAlignment Vertical alignment of the contents within the parent container
 * @property {String} width CSS width property
 */


Column.propTypes = {
  appearance: oneOf(['align-top', 'align-center', 'align-bottom', 'full-height']),
  backgroundAttachment: string,
  backgroundColor: string,
  backgroundPosition: string,
  backgroundRepeat: string,
  backgroundSize: string,
  border: string,
  borderColor: string,
  borderRadius: string,
  borderWidth: string,
  classes: shape({
    root: string
  }),
  cssClasses: arrayOf(string),
  desktopImage: string,
  marginBottom: string,
  marginLeft: string,
  marginRight: string,
  marginTop: string,
  minHeight: string,
  mobileImage: string,
  paddingBottom: string,
  paddingRight: string,
  paddingTop: string,
  textAlign: string,
  verticalAlignment: oneOf(['top', 'middle', 'bottom']),
  width: string
};
export default Column;