import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useMemo, useCallback } from 'react';
import { ChevronDown as ArrowDown } from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import { array, arrayOf, shape, string, oneOf } from 'prop-types';
import { useDropdown } from '@magento/peregrine/lib/hooks/useDropdown';
import SortItem from './sortItem';
import defaultClasses from './productSort.module.css';
import { DownArrow, UpArrow } from '../Common/Icons';
import { useMergeCssClasses } from '../../Utils/utils';

var ProductSort = function ProductSort(_ref) {
  var availableSortMethods = _ref.availableSortMethods,
      sortProps = _ref.sortProps,
      propClasses = _ref.classes,
      klevuSortProps = _ref.klevuSortProps,
      useKlevuSort = _ref.useKlevuSort;

  var _sortProps = _slicedToArray(sortProps, 2),
      currentSort = _sortProps[0],
      setSort = _sortProps[1];

  var _useDropdown = useDropdown(),
      elementRef = _useDropdown.elementRef,
      expanded = _useDropdown.expanded,
      setExpanded = _useDropdown.setExpanded;

  var _ref2 = klevuSortProps ? klevuSortProps : [],
      _ref3 = _slicedToArray(_ref2, 2),
      klevuSort = _ref3[0],
      setKlevuSort = _ref3[1];

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage,
      locale = _useIntl.locale;

  var orderSortingList = useCallback(function (list) {
    return list.sort(function (a, b) {
      return a.text.localeCompare(b.text, locale, {
        sensitivity: 'base'
      });
    });
  }, [locale]);
  var classes = useMergeCssClasses(defaultClasses, propClasses); // click event for menu items

  var handleItemClick = useCallback(function (sortAttribute) {
    setSort(function (prevSort) {
      return {
        sortText: sortAttribute.text,
        sortId: sortAttribute.id,
        sortAttribute: sortAttribute.attribute,
        sortDirection: sortAttribute.sortDirection,
        sortFromSearch: prevSort.sortFromSearch
      };
    });

    if (useKlevuSort) {
      setKlevuSort(sortAttribute === null || sortAttribute === void 0 ? void 0 : sortAttribute.value);
    }

    setExpanded(false);
  }, [setExpanded, setSort, useKlevuSort]);
  var sortMethodsFromConfig = availableSortMethods ? availableSortMethods.map(function (method) {
    var value = method.value,
        label = method.label;

    if (value !== 'price' && value !== 'position') {
      return {
        id: "sortItem.".concat(value),
        text: label,
        attribute: value,
        sortDirection: 'ASC',
        value: useKlevuSort ? value : null
      };
    }
  }).filter(function (method) {
    return !!method;
  }) : null;
  var sortElements = useMemo(function () {
    // should be not render item in collapsed mode.
    if (!expanded) {
      return null;
    }

    var defaultSortMethods = useKlevuSort ? [] : [{
      id: 'sortItem.relevance',
      text: formatMessage({
        id: 'sortItem.relevance',
        defaultMessage: 'Relevance'
      }),
      attribute: 'relevance',
      sortDirection: 'DESC'
    }, {
      id: 'sortItem.priceDesc',
      text: formatMessage({
        id: 'sortItem.priceDesc',
        defaultMessage: 'Price: High to Low'
      }),
      attribute: 'price',
      sortDirection: 'DESC'
    }, {
      id: 'sortItem.priceAsc',
      text: formatMessage({
        id: 'sortItem.priceAsc',
        defaultMessage: 'Price: Low to High'
      }),
      attribute: 'price',
      sortDirection: 'ASC'
    }]; // Do not display Position in Search

    if (!currentSort.sortFromSearch && !useKlevuSort) {
      defaultSortMethods.push({
        id: 'sortItem.position',
        text: formatMessage({
          id: 'sortItem.position',
          defaultMessage: 'Position'
        }),
        attribute: 'position',
        sortDirection: 'ASC'
      });
    }

    var allSortingMethods = sortMethodsFromConfig ? orderSortingList([sortMethodsFromConfig, defaultSortMethods].flat()) : defaultSortMethods;
    var itemElements = Array.from(allSortingMethods, function (sortItem) {
      var attribute = sortItem.attribute,
          sortDirection = sortItem.sortDirection;
      if (attribute === 'position') return null;
      var isActive = currentSort.sortAttribute === attribute && currentSort.sortDirection === sortDirection;
      var key = "".concat(attribute, "--").concat(sortDirection);
      return React.createElement("li", {
        key: key,
        className: classes.menuItem
      }, React.createElement(SortItem, {
        sortItem: sortItem,
        active: isActive,
        onClick: handleItemClick
      }));
    });
    return React.createElement("div", {
      className: classes.menu
    }, React.createElement("ul", null, itemElements));
  }, [availableSortMethods, classes.menu, classes.menuItem, currentSort.sortAttribute, currentSort.sortDirection, expanded, handleItemClick]); // expand or collapse on click

  var handleSortClick = function handleSortClick() {
    return setExpanded(!expanded);
  };

  var arrowClasses = expanded ? classes.iconActive : classes.icon;
  return React.createElement("div", {
    ref: elementRef,
    className: classes.root,
    "aria-live": "polite",
    "aria-busy": "false"
  }, React.createElement("div", {
    className: classes.dropdownLabel,
    onClick: handleSortClick
  }, React.createElement("span", {
    className: classes.mobileText
  }, React.createElement(FormattedMessage, {
    id: 'productSort.sortByButton',
    defaultMessage: 'Sort by'
  })), React.createElement("span", {
    className: classes.desktopText
  }, React.createElement("span", {
    className: classes.sortText
  }, React.createElement(FormattedMessage, {
    id: 'productSort.sortByButton',
    defaultMessage: 'Sort by'
  }), "\xA0", currentSort.sortText)), React.createElement("div", {
    className: arrowClasses
  }, React.createElement(DownArrow, null))), sortElements);
};

var sortDirections = oneOf(['ASC', 'DESC']);
ProductSort.propTypes = {
  classes: shape({
    menuItem: string,
    menu: string,
    root: string,
    sortButton: string
  }),
  availableSortMethods: arrayOf(shape({
    text: string,
    id: string,
    attribute: string,
    sortDirection: sortDirections
  })),
  sortProps: array
};
ProductSort.defaultProps = {
  availableSortMethods: [{
    text: 'Position',
    id: 'sortItem.position',
    attribute: 'position',
    sortDirection: 'ASC'
  }, {
    id: 'sortItem.relevance',
    text: 'Relevance',
    attribute: 'relevance',
    sortDirection: 'DESC'
  }, {
    id: 'sortItem.priceAsc',
    text: 'Price: Low to High',
    attribute: 'price',
    sortDirection: 'ASC'
  }, {
    id: 'sortItem.priceDesc',
    text: 'Price: High to Low',
    attribute: 'price',
    sortDirection: 'DESC'
  }]
};
export default ProductSort;