import React from 'react';
import { shape, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useSearchTrigger } from '@magento/peregrine/lib/talons/Header/useSearchTrigger';
import { useStyle } from '@magento/venia-ui/lib/classify';
import defaultClasses from '@magento/venia-ui/lib/components/Header/searchTrigger.module.css';
import { RoundSearchIcon } from '../..';
var SearchTrigger = React.forwardRef(function (props, ref) {
  var active = props.active,
      onClick = props.onClick;
  var talonProps = useSearchTrigger({
    onClick: onClick
  });
  var handleClick = talonProps.handleClick;

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var classes = useStyle(defaultClasses, props.classes);
  var searchClass = active ? classes.open : classes.root;
  var searchText = formatMessage({
    id: 'searchTrigger.search',
    defaultMessage: 'Search'
  });
  return React.createElement("button", {
    className: searchClass,
    "aria-label": searchText,
    onClick: handleClick,
    ref: ref
  }, React.createElement(RoundSearchIcon, null));
});
SearchTrigger.propTypes = {
  classes: shape({
    root: string,
    open: string
  })
};
export default SearchTrigger;