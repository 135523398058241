import React from 'react';
import { func, number, shape, string } from 'prop-types';
import { useCategoryBranch } from '@magento/peregrine/lib/talons/CategoryTree';
import { useIntl } from 'react-intl';
import defaultClasses from './categoryBranch.module.css';
import { useMergeCssClasses } from '../../Utils/utils';
import { DownArrow } from '../';

var Branch = function Branch(props) {
  var category = props.category,
      setCategoryId = props.setCategoryId;
  var name = category.name;

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var classes = useMergeCssClasses(defaultClasses, props.classes);
  var talonProps = useCategoryBranch({
    category: category,
    setCategoryId: setCategoryId
  });
  var exclude = talonProps.exclude,
      handleClick = talonProps.handleClick;

  if (exclude) {
    return null;
  }

  return React.createElement("li", {
    className: classes.root
  }, React.createElement("button", {
    className: classes.target,
    type: "button",
    onClick: handleClick,
    "aria-label": name
  }, React.createElement("span", {
    className: classes.text
  }, name), React.createElement("div", {
    className: classes.arrow
  }, React.createElement(DownArrow, null))));
};

export default Branch;
Branch.propTypes = {
  category: shape({
    uid: string.isRequired,
    include_in_menu: number,
    name: string.isRequired
  }).isRequired,
  classes: shape({
    root: string,
    target: string,
    text: string
  }),
  setCategoryId: func.isRequired
};