import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppContext } from '@magento/peregrine/lib/context/app';
import mergeOperations from '@magento/peregrine/lib/util/shallowMerge';
import { useFilterState } from '@magento/peregrine/lib/talons/FilterModal';
import { getSearchFromState, getStateFromSearch, sortFiltersArray, stripHtml } from '@magento/peregrine/lib/talons/FilterModal/helpers';
import DEFAULT_OPERATIONS from '@magento/peregrine/lib/talons/FilterModal/filterModal.gql';
import { getAllFilters, AddFilter, removeFilter, removeAllFilters } from '../FilterModal/customFilterLogic';
var DRAWER_NAME = 'filter';
export var useFilterSidebar = function useFilterSidebar(props) {
  var filters = props.filters;
  var history = useHistory();
  var allFilters = getAllFilters(history);
  var operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  var getFilterInputsQuery = operations.getFilterInputsQuery;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isApplying = _useState2[0],
      setIsApplying = _useState2[1];

  var _useAppContext = useAppContext(),
      _useAppContext2 = _slicedToArray(_useAppContext, 2),
      drawer = _useAppContext2[0].drawer,
      _useAppContext2$ = _useAppContext2[1],
      toggleDrawer = _useAppContext2$.toggleDrawer,
      closeDrawer = _useAppContext2$.closeDrawer;

  var _useFilterState = useFilterState(),
      _useFilterState2 = _slicedToArray(_useFilterState, 2),
      filterState = _useFilterState2[0],
      filterApi = _useFilterState2[1];

  var prevDrawer = useRef(null);
  var isOpen = drawer === DRAWER_NAME;

  var _useLocation = useLocation(),
      pathname = _useLocation.pathname,
      search = _useLocation.search;

  var _useQuery = useQuery(getFilterInputsQuery),
      introspectionData = _useQuery.data;

  var attributeCodes = useMemo(function () {
    return filters.map(function (_ref) {
      var attribute_code = _ref.attribute_code;
      return attribute_code;
    });
  }, [filters]); // Create a set of disabled filters.

  var DISABLED_FILTERS = useMemo(function () {
    var disabled = new Set(); // Disable category filtering when not on a search page.

    if (pathname !== '/search.html') {
      disabled.add('category_id');
      disabled.add('category_uid');
    }

    return disabled;
  }, [pathname]); // Get "allowed" filters by intersection of filter attribute codes and
  // schema input field types. This restricts the displayed filters to those
  // that the api will understand.
  // console.log(introspectionData);

  var possibleFilters = useMemo(function () {
    var nextFilters = new Set();
    var inputFields = introspectionData ? introspectionData.__type.inputFields : []; // perform mapping and filtering in the same cycle

    for (var _i = 0, _arr = [].concat(_toConsumableArray(inputFields), [{
      name: 'klevu_price',
      type: {
        __typename: '__Type',
        name: 'FilterRangeTypeInput'
      },
      __typename: '__InputValue'
    }]); _i < _arr.length; _i++) {
      var name = _arr[_i].name;
      var isValid = attributeCodes.includes(name);
      var isEnabled = !DISABLED_FILTERS.has(name);

      if (isValid && isEnabled) {
        nextFilters.add(name);
      }
    }

    return nextFilters;
  }, [DISABLED_FILTERS, attributeCodes, introspectionData]);

  var isBooleanFilter = function isBooleanFilter(options) {
    var optionsString = JSON.stringify(options);
    return (options === null || options === void 0 ? void 0 : options.length) <= 2 && (optionsString.includes(JSON.stringify({
      __typename: 'AggregationOption',
      label: '0',
      value: '0'
    })) || optionsString.includes(JSON.stringify({
      __typename: 'AggregationOption',
      label: '1',
      value: '1'
    })));
  }; // iterate over filters once to set up all the collections we need


  var _useMemo = useMemo(function () {
    var names = new Map();
    var keys = new Set();
    var frontendInput = new Map();
    var itemsByGroup = new Map();
    var sortedFilters = sortFiltersArray(_toConsumableArray(filters));

    var _iterator = _createForOfIteratorHelper(sortedFilters),
        _step;

    try {
      var _loop = function _loop() {
        var filter = _step.value;
        var options = filter.options,
            name = filter.label,
            group = filter.attribute_code; // If this aggregation is not a possible filter, just back out.

        // If this aggregation is not a possible filter, just back out.
        if (possibleFilters.has(group)) {
          var items = []; // add filter name

          // add filter name
          names.set(group, name); // add filter key permutations

          // add filter key permutations
          keys.add("".concat(group, "[filter]")); // TODO: Get all frontend input type from gql if other filter input types are needed
          // See https://github.com/magento-commerce/magento2-pwa/pull/26
          // console.log(p);

          // TODO: Get all frontend input type from gql if other filter input types are needed
          // See https://github.com/magento-commerce/magento2-pwa/pull/26
          // console.log(p);
          if (isBooleanFilter(options)) {
            frontendInput.set(group, 'boolean'); // add items

            // add items
            items.push({
              title: 'No',
              value: '0',
              label: name + ':' + 'No'
            });
            items.push({
              title: 'Yes',
              value: '1',
              label: name + ':' + 'Yes'
            });
          } else {
            if (group !== 'klevu_price' && group !== 'hf_height' && group !== 'hf_width' && group !== 'hf_depth_length' && group !== 'hf_length') {
              // Add frontend input type
              frontendInput.set(group, null); // add items

              // add items
              var _iterator2 = _createForOfIteratorHelper(options),
                  _step2;

              try {
                for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                  var _step2$value = _step2.value,
                      label = _step2$value.label,
                      value = _step2$value.value,
                      swatch_data = _step2$value.swatch_data;
                  items.push({
                    title: stripHtml(label),
                    value: value,
                    swatch_data: swatch_data
                  });
                }
              } catch (err) {
                _iterator2.e(err);
              } finally {
                _iterator2.f();
              }
            } else {
              var foundPriceFilter = filters === null || filters === void 0 ? void 0 : filters.find(function (x) {
                return x.key === group;
              });

              if ((foundPriceFilter === null || foundPriceFilter === void 0 ? void 0 : foundPriceFilter.min) !== (foundPriceFilter === null || foundPriceFilter === void 0 ? void 0 : foundPriceFilter.max)) {
                items.push({
                  title: foundPriceFilter === null || foundPriceFilter === void 0 ? void 0 : foundPriceFilter.min,
                  value: foundPriceFilter === null || foundPriceFilter === void 0 ? void 0 : foundPriceFilter.min
                }, {
                  title: foundPriceFilter === null || foundPriceFilter === void 0 ? void 0 : foundPriceFilter.max,
                  value: foundPriceFilter === null || foundPriceFilter === void 0 ? void 0 : foundPriceFilter.max
                });
              }
            }
          }

          itemsByGroup.set(group, items);
        }
      };

      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        _loop();
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return [names, keys, itemsByGroup, frontendInput];
  }, [filters, possibleFilters]),
      _useMemo2 = _slicedToArray(_useMemo, 4),
      filterNames = _useMemo2[0],
      filterKeys = _useMemo2[1],
      filterItems = _useMemo2[2],
      filterFrontendInput = _useMemo2[3]; // on apply, write filter state to location


  useEffect(function () {
    if (isApplying) {
      var nextSearch = getSearchFromState(search, filterKeys, filterState); // write filter state to history

      history.push({
        pathname: pathname,
        search: nextSearch
      }); // mark the operation as complete

      setIsApplying(false);
    }
  }, [filterKeys, filterState, history, isApplying, pathname, search]);
  var handleOpen = useCallback(function () {
    toggleDrawer(DRAWER_NAME);
  }, [toggleDrawer]);
  var handleClose = useCallback(function () {
    closeDrawer();
  }, [closeDrawer]);
  var handleApply = useCallback(function () {
    setIsApplying(true); // handleClose();
  }, [handleClose]);
  var handleReset = useCallback(function () {
    filterApi.clear();
    setIsApplying(true);
    removeAllFilters(history);
  }, [filterApi, setIsApplying, history]);
  var handleAddFilter = useCallback(function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    if (args.length > 2) {
      removeFilter(args, history);
    } else {
      AddFilter.apply(void 0, args.concat([history]));
    }
  }, [history]);
  var handleKeyDownActions = useCallback(function (event) {
    // do not handle keyboard actions when the modal is closed
    if (!isOpen) {
      return;
    }

    switch (event.keyCode) {
      // when "Esc" key fired -> close the modal
      case 27:
        handleClose();
        break;
    }
  }, [isOpen, handleClose]); // useEffect(() => {
  //     const justOpened = prevDrawer.current === null && drawer === DRAWER_NAME;
  //     const justClosed = prevDrawer.current === DRAWER_NAME && drawer === null;
  //     // on drawer toggle, read filter state from location
  //     if (justOpened || justClosed) {
  //         const nextState = getStateFromSearch(search, filterKeys, filterItems);
  //         filterApi.setItems(nextState);
  //     }
  //     // on drawer close, update the modal visibility state
  //     if (justClosed) {
  //         handleClose();
  //     }
  //     prevDrawer.current = drawer;
  // }, [drawer, filterApi, filterItems, filterKeys, search, handleClose]);

  useEffect(function () {
    var nextState = getStateFromSearch(search, filterKeys, filterItems);
    filterApi.setItems(nextState);
  }, [filterApi, filterItems, filterKeys, search]);
  return {
    filterApi: filterApi,
    filterItems: filterItems,
    filterKeys: filterKeys,
    filterNames: filterNames,
    filterFrontendInput: filterFrontendInput,
    filterState: filterState,
    handleApply: handleApply,
    handleClose: handleClose,
    handleKeyDownActions: handleKeyDownActions,
    handleOpen: handleOpen,
    handleReset: handleReset,
    isApplying: isApplying,
    isOpen: isOpen,
    AddFilter: handleAddFilter,
    allFilters: allFilters
  };
};