import React from 'react';

var PrevIcon = function PrevIcon() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "48",
    height: "48",
    viewBox: "0 0 48 48"
  }, React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("circle", {
    cx: "24",
    cy: "24",
    r: "24",
    fill: "#3E3E3E",
    fillOpacity: "0.7"
  }), React.createElement("path", {
    stroke: "#FFF",
    strokeLinecap: "round",
    strokeWidth: "2",
    d: "M23.497 35l-8.914-10.497L23.503 14m-7.982 10.5h18.486"
  })));
};

export default PrevIcon;