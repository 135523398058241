import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["alt", "width", "classes", "size", "src", "height"];
import React from 'react';
import { number, shape, string } from 'prop-types';
import { useStyle } from '@magento/venia-ui/lib/classify';
import Image from '../Image/image';
import defaultClasses from './icon.module.css';

var Icon = function Icon(props) {
  // destructure `propClasses` to exclude it from `restProps`
  var alt = props.alt,
      width = props.width,
      propClasses = props.classes,
      size = props.size,
      src = props.src,
      height = props.height,
      restProps = _objectWithoutProperties(props, _excluded);

  var classes = useStyle(defaultClasses, propClasses);
  return React.createElement("span", _extends({
    className: classes.root
  }, restProps), React.createElement("img", {
    src: src,
    alt: alt,
    className: classes.icon
  }));
};

export default Icon;
Icon.propTypes = {
  width: string,
  classes: shape({
    icon: string,
    root: string
  }),
  size: number,
  src: string
};