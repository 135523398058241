import React from 'react';
import defaultClasses from './appBodyLayout.module.css';
import { useMergeCssClasses } from '../../../Utils/utils';

var AppBodyLayout = function AppBodyLayout(_ref) {
  var children = _ref.children,
      propClasses = _ref.classes;
  var classes = useMergeCssClasses(defaultClasses, propClasses);
  return React.createElement("div", {
    className: classes.root
  }, React.createElement("div", {
    className: classes.body
  }, children));
};

export default AppBodyLayout;