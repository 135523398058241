// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".categoryLeaf-root-MdG {\n    align-items: center;\n    border-bottom: 1px solid var(--venia-global-color-snowbound);\n    display: flex;\n    margin: 0 15px;\n}\n\n.categoryLeaf-target-iN- {\n    display: flex;\n    flex: auto;\n}\n\n.categoryLeaf-target-iN- > span > img {\n    transform: rotate(270deg);\n}\n\n.categoryLeaf-text-MaT {\n    display: inline-block;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "categoryLeaf-root-MdG text-sm",
	"target": "categoryLeaf-target-iN- w-full flex justify-between items-center py-2.5 leading-171",
	"text": "categoryLeaf-text-MaT text-black bg-white leading-171"
};
export default ___CSS_LOADER_EXPORT___;
