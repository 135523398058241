import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["alt", "classes", "displayPlaceholder", "height", "onError", "onLoad", "placeholder", "resource", "src", "type", "width", "widths", "ratio", "logoImage", "logoJola"];
import React from 'react';
import PropTypes, { bool, func, instanceOf, number, oneOfType, shape, string } from 'prop-types';
import { useImage } from '@magento/peregrine/lib/talons/Image/useImage';
import { DEFAULT_WIDTH_TO_HEIGHT_RATIO } from '@magento/peregrine/lib/util/imageUtils';
import PlaceholderImage from '@magento/venia-ui/lib/components/Image/placeholderImage';
import ResourceImage from '@magento/venia-ui/lib/components/Image/resourceImage';
import SimpleImage from '@magento/venia-ui/lib/components/Image/simpleImage';
import defaultClasses from './image.module.css';
import { useMergeCssClasses } from '../../../Utils/utils';
/**
 * The Image component renders a placeholder until the image is loaded.
 *
 * @param {object}   props.classes any classes to apply to this component
 * @param {bool}     props.displayPlaceholder whether or not to display a placeholder while the image loads or if it errors on load.
 * @param {number}   props.height the intrinsic height of the image & the height to request for the fallback image for browsers that don't support srcset / sizes.
 * @param {function} props.onError callback for error loading image
 * @param {function} props.onLoad callback for when image loads successfully
 * @param {string}   props.placeholder the placeholder source to display while the image loads or if it errors on load
 * @param {string}   props.resource the Magento path to the image ex: /v/d/vd12-rn_main_2.jpg
 * @param {string}   props.src the source of the image, ready to use in an img element
 * @param {string}   props.type the Magento image type ("image-category" / "image-product"). Used to build the resource URL.
 * @param {number}   props.width the intrinsic width of the image & the width to request for the fallback image for browsers that don't support srcset / sizes.
 * @param {number}   props.ratio is the image width to height ratio. Defaults to `DEFAULT_WIDTH_TO_HEIGHT_RATIO` from `util/images.js`.
 * @param {Map}      props.widths a map of breakpoints to possible widths used to create the img's sizes attribute.
 */

var Image = function Image(props) {
  var alt = props.alt,
      propsClasses = props.classes,
      displayPlaceholder = props.displayPlaceholder,
      height = props.height,
      onError = props.onError,
      onLoad = props.onLoad,
      placeholder = props.placeholder,
      resource = props.resource,
      src = props.src,
      type = props.type,
      width = props.width,
      widths = props.widths,
      ratio = props.ratio,
      logoImage = props.logoImage,
      logoJola = props.logoJola,
      rest = _objectWithoutProperties(props, _excluded);

  var talonProps = useImage({
    onError: onError,
    onLoad: onLoad,
    width: width,
    widths: widths,
    height: height,
    ratio: ratio
  });
  var handleError = talonProps.handleError,
      handleImageLoad = talonProps.handleImageLoad,
      hasError = talonProps.hasError,
      isLoaded = talonProps.isLoaded,
      talonResourceWidth = talonProps.resourceWidth,
      talonResourceHeight = talonProps.resourceHeight;
  var classes = useMergeCssClasses(defaultClasses, propsClasses);
  var containerClass = "".concat(classes.root, " ").concat(classes.container);
  var isLoadedClass = isLoaded ? classes.loaded : classes.notLoaded;
  var imageClass = "".concat(classes.image, " ").concat(isLoadedClass); // If we have a src, use it directly. If not, assume this is a resource image.

  var actualImage = src ? React.createElement(SimpleImage, _extends({
    alt: alt,
    className: imageClass,
    handleError: handleError,
    handleLoad: handleImageLoad,
    height: talonResourceHeight,
    src: src,
    width: width
  }, rest)) : React.createElement(ResourceImage, _extends({
    alt: alt,
    className: imageClass,
    handleError: handleError,
    handleLoad: handleImageLoad,
    height: talonResourceHeight,
    resource: resource,
    type: type,
    width: talonResourceWidth,
    widths: widths,
    ratio: ratio
  }, rest));
  return React.createElement("div", {
    className: containerClass
  }, React.createElement(PlaceholderImage, _extends({
    alt: alt,
    classes: classes,
    displayPlaceholder: displayPlaceholder,
    height: height,
    imageHasError: hasError,
    imageIsLoaded: isLoaded,
    src: placeholder,
    ratio: ratio,
    width: talonResourceWidth
  }, rest)), actualImage);
};

var conditionallyRequiredString = function conditionallyRequiredString(props, propName, componentName) {
  // This component needs one of src or resource to be provided.
  if (!props.src && !props.resource) {
    return new Error("Missing both 'src' and 'resource' props in ".concat(componentName, ". ").concat(componentName, " needs at least one of these to be provided."));
  }

  return PropTypes.checkPropTypes({
    resource: string,
    src: string
  }, props, propName, componentName);
};

Image.propTypes = {
  alt: string.isRequired,
  classes: shape({
    container: string,
    loaded: string,
    notLoaded: string,
    root: string
  }),
  displayPlaceholder: bool,
  height: oneOfType([number, string]),
  onError: func,
  onLoad: func,
  placeholder: string,
  resource: conditionallyRequiredString,
  src: conditionallyRequiredString,
  type: string,
  width: oneOfType([number, string]),
  widths: instanceOf(Map),
  ratio: number
};
Image.defaultProps = {
  displayPlaceholder: true,
  ratio: DEFAULT_WIDTH_TO_HEIGHT_RATIO
};
export default Image;