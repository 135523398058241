import { useCallback } from 'react';
/**
 * Returns props necessary to render a CategoryLeaf component.
 *
 * @param {object} props
 * @param {function} props.onNavigate - callback to fire on link click
 * @return {{ handleClick: function }}
 */

export var useCategoryLeaf = function useCategoryLeaf(props) {
  var onNavigate = props.onNavigate;
  var handleClick = useCallback(function () {
    onNavigate();
  }, [onNavigate]);
  return {
    handleClick: handleClick
  };
};