import React from 'react';
import classes from './productsLayout.module.css';

var ProductsLayout = function ProductsLayout(_ref) {
  var children = _ref.children;
  return React.createElement("div", {
    className: classes.productsWrapper
  }, children);
};

export default ProductsLayout;