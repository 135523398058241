import React from 'react';

var CallUsIcon = function CallUsIcon() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "34",
    height: "57",
    viewBox: "0 0 34 57"
  }, React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    transform: "translate(.4)"
  }, React.createElement("rect", {
    width: "9.005",
    height: "3",
    x: "12.006",
    y: "5",
    fill: "#121111",
    rx: "1.5"
  }), React.createElement("rect", {
    width: "31.017",
    height: "55",
    x: "1",
    y: "1",
    stroke: "#121111",
    strokeWidth: "2",
    rx: "7"
  }), React.createElement("ellipse", {
    cx: "16.008",
    cy: "49",
    fill: "#121111",
    rx: "3.002",
    ry: "3"
  })));
};

export default CallUsIcon;