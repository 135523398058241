// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".currentFilterItem-filterItemWrapper-rZ5 {\n}\n\n.currentFilterItem-filterItemWrapper-rZ5 svg {\n    height: 18px;\n    width: 18px;\n    margin-left: 1rem;\n}\n\n.currentFilterItem-filterItemWrapper-rZ5 span {\n    font-size: 14px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterItemWrapper": "currentFilterItem-filterItemWrapper-rZ5 flex items-center justify-between text-bronze bg-snowbound px-5 py-2 rounded-3xl mx-2.5 my-1.5"
};
export default ___CSS_LOADER_EXPORT___;
