import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from 'react';
import { useQuery } from '@apollo/client';
import { shape, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useUserContext } from '@magento/peregrine/lib/context/user';
import NavFavouriteIcon from '../../Common/Icons/navFavoriteIcon';
import FilledFavIcon from '../../Common/Icons/filledFavIcon';
import { GET_WISHLISTS } from './favTrigger.gql.js';
import classes from './favorites.module.css';
var FavTrigger = React.forwardRef(function (props, ref) {
  var _data$customer;

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      isUserSignedIn = _useUserContext2[0].isSignedIn;

  var _useQuery = useQuery(GET_WISHLISTS, {
    fetchPolicy: 'cache-and-network',
    skip: !isUserSignedIn
  }),
      data = _useQuery.data,
      loading = _useQuery.loading,
      error = _useQuery.error;

  var searchText = formatMessage({
    id: 'searchTrigger.favoriteText',
    defaultMessage: 'Favorite'
  });
  var haveFavorites = (data === null || data === void 0 ? void 0 : (_data$customer = data.customer) === null || _data$customer === void 0 ? void 0 : _data$customer.wishlists[0].items_count) > 0;
  return React.createElement(Link, {
    to: '/favorites',
    "aria-label": searchText,
    ref: ref,
    className: haveFavorites ? classes.activeBtn : ''
  }, haveFavorites ? React.createElement(FilledFavIcon, null) : React.createElement(NavFavouriteIcon, null));
});
FavTrigger.propTypes = {
  classes: shape({
    root: string,
    open: string
  })
};
export default FavTrigger;