import React, { useEffect } from 'react';
import { node, shape, string } from 'prop-types'; //import { Menu as MenuIcon } from 'react-feather';

import { useIntl } from 'react-intl';
import { useStyle } from '@magento/venia-ui/lib/classify';
import Icon from '../Common/Icon/icon';
import defaultClasses from './navTrigger.module.css';
import menuIcon from 'src/assets/menu-icon.svg';
import CloseIcon from '../Common/Icons/closeIcon';
import { useNavigationTrigger } from './useNavigationTrigger';
/**
 * A component that toggles the navigation menu.
 */

var NavigationTrigger = function NavigationTrigger(props) {
  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var _useNavigationTrigger = useNavigationTrigger(),
      appState = _useNavigationTrigger.appState,
      handleOpenNavigation = _useNavigationTrigger.handleOpenNavigation;

  var classes = useStyle(defaultClasses, props.classes);
  return React.createElement("div", {
    className: classes.root,
    "aria-label": formatMessage({
      id: 'navigationTrigger.ariaLabel',
      defaultMessage: 'Toggle navigation panel'
    }),
    onClick: handleOpenNavigation
  }, appState.drawer ? React.createElement(CloseIcon, null) : React.createElement(Icon, {
    src: menuIcon,
    alt: "menu-icon"
  }));
};

NavigationTrigger.propTypes = {
  children: node,
  classes: shape({
    root: string
  })
};
export default NavigationTrigger;