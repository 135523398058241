import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { gql } from '@apollo/client';
export var GET_PAGE_SIZE = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query getPageSize {\n        storeConfig {\n            id\n            store_code\n            grid_per_page\n            grid_per_page_values\n        }\n    }\n"])));
export var GET_GROUPED_PRODUCTS_PRICES = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query getProductPrices ($skus: [String]!) {\n        products(\n            filter: {\n                sku: {\n                    in: $skus\n                }\n            },\n            pageSize: 100\n        ) \n        {\n            items {\n                uid\n                name\n                sku\n                ... on GroupedProduct {\n                    sku\n                    uid\n                    items {\n                        product {\n                            uid\n                            sku\n                            price_range {\n                                minimum_price {\n                                    final_price {\n                                        value\n                                        currency\n                                    }\n                                    regular_price {\n                                        value\n                                        currency\n                                    }\n                                }\n                            }\n                        }\n                    }\n                }\n            }\n        }\n    }\n"])));
export default {
  queries: {
    getPageSize: GET_PAGE_SIZE,
    getGroupedProductsPrices: GET_GROUPED_PRODUCTS_PRICES
  }
};