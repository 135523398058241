import _extends from "@babel/runtime/helpers/extends";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { useStyle } from '@magento/venia-ui/lib/classify';
import { useWindowSize } from '@magento/peregrine/lib/hooks/useWindowSize';
import defaultClasses from './text.module.css';

var toHTML = function toHTML(str) {
  return {
    __html: str
  };
};
/**
 * Page Builder Text component.
 *
 * This component is part of the Page Builder / PWA integration. It can be consumed without Page Builder.
 *
 * @typedef Text
 * @kind functional component
 *
 * @param {props} props React component props
 *
 * @returns {React.Element} A React component that displays a Text content type which contains content.
 */


var Text = function Text(props) {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isTextExpanded = _useState2[0],
      setIsTextExpanded = _useState2[1];

  var classes = useStyle(defaultClasses, props.classes);
  var content = props.content,
      textAlign = props.textAlign,
      border = props.border,
      borderColor = props.borderColor,
      borderWidth = props.borderWidth,
      borderRadius = props.borderRadius,
      marginTop = props.marginTop,
      marginRight = props.marginRight,
      marginBottom = props.marginBottom,
      marginLeft = props.marginLeft,
      paddingTop = props.paddingTop,
      paddingRight = props.paddingRight,
      paddingBottom = props.paddingBottom,
      paddingLeft = props.paddingLeft,
      _props$cssClasses = props.cssClasses,
      cssClasses = _props$cssClasses === void 0 ? [] : _props$cssClasses;
  var dynamicStyles = {
    textAlign: textAlign,
    border: border,
    borderColor: borderColor,
    borderWidth: borderWidth,
    borderRadius: borderRadius,
    marginTop: marginTop,
    marginRight: marginRight,
    marginBottom: marginBottom,
    marginLeft: marginLeft,
    paddingTop: paddingTop,
    paddingRight: paddingRight,
    paddingBottom: paddingBottom,
    paddingLeft: paddingLeft
  };

  var _useWindowSize = useWindowSize(),
      innerWidth = _useWindowSize.innerWidth;

  var handleExpandTextClick = function handleExpandTextClick() {
    setIsTextExpanded(function (prevState) {
      return !prevState;
    });
  };

  var isSmallDevice = innerWidth < 768;
  var doesTextHasClass = cssClasses.includes('expandText') && isSmallDevice;
  var textClasses = isTextExpanded ? classes.expanded : classes.notExpanded;
  return React.createElement("div", {
    style: dynamicStyles,
    className: [classes.root].concat(_toConsumableArray(cssClasses)).join(' ')
  }, React.createElement("p", _extends({}, isSmallDevice && doesTextHasClass && {
    className: textClasses
  }, {
    dangerouslySetInnerHTML: toHTML(content)
  })), doesTextHasClass && React.createElement("div", {
    className: classes.readMoreBtn,
    onClick: handleExpandTextClick
  }, React.createElement("span", {
    className: "pr-2"
  }, isTextExpanded ? '-' : '+'), isTextExpanded ? 'Read less' : 'Read more'));
};
/**
 * Props for {@link Text}
 *
 * @typedef props
 *
 * @property {Object} classes An object containing the class names for the Text
 * @property {String} classes.root CSS class for the root text element
 * @property {String} content Content to be rendered within the content type
 * @property {String} textAlign Alignment of content within the text
 * @property {String} border CSS border property
 * @property {String} borderColor CSS border color property
 * @property {String} borderWidth CSS border width property
 * @property {String} borderRadius CSS border radius property
 * @property {String} marginTop CSS margin top property
 * @property {String} marginRight CSS margin right property
 * @property {String} marginBottom CSS margin bottom property
 * @property {String} marginLeft CSS margin left property
 * @property {String} paddingTop CSS padding top property
 * @property {String} paddingRight CSS padding right property
 * @property {String} paddingBottom CSS padding bottom property
 * @property {String} paddingLeft CSS padding left property
 * @property {Array} cssClasses List of CSS classes to be applied to the component
 */


Text.propTypes = {
  classes: shape({
    root: string
  }),
  content: string,
  textAlign: string,
  border: string,
  borderColor: string,
  borderWidth: string,
  borderRadius: string,
  marginTop: string,
  marginRight: string,
  marginBottom: string,
  marginLeft: string,
  paddingTop: string,
  paddingRight: string,
  paddingBottom: string,
  cssClasses: arrayOf(string)
};
export default Text;