import { useState } from 'react'; // TODO: Read the default/initial sort from config.

var defaultSort = {
  sortText: 'Relevance',
  sortId: 'sortItem.relevance',
  sortAttribute: 'relevance',
  sortDirection: 'DESC'
};
/**
 *
 * @param props
 * @returns {[{sortDirection: string, sortAttribute: string, sortText: string}, React.Dispatch<React.SetStateAction<{sortDirection: string, sortAttribute: string, sortText: string}>>]}
 */

export var useSort = function useSort() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useState(function () {
    return Object.assign({}, defaultSort, props);
  });
};