// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".createWishlistForm-formErrors-fK8 {\n}\n\n.createWishlistForm-listname-5Q2 {\n}\n\n.createWishlistForm-actions-NgT {\n}\n\n.createWishlistForm-createBtn-5Bt {\n}\n\n.createWishlistForm-btnWishlist-ylJ:hover {\n    background-color: var(--venia-global-color-urbane-bronze);\n    border-color: var(--venia-global-color-urbane-bronze);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formErrors": "createWishlistForm-formErrors-fK8",
	"listname": "createWishlistForm-listname-5Q2",
	"actions": "createWishlistForm-actions-NgT gap-2xs grid grid-flow-col pt-md",
	"createBtn": "createWishlistForm-createBtn-5Bt mt-4 w-full",
	"btnWishlist": "createWishlistForm-btnWishlist-ylJ"
};
export default ___CSS_LOADER_EXPORT___;
