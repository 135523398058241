// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footerLinksGroup-accordionTitle-y8j {\n}\n\n.footerLinksGroup-footerLink-a8C {\n    color: #3e3e3e;\n}\n\n.footerLinksGroup-footerLink-a8C:hover {\n    text-decoration: underline;\n}\n\n.footerLinksGroup-closeIcon-4pj {\n    transform: rotate(180deg);\n    transition: all 0.25s ease-in-out;\n}\n\n.footerLinksGroup-openIcon-MDx {\n    transition: all 0.25s ease-in-out;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordionTitle": "footerLinksGroup-accordionTitle-y8j text-sm font-bold uppercase items-center",
	"footerLink": "footerLinksGroup-footerLink-a8C",
	"closeIcon": "footerLinksGroup-closeIcon-4pj",
	"openIcon": "footerLinksGroup-openIcon-MDx"
};
export default ___CSS_LOADER_EXPORT___;
