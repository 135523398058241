import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { func, shape, string } from 'prop-types';
import { useStyle } from '@magento/venia-ui/lib/classify';
import defaultClasses from './errorView.module.css';
import backgroundUrl from 'src/assets/404_page.jpg'; // import mobileBackgroundUrl from './errorViewBackground-380x600.png';

import { Button } from 'src/components';
var DEFAULT_HEADER = 'Oops!';
var DEFAULT_MESSAGE = 'We can’t seem to find the page you are looking for. Follow some of the links below to get back on track';
var DEFAULT_PROMPT = 'back to homepage';

var ErrorView = function ErrorView(props) {
  var classes = useStyle(defaultClasses, props.classes);
  var history = useHistory();
  var handleClick = useCallback(function (route) {
    history.push(route);
  }, [history]);
  var style = {
    '--backroundImageUrl': "url(\"".concat(backgroundUrl, "\")") // '--mobileBackgroundImageUrl': `url("${mobileBackgroundUrl}")`

  };
  return React.createElement("div", {
    className: classes.root,
    style: style
  }, React.createElement("div", {
    className: classes.errorWrapper
  }, React.createElement("div", {
    className: classes.content
  }, React.createElement("p", {
    className: classes.header
  }, DEFAULT_HEADER), React.createElement("p", {
    className: classes.message
  }, DEFAULT_MESSAGE), React.createElement("div", {
    className: classes.actionsContainer
  }, React.createElement(Button, {
    classes: {
      root: 'mb-7 uppercase'
    },
    onClick: function onClick() {
      return handleClick('/');
    },
    "aria-label": "Go to Home page"
  }, DEFAULT_PROMPT), React.createElement(Button, {
    classes: {
      root: 'mb-7'
    },
    onClick: function onClick() {
      return handleClick('/products/view-by-category');
    },
    "aria-label": "Go to category page"
  }, "EXPLORE PRODUCTS")))));
};

ErrorView.propTypes = {
  header: string,
  message: string,
  buttonPrompt: string,
  onClick: func,
  classes: shape({
    root: string,
    content: string,
    errorCode: string,
    header: string,
    message: string,
    actionsContainer: string
  })
};
export default ErrorView;