// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon-root-Lbh {\n  align-items: center;\n  display: inline-flex;\n  justify-content: center;\n  touch-action: manipulation;\n}\n\n@media (min-width: 641px) {\n  .icon-icon_desktop-wLx {\n    width: 28px;\n    height: 28px;\n  }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "icon-root-Lbh",
	"icon_desktop": "icon-icon_desktop-wLx"
};
export default ___CSS_LOADER_EXPORT___;
