import React, { useEffect, useRef, useState } from 'react';
import { useIsInViewport } from '@magento/peregrine/lib/hooks/useIsInViewport';
import { useStyle } from '@magento/venia-ui/lib/classify';
import MegaMenuItem from './megaMenuItem';
import defaultClasses from './megaMenu.module.css';
import { useMegaMenu } from './useMegaMenu';
/**
 * The MegaMenu component displays menu with categories on desktop devices
 */

var MegaMenu = function MegaMenu(props) {
  var _megaMenuData$childre;

  var mainNavRef = useRef(null);

  var _useMegaMenu = useMegaMenu({
    mainNavRef: mainNavRef
  }),
      megaMenuData = _useMegaMenu.megaMenuData,
      activeCategoryId = _useMegaMenu.activeCategoryId,
      subMenuState = _useMegaMenu.subMenuState,
      disableFocus = _useMegaMenu.disableFocus,
      handleSubMenuFocus = _useMegaMenu.handleSubMenuFocus,
      categoryUrlSuffix = _useMegaMenu.categoryUrlSuffix,
      handleNavigate = _useMegaMenu.handleNavigate;

  var classes = useStyle(defaultClasses, props.classes);
  var shouldRenderItems = useIsInViewport({
    elementRef: mainNavRef
  });
  var items = (_megaMenuData$childre = megaMenuData.children) === null || _megaMenuData$childre === void 0 ? void 0 : _megaMenuData$childre.map(function (category) {
    return React.createElement(MegaMenuItem, {
      category: category,
      activeCategoryId: activeCategoryId,
      categoryUrlSuffix: categoryUrlSuffix // mainNavWidth={mainNavWidth}
      ,
      onNavigate: handleNavigate,
      key: category.id,
      subMenuState: subMenuState,
      disableFocus: disableFocus
    });
  });
  return React.createElement("nav", {
    ref: mainNavRef,
    className: classes.megaMenu // role="navigation"
    ,
    onFocus: handleSubMenuFocus
  }, shouldRenderItems ? items : null);
};

export default MegaMenu;