import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useRef, useMemo } from 'react';
import { string, node, shape } from 'prop-types';

var Accordion = function Accordion(_ref) {
  var _contentEl$current;

  var title = _ref.title,
      content = _ref.content,
      openIcon = _ref.openIcon,
      closeIcon = _ref.closeIcon,
      classes = _ref.classes,
      _ref$activeAccordionI = _ref.activeAccordionId,
      activeAccordionId = _ref$activeAccordionI === void 0 ? null : _ref$activeAccordionI,
      _ref$id = _ref.id,
      id = _ref$id === void 0 ? '' : _ref$id;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isOpen = _useState2[0],
      setIsOpen = _useState2[1];

  var contentEl = useRef();

  var handleAccordionClick = function handleAccordionClick() {
    if (id) return;else setIsOpen(function (prev) {
      return !prev;
    });
  };

  return React.createElement("div", {
    className: classes !== null && classes !== void 0 && classes.wrapper ? "".concat(classes.wrapper) : 'py-2'
  }, React.createElement("div", {
    className: "flex cursor-pointer"
  }, React.createElement("span", {
    onClick: handleAccordionClick,
    id: id || title,
    className: "".concat(classes !== null && classes !== void 0 && classes.title ? "".concat(classes.title) : '', " flex flex-1 justify-between")
  }, title, React.createElement("div", {
    className: "cursor-pointer self-center ml-11"
  }, activeAccordionId === id || isOpen ? React.createElement("div", {
    className: classes === null || classes === void 0 ? void 0 : classes.closeIcon
  }, closeIcon ? closeIcon : openIcon) : React.createElement("div", {
    className: classes === null || classes === void 0 ? void 0 : classes.openIcon
  }, openIcon)))), React.createElement("div", null, React.createElement("div", {
    style: activeAccordionId === id || isOpen ? {
      height: contentEl === null || contentEl === void 0 ? void 0 : (_contentEl$current = contentEl.current) === null || _contentEl$current === void 0 ? void 0 : _contentEl$current.scrollHeight,
      transition: 'height 0.3s ease-in',
      overflow: 'hidden'
    } : {
      height: '0px',
      transition: 'height 0.3s ease-out',
      overflow: 'hidden'
    },
    ref: contentEl
  }, content)));
};

export default Accordion;
Accordion.propTypes = {
  classes: shape({
    title: string,
    wrapper: string,
    openIcon: string,
    closeIcon: string
  }),
  title: string,
  content: node,
  openIcon: node,
  closeIcon: node
};