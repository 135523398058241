// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".megaMenuItem-megaMenuItem-lrJ {\n}\n\n.megaMenuItem-megaMenuItem-lrJ:hover .megaMenuItem-megaMenuLink-s-J::before {\n    content: '';\n    position: absolute;\n    bottom: -3px;\n    left: 0;\n    height: 6px;\n    width: 100%;\n    background-color: var(--venia-global-color-red);\n    z-index: 100;\n}\n\n.megaMenuItem-megaMenuLink-s-J {\n    display: inline-flex;\n    min-height: 5rem;\n}\n\n.megaMenuItem-megaMenuLinkActive-4k- {\n}\n\n.megaMenuItem-megaMenuItem-lrJ:hover > div,\n.megaMenuItem-megaMenuItem-lrJ:focus > div {\n    display: flex;\n}\n\n.megaMenuItem-megaMenuItem_active-iJw {\n}\n\n/* .arrowDown {\n  padding-left: 0.5rem;\n  line-height: 0;\n} */\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"megaMenuItem": "megaMenuItem-megaMenuItem-lrJ px-4 lg_pl-0 lg_pr-4 xl_px-4 relative",
	"megaMenuLink": "megaMenuItem-megaMenuLink-s-J items-center justify-center cursor-pointer text-sm text-white",
	"megaMenuLinkActive": "megaMenuItem-megaMenuLinkActive-4k- megaMenuItem-megaMenuLink-s-J items-center justify-center cursor-pointer text-sm text-white",
	"megaMenuItem_active": "megaMenuItem-megaMenuItem_active-iJw megaMenuItem-megaMenuItem-lrJ px-4 lg_pl-0 lg_pr-4 xl_px-4 relative flex"
};
export default ___CSS_LOADER_EXPORT___;
