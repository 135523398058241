// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../node_modules/postcss-loader/dist/cjs.js!./categoryLeaf.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".categoryBranch-root-bSR {\n}\n\n.categoryBranch-target-Ibd {\n}\n\n.categoryBranch-text-toq {\n}\n.categoryBranch-arrow-bu8 {\n    transform: rotate(-90deg);\n}\n.categoryBranch-arrow-bu8 svg path {\n    stroke-width: 1px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "categoryBranch-root-bSR " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["root"] + "",
	"target": "categoryBranch-target-Ibd " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["target"] + " bg-white",
	"text": "categoryBranch-text-toq " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["text"] + "",
	"arrow": "categoryBranch-arrow-bu8"
};
export default ___CSS_LOADER_EXPORT___;
