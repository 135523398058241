import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback } from 'react';
import { useAppContext } from '@magento/peregrine/lib/context/app';
export var useNavigationTrigger = function useNavigationTrigger() {
  var _useAppContext = useAppContext(),
      _useAppContext2 = _slicedToArray(_useAppContext, 2),
      appState = _useAppContext2[0],
      _useAppContext2$ = _useAppContext2[1],
      toggleDrawer = _useAppContext2$.toggleDrawer,
      closeDrawer = _useAppContext2$.closeDrawer;

  var handleOpenNavigation = useCallback(function () {
    /*   toggleDrawer('nav');*/
    if (!appState.drawer) return toggleDrawer('nav');
    closeDrawer();
  }, [toggleDrawer, closeDrawer, appState]);
  return {
    appState: appState,
    handleOpenNavigation: handleOpenNavigation
  };
};