// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".categoryTree-treeTitle-ooC {\n    text-transform: uppercase;\n    border-left: 5px solid var(--venia-global-color-worldly-gray);\n}\n\n.categoryTree-inactive-ia3 {\n    position: fixed;\n    visibility: hidden;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"treeTitle": "categoryTree-treeTitle-ooC text-sm font-bold text-gray leading-171 px-2.5 py-2.5",
	"inactive": "categoryTree-inactive-ia3"
};
export default ___CSS_LOADER_EXPORT___;
