import React from 'react';
import defaultClasses from './indicator.module.css';
import { useMergeCssClasses } from '../../../../Utils/utils';
import { LoadingIcon } from '../../../../components';

var LoadingIndicator = function LoadingIndicator(props) {
  var classes = useMergeCssClasses(defaultClasses, props.classes);
  var className = props.global ? classes.global : classes.root;
  return React.createElement("div", {
    className: className
  }, React.createElement(LoadingIcon, null), React.createElement("span", {
    className: classes.message
  }, props.children));
};

export default LoadingIndicator;