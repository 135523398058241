import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useUserContext } from '@magento/peregrine/lib/context/user/';
import classes from './resourcesTabs.module.css';

function ResourcesTabs() {
  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      _useUserContext2$ = _useUserContext2[0],
      customer_flow_map_group = _useUserContext2$.currentUser.customer_flow_map_group,
      isUserSignedIn = _useUserContext2$.isSignedIn;

  var resourcesAllLinks = useSelector(function (state) {
    return state.resources.resourcesLinks;
  });
  return React.createElement("div", {
    className: classes.resourcesTab,
    id: "tabs"
  }, resourcesAllLinks === null || resourcesAllLinks === void 0 ? void 0 : resourcesAllLinks.filter(function (item) {
    if (item.customer_group) {
      if (item.name === 'Forms and Docs' && !customer_flow_map_group) {
        return null;
      } else {
        var _item$customer_group;

        return (_item$customer_group = item.customer_group) === null || _item$customer_group === void 0 ? void 0 : _item$customer_group.includes(customer_flow_map_group ? customer_flow_map_group : 'Consumer');
      }
    } else {
      return item;
    }
  }).map(function (tabLink) {
    var _tabLink$name;

    return React.createElement(Link, {
      className: "text-sm py-2 leading-171 relative",
      key: tabLink.category_id,
      target: ((_tabLink$name = tabLink.name) === null || _tabLink$name === void 0 ? void 0 : _tabLink$name.trim()) === 'Custom Quote Request Form' ? '_blank' : '_self',
      to: tabLink.name === 'Custom Quote Request Form' ? "/custom-product-request-form" : "/resources?tab=".concat(tabLink.name),
      "aria-label": "Go to ".concat(tabLink.name)
    }, tabLink.name);
  }));
}

export default ResourcesTabs;