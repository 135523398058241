// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".collectionSlider-root-t5k {\n    /* composes: col-span-12 from global; */\n}\n\n.collectionSlider-productContent-upv {\n}\n\n.collectionSlider-actionsContainer-8dI {\n    min-height: 0px;\n}\n\n.collectionSlider-name-DUG {\n}\n\n.collectionSlider-skuContent-lhb {\n}\n\n.collectionSlider-price---5 {\n}\n\n.collectionSlider-carousel-oyy {\n    gap: 0px !important;\n}\n\n.collectionSlider-sliderItem-MTQ {\n    flex-basis: 25%;\n    flex-shrink: 0;\n    min-width: 250px;\n}\n\n.collectionSlider-sliderThreeItems-H2A,\n.collectionSlider-sliderTwoItems-wrV,\n.collectionSlider-sliderOneItem-nfM,\n.collectionSlider-sliderItemTwoItems-Wld {\n    flex-shrink: 0;\n}\n\n.collectionSlider-sliderThreeItems-H2A {\n    flex-basis: 33%;\n}\n\n.collectionSlider-sliderTwoItems-wrV {\n    flex-basis: 50%;\n}\n.collectionSlider-sliderOneItem-nfM {\n    flex-basis: 100%;\n}\n.collectionSlider-sliderItemTwoItems-Wld {\n    flex-basis: 50%;\n}\n\n@media (max-width: 980px) {\n    .collectionSlider-sliderItem-MTQ {\n        flex-basis: 50%;\n        min-width: auto;\n    }\n\n    .collectionSlider-root-t5k {\n        margin: 0 -15px;\n    }\n\n    .collectionSlider-root-t5k button {\n        visibility: visible !important;\n    }\n\n    .collectionSlider-root-t5k button svg {\n        width: 32px;\n        height: 32px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "collectionSlider-root-t5k bg-snowbound py-8 lg_py-10 pl-2 lg_pl-5 sm_mt-10 pr-5 mb-5 overflow-hidden",
	"productContent": "collectionSlider-productContent-upv p-0",
	"actionsContainer": "collectionSlider-actionsContainer-8dI",
	"name": "collectionSlider-name-DUG text-sm font-bold leading-156",
	"skuContent": "collectionSlider-skuContent-lhb text-xs leading-229 flex justify-between",
	"price": "collectionSlider-price---5 text-sm leading-2 font-bold",
	"carousel": "collectionSlider-carousel-oyy",
	"sliderItem": "collectionSlider-sliderItem-MTQ px-2.5",
	"sliderThreeItems": "collectionSlider-sliderThreeItems-H2A",
	"sliderTwoItems": "collectionSlider-sliderTwoItems-wrV",
	"sliderOneItem": "collectionSlider-sliderOneItem-nfM",
	"sliderItemTwoItems": "collectionSlider-sliderItemTwoItems-Wld"
};
export default ___CSS_LOADER_EXPORT___;
