import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from '@magento/peregrine/lib/context/user';
import { isRequired } from '@magento/venia-ui/lib/util/formValidators';
import { Form } from 'informed';
import { useLazyQuery } from '@apollo/client';
import { GET_CART_DETAILS_QUERY } from '@magento/venia-ui/lib/components/SignIn/signIn.gql';
import LoadingIndicator from '@magento/venia-ui/lib/components/LoadingIndicator';
import { FormattedMessage } from 'react-intl';
import Trigger from '@magento/venia-ui/lib/components/Trigger';
import TextInput from '@magento/venia-ui/lib/components/TextInput';
import { useSignIn } from '@magento/peregrine/lib/talons/SignIn/useSignIn';
import { useHistory } from 'react-router-dom';
import classes from './signInModal.module.css';
import { CHECK_EMAIL } from '../actions';
import { Modal, Button, Field, ModalHeader, ModalBody } from '../../';
import FavouritesItem from './favouritesItem';
import { addScrollToBody, removeScrollFromBody } from '../../../hooks/utils';

var SignInModal = function SignInModal(_ref) {
  var item = _ref.item,
      isOpen = _ref.isOpen,
      closeModal = _ref.closeModal,
      itemOption = _ref.itemOption;

  var _useState = useState(1),
      _useState2 = _slicedToArray(_useState, 2),
      step = _useState2[0],
      setStep = _useState2[1];

  var _useState3 = useState(''),
      _useState4 = _slicedToArray(_useState3, 2),
      email = _useState4[0],
      setEmail = _useState4[1];

  var _useState5 = useState(''),
      _useState6 = _slicedToArray(_useState5, 2),
      username = _useState6[0],
      setUsername = _useState6[1];

  var _useState7 = useState(''),
      _useState8 = _slicedToArray(_useState7, 2),
      password = _useState8[0],
      setPassword = _useState8[1];

  var _useState9 = useState(null),
      _useState10 = _slicedToArray(_useState9, 2),
      error = _useState10[0],
      setError = _useState10[1];

  var history = useHistory();

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      isUserSignedIn = _useUserContext2[0].isSignedIn;

  var _useLazyQuery = useLazyQuery(CHECK_EMAIL),
      _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
      checkEmail = _useLazyQuery2[0],
      _useLazyQuery2$ = _useLazyQuery2[1],
      data = _useLazyQuery2$.data,
      emailError = _useLazyQuery2$.error;

  var _useSignIn = useSignIn({
    getCartDetailsQuery: GET_CART_DETAILS_QUERY,
    isWishlist: true,
    itemOption: itemOption,
    setError: setError
  }),
      errors = _useSignIn.errors,
      handleSubmit = _useSignIn.handleSubmit,
      isBusy = _useSignIn.isBusy,
      setFormApi = _useSignIn.setFormApi;

  useEffect(function () {
    return !email && error && setError(null);
  }, [email]);
  useEffect(function () {
    return emailError && setError(emailError === null || emailError === void 0 ? void 0 : emailError.message);
  }, [emailError]);
  useEffect(function () {
    return isBusy && isUserSignedIn && closeModal();
  }, [isBusy, isUserSignedIn]);
  useEffect(function () {
    var _data$checkEmail;

    if (data !== null && data !== void 0 && (_data$checkEmail = data.checkEmail) !== null && _data$checkEmail !== void 0 && _data$checkEmail.username) {
      var _data$checkEmail2;

      setUsername(data === null || data === void 0 ? void 0 : (_data$checkEmail2 = data.checkEmail) === null || _data$checkEmail2 === void 0 ? void 0 : _data$checkEmail2.username);
      setStep(2);
      setError(null);
    }
  }, [data]);
  useEffect(function () {
    if (isOpen) removeScrollFromBody(false);else addScrollToBody(false);
    return function () {
      return addScrollToBody(false);
    };
  }, [isOpen]);
  return React.createElement(React.Fragment, null, React.createElement(Modal, {
    isOpen: isOpen,
    classes: {
      modalWrapper: classes.modalWrapper
    }
  }, React.createElement(ModalHeader, {
    title: "Select your preferred list(s)",
    classes: {
      root: 'text-22 py-6 text-white bg-bronze px-8'
    },
    closeModal: closeModal
  }), step === 1 && React.createElement(ModalBody, {
    classes: {
      root: 'px-4 md_px-16'
    }
  }, React.createElement(FavouritesItem, {
    item: item
  }), React.createElement("div", {
    className: "text-center mt-1 pb-10 px-10"
  }, React.createElement("h5", {
    className: "mt-9 leading-160 text-black text-h5"
  }, "Before you do that"), React.createElement("p", {
    className: "lg_pb-8 mt-1.5 mb-2 md_mb-0 text-bronze px-5 md_px-0"
  }, "Please sign in with your email and password"), React.createElement(Form, {
    onSubmit: function onSubmit() {
      return checkEmail({
        variables: {
          email: email
        }
      });
    }
  }, React.createElement(Field, {
    id: "email",
    label: "email"
  }, React.createElement(TextInput, {
    autoComplete: "email",
    field: "email",
    id: "email",
    onChange: function onChange(e) {
      return setEmail(e.target.value);
    },
    value: email,
    validate: isRequired,
    placeholder: "Email address *",
    classes: {
      input: 'text-xs md_text-sm'
    }
  })), error && React.createElement("p", {
    className: "text-red mt-2 text-left"
  }, error), React.createElement("div", {
    className: "mt-7 md_mt-10 mb-8"
  }, React.createElement(Button, {
    priority: "low",
    type: "submit",
    size: "large",
    "aria-label": "Continue"
  }, "CONTINUE"))), React.createElement("p", {
    className: "text-bronze leading-2 text-sm"
  }, "You do not have web access with us?"), React.createElement("p", {
    className: "text-bronze leading-2 text-sm"
  }, "Please", React.createElement(Link, {
    to: '/register',
    onClick: closeModal,
    className: "underline cursor-pointer ml-1.5 font-bold",
    "aria-label": "Sign up Here"
  }, "Sign up Here")))), step === 2 && React.createElement("div", {
    className: "flex flex-col text-center"
  }, React.createElement("div", {
    className: "bg-white px-4 md_px-16"
  }, React.createElement("h5", {
    className: "whitespace-pre-line break-all text-black mt-6 md_mt-9"
  }, email), React.createElement(Trigger, {
    action: function action() {
      return setStep(1);
    },
    classes: {
      root: 'mt-2.5 underline font-bold text-bronze cursor-pointer'
    },
    "aria-label": "Not your email? Go Back"
  }, "Not your email? Go Back"), React.createElement("hr", {
    className: "mt-4 mb-7 md_mb-6 h-1/4 border-solid text-bronze"
  }), React.createElement("h5", {
    className: "mb-1"
  }, "Welcome back, ", username), React.createElement("p", {
    className: "text-bronze w-3/5 mx-auto text-center text-sm"
  }, "Great to see you again. Enter your Password to continue."), isBusy && !isUserSignedIn && React.createElement(LoadingIndicator, null, React.createElement(FormattedMessage, {
    id: 'signIn.loadingText',
    defaultMessage: 'Signing In'
  })), React.createElement(Form, {
    getApi: setFormApi,
    onSubmit: function onSubmit() {
      return handleSubmit({
        email: email,
        password: password
      }).then(function (data) {
        if (data !== null && data !== void 0 && data.message) return;
      });
    },
    className: "px-0 md_px-20 mt-1 md_mt-5"
  }, React.createElement(Field, {
    id: "password",
    label: "password"
  }, React.createElement(TextInput, {
    placeholder: "Password",
    autoComplete: "password",
    field: "password",
    id: "password",
    type: "password",
    onChange: function onChange(e) {
      return setPassword(e.target.value);
    },
    value: password
  })), error && React.createElement("p", {
    className: "text-red mt-2 text-left"
  }, error), React.createElement("div", {
    className: "mt-7 md_mt-10"
  }, React.createElement(Button, {
    priority: "low",
    type: "submit",
    size: "large",
    "aria-label": "Login"
  }, "LOGIN"))), React.createElement("p", {
    className: "underline under text-textColorSecondary cursor-pointer mt-3 md_mt-8"
  }, React.createElement(Link, {
    to: '/reset-password',
    onClick: closeModal,
    className: "underline cursor-pointer font-bold",
    "aria-label": "Go to Reset password page"
  }, "Forgot Password?"))), React.createElement("div", {
    className: "mt-6 pt-7 px-4 lg_px-14 md_py-8 md_mt-10 bg-snowbound text-sm"
  }, React.createElement("div", {
    className: "md_px-7"
  }, React.createElement("h6", null, "Have an Existing Account, But Logging in for the First Time?")), React.createElement("p", {
    className: "md_px-9 pt-3 pb-5 text-bronze text-sm"
  }, "If you had an account with us on our previous site, please reset your password below."), React.createElement("div", {
    className: classes.resetPasswordButton
  }, React.createElement(Button, {
    priority: "normal",
    size: "large",
    onClick: function onClick() {
      return history.push('/reset-password');
    },
    "aria-label": "Reset password"
  }, React.createElement("span", {
    className: "uppercase"
  }, "Reset Password")))))));
};

export default SignInModal;