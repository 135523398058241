// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".message-root-PRA {\n    margin-top: 5px;\n}\n\n.message-root-PRA:empty {\n    display: none;\n}\n\n.message-root_error-UMR {\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "message-root-PRA text-bronze text-xs",
	"root_error": "message-root_error-UMR message-root-PRA text-bronze text-xs text-red"
};
export default ___CSS_LOADER_EXPORT___;
