// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mask-root-ay- {\n    transition-duration: 192ms;\n    transition-property: opacity, visibility;\n    transition-timing-function: linear;\n\n    /* TODO @TW: review (B6) */\n    /* composes: invisible from global; */\n    visibility: hidden;\n}\n\n.mask-root_active-T-a {\n    transition-duration: 224ms;\n\n    /* TODO @TW: review (B6) */\n    /* composes: visible from global; */\n    visibility: visible;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "mask-root-ay- appearance-none bg-black block cursor-pointer fixed h-full left-0 opacity-0 top-0 w-full",
	"root_active": "mask-root_active-T-a mask-root-ay- appearance-none bg-black block cursor-pointer fixed h-full left-0 opacity-0 top-0 w-full opacity-50"
};
export default ___CSS_LOADER_EXPORT___;
