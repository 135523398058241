// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".viewByCategoryLinks-subitemsWrapper-vMA {\n    display: none;\n}\n\n.viewByCategoryLinks-subitemsWrapper_active-cXw {\n    display: flex;\n    height: 520px;\n    width: 750px;\n}\n\n@media (max-width: 1536px) {\n    .viewByCategoryLinks-subitemsWrapper_active-cXw {\n        width: 587px;\n    }\n}\n\n@media (max-width: 1280px) {\n    .viewByCategoryLinks-subitemsWrapper_active-cXw {\n        width: 500px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subitemsWrapper": "viewByCategoryLinks-subitemsWrapper-vMA border-l border-solid border-gray",
	"subitemsWrapper_active": "viewByCategoryLinks-subitemsWrapper_active-cXw viewByCategoryLinks-subitemsWrapper-vMA border-l border-solid border-gray flex-col flex-wrap"
};
export default ___CSS_LOADER_EXPORT___;
