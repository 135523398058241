import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client'; // import { useAppContext } from '@magento/peregrine/lib/context/app';

import { useScrollTopOnChange } from '@magento/peregrine/lib/hooks/useScrollTopOnChange';
import { getFiltersFromSearch, getFilterInput } from '@magento/peregrine/lib/talons/FilterModal/helpers';
import DEFAULT_OPERATIONS from '@magento/peregrine/lib/talons/RootComponents/Category/category.gql';
import mergeOperations from '@magento/peregrine/lib/util/shallowMerge';
import { useAppContext } from '@magento/peregrine/lib/context/app';
import { useSort } from '@magento/peregrine/lib/hooks/useSort';
import { usePagination } from '@magento/peregrine/lib/hooks/usePagination';
/**
 * A [React Hook]{@link https://reactjs.org/docs/hooks-intro.html} that
 * controls the logic for the Category Root Component.
 *
 * @kind function
 *
 * @param {object}      props
 * @param {String}      props.id - Category uid.
 * @param {GraphQLAST}  props.operations.getCategoryQuery - Fetches category using a server query
 * @param {GraphQLAST}  props.operations.getFilterInputsQuery - Fetches "allowed" filters using a server query
 * @param {GraphQLAST}  props.queries.getStoreConfig - Fetches store configuration using a server query
 *
 * @returns {object}    result
 * @returns {object}    result.error - Indicates a network error occurred.
 * @returns {object}    result.categoryData - Category data.
 * @returns {bool}      result.isLoading - Category data loading.
 * @returns {string}    result.metaDescription - Category meta description.
 * @returns {object}    result.pageControl - Category pagination state.
 * @returns {array}     result.sortProps - Category sorting parameters.
 * @returns {number}    result.pageSize - Category total pages.
 */

export var useCategory = function useCategory(props) {
  var _pageSizeData$storeCo, _pageSizeData$storeCo2;

  var id = props.id,
      getPageSize = props.queries.getPageSize;
  var operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  var getCategoryQuery = operations.getCategoryQuery,
      getFilterInputsQuery = operations.getFilterInputsQuery;

  var _useQuery = useQuery(getPageSize, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  }),
      pageSizeData = _useQuery.data;

  var pageSize = pageSizeData && pageSizeData.storeConfig.grid_per_page;
  var pageSizeValues = pageSizeData && (pageSizeData === null || pageSizeData === void 0 ? void 0 : (_pageSizeData$storeCo = pageSizeData.storeConfig) === null || _pageSizeData$storeCo === void 0 ? void 0 : (_pageSizeData$storeCo2 = _pageSizeData$storeCo.grid_per_page_values) === null || _pageSizeData$storeCo2 === void 0 ? void 0 : _pageSizeData$storeCo2.split(','));

  var _usePagination = usePagination(),
      _usePagination2 = _slicedToArray(_usePagination, 2),
      paginationValues = _usePagination2[0],
      paginationApi = _usePagination2[1];

  var currentPage = paginationValues.currentPage,
      totalPages = paginationValues.totalPages;
  var setCurrentPage = paginationApi.setCurrentPage,
      setTotalPages = paginationApi.setTotalPages;
  var sortProps = useSort({
    sortFromSearch: false
  });

  var _sortProps = _slicedToArray(sortProps, 1),
      currentSort = _sortProps[0]; // Keep track of the sort criteria so we can tell when they change.


  var previousSort = useRef(currentSort);
  var pageControl = {
    currentPage: currentPage,
    setPage: setCurrentPage,
    totalPages: totalPages
  };

  var _useAppContext = useAppContext(),
      _useAppContext2 = _slicedToArray(_useAppContext, 2),
      setPageLoading = _useAppContext2[1].actions.setPageLoading;

  var _useLazyQuery = useLazyQuery(getCategoryQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  }),
      _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
      runQuery = _useLazyQuery2[0],
      queryResponse = _useLazyQuery2[1];

  var categoryCalled = queryResponse.called,
      categoryLoading = queryResponse.loading,
      error = queryResponse.error,
      data = queryResponse.data;

  var _useLocation = useLocation(),
      search = _useLocation.search;

  var isBackgroundLoading = !!data && categoryLoading; // Update the page indicator if the GraphQL query is in flight.

  useEffect(function () {
    setPageLoading(isBackgroundLoading);
  }, [isBackgroundLoading, setPageLoading]); // Keep track of the search terms so we can tell when they change.

  var previousSearch = useRef(search); // Get "allowed" filters by intersection of schema and aggregations

  var _useQuery2 = useQuery(getFilterInputsQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  }),
      introspectionCalled = _useQuery2.called,
      introspectionData = _useQuery2.data,
      introspectionLoading = _useQuery2.loading; // Create a type map we can reference later to ensure we pass valid args
  // to the graphql query.
  // For example: { category_id: 'FilterEqualTypeInput', price: 'FilterRangeTypeInput' }


  var filterTypeMap = useMemo(function () {
    var typeMap = new Map();

    if (introspectionData) {
      introspectionData.__type.inputFields.forEach(function (_ref) {
        var name = _ref.name,
            type = _ref.type;
        typeMap.set(name, type.name);
      });

      typeMap.set('klevu_price', 'FilterRangeTypeInput');
    }

    return typeMap;
  }, [introspectionData]); // Run the category query immediately and whenever its variable values change.

  useEffect(function () {
    // Wait until we have the type map to fetch product data.
    if (!filterTypeMap.size || !pageSize) {
      return;
    }

    var filters = getFiltersFromSearch(search); // Construct the filter arg object.

    var newFilters = {};
    filters.forEach(function (values, key) {
      newFilters[key] = getFilterInput(values, filterTypeMap.get(key));
    }); // Use the category uid for the current category page regardless of the
    // applied filters. Follow-up in PWA-404.

    newFilters['category_uid'] = {
      eq: id
    }; // runQuery({
    //     variables: {
    //         currentPage: Number(currentPage),
    //         id: id,
    //         filters: newFilters,
    //         pageSize: Number(pageSize),
    //         sort: { [currentSort.sortAttribute]: currentSort.sortDirection }
    //     }
    // });
  }, [currentPage, currentSort, filterTypeMap, id, pageSize, runQuery, search]);
  var totalPagesFromData = data ? data.products.page_info.total_pages : null;
  useEffect(function () {
    setTotalPages(totalPagesFromData);
    return function () {
      setTotalPages(null);
    };
  }, [setTotalPages, totalPagesFromData]); // If we get an error after loading we should try to reset to page 1.
  // If we continue to have errors after that, render an error message.

  useEffect(function () {
    if (error && !categoryLoading && !data && currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [currentPage, error, categoryLoading, setCurrentPage, data]); // Reset the current page back to one (1) when the search string, filters
  // or sort criteria change.

  useEffect(function () {
    // We don't want to compare page value.
    var prevSearch = new URLSearchParams(previousSearch.current);
    var nextSearch = new URLSearchParams(search);
    prevSearch["delete"]('page');
    nextSearch["delete"]('page');

    if (prevSearch.toString() !== nextSearch.toString() || previousSort.current.sortAttribute.toString() !== currentSort.sortAttribute.toString() || previousSort.current.sortDirection.toString() !== currentSort.sortDirection.toString()) {
      // The search term changed.
      setCurrentPage(1, true); // And update the ref.

      previousSearch.current = search;
      previousSort.current = currentSort;
    }
  }, [currentSort, previousSearch, search, setCurrentPage]);
  var categoryData = categoryLoading && !data ? null : data;
  var categoryNotFound = !categoryLoading && data && data.categories.items.length === 0;
  var metaDescription = data && data.categories.items[0] && data.categories.items[0].meta_description ? data.categories.items[0].meta_description : ''; // When only categoryLoading is involved, noProductsFound component flashes for a moment

  var loading = introspectionCalled && !categoryCalled || categoryLoading && !data || introspectionLoading;
  useScrollTopOnChange(currentPage);
  return {
    error: error,
    categoryData: categoryData,
    loading: loading,
    metaDescription: metaDescription,
    pageControl: pageControl,
    sortProps: sortProps,
    pageSize: pageSize,
    pageSizeValues: pageSizeValues,
    categoryNotFound: categoryNotFound
  };
};