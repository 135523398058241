// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdown-dropdown-Rna {\n}\n\n.dropdown-dropdownLabel-7an {\n}\n\n.dropdown-dropdownItem-F8O {\n}\n.dropdown-dropdownItem-F8O:hover {\n    background-color: var(--venia-global-color-urbane-bronze);\n    color: var(--venia-global-color-white);\n}\n\n.dropdown-dropdownLabel-7an svg {\n    height: 4px;\n    height: 8px;\n}\n\n.dropdown-dropdownOptions-BUF {\n    transform: translateY(100%);\n}\n.dropdown-icon-Ft7 {\n    transform: rotate(0deg);\n    transition: all 0.3s ease-in-out;\n}\n\n.dropdown-iconActive-RYi {\n    transform: rotate(180deg);\n    transition: all 0.3s ease-in-out;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "dropdown-dropdown-Rna relative",
	"dropdownLabel": "dropdown-dropdownLabel-7an flex leading-171 justify-between items-center px-4 py-2 border border-solid border-bronze",
	"dropdownItem": "dropdown-dropdownItem-F8O leading-171 py-2",
	"dropdownOptions": "dropdown-dropdownOptions-BUF bg-snowbound z-10 absolute bottom-1 border-l border-r border-b border-solid border-bronze w-full overflow-hidden",
	"icon": "dropdown-icon-Ft7",
	"iconActive": "dropdown-iconActive-RYi"
};
export default ___CSS_LOADER_EXPORT___;
