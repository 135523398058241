import _extends from "@babel/runtime/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import resourceUrl from '@magento/peregrine/lib/util/makeUrl';
import { useStyle } from '@magento/venia-ui/lib/classify';
import { Link } from 'react-router-dom';
import urlReMapper from './mapper';
import defaultClasses from './subMenuColumn.module.css';
/**
 * The SubmenuColumn component displays columns with categories in submenu
 *
 * @param {MegaMenuCategory} props.category
 * @param {function} props.onNavigate - function called when clicking on Link
 */

var SubmenuColumn = function SubmenuColumn(_ref) {
  var category = _ref.category,
      categoryUrlSuffix = _ref.categoryUrlSuffix,
      onNavigate = _ref.onNavigate,
      isActive = _ref.isActive,
      handleHoverLink = _ref.handleHoverLink,
      classes = _ref.classes,
      keyboardProps = _ref.keyboardProps;
  var newCasses = useStyle(defaultClasses, classes);
  var resourcesAllLinks = useSelector(function (state) {
    return state.resources.resourcesLinks;
  });
  var categoryUrl = resourceUrl("/".concat(category.url_path).concat(categoryUrlSuffix || ''));
  var remappedUrl = urlReMapper.find(function (x) {
    return x.url === categoryUrl;
  });

  if (remappedUrl) {
    categoryUrl = remappedUrl.redirectUrl;
  } // setting keyboardProps if category does not have any sub-category


  var newKeyboardProps = category.children.length ? {} : keyboardProps;
  var isActiveSubColumn = isActive ? newCasses.submenuColumn_active : newCasses.submenuColumn;
  var content;

  if (category.name === 'Blog') {
    content = React.createElement("a", {
      className: isActiveSubColumn,
      onMouseOver: function onMouseOver() {
        return handleHoverLink(category.id);
      },
      href: "https://blog.hubbardtonforge.com/",
      target: "_blank",
      rel: "noopener"
    }, category.name);
  } else if (category.name === 'Professional Resources') {
    var _resourcesAllLinks$;

    content = React.createElement(Link, {
      to: "/resources?tab=".concat(resourcesAllLinks === null || resourcesAllLinks === void 0 ? void 0 : (_resourcesAllLinks$ = resourcesAllLinks[0]) === null || _resourcesAllLinks$ === void 0 ? void 0 : _resourcesAllLinks$.name),
      className: isActiveSubColumn,
      onMouseOver: function onMouseOver() {
        return handleHoverLink(category.id);
      },
      "aria-label": "go to Resources page"
    }, React.createElement("button", _extends({
      type: "button"
    }, newKeyboardProps, {
      className: newCasses.link,
      onClick: onNavigate,
      "aria-label": "go to Resources page"
    }), React.createElement("span", {
      className: newCasses.heading
    }, category.name)));
  } else if (category.name === 'View by Category' || category.name === 'View by Room Setting') {
    content = React.createElement("div", {
      className: isActiveSubColumn,
      onMouseOver: function onMouseOver() {
        return handleHoverLink(category.id);
      }
    }, React.createElement("span", {
      className: newCasses.heading
    }, category.name));
  } else {
    content = React.createElement(Link, {
      to: categoryUrl,
      className: isActiveSubColumn,
      onMouseOver: function onMouseOver() {
        return handleHoverLink(category.id);
      },
      "aria-label": "Go to ".concat(category.name)
    }, React.createElement("button", _extends({
      type: "button"
    }, newKeyboardProps, {
      className: newCasses.link,
      onClick: onNavigate,
      "aria-label": "Go to ".concat(category.name)
    }), React.createElement("span", {
      className: newCasses.heading
    }, category.name)));
  }

  return React.createElement(React.Fragment, null, content);
};

export default SubmenuColumn;
SubmenuColumn.propTypes = {
  category: PropTypes.shape({
    children: PropTypes.array,
    id: PropTypes.number.isRequired,
    include_in_menu: PropTypes.number,
    isActive: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.array.isRequired,
    position: PropTypes.number.isRequired,
    url_path: PropTypes.string.isRequired
  }).isRequired,
  categoryUrlSuffix: PropTypes.string,
  onNavigate: PropTypes.func.isRequired,
  handleHoverLink: PropTypes.func
};