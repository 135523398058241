import { useQuery } from '@apollo/client';
import mergeOperations from '@magento/peregrine/lib/util/shallowMerge';
import DEFAULT_OPERATIONS from './footerData.gql';
/**
 *
 * @param {*} props.operations GraphQL operations used by talons
 */
//this hook returns data from footer module. It contains list of grouped links displayed in footer.

export var useFooterData = function useFooterData() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  var getFooterData = operations.getFooterData,
      getCopyrightQuery = operations.getCopyrightQuery;

  var _useQuery = useQuery(getFooterData, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only'
  }),
      isLoading = _useQuery.isLoading,
      error = _useQuery.error,
      data = _useQuery.data;

  var _useQuery2 = useQuery(getCopyrightQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only'
  }),
      copyrightLoading = _useQuery2.isLoading,
      copyrightError = _useQuery2.error,
      copyrightData = _useQuery2.data;

  return {
    footerData: data && data.getFooter,
    copyrightData: copyrightData && copyrightData.footerBottom,
    isLoading: copyrightLoading || isLoading,
    error: error || copyrightError
  };
};