import app from '@magento/peregrine/lib/store/reducers/app';
import cart from '@magento/peregrine/lib/store/reducers/cart';
import catalog from '@magento/peregrine/lib/store/reducers/catalog';
import checkout from '@magento/peregrine/lib/store/reducers/checkout';
import user from './user';
import resources from './resources';
import quotes from './quotes';
var reducers = {
  app: app,
  cart: cart,
  catalog: catalog,
  checkout: checkout,
  user: user,
  resources: resources,
  quotes: quotes
};
export default reducers;