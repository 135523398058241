import React from 'react';

var SidebarIcon = function SidebarIcon() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "40",
    height: "40",
    viewBox: "0 0 40 40"
  }, React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("circle", {
    cx: "20",
    cy: "20",
    r: "20",
    fill: "#3E3E3E"
  }), React.createElement("path", {
    stroke: "#FFF",
    strokeLinecap: "square",
    strokeWidth: "2",
    d: "M8.5 26.5h23m-23-13h23m-3 2v-4m-17 17v-4"
  })));
};

export default SidebarIcon;