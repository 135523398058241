function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { rangeSliderFilters } from '../../../venia-ui/components/SearchPage/useKlevuSearchResults';
export var getAllFilters = function getAllFilters() {
  var allFilters = new URLSearchParams(window.location.search);
  var filters = [];
  allFilters.forEach(function (value, index) {
    if (index.includes('[filter]') || index.includes('page')) {
      var isRangeSlider = rangeSliderFilters.includes(index === null || index === void 0 ? void 0 : index.replace('[filter]', ''));
      var newValue = isRangeSlider ? value === null || value === void 0 ? void 0 : value.replace(',', '_') : value;
      filters.push({
        value: newValue,
        key: index === null || index === void 0 ? void 0 : index.replace('[filter]', '')
      });
    }

    return value;
  });
  return filters;
};
export var AddFilter = function AddFilter(group, value, history) {
  var groupName = group + '[filter]';
  var finalValue = !(value !== null && value !== void 0 && value.type) ? (value === null || value === void 0 ? void 0 : value.title) + ',' + (value === null || value === void 0 ? void 0 : value.value) : (value === null || value === void 0 ? void 0 : value.type) === 'range' ? (value === null || value === void 0 ? void 0 : value.title) + '_' + (value === null || value === void 0 ? void 0 : value.value) : (value === null || value === void 0 ? void 0 : value.title) + ',' + (value === null || value === void 0 ? void 0 : value.value);
  var newState = new URLSearchParams(window.location.search);
  var currentFilter = newState.getAll(groupName);

  if (!(value !== null && value !== void 0 && value.type)) {
    if (currentFilter.length) {
      var valueExists = currentFilter.find(function (x) {
        return x === finalValue;
      });

      if (valueExists) {
        newState["delete"](groupName);

        var _iterator = _createForOfIteratorHelper(currentFilter),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var _value = _step.value;

            if (_value !== finalValue) {
              newState.append(groupName, _value);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      } else {
        newState.append(groupName, finalValue);
      }
    } else {
      newState.append(groupName, finalValue);
    }
  } else if ((value === null || value === void 0 ? void 0 : value.type) === 'range') {
    if (currentFilter.length) {
      newState["delete"](groupName);
    }

    newState.append(groupName, finalValue);
  }

  history.push({
    pathname: window.location.pathname,
    search: newState.toString()
  });
};
export var removeFilter = function removeFilter(args, history) {
  var value = args[1];
  var groupNameReal = args[0] + '[filter]';
  var oldState = new URLSearchParams(window.location.search);
  var finalValue = !(value !== null && value !== void 0 && value.type) ? (value === null || value === void 0 ? void 0 : value.title) + ',' + (value === null || value === void 0 ? void 0 : value.title) : (value === null || value === void 0 ? void 0 : value.type) === 'range' ? value === null || value === void 0 ? void 0 : value.title : (value === null || value === void 0 ? void 0 : value.title) + ',' + (value === null || value === void 0 ? void 0 : value.value);
  var currentFilter = oldState.getAll(groupNameReal);

  if (!(value !== null && value !== void 0 && value.type)) {
    if (currentFilter.length) {
      var valueExists = currentFilter.find(function (x) {
        return x === finalValue;
      });

      if (valueExists) {
        console.log(groupNameReal);
        oldState["delete"](groupNameReal);

        var _iterator2 = _createForOfIteratorHelper(currentFilter),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var _value2 = _step2.value;

            if (_value2 !== finalValue) {
              oldState.append(groupNameReal, _value2);
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      } else {
        oldState.append(groupNameReal, finalValue);
      }
    } else {
      oldState.append(groupNameReal, finalValue);
    }
  } else if ((value === null || value === void 0 ? void 0 : value.type) === 'range') {
    if (currentFilter.length) {
      oldState["delete"](groupNameReal);
    }
  }

  history.push({
    pathname: window.location.pathname,
    search: oldState.toString()
  });
};
export var removeAllFilters = function removeAllFilters(history) {
  var oldState = new URLSearchParams(window.location.search);
  var newState = new URLSearchParams();
  var currentFilter = oldState.getAll('query');

  if (currentFilter) {
    newState.append('query', currentFilter);
    newState.append('page', '1');
  }

  history.push({
    pathname: window.location.pathname,
    search: newState.toString()
  });
};