import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDropdown } from '@magento/peregrine/lib/hooks/useDropdown';
var initialValues = {
  search_query: ''
};
export var useSearchBar = function useSearchBar() {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      valid = _useState2[0],
      setValid = _useState2[1];

  var _useDropdown = useDropdown(),
      elementRef = _useDropdown.elementRef,
      isAutoCompleteOpen = _useDropdown.expanded,
      setIsAutoCompleteOpen = _useDropdown.setExpanded;

  var history = useHistory();
  var push = history.push; // expand or collapse on input change

  var handleChange = useCallback(function (value) {
    var hasValue = !!value;
    var isValid = hasValue && value.length > 2;
    setValid(isValid);
    setIsAutoCompleteOpen(hasValue);
  }, [setIsAutoCompleteOpen, setValid]); // expand on focus

  var handleFocus = useCallback(function () {
    setIsAutoCompleteOpen(true);
  }, [setIsAutoCompleteOpen]); // navigate on submit

  var handleSubmit = useCallback(function (_ref) {
    var search_query = _ref.search_query;

    if (search_query != null && search_query.trim().length > 0) {
      push("/search.html?query=".concat(search_query));
      setIsAutoCompleteOpen(false);
    }
  }, [push, setIsAutoCompleteOpen]);
  return {
    containerRef: elementRef,
    handleChange: handleChange,
    handleFocus: handleFocus,
    handleSubmit: handleSubmit,
    initialValues: initialValues,
    isAutoCompleteOpen: isAutoCompleteOpen,
    setIsAutoCompleteOpen: setIsAutoCompleteOpen,
    setValid: setValid,
    valid: valid
  };
};