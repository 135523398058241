import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { gql } from '@apollo/client';
export var GET_CATEGORY_URL_SUFFIX = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query GetStoreConfigForCategoryTree {\n        # eslint-disable-next-line @graphql-eslint/require-id-when-available\n        storeConfig {\n            store_code\n            category_url_suffix\n        }\n    }\n"])));
export var GET_NAVIGATION_MENU = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query GetNavigationMenu($id: String!) {\n        categories(filters: { category_uid: { in: [$id] } }) {\n            # eslint-disable-next-line @graphql-eslint/require-id-when-available\n            items {\n                uid\n                name\n                # eslint-disable-next-line @graphql-eslint/require-id-when-available\n                children {\n                    children_count\n                    uid\n                    include_in_menu\n                    name\n                    position\n                    url_path\n                    url_suffix\n                }\n                children_count\n                include_in_menu\n                url_path\n            }\n        }\n    }\n"])));
export default {
  getNavigationMenuQuery: GET_NAVIGATION_MENU,
  getCategoryUrlSuffixQuery: GET_CATEGORY_URL_SUFFIX
};