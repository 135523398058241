import React, { useCallback } from 'react';
import Trigger from '@magento/venia-ui/lib/components/Trigger';
import { useIntl } from 'react-intl';
import { CloseIconNew } from '..';
import classes from './currentFilterItem.module.css';
import { rangeSliderFilters } from '../../overrides/venia-ui/components/SearchPage/useKlevuSearchResults';

var CurrentFilterItem = function CurrentFilterItem(_ref) {
  var item = _ref.item,
      group = _ref.group,
      removeItem = _ref.removeItem,
      onRemove = _ref.onRemove,
      klevuFilterManager = _ref.klevuFilterManager;

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var title = item.title;
  var handleClick = useCallback(function () {
    // removeItem({ group, item });
    // if (rangeSliderFilters.includes(group)) {
    //     klevuFilterManager.updateSlide(group, '', '');
    // }
    if (typeof onRemove === 'function') {
      onRemove(group, item, 'remove');
    }
  }, [group, item, removeItem]);
  var ariaLabel = formatMessage({
    id: 'filterModal.action.clearFilterItem.ariaLabel',
    defaultMessage: 'Clear filter'
  }, {
    name: item.title
  }); // TODO change name

  if (group === 'hf_sloped_ceiling_bool' || group === 'hf_new_designs' || group === 'hf_dark_sky_friendly' || group === 'hf_led_bool' || group === 'hf_adacompliant') {
    if (group === 'hf_sloped_ceiling_bool') {
      title = 'Slope Ceiling Adaptable';
    }

    if (group === 'hf_new_designs') {
      title = 'New Designs';
    }

    if (group === 'hf_dark_sky_friendly') {
      title = 'Dark Sky Friendly';
    }

    if (group === 'hf_led_bool') {
      title = 'LED';
    }

    if (group === 'hf_adacompliant') {
      title = 'ADA Compliant';
    } // title = group;

  }

  return React.createElement("div", {
    className: classes.filterItemWrapper
  }, React.createElement("span", {
    className: "text-h6"
  }, title), React.createElement(Trigger, {
    action: handleClick,
    ariaLabel: ariaLabel,
    "aria-label": "Close filter"
  }, React.createElement("span", {
    className: "sr-only text-black"
  }, "Close filter"), React.createElement(CloseIconNew, null)));
};

export default CurrentFilterItem;