import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { array, func, shape, string } from 'prop-types';
import { useToasts } from '@magento/peregrine';
import useDelayedTransition from '@magento/peregrine/lib/hooks/useDelayedTransition';
import { useApp } from '@magento/peregrine/lib/talons/App/useApp';
import { HeadProvider, Meta, StoreTitle } from '@magento/venia-ui/lib/components/Head';
import Mask from '@magento/venia-ui/lib/components/Mask';
import Routes from '@magento/venia-ui/lib/components/Routes';
import ToastContainer from '@magento/venia-ui/lib/components/ToastContainer';
import Icon from '@magento/venia-ui/lib/components/Icon';
import Main from '../Main';
import globalCSS from './index.module.css';
import { AlertCircle as AlertCircleIcon, CloudOff as CloudOffIcon, Wifi as WifiIcon } from 'react-feather';
var OnlineIcon = React.createElement(Icon, {
  src: WifiIcon,
  attrs: {
    width: 18
  }
});
var OfflineIcon = React.createElement(Icon, {
  src: CloudOffIcon,
  attrs: {
    width: 18
  }
});
var ErrorIcon = React.createElement(Icon, {
  src: AlertCircleIcon,
  attrs: {
    width: 18
  }
});

var App = function App(props) {
  var markErrorHandled = props.markErrorHandled,
      renderError = props.renderError,
      unhandledErrors = props.unhandledErrors;

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var _useToasts = useToasts(),
      _useToasts2 = _slicedToArray(_useToasts, 2),
      addToast = _useToasts2[1].addToast;

  useDelayedTransition();
  var ERROR_MESSAGE = formatMessage({
    id: 'app.errorUnexpected',
    defaultMessage: 'Sorry! An unexpected error occurred.'
  });
  var handleIsOffline = useCallback(function () {
    addToast({
      type: 'error',
      icon: OfflineIcon,
      message: formatMessage({
        id: 'app.errorOffline',
        defaultMessage: 'You are offline. Some features may be unavailable.'
      }),
      timeout: 3000
    });
  }, [addToast, formatMessage]);
  var handleIsOnline = useCallback(function () {
    addToast({
      type: 'info',
      icon: OnlineIcon,
      message: formatMessage({
        id: 'app.infoOnline',
        defaultMessage: 'You are online.'
      }),
      timeout: 3000
    });
  }, [addToast, formatMessage]);
  var handleError = useCallback(function (error, id, loc, handleDismissError) {
    var errorToastProps = {
      icon: ErrorIcon,
      message: "".concat(ERROR_MESSAGE, "\nDebug: ").concat(id, " ").concat(loc),
      onDismiss: function onDismiss(remove) {
        handleDismissError();
        remove();
      },
      timeout: 15000,
      type: 'error'
    };
    addToast(errorToastProps);
  }, [ERROR_MESSAGE, addToast]);

  var _useApp = useApp({
    handleError: handleError,
    handleIsOffline: handleIsOffline,
    handleIsOnline: handleIsOnline,
    markErrorHandled: markErrorHandled,
    renderError: renderError,
    unhandledErrors: unhandledErrors
  }),
      hasOverlay = _useApp.hasOverlay,
      handleCloseDrawer = _useApp.handleCloseDrawer;

  if (renderError) {
    return React.createElement(HeadProvider, null, React.createElement(StoreTitle, null), React.createElement(Main, {
      isMasked: true
    }), React.createElement(Mask, {
      isActive: true
    }), React.createElement(ToastContainer, null));
  }

  return React.createElement(HeadProvider, null, React.createElement(StoreTitle, null), React.createElement(Meta, {
    name: "viewport",
    content: "width=device-width, initial-scale=1.0 user-scalable=no"
  }), React.createElement(Main, {
    isMasked: hasOverlay
  }, React.createElement(Routes, null)), React.createElement(Mask, {
    isActive: hasOverlay,
    dismiss: handleCloseDrawer,
    classes: {
      root_active: 'z-0'
    }
  }), React.createElement(ToastContainer, null));
};

App.propTypes = {
  markErrorHandled: func.isRequired,
  renderError: shape({
    stack: string
  }),
  unhandledErrors: array
};
App.globalCSS = globalCSS;
export default App;