// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".currentFilters-filterDropdown-vor {\n}\n\n.currentFilters-filterDropdown_active-jQ4 {\n  width: 414px;\n}\n.currentFilters-filterDropdown_active-jQ4 h6,\n.currentFilters-filterDropdown_active-jQ4 button {\n  color: var(--venia-global-color-white);\n}\n\n.currentFilters-optionsWrapper-sxb {\n  transform: translateY(100%);\n}\n.currentFilters-item-iOJ {\n}\n\n.currentFilters-triggerRoot-HPa {\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterDropdown": "currentFilters-filterDropdown-vor relative flex ml-5",
	"filterDropdown_active": "currentFilters-filterDropdown_active-jQ4 currentFilters-filterDropdown-vor relative flex ml-5 bg-bronze",
	"optionsWrapper": "currentFilters-optionsWrapper-sxb absolute bottom-0 left-0 flex flex-wrap pl-2 pr-2.5 bg-bronze z-10 w-full py-4",
	"item": "currentFilters-item-iOJ list-none",
	"triggerRoot": "currentFilters-triggerRoot-HPa ml-7 underline text-bronze font-bold"
};
export default ___CSS_LOADER_EXPORT___;
