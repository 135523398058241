// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-modalWrapper-Y-Z {\n    overflow: scroll;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 10001;\n    overflow: hidden;\n}\n\n.modal-fullModal-gGU {\n    /* composes: flex from global;\n    composes: items-center from global;\n    composes: justify-center from global; */\n}\n\n.modal-normalModal-TE6 {\n    max-width: 647px;\n}\n\n@media (max-width: 768px) {\n    .modal-normalModal-TE6 {\n        width: 95%;\n        min-width: auto;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": "modal-modalWrapper-Y-Z fixed t-0 l-0 w-full flex items-center justify-center",
	"fullModal": "modal-fullModal-gGU h-full w-full bg-bronze",
	"normalModal": "modal-normalModal-TE6 bg-white"
};
export default ___CSS_LOADER_EXPORT___;
