import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React, { useEffect, useRef, useState } from 'react';
import { verticalAlignmentToFlex } from '@magento/pagebuilder/lib/utils';
import { useStyle } from '@magento/venia-ui/lib/classify';
import { arrayOf, oneOf, shape, bool, string, number } from 'prop-types';
import resourceUrl from '@magento/peregrine/lib/util/makeUrl';
import { useDetectScrollWidth } from '@magento/peregrine/lib/hooks/useDetectScrollWidth';
import defaultClasses from './row.module.css';
var matchMedia = globalThis.matchMedia;
/**
 * Page Builder Row component.
 *
 * This component is part of the Page Builder / PWA integration. It can be consumed without Page Builder.
 *
 * @typedef Row
 * @kind functional component
 *
 * @param {props} props React component props
 *
 * @returns {React.Element} A React component that displays a Row which contains content.
 */

var Row = function Row(props) {
  var backgroundElement = useRef(null);

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      bgImageStyle = _useState2[0],
      setBgImageStyle = _useState2[1];

  var classes = useStyle(defaultClasses, props.classes);
  var appearance = props.appearance,
      verticalAlignment = props.verticalAlignment,
      minHeight = props.minHeight,
      backgroundColor = props.backgroundColor,
      desktopImage = props.desktopImage,
      mobileImage = props.mobileImage,
      backgroundSize = props.backgroundSize,
      backgroundPosition = props.backgroundPosition,
      backgroundAttachment = props.backgroundAttachment,
      backgroundRepeat = props.backgroundRepeat,
      enableParallax = props.enableParallax,
      _props$parallaxSpeed = props.parallaxSpeed,
      parallaxSpeed = _props$parallaxSpeed === void 0 ? 0.5 : _props$parallaxSpeed,
      textAlign = props.textAlign,
      border = props.border,
      borderColor = props.borderColor,
      borderWidth = props.borderWidth,
      borderRadius = props.borderRadius,
      marginTop = props.marginTop,
      marginRight = props.marginRight,
      marginBottom = props.marginBottom,
      marginLeft = props.marginLeft,
      paddingTop = props.paddingTop,
      paddingRight = props.paddingRight,
      paddingBottom = props.paddingBottom,
      paddingLeft = props.paddingLeft,
      children = props.children,
      _props$cssClasses = props.cssClasses,
      cssClasses = _props$cssClasses === void 0 ? [] : _props$cssClasses,
      backgroundType = props.backgroundType,
      videoSrc = props.videoSrc,
      videoFallbackSrc = props.videoFallbackSrc,
      videoLoop = props.videoLoop,
      videoPlayOnlyVisible = props.videoPlayOnlyVisible,
      videoLazyLoading = props.videoLazyLoading,
      videoOverlayColor = props.videoOverlayColor;
  var image = desktopImage;

  if (mobileImage && matchMedia && matchMedia('(max-width: 768px)').matches) {
    image = mobileImage;
  }

  var dynamicStyles = {
    minHeight: minHeight,
    backgroundColor: backgroundColor,
    textAlign: textAlign,
    border: border,
    borderColor: borderColor,
    borderWidth: borderWidth,
    borderRadius: borderRadius,
    marginTop: marginTop,
    marginRight: marginRight,
    marginBottom: marginBottom,
    marginLeft: marginLeft,
    paddingTop: paddingTop,
    paddingRight: paddingRight,
    paddingBottom: paddingBottom,
    paddingLeft: paddingLeft
  };
  var videoOverlayStyles = {
    backgroundColor: videoOverlayColor
  };

  if (image) {
    dynamicStyles.backgroundImage = bgImageStyle;
    dynamicStyles.backgroundSize = backgroundSize;
    dynamicStyles.backgroundPosition = backgroundPosition;
    dynamicStyles.backgroundAttachment = backgroundAttachment;
    dynamicStyles.backgroundRepeat = backgroundRepeat;
  }

  if (verticalAlignment) {
    dynamicStyles.display = 'flex';
    dynamicStyles.justifyContent = verticalAlignmentToFlex(verticalAlignment);
    dynamicStyles.flexDirection = 'column';
  } //


  useDetectScrollWidth(); // Determine the containers width and optimize the image

  useEffect(function () {
    // Intelligently resize cover background images
    if (image && backgroundElement.current) {
      if (backgroundSize === 'cover') {
        var elementWidth = backgroundElement.current.offsetWidth;
        var elementHeight = backgroundElement.current.offsetHeight; // If parallax is enabled resize at a higher resolution, as the image will be zoomed

        if (enableParallax) {
          elementWidth = Math.round(elementWidth * 1.25);
          elementHeight = Math.round(elementHeight * 1.25);
        }

        setBgImageStyle("url(".concat(resourceUrl(image, {
          type: 'image-wysiwyg',
          width: elementWidth,
          height: elementHeight,
          quality: 85,
          crop: false,
          fit: 'cover'
        }), ")"));
      } else {
        setBgImageStyle("url(".concat(resourceUrl(image, {
          type: 'image-wysiwyg',
          quality: 85
        }), ")"));
      }
    }
  }, [backgroundSize, enableParallax, image, setBgImageStyle]); // Initiate jarallax for Parallax and background video

  useEffect(function () {
    var parallaxElement;
    var jarallax;
    var jarallaxVideo;

    if (enableParallax && bgImageStyle && backgroundType !== 'video') {
      var _require = require('jarallax');

      jarallax = _require.jarallax;
      parallaxElement = backgroundElement.current;
      jarallax(parallaxElement, {
        speed: parallaxSpeed,
        imgSize: backgroundSize,
        imgPosition: backgroundPosition,
        imgRepeat: backgroundRepeat
      });
    }

    if (backgroundType === 'video') {
      var _require2 = require('jarallax');

      jarallax = _require2.jarallax;

      var _require3 = require('jarallax');

      jarallaxVideo = _require3.jarallaxVideo;
      jarallaxVideo();
      parallaxElement = backgroundElement.current;
      jarallax(parallaxElement, {
        speed: enableParallax ? parallaxSpeed : 1,
        imgSrc: videoFallbackSrc ? resourceUrl(videoFallbackSrc, {
          type: 'image-wysiwyg',
          quality: 85
        }) : null,
        videoSrc: videoSrc,
        videoLoop: videoLoop,
        videoPlayOnlyVisible: videoPlayOnlyVisible,
        videoLazyLoading: videoLazyLoading
      });
      parallaxElement.jarallax.video && parallaxElement.jarallax.video.on('started', function () {
        var self = parallaxElement.jarallax; // show video

        if (self.$video) {
          self.$video.style.visibility = 'visible';
        }
      });
    }

    return function () {
      if (enableParallax && parallaxElement && bgImageStyle || parallaxElement && backgroundType === 'video') {
        jarallax(parallaxElement, 'destroy');
      }
    };
  }, [backgroundPosition, backgroundRepeat, backgroundSize, bgImageStyle, enableParallax, parallaxSpeed, backgroundType, videoSrc, videoFallbackSrc, videoLoop, videoPlayOnlyVisible, videoLazyLoading]);
  var videoOverlay = videoOverlayColor ? React.createElement("div", {
    className: classes.videoOverlay,
    style: videoOverlayStyles
  }) : null;

  if (appearance === 'full-bleed') {
    return React.createElement("div", {
      ref: backgroundElement,
      style: _objectSpread(_objectSpread({}, dynamicStyles), {}, {
        marginLeft: null,
        marginRight: null,
        '--pbRowMarginLeft': marginLeft,
        '--pbRowMarginRight': marginRight
      }),
      className: [classes.fullBleed, classes.root].concat(_toConsumableArray(cssClasses)).join(' ')
    }, videoOverlay, children);
  }

  if (appearance === 'full-width') {
    return React.createElement("div", {
      ref: backgroundElement,
      style: _objectSpread(_objectSpread({}, dynamicStyles), {}, {
        marginLeft: null,
        marginRight: null,
        '--pbRowMarginLeft': marginLeft,
        '--pbRowMarginRight': marginRight
      }),
      className: [classes.fullBleed, classes.root].concat(_toConsumableArray(cssClasses)).join(' ')
    }, videoOverlay, React.createElement("div", {
      className: classes.contained
    }, children));
  }

  return React.createElement("div", {
    className: [classes.contained].concat(_toConsumableArray(cssClasses)).join(' ')
  }, React.createElement("div", {
    ref: backgroundElement,
    className: classes.inner,
    style: dynamicStyles
  }, videoOverlay, children));
};
/**
 * Props for {@link Row}
 *
 * @typedef props
 *
 * @property {Object} classes An object containing the class names for the Row
 * @property {String} classes.contained CSS class for the contained appearance element
 * @property {String} classes.inner CSS class for the inner appearance element
 * @property {String} classes.root CSS class for the row root element
 * @property {String} classes.videoOverlay CSS class for the videoOverlay element
 * @property {String} minHeight CSS minimum height property
 * @property {String} backgroundColor CSS background-color property
 * @property {String} desktopImage Background image URL to be displayed on desktop devices
 * @property {String} mobileImage Background image URL to be displayed on mobile devices
 * @property {String} backgroundSize CSS background-size property
 * @property {String} backgroundPosition CSS background-position property
 * @property {String} backgroundAttachment CSS background-attachment property
 * @property {String} backgroundRepeat CSS background-repeat property
 * @property {Boolean} enableParallax Enable parallax on this row
 * @property {Number} parallaxSpeed The speed which Parallax should scroll, from -1.0 to 2.0
 * @property {String} textAlign Alignment of content within the row
 * @property {String} border CSS border property
 * @property {String} borderColor CSS border color property
 * @property {String} borderWidth CSS border width property
 * @property {String} borderRadius CSS border radius property
 * @property {String} marginTop CSS margin top property
 * @property {String} marginRight CSS margin right property
 * @property {String} marginBottom CSS margin bottom property
 * @property {String} marginLeft CSS margin left property
 * @property {String} paddingTop CSS padding top property
 * @property {String} paddingRight CSS padding right property
 * @property {String} paddingBottom CSS padding bottom property
 * @property {String} paddingLeft CSS padding left property
 * @property {Array} cssClasses List of CSS classes to be applied to the component
 * @property {String} backgroundType Background type
 * @property {String} videoSrc URL to the video
 * @property {String} videoFallbackSrc URL to the image which will be displayed before video
 * @property {Boolean} videoLoop Play video in loop
 * @property {Boolean} videoPlayOnlyVisible Play video when it is visible
 * @property {Boolean} videoLazyLoading Load video when it is visible
 * @property {String} videoOverlayColor Color for video overlay
 */


Row.propTypes = {
  classes: shape({
    root: string,
    contained: string,
    fullBleed: string,
    inner: string,
    videoOverlay: string
  }),
  appearance: oneOf(['contained', 'full-width', 'full-bleed']),
  verticalAlignment: oneOf(['top', 'middle', 'bottom']),
  minHeight: string,
  backgroundColor: string,
  desktopImage: string,
  mobileImage: string,
  backgroundSize: string,
  backgroundPosition: string,
  backgroundAttachment: string,
  backgroundRepeat: string,
  enableParallax: bool,
  parallaxSpeed: number,
  textAlign: string,
  border: string,
  borderColor: string,
  borderWidth: string,
  borderRadius: string,
  marginTop: string,
  marginRight: string,
  marginBottom: string,
  marginLeft: string,
  paddingTop: string,
  paddingRight: string,
  paddingBottom: string,
  paddingLeft: string,
  cssClasses: arrayOf(string),
  backgroundType: string,
  videoSrc: string,
  videoFallbackSrc: string,
  videoLoop: bool,
  videoPlayOnlyVisible: bool,
  videoLazyLoading: bool,
  videoOverlayColor: string
};
export default Row;