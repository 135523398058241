import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useMemo } from 'react';
import { useUserContext } from '@magento/peregrine/lib/context/user';
import { determineSelectedPermissionsBasedOnPermissionValues, recursiveStructureTreeKeys } from '../pages/Account/RolesPermissions/permissionsUtil';

var useCompanyPermissions = function useCompanyPermissions() {
  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      currentUser = _useUserContext2[0].currentUser;

  var defaultKeys = useMemo(function () {
    var _currentUser$role;

    return recursiveStructureTreeKeys(currentUser === null || currentUser === void 0 ? void 0 : (_currentUser$role = currentUser.role) === null || _currentUser$role === void 0 ? void 0 : _currentUser$role.permissions, 'id');
  }, [currentUser]);
  var isCompanyUser = useMemo(function () {
    return currentUser && !!(currentUser !== null && currentUser !== void 0 && currentUser.role);
  }, [currentUser]);
  /**
   * If user is not a company user his role property is null
   * and this permissions are not valid for him
   */

  var permissions = useMemo(function () {
    return currentUser !== null && currentUser !== void 0 && currentUser.role ? determineSelectedPermissionsBasedOnPermissionValues(defaultKeys) : null;
  }, [defaultKeys, currentUser]);
  /**
   * Give your new account user the same access as you 
   * have as an account administrator, 
   * including the ability to create 
   * more new account users.)
   */

  var fullAccountAccess = useMemo(function () {
    return !isCompanyUser || (permissions === null || permissions === void 0 ? void 0 : permissions.full_account_access);
  }, [permissions, isCompanyUser]);
  /**--
   * Allow your new user to view your pricing, 
   * complete orders & check out & be able 
   * to place orders within your account using your specific
   * payment terms.
   * 
   * this one is actually split in canViewPricingAndPurchase and payOnAccount
   */

  var allPurchasingFunctions = useMemo(function () {
    return !isCompanyUser || (permissions === null || permissions === void 0 ? void 0 : permissions.all_purchasing_functions);
  }, [permissions, isCompanyUser]);
  /**
   * Users are able to view pricing & purchase
   */

  var canViewPricingAndPurchase = useMemo(function () {
    return !isCompanyUser || (permissions === null || permissions === void 0 ? void 0 : permissions.can_view_pricing_and_purchase);
  }, [permissions, isCompanyUser]);
  /**
   * Purchase using your saved, on account payment method 
   * (use pay on account method)
   */

  var payOnAccount = useMemo(function () {
    return !isCompanyUser || (permissions === null || permissions === void 0 ? void 0 : permissions.pay_on_account);
  }, [permissions]);
  /**-
   * This user will be able to see all 
   * orders related to this company account 
   * including orders of subordinate users.
   */

  var viewAllOrderHistory = useMemo(function () {
    return !isCompanyUser || (permissions === null || permissions === void 0 ? void 0 : permissions.view_all_order_history);
  }, [permissions, isCompanyUser]);
  /**-
   * This user can view & maintain account management structure 
   * (including the ability to manage roles and permissions, 
   * and manage users and teams.)
   */

  var viewAndMaintainAccountManagementStructure = useMemo(function () {
    return !isCompanyUser || (permissions === null || permissions === void 0 ? void 0 : permissions.view_and_maintain_account_management_structure);
  }, [permissions, isCompanyUser]);
  /**-
   * This user will will be able to view the 
   * payment terms in the company profile.
   */

  var viewPaymentTerms = useMemo(function () {
    return !isCompanyUser || (permissions === null || permissions === void 0 ? void 0 : permissions.view_payment_terms);
  }, [permissions, isCompanyUser]);
  return {
    permissions: permissions,
    isCompanyUser: isCompanyUser,
    fullAccountAccess: fullAccountAccess,
    allPurchasingFunctions: allPurchasingFunctions,
    canViewPricingAndPurchase: canViewPricingAndPurchase,
    payOnAccount: payOnAccount,
    viewAllOrderHistory: viewAllOrderHistory,
    viewAndMaintainAccountManagementStructure: viewAndMaintainAccountManagementStructure,
    viewPaymentTerms: viewPaymentTerms
  };
};

export default useCompanyPermissions;