// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".errorView-root-q2k {\n    background: var(--backroundImageUrl);\n    /* two column grid because the \"hangers\" are on the right side of the page */\n    /* TODO @TW: review */\n    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),\n        var(--backroundImageUrl);\n}\n\n.errorView-errorWrapper-j0g {\n    height: 800px;\n}\n\n.errorView-content-Bj8 {\n}\n\n.errorView-header-S-t {\n}\n\n.errorView-message-1-9 {\n    /* add a little space so the button is centered below the message */\n}\n\n.errorView-actionsContainer-s5p {\n}\n\n/* @media (max-width: 639px) {\n    .root {\n        background-image: var(--mobileBackgroundImageUrl);\n        two row grid because the \"hangers\" are on the top of the page\n        grid-template-rows: 1fr 1fr;\n        grid-template-areas:\n            '.'\n            'content';\n    }\n} */\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "errorView-root-q2k bg-cover",
	"errorWrapper": "errorView-errorWrapper-j0g content-center grid grid-cols-12 p-xs container",
	"content": "errorView-content-Bj8 content-start col-span-12 md_col-span-6 xl_col-span-4 gap-xs grid text-center text-white md_col-start-1 xl_col-start-2",
	"header": "errorView-header-S-t font-semibold text-2xl",
	"message": "errorView-message-1-9 leading-tight pb-12",
	"actionsContainer": "errorView-actionsContainer-s5p flex flex-col items-center"
};
export default ___CSS_LOADER_EXPORT___;
