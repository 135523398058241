import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from 'react';
import { func, shape, string } from 'prop-types';
import { useCategoryLeaf, useCategoryTree } from '@magento/peregrine/lib/talons/CategoryTree';
import { useStyle } from '@magento/venia-ui/lib/classify';
import Branch from './categoryBranch';
import Leaf from './categoryLeaf';
import defaultClasses from './categoryTree.module.css';
import { Link } from 'react-router-dom';

var Tree = function Tree(props) {
  var _data$categories, _treeTitle$props, _treeTitle$props$chil;

  var categoryId = props.categoryId,
      onNavigate = props.onNavigate,
      setCategoryId = props.setCategoryId,
      updateCategories = props.updateCategories,
      isTopLevel = props.isTopLevel;
  var talonProps = useCategoryTree({
    categoryId: categoryId,
    updateCategories: updateCategories
  });

  var _useCategoryLeaf = useCategoryLeaf({
    onNavigate: onNavigate
  }),
      handleClick = _useCategoryLeaf.handleClick;

  var data = talonProps.data,
      childCategories = talonProps.childCategories,
      categoryUrlSuffix = talonProps.categoryUrlSuffix;
  var classes = useStyle(defaultClasses, props.classes); // for each child category, render a direct link if it has no children
  // otherwise render a branch

  var branches = data ? Array.from(childCategories, function (childCategory) {
    var _childCategory = _slicedToArray(childCategory, 2),
        id = _childCategory[0],
        _childCategory$ = _childCategory[1],
        category = _childCategory$.category,
        isLeaf = _childCategory$.isLeaf;

    return isLeaf ? React.createElement(Leaf, {
      key: id,
      category: category,
      onNavigate: onNavigate,
      categoryUrlSuffix: categoryUrlSuffix
    }) : React.createElement(Branch, {
      key: id,
      category: category,
      setCategoryId: setCategoryId
    });
  }) : null;
  var treeTitle = !isTopLevel ? React.createElement("div", {
    className: classes.treeTitle
  }, data && data.categories && ((_data$categories = data.categories) === null || _data$categories === void 0 ? void 0 : _data$categories.items[0].name)) : null;
  return React.createElement("div", {
    className: classes.root
  }, (treeTitle === null || treeTitle === void 0 ? void 0 : (_treeTitle$props = treeTitle.props) === null || _treeTitle$props === void 0 ? void 0 : (_treeTitle$props$chil = _treeTitle$props.children) === null || _treeTitle$props$chil === void 0 ? void 0 : _treeTitle$props$chil.toLowerCase()) === 'outlet' ? React.createElement(Link, {
    to: "/outlet",
    onClick: handleClick
  }, treeTitle) : treeTitle, React.createElement("ul", {
    className: classes.tree
  }, branches));
};

export default Tree;
Tree.propTypes = {
  categoryId: string,
  classes: shape({
    root: string,
    tree: string
  }),
  onNavigate: func.isRequired,
  setCategoryId: func.isRequired,
  updateCategories: func.isRequired
};