import React from 'react';
import ReactDOM from 'react-dom';
import { string, bool, func, node } from 'prop-types';
import defaultClasses from './modal.module.css';
import { useMergeCssClasses, useClickOutside } from '../../Utils/utils';

var Modal = function Modal(_ref) {
  var children = _ref.children,
      isOpen = _ref.isOpen,
      size = _ref.size,
      toggleModal = _ref.toggleModal,
      propClasses = _ref.classes;

  var handleCloseModal = function handleCloseModal() {
    typeof toggleModal === 'function' && toggleModal();
  };

  var classes = useMergeCssClasses(defaultClasses, propClasses);
  var isFull = size === 'full';
  var modalRef;

  if (isOpen) {
    modalRef = useClickOutside(handleCloseModal);
  }

  var modalClasses = isFull ? classes.fullModal : classes.normalModal;
  return ReactDOM.createPortal(React.createElement(React.Fragment, null, isOpen && React.createElement("div", {
    className: classes.modalWrapper
  }, React.createElement("div", {
    ref: modalRef,
    className: modalClasses
  }, children))), document.getElementById('slider-modal'));
};

export default Modal;
Modal.propType = {
  isOpen: bool.isRequired,
  size: string,
  toggleModal: func.isRequired,
  children: node
};