import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { gql } from '@apollo/client';
export var GET_CUSTOMER = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query GetCustomerForLeftNav {\n        # eslint-disable-next-line @graphql-eslint/require-id-when-available\n        customer {\n            email\n            firstname\n            lastname\n            is_subscribed\n            group_uid\n            customer_flow_map_group\n            customer_price_group\n            company_role\n            addresses {\n                firstname\n                lastname\n                street\n                city\n                region {\n                    region_code\n                    region\n                    region_id\n                }\n                postcode\n                country_code\n                telephone\n            }\n            role {\n                id\n                name\n                permissions {\n                    id\n                    text\n                    children {\n                        id\n                        text\n                        children {\n                            id\n                            text\n                            children {\n                                id\n                                text\n                                children {\n                                    id\n                                    text\n                                    children {\n                                        id\n                                        text\n                                    }\n                                }\n                            }\n                        }\n                    }\n                }\n            }\n        }\n    }\n"])));
var GET_ROOT_CATEGORY_ID = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query getRootCategoryId {\n        # eslint-disable-next-line @graphql-eslint/require-id-when-available\n        storeConfig {\n            store_code\n            root_category_uid\n        }\n    }\n"])));
export default {
  getCustomerQuery: GET_CUSTOMER,
  getRootCategoryId: GET_ROOT_CATEGORY_ID
};