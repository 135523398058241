import React, { Fragment } from 'react';
import { number, string, shape } from 'prop-types';
import { useIntl } from 'react-intl';
import patches from '@magento/peregrine/lib/util/intlPatches';
/**
 * The **Price** component is used anywhere a price needs to be displayed.
 *
 * Formatting of prices and currency symbol selection is handled entirely by the ECMAScript Internationalization API available in modern browsers.
 *
 * A [polyfill][] is required for any JavaScript runtime that does not have [Intl.NumberFormat.prototype.formatToParts][].
 *
 * [polyfill]: https://www.npmjs.com/package/intl
 * [Intl.NumberFormat.prototype.formatToParts]: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat/formatToParts
 */

import defaultClasses from './price.module.css';
import { useMergeCssClasses } from '../../../../Utils/utils';
import useCompanyPermissions from '../../../../hooks/useCompanyPermissions';

var Price = function Price(props) {
  var _useIntl = useIntl(),
      locale = _useIntl.locale;

  var value = props.value,
      _props$currencyCode = props.currencyCode,
      currencyCode = _props$currencyCode === void 0 ? 'USD' : _props$currencyCode,
      propClasses = props.classes;

  var _useCompanyPermission = useCompanyPermissions(),
      canViewPricingAndPurchase = _useCompanyPermission.canViewPricingAndPurchase;

  var classes = useMergeCssClasses(defaultClasses, propClasses);
  var parts = patches.toParts.call(new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode
  }), value);
  var children = parts.map(function (part, i) {
    var partClass = classes[part.type];
    var key = "".concat(i, "-").concat(part.value);
    return React.createElement("span", {
      key: key,
      className: partClass
    }, canViewPricingAndPurchase && part.value);
  });
  return React.createElement("div", {
    className: classes.root
  }, children);
};

Price.propTypes = {
  /**
   * Class names to use when styling this component
   */
  classes: shape({
    currency: string,
    integer: string,
    decimal: string,
    fraction: string
  }),

  /**
   * The numeric price
   */
  value: number.isRequired
  /**
   * A string with any of the currency code supported by Intl.NumberFormat
   */
  // currencyCode: string.isRequired

};
Price.defaultProps = {
  classes: {}
};
export default Price;