import React, { Suspense, useEffect } from 'react';
import { shape, string } from 'prop-types';
import { useNavigation } from '@magento/peregrine/lib/talons/Navigation/useNavigation';
import { useStyle } from '@magento/venia-ui/lib/classify';
import LoadingIndicator from '@magento/venia-ui/lib/components/LoadingIndicator';
import CategoryTree from 'src/components/CategoryTree';
import NavHeader from './navHeader';
import defaultClasses from './navigation.module.css';
import { removeScrollFromBody, addScrollToBody } from '../../hooks/utils';
var AuthModal = React.lazy(function () {
  return import('@magento/venia-ui/lib/components/AuthModal');
});

var Navigation = function Navigation(props) {
  var _useNavigation = useNavigation(),
      catalogActions = _useNavigation.catalogActions,
      categoryId = _useNavigation.categoryId,
      handleBack = _useNavigation.handleBack,
      handleClose = _useNavigation.handleClose,
      hasModal = _useNavigation.hasModal,
      isOpen = _useNavigation.isOpen,
      isTopLevel = _useNavigation.isTopLevel,
      setCategoryId = _useNavigation.setCategoryId,
      showCreateAccount = _useNavigation.showCreateAccount,
      showForgotPassword = _useNavigation.showForgotPassword,
      showMainMenu = _useNavigation.showMainMenu,
      showMyAccount = _useNavigation.showMyAccount,
      showSignIn = _useNavigation.showSignIn,
      view = _useNavigation.view;

  useEffect(function () {
    return isOpen ? removeScrollFromBody(false) : addScrollToBody(false);
  }, [isOpen]);
  var classes = useStyle(defaultClasses, props.classes);
  var rootClassName = isOpen ? classes.root_open : classes.root;
  var modalClassName = hasModal ? classes.modal_open : classes.modal;
  var bodyClassName = hasModal ? classes.body_masked : classes.body; // Lazy load the auth modal because it may not be needed.

  var authModal = hasModal ? React.createElement(Suspense, {
    fallback: React.createElement(LoadingIndicator, null)
  }, React.createElement(AuthModal, {
    closeDrawer: handleClose,
    showCreateAccount: showCreateAccount,
    showForgotPassword: showForgotPassword,
    showMainMenu: showMainMenu,
    showMyAccount: showMyAccount,
    showSignIn: showSignIn,
    view: view
  })) : null;
  var manuHeader;

  if (!isTopLevel) {
    manuHeader = React.createElement("header", {
      className: classes.header
    }, React.createElement(NavHeader, {
      isTopLevel: isTopLevel,
      onBack: handleBack,
      view: view
    }));
  }

  return React.createElement("aside", {
    className: rootClassName
  }, React.createElement("div", {
    className: bodyClassName
  }, manuHeader, React.createElement("div", {
    className: classes.categoryTree
  }, React.createElement(CategoryTree, {
    categoryId: categoryId,
    onNavigate: handleClose,
    setCategoryId: setCategoryId,
    updateCategories: catalogActions.updateCategories,
    isTopLevel: isTopLevel
  }))), React.createElement("div", {
    className: classes.footer
  }), React.createElement("div", {
    className: modalClassName
  }, authModal));
};

export default Navigation;
Navigation.propTypes = {
  classes: shape({
    body: string,
    form_closed: string,
    form_open: string,
    footer: string,
    header: string,
    root: string,
    root_open: string,
    signIn_closed: string,
    signIn_open: string
  })
};