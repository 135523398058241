import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

import React from 'react';
import { gql, useQuery } from '@apollo/client';

var fetchCustomAttribute = function fetchCustomAttribute() {
  var _useQuery = useQuery(query, {
    fetchPolicy: 'no-cache'
  }),
      data = _useQuery.data,
      loading = _useQuery.loading,
      error = _useQuery.error;

  return {
    data: data,
    loading: loading,
    error: error
  };
};

var query = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query FetchSwatchAttributes {\n        customAttributeMetadata(\n            attributes: [{ attribute_code: \"hf_fixture_shape\", entity_type: \"catalog_product\" }]\n        ) {\n            items {\n                attribute_code\n                attribute_options {\n                    label\n                    value\n                    swatch_data {\n                        type\n                        value\n                    }\n                }\n                attribute_type\n                entity_type\n                input_type\n            }\n        }\n    }\n"])));
export default fetchCustomAttribute;