import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from 'react';
import classes from './cmsVideoSection.module.css';
import playButtonImage from '../../assets/play-button.svg';
import { useAppContext } from '@magento/peregrine/lib/context/app';
import { Modal, ModalBody, ModalHeader } from '../Modal';

function CmsVideoSection(_ref) {
  var video = _ref.video;

  var _useAppContext = useAppContext(),
      _useAppContext2 = _slicedToArray(_useAppContext, 2),
      drawer = _useAppContext2[0].drawer,
      _useAppContext2$ = _useAppContext2[1],
      closeDrawer = _useAppContext2$.closeDrawer,
      toggleDrawer = _useAppContext2$.toggleDrawer;

  var isOpen = drawer === video;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: classes.cmsVideoSection
  }, React.createElement("img", {
    onClick: function onClick() {
      return toggleDrawer(video);
    },
    src: playButtonImage,
    alt: "play-video"
  })), isOpen ? React.createElement(Modal, {
    isOpen: isOpen,
    toggleModal: closeDrawer,
    size: "full",
    classes: {
      fullModal: 'flex flex-col items-center  h-full'
    }
  }, React.createElement(ModalHeader, {
    classes: {
      root: 'text-white bg-bronze flex justify-end w-full pt-4 lg_pt-12 lg_pb-8 pr-4 lg_pr-12',
      closeIcon: classes.closeIcon
    },
    closeModal: closeDrawer
  }), React.createElement(ModalBody, {
    classes: {
      root: 'flex-1 w-full pb-32'
    }
  }, React.createElement("iframe", {
    width: "100%",
    src: "".concat(video, "?autoplay=1"),
    title: "Video",
    height: "100%",
    frameBorder: "0",
    allow: "autoplay; fullscreen",
    allowFullScreen: true,
    muted: true
  }))) : null);
}

export default CmsVideoSection;