// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".characterCounter-limit-mfG {\n    position: absolute;\n    top: 50%;\n    right: 0;\n    transform: translate(-50%, -50%);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"limit": "characterCounter-limit-mfG"
};
export default ___CSS_LOADER_EXPORT___;
