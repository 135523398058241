// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".viewByRoomSettings-subitemsWrapper-qC9 {\n    display: none;\n}\n\n.viewByRoomSettings-subitemsWrapper_active-fry {\n    /* width: 700px; */\n}\n\n.viewByRoomSettings-subMenuItemCol-Svy {\n    min-width: 250px;\n}\n\n.viewByRoomSettings-submenuItem-sAA {\n    padding-left: 34px;\n    padding-right: 34px;\n    white-space: nowrap;\n}\n\n.viewByRoomSettings-submenuItem-sAA:hover,\n.viewByRoomSettings-active-tJb {\n    font-weight: bold;\n}\n\n.viewByRoomSettings-submenuItem-sAA:hover.viewByRoomSettings-submenuItem-sAA::before,\n.viewByRoomSettings-active-tJb::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 10px;\n    background-color: var(--venia-global-color-worldly-gray);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subitemsWrapper": "viewByRoomSettings-subitemsWrapper-qC9 border-l border-solid border-gray",
	"subitemsWrapper_active": "viewByRoomSettings-subitemsWrapper_active-fry viewByRoomSettings-subitemsWrapper-qC9 border-l border-solid border-gray flex",
	"subMenuItemCol": "viewByRoomSettings-subMenuItemCol-Svy flex flex-col",
	"submenuItem": "viewByRoomSettings-submenuItem-sAA cursor-pointer py-1.5 relative text-bronze text-sm leading-171",
	"active": "viewByRoomSettings-active-tJb"
};
export default ___CSS_LOADER_EXPORT___;
