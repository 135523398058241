import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { string, shape, array } from 'prop-types';
import { useWindowSize } from '@magento/peregrine/lib/hooks/useWindowSize';
import { useGallery } from '@magento/peregrine/lib/talons/Gallery/useGallery';
import { useQuery, useLazyQuery } from '@apollo/client';
import { DOWNLOAD_IMAGES } from '../../graphql/downloadImages/actions.gql';
import { useUserContext } from '@magento/peregrine/lib/context/user';
import classes from './gallery.module.css';
import RowItem from './rowItem';
import ProductItem from './productItem';
import { CROSSSELLPRODUCTS_QUERY } from './crosssellQuery';
import { useParams, useLocation } from 'react-router-dom';
/**
 * Renders a Gallery of items. If items is an array of nulls Gallery will render
 * a placeholder item for each.
 *
 * isCollectionBtnAllowed - this means that we use plp page insted of collections page- this is indicator
 *
 * @params {Array} props.items an array of items to render
 */

var Gallery = function Gallery(_ref) {
  var items = _ref.items,
      outletPriceRange = _ref.outletPriceRange,
      pageControl = _ref.pageControl,
      isCollectionBtnAllowed = _ref.isCollectionBtnAllowed,
      pageSize = _ref.pageSize,
      isFiltersHidden = _ref.isFiltersHidden,
      klevuManager = _ref.klevuManager,
      productMarkerId = _ref.productMarkerId,
      persistScrollPosition = _ref.persistScrollPosition,
      handleProductScroll = _ref.handleProductScroll,
      isProductScrolled = _ref.isProductScrolled;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      closeAllCollections = _useState2[0],
      setCloseAllCollections = _useState2[1];

  var _useWindowSize = useWindowSize(),
      innerWidth = _useWindowSize.innerWidth;

  var _useGallery = useGallery({
    items: items
  }),
      storeConfig = _useGallery.storeConfig,
      favoritesData = _useGallery.favoritesData,
      refetchFavorites = _useGallery.refetchFavorites;

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      isSignedIn = _useUserContext2[0].isSignedIn;

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      downloadImages = _useState4[0],
      setDownloadImages = _useState4[1];

  var _useParams = useParams(),
      collectionsSlug = _useParams.collectionsSlug;

  var location = useLocation();
  var listOfSkus = items === null || items === void 0 ? void 0 : items.map(function (x) {
    return x === null || x === void 0 ? void 0 : x.sku;
  });

  var _useLazyQuery = useLazyQuery(DOWNLOAD_IMAGES, {
    variables: {
      pageSize: 100,
      currentPage: 1
    },
    fetchPolicy: 'cache-and-network'
  }),
      _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
      downloadimagesFetch = _useLazyQuery2[0],
      _useLazyQuery2$ = _useLazyQuery2[1],
      data = _useLazyQuery2$.data,
      refetch = _useLazyQuery2$.refetch;

  useEffect(function () {
    return data && setDownloadImages(data.downloadimages);
  }, [data]);
  useEffect(function () {
    return !downloadImages && isSignedIn && downloadimagesFetch();
  }, [downloadImages]);

  var _useQuery = useQuery(CROSSSELLPRODUCTS_QUERY, {
    variables: {
      skus: listOfSkus,
      pageSize: pageSize
    },
    // For some reason - 'cache-and-network' policy causes an endless loop in some envs
    // fetchPolicy: 'cache-and-network'
    fetchPolicy: 'no-cache'
  }),
      crosssellData = _useQuery.data,
      loading = _useQuery.loading;

  var isSmallDevice = innerWidth < 881;
  var isUltraSmallDevice = innerWidth < 480;
  var finalGalleryItemData = useMemo(function () {
    return items === null || items === void 0 ? void 0 : items.map(function (product) {
      var _crosssellData$produc, _crosssellData$produc2;

      var foundCrosssellData = crosssellData === null || crosssellData === void 0 ? void 0 : (_crosssellData$produc = crosssellData.products) === null || _crosssellData$produc === void 0 ? void 0 : (_crosssellData$produc2 = _crosssellData$produc.items) === null || _crosssellData$produc2 === void 0 ? void 0 : _crosssellData$produc2.find(function (x) {
        return (x === null || x === void 0 ? void 0 : x.sku) === (product === null || product === void 0 ? void 0 : product.sku);
      });

      if ((product === null || product === void 0 ? void 0 : product.sku) === '131068') {// console.log(foundCrosssellData);
      }

      if (foundCrosssellData) {
        return _objectSpread(_objectSpread({}, product), {}, {
          crosssell_products: foundCrosssellData === null || foundCrosssellData === void 0 ? void 0 : foundCrosssellData.crosssell_products
        });
      }

      return product;
    });
  }, [items, crosssellData]);
  var i;
  var sortderItems = [];
  var numberItemsInRow = isSmallDevice ? 2 : 3; // sorting items from back

  for (i = 0; i < finalGalleryItemData.length / numberItemsInRow; i++) {
    sortderItems = [].concat(_toConsumableArray(sortderItems), [finalGalleryItemData.slice(i * numberItemsInRow, (i + 1) * numberItemsInRow)]);
  }

  var handleCloseAllCollections = useCallback(function (item) {
    return setCloseAllCollections(item);
  });
  var galleryItems = useMemo(function () {
    return sortderItems.map(function (item, index) {
      var _crosssellData$produc3;

      return React.createElement(RowItem, {
        key: index,
        items: item,
        outletPriceRange: outletPriceRange,
        isFiltersHidden: isFiltersHidden,
        pageControl: pageControl,
        isCollectionBtnAllowed: isCollectionBtnAllowed,
        storeConfig: storeConfig,
        refetch: refetch,
        downloadImages: downloadImages,
        klevuManager: klevuManager,
        isUltraSmallDevice: isUltraSmallDevice,
        handleCloseAllCollections: handleCloseAllCollections,
        closeAllCollections: closeAllCollections,
        crosssellData: crosssellData === null || crosssellData === void 0 ? void 0 : (_crosssellData$produc3 = crosssellData.products) === null || _crosssellData$produc3 === void 0 ? void 0 : _crosssellData$produc3.items,
        productMarkerId: productMarkerId,
        persistScrollPosition: persistScrollPosition,
        handleProductScroll: handleProductScroll,
        isProductScrolled: isProductScrolled,
        favoritesData: favoritesData,
        refetchFavorites: refetchFavorites
      });
    });
  }, [favoritesData, sortderItems, finalGalleryItemData, downloadImages, numberItemsInRow, closeAllCollections, outletPriceRange]);
  var collectionsItems = useMemo(function () {
    var _items$filter;

    return items === null || items === void 0 ? void 0 : (_items$filter = items.filter(function (item) {
      var _item$sku;

      return !(item !== null && item !== void 0 && (_item$sku = item.sku) !== null && _item$sku !== void 0 && _item$sku.startsWith('OUT-'));
    })) === null || _items$filter === void 0 ? void 0 : _items$filter.map(function (item) {
      var _favoritesData$existI, _favoritesData$existI2;

      return React.createElement(ProductItem, {
        key: item.id,
        item: item,
        storeConfig: storeConfig,
        downloadImages: downloadImages,
        refetch: refetch,
        isCollectionBtnAllowed: isCollectionBtnAllowed,
        isInFavorites: (favoritesData === null || favoritesData === void 0 ? void 0 : (_favoritesData$existI = favoritesData.existInFavorites) === null || _favoritesData$existI === void 0 ? void 0 : (_favoritesData$existI2 = _favoritesData$existI.find(function (x) {
          var _item$id;

          var strId = item === null || item === void 0 ? void 0 : (_item$id = item.id) === null || _item$id === void 0 ? void 0 : _item$id.toString(); // x.id - id is actually the id coming back from klevu which is a combination of product id and variant id 
          // (configuable product id and simple product id) in the form of: "{product_id}-{variant_id}"
          // get substring until first dash

          // x.id - id is actually the id coming back from klevu which is a combination of product id and variant id 
          // (configuable product id and simple product id) in the form of: "{product_id}-{variant_id}"
          // get substring until first dash
          return strId === null || strId === void 0 ? void 0 : strId.startsWith(x === null || x === void 0 ? void 0 : x.product_id);
        })) === null || _favoritesData$existI2 === void 0 ? void 0 : _favoritesData$existI2.exist) || false,
        refetchFavorites: refetchFavorites
      });
    });
  }, [favoritesData, finalGalleryItemData, downloadImages, numberItemsInRow]);
  var collectionsLayout = classes.gridCollections;

  if (collectionsItems && (collectionsSlug || location.pathname.includes("about/partnerships"))) {
    if (collectionsItems.length === 1) {
      collectionsLayout = classes.collectionOneItem;
    }

    if (collectionsItems.length === 2) {
      collectionsLayout = classes.collectionTwoItems;
    }

    if (collectionsItems.length === 3) {
      collectionsLayout = classes.collectionThreeItems;
    }
  }

  return React.createElement("div", {
    "aria-live": "polite",
    "aria-busy": "false"
  }, isCollectionBtnAllowed ? galleryItems : React.createElement("div", {
    className: collectionsLayout
  }, collectionsItems));
};

Gallery.propTypes = {
  classes: shape({
    filters: string,
    items: string,
    pagination: string,
    root: string
  }),
  items: array.isRequired
};
export default Gallery;