import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useCallback } from 'react';
import defaultClasses from './dropdown.module.css';
import { DownArrow } from '../Common/Icons';
import { useMergeCssClasses } from '../../Utils/utils';

var Dropdown = function Dropdown(_ref) {
  var title = _ref.title,
      options = _ref.options,
      propClasses = _ref.classes,
      type = _ref.type,
      handleOptionClick = _ref.handleOptionClick,
      _ref$selectedOption = _ref.selectedOption,
      selectedOption = _ref$selectedOption === void 0 ? null : _ref$selectedOption;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isOpen = _useState2[0],
      setIsOpen = _useState2[1];

  var classes = useMergeCssClasses(defaultClasses, propClasses);
  var arrowClasses = isOpen ? classes.iconActive : classes.icon;
  var toggleDropdown = useCallback(function () {
    setIsOpen(function (prevState) {
      return !prevState;
    });
  }, []);
  var handleOption = useCallback(function (value) {
    handleOptionClick(value);
    setIsOpen(false);
  }, [handleOptionClick]);
  return React.createElement("div", {
    className: classes.dropdown
  }, React.createElement("div", {
    className: classes.dropdownLabel,
    onClick: toggleDropdown
  }, selectedOption ? selectedOption : title, ' ', React.createElement("div", {
    className: arrowClasses
  }, React.createElement(DownArrow, null))), isOpen && React.createElement("ul", {
    className: classes.dropdownOptions
  }, options === null || options === void 0 ? void 0 : options.map(function (option, key) {
    return React.createElement("li", {
      key: key,
      className: classes.dropdownItem,
      onClick: function onClick() {
        return handleOption(option.value);
      }
    }, React.createElement("span", {
      className: "px-4"
    }, " ", option.label));
  })));
};

export default Dropdown;