import { useCallback, useMemo } from 'react';
export var usePagination = function usePagination(props) {
  var currentPage = props.currentPage,
      setPage = props.setPage,
      totalPages = props.totalPages,
      _props$tileBuffer = props.tileBuffer,
      tileBuffer = _props$tileBuffer === void 0 ? 8 : _props$tileBuffer;
  var getLeadTile = useCallback(function (currentPage, totalPages) {
    var selectedTile = currentPage;
    var leftBound = 1 + tileBuffer;
    var rightBound = totalPages - tileBuffer;
    var leadTile = selectedTile - tileBuffer;

    if (selectedTile < leftBound) {
      leadTile = 1;
    } else if (selectedTile > rightBound) {
      leadTile = Math.max(totalPages - tileBuffer * 2, 1);
    }

    return leadTile;
  }, [tileBuffer]);
  var handleLeftSkip = useCallback(function () {
    var leadTile = getLeadTile(currentPage, totalPages);
    var leftSkip = Math.max(1, leadTile - (tileBuffer + 1));
    setPage(leftSkip);
  }, [currentPage, getLeadTile, setPage, totalPages, tileBuffer]);
  var handleRightSkip = useCallback(function () {
    var leadTile = getLeadTile(currentPage, totalPages);
    var rightSkip = Math.min(totalPages, leadTile + tileBuffer * 2 + (tileBuffer + 1));
    setPage(rightSkip);
  }, [currentPage, getLeadTile, setPage, totalPages, tileBuffer]);
  var handleNavBack = useCallback(function () {
    if (currentPage > 1) {
      setPage(currentPage - 1);
    }
  }, [currentPage, setPage]);
  var handleNavForward = useCallback(function () {
    if (currentPage < totalPages) {
      setPage(currentPage + 1);
    }
  }, [currentPage, setPage, totalPages]);
  var isActiveLeft = currentPage !== 1;
  var isActiveRight = currentPage !== totalPages;
  var tiles = useMemo(function () {
    var tiles = [];
    var visibleBuffer = Math.min(tileBuffer * 2, totalPages - 1);
    var leadTile = getLeadTile(currentPage, totalPages);

    for (var i = leadTile; i <= leadTile + visibleBuffer; i++) {
      var tile = i;
      tiles.push(tile);
    }

    return tiles;
  }, [currentPage, getLeadTile, totalPages, tileBuffer]);
  return {
    handleLeftSkip: handleLeftSkip,
    handleRightSkip: handleRightSkip,
    handleNavBack: handleNavBack,
    handleNavForward: handleNavForward,
    isActiveLeft: isActiveLeft,
    isActiveRight: isActiveRight,
    tiles: tiles
  };
};