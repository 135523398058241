import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useQuery } from '@apollo/client';
import { useUserContext } from '@magento/peregrine/lib/context/user';
import { GET_CUSTOMER } from '../overrides/peregrine/talons/SignIn/signIn.gql';

function getCustomerGroupData() {
  var _currentUser$addresse;

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      _useUserContext2$ = _useUserContext2[0],
      isGettingDetails = _useUserContext2$.isGettingDetails,
      isSignedIn = _useUserContext2$.isSignedIn,
      currentUser = _useUserContext2$.currentUser; // const { data, loading } = useQuery(GET_CUSTOMER, {
  //     fetchPolicy: 'cache-and-network',
  //     skip: !isSignedIn
  // });


  return {
    customer_flow_map_group: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.customer_flow_map_group) || 'Consumer',
    loading: isGettingDetails,
    firstname: currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstname,
    lastname: currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastname,
    companyType: currentUser === null || currentUser === void 0 ? void 0 : currentUser.company_role,
    email: currentUser === null || currentUser === void 0 ? void 0 : currentUser.email,
    address: currentUser === null || currentUser === void 0 ? void 0 : (_currentUser$addresse = currentUser.addresses) === null || _currentUser$addresse === void 0 ? void 0 : _currentUser$addresse[0]
  };
}

export default getCustomerGroupData;