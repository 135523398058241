import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useMemo, useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import mergeOperations from '@magento/peregrine/lib/util/shallowMerge';
import useInternalLink from '@magento/peregrine/lib/hooks/useInternalLink';
import { useEventListener } from '@magento/peregrine/lib/hooks/useEventListener';
import DEFAULT_OPERATIONS from './megaMenu.gql';
/**
 * The useMegaMenu talon complements the MegaMenu component.
 *
 * @param {Object} props
 * @param {*} props.operations GraphQL operations used by talons
 * @param {React.RefObject} props.mainNavRef Reference to main navigation DOM node
 *
 * @return {MegaMenuTalonProps}
 */

export var useMegaMenu = function useMegaMenu() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  var getMegaMenuQuery = operations.getMegaMenuQuery,
      getStoreConfigQuery = operations.getStoreConfigQuery;
  var location = useLocation();

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      activeCategoryId = _useState2[0],
      setActiveCategoryId = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      subMenuState = _useState4[0],
      setSubMenuState = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      disableFocus = _useState6[0],
      setDisableFocus = _useState6[1];

  var _useQuery = useQuery(getStoreConfigQuery, {
    fetchPolicy: 'cache-and-network'
  }),
      storeConfigData = _useQuery.data;

  var _useQuery2 = useQuery(getMegaMenuQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  }),
      data = _useQuery2.data;

  var categoryUrlSuffix = useMemo(function () {
    if (storeConfigData) {
      return storeConfigData.storeConfig.category_url_suffix;
    }
  }, [storeConfigData]);
  /**
   * Check if category should be visible on the storefront.
   *
   * @param {MegaMenuCategory} category
   * @returns {boolean}
   */

  var shouldRenderMegaMenuItem = function shouldRenderMegaMenuItem(category) {
    //Because category "Outlet" is enabled in menu in magento (include_in_menu: 1)
    //for mobile menu to have it shown, manually disabling "Outlet" for header menu
    //preferably mobile menu should have it's own include variable
    return !!category.include_in_menu && category.uid !== "NDY2";
  };
  /**
   * Check if category is the active category based on the current location.
   *
   * @param {MegaMenuCategory} category
   * @returns {boolean}
   */


  var isActive = useCallback(function (_ref) {
    var url_path = _ref.url_path;
    if (!url_path) return false;
    var categoryUrlPath = "/".concat(url_path).concat(categoryUrlSuffix || '');
    return location.pathname === categoryUrlPath;
  }, [location.pathname, categoryUrlSuffix]);
  /**
   * Recursively map data returned by GraphQL query.
   *
   * @param {MegaMenuCategory} category
   * @param {array} - path from the given category to the first level category
   * @param {boolean} isRoot - describes is category a root category
   * @return {MegaMenuCategory}
   */

  var processData = useCallback(function (category) {
    var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var isRoot = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

    if (!category) {
      return;
    }

    var megaMenuCategory = Object.assign({}, category);

    if (!isRoot) {
      megaMenuCategory.path = [].concat(_toConsumableArray(path), [category.id]);
    }

    megaMenuCategory.isActive = isActive(megaMenuCategory);

    if (megaMenuCategory.children) {
      megaMenuCategory.children = _toConsumableArray(megaMenuCategory.children).filter(function (category) {
        return shouldRenderMegaMenuItem(category);
      }).sort(function (a, b) {
        return a.position > b.position ? 1 : -1;
      }).map(function (child) {
        return processData(child, megaMenuCategory.path, false);
      });
    }

    return megaMenuCategory;
  }, [isActive]);
  var megaMenuData = useMemo(function () {
    return data ? processData(data.categoryList[0]) : {};
  }, [data, processData]);
  var findActiveCategory = useCallback(function (pathname, category) {
    if (isActive(category)) {
      return category;
    }

    if (category.children) {
      return category.children.find(function (category) {
        return findActiveCategory(pathname, category);
      });
    }
  }, [isActive]);

  var handleClickOutside = function handleClickOutside(e) {
    if (!props.mainNavRef.current.contains(e.target)) {
      setSubMenuState(false);
      setDisableFocus(true);
    }
  };

  useEventListener(globalThis, 'mousedown', handleClickOutside);
  useEventListener(globalThis, 'mouseout', handleClickOutside);
  useEventListener(globalThis, 'keydown', handleClickOutside);
  var handleSubMenuFocus = useCallback(function () {
    setSubMenuState(true);
  }, [setSubMenuState]);
  useEffect(function () {
    var activeCategory = findActiveCategory(location.pathname, megaMenuData);

    if (activeCategory) {
      setActiveCategoryId(activeCategory.path[0]);
    } else {
      setActiveCategoryId(null);
    }
  }, [findActiveCategory, location.pathname, megaMenuData]);
  /**
   * Sets next root component to show proper loading effect
   *
   * @returns {void}
   */

  var _useInternalLink = useInternalLink('category'),
      setShimmerType = _useInternalLink.setShimmerType;

  return {
    megaMenuData: megaMenuData,
    activeCategoryId: activeCategoryId,
    categoryUrlSuffix: categoryUrlSuffix,
    handleClickOutside: handleClickOutside,
    subMenuState: subMenuState,
    disableFocus: disableFocus,
    handleSubMenuFocus: handleSubMenuFocus,
    handleNavigate: setShimmerType
  };
};
/** JSDocs type definitions */

/**
 * @typedef {Object} MegaMenuTalonProps
 *
 * @property {MegaMenuCategory} megaMenuData - The Object with categories contains only categories
 *                                             with the include_in_menu = 1 flag. The categories are sorted
 *                                             based on the field position.
 * @property {int} activeCategoryId returns the currently selected category id.
 * @property {String} categoryUrlSuffix  store's category url suffix to construct category URL
 * @property {Function} handleClickOutside function to handle mouse/key events.
 * @property {Boolean} subMenuState maintaining sub-menu open/close state
 * @property {Boolean} disableFocus state to disable focus
 * @property {Function} handleSubMenuFocus toggle function to handle sub-menu focus
 * @property {function} handleNavigate - callback to fire on link click
 */

/**
 * Object type returned by the {@link useMegaMenu} talon.
 * @typedef {Object} MegaMenuCategory
 *
 * @property {int} id - id of the category
 * @property {int} include_in_menu - describes if category should be included in menu
 * @property {String} name - name of the category
 * @property {int} position - value used for sorting
 * @property {String} url_path - URL path for a category
 * @property {MegaMenuCategory} children - child category
 */