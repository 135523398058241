import React from 'react';
import { bool } from 'prop-types';

var CloseIcon = function CloseIcon(_ref) {
  var isWhite = _ref.isWhite;
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "26",
    height: "26",
    viewBox: "0 0 26 26"
  }, React.createElement("path", {
    fill: "none",
    fillRule: "evenodd",
    stroke: isWhite ? '#FFF' : '#121111',
    strokeLinecap: "square",
    strokeWidth: "2",
    d: "M1.707 24.335L24.335 1.707m-22.628 0l22.628 22.628"
  }));
};

export default CloseIcon;
CloseIcon.propTypes = {
  isWhite: bool
};
CloseIcon.defaultProps = {
  isWhite: false
};