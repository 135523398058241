// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quoteMiniCart-counter-43p {\n    border-radius: 50%;\n    height: 14px;\n    width: 14px;\n    font-size: 0.6875rem;\n    line-height: 1;\n    bottom: -7px;\n    right: -6px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"counter": "quoteMiniCart-counter-43p absolute bg-red flex items-center justify-center"
};
export default ___CSS_LOADER_EXPORT___;
