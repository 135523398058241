// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".viewByCategoryLink-submenuItem-OhK {\n    padding-left: 30px;\n    padding-right: 30px;\n    white-space: nowrap;\n}\n\n.viewByCategoryLink-submenuItemName-NDK {\n}\n\n.viewByCategoryLink-submenuItem_active-bB6::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: -30px;\n    height: 100%;\n    width: 10px;\n    background-color: var(--venia-global-color-worldly-gray);\n}\n\n.viewByCategoryLink-submenuItemChild-qSx {\n}\n\n@media (max-width: 1150px) {\n    .viewByCategoryLink-submenuItem-OhK {\n        padding-left: 15px;\n        padding-right: 15px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submenuItem": "viewByCategoryLink-submenuItem-OhK cursor-pointer py-2 leading-171 relative text-bronze col-span-1 text-sm",
	"submenuItemName": "viewByCategoryLink-submenuItemName-NDK py-2 uppercase font-bold text-black",
	"submenuItem_active": "viewByCategoryLink-submenuItem_active-bB6",
	"submenuItemChild": "viewByCategoryLink-submenuItemChild-qSx leading-171 py-2 text-bronze"
};
export default ___CSS_LOADER_EXPORT___;
