import React from 'react';

var EmailsIcon = function EmailsIcon() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "56",
    height: "39",
    viewBox: "0 0 56 39"
  }, React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "#121111",
    strokeWidth: "2",
    transform: "translate(.602)"
  }, React.createElement("rect", {
    width: "53.029",
    height: "37",
    x: "1",
    y: "1",
    rx: "6"
  }), React.createElement("path", {
    strokeLinecap: "round",
    d: "M3.002 36L19.01 20m34.018 16l-16.01-16"
  }), React.createElement("path", {
    d: "M3.002 2.686L21.165 20.84c4.508 4.81 9.064 4.81 13.668 0 4.604-4.81 10.669-10.861 18.195-18.154"
  })));
};

export default EmailsIcon;