// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tile-root-hax {\n    align-items: center;\n    border: 1px none currentColor;\n    border-radius: 2px;\n    display: flex;\n    justify-content: center;\n    min-height: 2rem;\n    outline: none;\n    position: relative;\n}\n\n.tile-root_active-1ys {\n    border-style: solid;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "tile-root-hax",
	"root_active": "tile-root_active-1ys tile-root-hax"
};
export default ___CSS_LOADER_EXPORT___;
