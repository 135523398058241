import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classes from './viewByRoomSettings.module.css';

var ViewByRoomSettings = function ViewByRoomSettings(_ref) {
  var item = _ref.item,
      setSubmenuItems = _ref.setSubmenuItems;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      hoverItem = _useState2[0],
      setHoverItem = _useState2[1];

  var subMenuItemClassname = item.isActive ? classes.subitemsWrapper_active : classes.subitemsWrapper;
  useEffect(function () {
    return setHoverItem(false);
  }, [item]);
  return React.createElement("div", {
    className: subMenuItemClassname
  }, React.createElement("div", {
    className: classes.subMenuItemCol
  }, item.children.map(function (item, idx) {
    return React.createElement(Link, {
      key: idx,
      className: "".concat(classes.submenuItem, " ").concat((hoverItem === null || hoverItem === void 0 ? void 0 : hoverItem.id) === item.id && classes.active),
      to: "/".concat(item.url_path),
      onClick: function onClick() {
        return setSubmenuItems(null);
      }
    }, React.createElement("span", {
      className: "text-bronze text-sm",
      onMouseOver: function onMouseOver() {
        return setHoverItem(item);
      }
    }, item.name));
  })), hoverItem ? React.createElement("div", {
    className: "flex flex-col border-l border-solid border-gray"
  }, hoverItem.children.map(function (link, idx) {
    return React.createElement(Link, {
      key: idx,
      to: "/".concat(link.url_path),
      className: classes.submenuItem
    }, link.name);
  })) : null);
};

export default ViewByRoomSettings;