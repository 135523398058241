// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../node_modules/postcss-loader/dist/cjs.js!./productSort.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".productSort-shimmer-root-akO {\n}\n\n.productSort-shimmer-sortButtonShimmer-P2R {\n  /* composes: root_button from '../Shimmer/shimmer.module.css';\n    composes: sortButton from './productSort.module.css'; */\n}\n\n@media (min-width: 1024px) {\n  .productSort-shimmer-sortButtonShimmer-P2R {\n    min-width: 12rem;\n  }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "productSort-shimmer-root-akO " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["root"] + "",
	"sortButtonShimmer": "productSort-shimmer-sortButtonShimmer-P2R"
};
export default ___CSS_LOADER_EXPORT___;
