import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React, { Fragment, useRef, useMemo } from 'react';
import { element, func, shape, string } from 'prop-types';
import { useAddToListButton } from '@magento/peregrine/lib/talons/Wishlist/AddToListButton/useAddToListButton';
import { useButton } from 'react-aria'; // import { useWishlist } from '@magento/peregrine/lib/talons/WishlistPage/useWishlist';

import WishlistDialog from '@magento/venia-ui/lib/components/Wishlist/WishlistDialog';
import defaultClasses from '@magento/venia-ui/lib/components/Wishlist/AddToListButton/addToListButton.module.css';
import { useCommonToasts } from '@magento/venia-ui/lib/components/Wishlist/AddToListButton/useCommonToasts';
import { useMergeCssClasses } from 'src/Utils/utils';
import { FavouriteIcon, ActiveFavIcon } from 'src/components';

var AddToListPdpButton = function AddToListPdpButton(props) {
  var buttonRef = useRef();
  var wishlists = props.wishlists,
      _props$setWishlistCha = props.setWishlistChanged,
      setWishlistChanged = _props$setWishlistCha === void 0 ? function () {} : _props$setWishlistCha,
      _props$selectedOption = props.selectedOptionsArray,
      selectedOptionsArray = _props$selectedOption === void 0 ? null : _props$selectedOption,
      isInFavorites = props.isInFavorites,
      refetchFavorites = props.refetchFavorites,
      configuredVariant = props.configuredVariant,
      _props$isPlp = props.isPlp,
      isPlp = _props$isPlp === void 0 ? false : _props$isPlp;
  var talonProps = useAddToListButton(_objectSpread(_objectSpread({}, props), {}, {
    isFavorite: isInFavorites,
    setWishlistChanged: setWishlistChanged,
    wishlists: wishlists,
    refetchFavorites: refetchFavorites
  }));
  var buttonProps = talonProps.buttonProps,
      errorToastProps = talonProps.errorToastProps,
      loginToastProps = talonProps.loginToastProps,
      modalProps = talonProps.modalProps,
      successToastProps = talonProps.successToastProps,
      isModalOpen = talonProps.isModalOpen;
  useCommonToasts({
    errorToastProps: errorToastProps,
    loginToastProps: loginToastProps,
    successToastProps: successToastProps
  });

  var _useButton = useButton(buttonProps, buttonRef),
      buttonAriaProps = _useButton.buttonProps;

  var multipleWishlistDialog = useMemo(function () {
    return modalProps && isInFavorites ? React.createElement(WishlistDialog, _extends({}, modalProps, {
      selectedItem: props.product,
      selectedOptionsArray: selectedOptionsArray,
      refetchFavorites: refetchFavorites,
      configuredVariant: configuredVariant,
      isPlp: isPlp
    })) : null;
  }, [modalProps, isInFavorites]);
  var classes = useMergeCssClasses(defaultClasses, props.classes);
  var buttonClass = props.isSmallIcon ? classes.smallHeight : classes.normalHeight;
  return React.createElement(Fragment, null, React.createElement("button", _extends({
    ref: buttonRef,
    className: buttonClass
  }, buttonAriaProps, {
    "aria-label": isInFavorites ? 'Remove from favorite list' : 'Add to favorite list'
  }), isInFavorites ? React.createElement(ActiveFavIcon, null) : React.createElement(FavouriteIcon, null)), isModalOpen && multipleWishlistDialog);
};

export default AddToListPdpButton;
AddToListPdpButton.propTypes = {
  afterAdd: func,
  beforeAdd: func,
  classes: shape({
    root: string,
    root_selected: string
  }),
  icon: element
};
AddToListPdpButton.defaultProps = {
  isSmallIcon: false
};