import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useState, useEffect, useRef, useMemo } from 'react';
import { useEventListener } from '@magento/peregrine/lib/hooks/useEventListener';
import getCustomerGroupData from '../hooks/getCustomerGroupData';
import { useUserContext } from '@magento/peregrine/lib/context/user'; // take youtube id from url in browser

export var useVideoId = function useVideoId(url) {
  var videoId = url === null || url === void 0 ? void 0 : url.split('=')[1].split('&')[0];
  return videoId;
}; // hook for input, message filds. Reposition placeholder depends is input clicked

export var useFieldTouched = function useFieldTouched() {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isTouched = _useState2[0],
      setIsTouched = _useState2[1];

  return [isTouched, setIsTouched];
}; // check is element in viewport

export var useIntersection = function useIntersection(element, rootMargin) {
  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isVisible = _useState4[0],
      setState = _useState4[1];

  useEffect(function () {
    var observer = new IntersectionObserver(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          entry = _ref2[0];

      setState(entry.isIntersecting);
    }, {
      rootMargin: rootMargin
    });
    element.current && observer.observe(element.current);
  }, [element.current]);
  return isVisible;
};
export var useMergeCssClasses = function useMergeCssClasses() {
  var defaultClasses = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var propClasses = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var mergedCssClasses = {};
  Object.entries(defaultClasses).forEach(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
        k = _ref4[0],
        v = _ref4[1];

    mergedCssClasses[k] = !mergedCssClasses[k] ? v : "".concat(mergedCssClasses[k], " ").concat(defaultClasses[k]);
  });
  Object.entries(propClasses).forEach(function (_ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
        k = _ref6[0],
        v = _ref6[1];

    mergedCssClasses[k] = !mergedCssClasses[k] ? v : "".concat(mergedCssClasses[k], " ").concat(propClasses[k]);
  });
  return mergedCssClasses;
};
export var useClickOutside = function useClickOutside(handler) {
  var nodeRef = useRef();

  var maybeCollapse = function maybeCollapse(_ref7) {
    var _nodeRef$current;

    var target = _ref7.target;

    if (!(nodeRef !== null && nodeRef !== void 0 && nodeRef.current) || !(nodeRef !== null && nodeRef !== void 0 && (_nodeRef$current = nodeRef.current) !== null && _nodeRef$current !== void 0 && _nodeRef$current.contains(target))) {
      handler();
    }
  };

  useEventListener(document, 'mousedown', maybeCollapse);
  return nodeRef;
};
export var checkBoundaries = function checkBoundaries(items, min, max) {
  var priceFilter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var minBoundary;
  var maxBoundary;

  if (min, max) {
    return [min, max];
  }

  if (items) {
    minBoundary = Number(items[0].value);
    maxBoundary = Number(items[items.length - 1].value);
  }

  if (priceFilter) {
    minBoundary = Math.floor(minBoundary / 100) * 100;
    maxBoundary = Math.ceil(maxBoundary / 1000) * 1000;
  }

  return [minBoundary, maxBoundary];
};
export var findCategoryParent = function findCategoryParent(filterCategoryData, categoryId) {
  var filterCategory;
  filterCategoryData === null || filterCategoryData === void 0 ? void 0 : filterCategoryData.children.map(function (parentCat) {
    parentCat.children.map(function (category) {
      if (category.uid === categoryId) {
        filterCategory = category;
      } else {
        return category.children.map(function (subCategory) {
          if (subCategory.uid === categoryId) {
            filterCategory = category;
          }
        });
      }
    });
  });
  return filterCategory;
}; // this util returns which a-z group belongs, etc A-C group id is 1

export var getGroup = function getGroup(item) {
  var _item$name, _item$name2, _item$name3, _item$name4, _item$name5, _item$name6, _item$name7, _item$name8, _item$name9, _item$name10, _item$name11, _item$name12, _item$name13, _item$name14, _item$name15, _item$name16, _item$name17, _item$name18, _item$name19, _item$name20, _item$name21, _item$name22, _item$name23, _item$name24, _item$name25, _item$name26;

  var group;

  if (item !== null && item !== void 0 && (_item$name = item.name) !== null && _item$name !== void 0 && _item$name.toLowerCase().startsWith('a') || item !== null && item !== void 0 && (_item$name2 = item.name) !== null && _item$name2 !== void 0 && _item$name2.toLowerCase().startsWith('b') || item !== null && item !== void 0 && (_item$name3 = item.name) !== null && _item$name3 !== void 0 && _item$name3.toLowerCase().startsWith('c') || item !== null && item !== void 0 && (_item$name4 = item.name) !== null && _item$name4 !== void 0 && _item$name4.toLowerCase().startsWith('d') || item !== null && item !== void 0 && (_item$name5 = item.name) !== null && _item$name5 !== void 0 && _item$name5.toLowerCase().startsWith('e')) {
    group = _objectSpread(_objectSpread({}, item), {}, {
      group_id: 1
    });
  } else if (item !== null && item !== void 0 && (_item$name6 = item.name) !== null && _item$name6 !== void 0 && _item$name6.toLowerCase().startsWith('f') || item !== null && item !== void 0 && (_item$name7 = item.name) !== null && _item$name7 !== void 0 && _item$name7.toLowerCase().startsWith('g') || item !== null && item !== void 0 && (_item$name8 = item.name) !== null && _item$name8 !== void 0 && _item$name8.toLowerCase().startsWith('h') || item !== null && item !== void 0 && (_item$name9 = item.name) !== null && _item$name9 !== void 0 && _item$name9.toLowerCase().startsWith('j') || item !== null && item !== void 0 && (_item$name10 = item.name) !== null && _item$name10 !== void 0 && _item$name10.toLowerCase().startsWith('i')) {
    group = _objectSpread(_objectSpread({}, item), {}, {
      group_id: 2
    });
  } else if (item !== null && item !== void 0 && (_item$name11 = item.name) !== null && _item$name11 !== void 0 && _item$name11.toLowerCase().startsWith('k') || item !== null && item !== void 0 && (_item$name12 = item.name) !== null && _item$name12 !== void 0 && _item$name12.toLowerCase().startsWith('l') || item !== null && item !== void 0 && (_item$name13 = item.name) !== null && _item$name13 !== void 0 && _item$name13.toLowerCase().startsWith('m') || item !== null && item !== void 0 && (_item$name14 = item.name) !== null && _item$name14 !== void 0 && _item$name14.toLowerCase().startsWith('n') || item !== null && item !== void 0 && (_item$name15 = item.name) !== null && _item$name15 !== void 0 && _item$name15.toLowerCase().startsWith('o')) {
    group = _objectSpread(_objectSpread({}, item), {}, {
      group_id: 3
    });
  } else if (item !== null && item !== void 0 && (_item$name16 = item.name) !== null && _item$name16 !== void 0 && _item$name16.toLowerCase().startsWith('p') || item !== null && item !== void 0 && (_item$name17 = item.name) !== null && _item$name17 !== void 0 && _item$name17.toLowerCase().startsWith('q') || item !== null && item !== void 0 && (_item$name18 = item.name) !== null && _item$name18 !== void 0 && _item$name18.toLowerCase().startsWith('e') || item !== null && item !== void 0 && (_item$name19 = item.name) !== null && _item$name19 !== void 0 && _item$name19.toLowerCase().startsWith('s') || item !== null && item !== void 0 && (_item$name20 = item.name) !== null && _item$name20 !== void 0 && _item$name20.toLowerCase().startsWith('t')) {
    group = _objectSpread(_objectSpread({}, item), {}, {
      group_id: 4
    });
  } else if (item !== null && item !== void 0 && (_item$name21 = item.name) !== null && _item$name21 !== void 0 && _item$name21.toLowerCase().startsWith('u') || item !== null && item !== void 0 && (_item$name22 = item.name) !== null && _item$name22 !== void 0 && _item$name22.toLowerCase().startsWith('v') || item !== null && item !== void 0 && (_item$name23 = item.name) !== null && _item$name23 !== void 0 && _item$name23.toLowerCase().startsWith('w') || item !== null && item !== void 0 && (_item$name24 = item.name) !== null && _item$name24 !== void 0 && _item$name24.toLowerCase().startsWith('x') || item !== null && item !== void 0 && (_item$name25 = item.name) !== null && _item$name25 !== void 0 && _item$name25.toLowerCase().startsWith('z') || item !== null && item !== void 0 && (_item$name26 = item.name) !== null && _item$name26 !== void 0 && _item$name26.toLowerCase().startsWith('y')) {
    group = _objectSpread(_objectSpread({}, item), {}, {
      group_id: 5
    });
  } else {
    group = _objectSpread({}, item);
  }

  return group;
};
export var getCustomerGroup = function getCustomerGroup() {
  var _getCustomerGroupData = getCustomerGroupData(),
      customer_flow_map_group = _getCustomerGroupData.customer_flow_map_group,
      loading = _getCustomerGroupData.loading;

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      isSignedIn = _useUserContext2[0].isSignedIn;

  var isConsumer = useMemo(function () {
    return !['HF Employee', 'Wholesale Dealers', 'RES Reps', 'RES Designer', 'Non-Stock Dealers', 'Trade Direct Account', 'COM Reps', 'COM Non-Purchasing', 'COM Purchasing', 'Customer Service Team', 'Not logged-in'].includes(customer_flow_map_group === null || customer_flow_map_group === void 0 ? void 0 : customer_flow_map_group.trim()) && isSignedIn;
  }, [customer_flow_map_group]);
  var isHfEmployee = useMemo(function () {
    return customer_flow_map_group === 'HF Employee';
  }, [customer_flow_map_group]);
  var isResReps = useMemo(function () {
    return customer_flow_map_group === 'RES Reps';
  }, [customer_flow_map_group]);
  var isWholesaleDealer = useMemo(function () {
    return customer_flow_map_group === 'Wholesale Dealers';
  }, [customer_flow_map_group]);
  var isNonStockDealers = useMemo(function () {
    return customer_flow_map_group === 'Non-Stock Dealers';
  }, [customer_flow_map_group]);
  var isResDesigner = useMemo(function () {
    return customer_flow_map_group === 'RES Designer';
  }, [customer_flow_map_group]);
  var isTradeDirectAccount = useMemo(function () {
    return customer_flow_map_group === 'Trade Direct Account';
  }, [customer_flow_map_group]);
  var isComReps = useMemo(function () {
    return customer_flow_map_group && customer_flow_map_group === 'COM Reps';
  }, [customer_flow_map_group]);
  var isComNonPurchasing = useMemo(function () {
    return customer_flow_map_group === 'COM Non-Purchasing';
  }, [customer_flow_map_group]);
  var isComPurchasing = useMemo(function () {
    return customer_flow_map_group === 'COM Purchasing';
  }, [customer_flow_map_group]);
  var isCustomerServiceTeam = useMemo(function () {
    return customer_flow_map_group === 'Customer Service Team';
  }, [customer_flow_map_group]);
  return {
    isConsumer: isConsumer,
    isHfEmployee: isHfEmployee,
    isResReps: isResReps,
    isWholesaleDealer: isWholesaleDealer,
    isNonStockDealers: isNonStockDealers,
    isResDesigner: isResDesigner,
    isTradeDirectAccount: isTradeDirectAccount,
    isComReps: isComReps,
    isComNonPurchasing: isComNonPurchasing,
    isComPurchasing: isComPurchasing,
    isCustomerServiceTeam: isCustomerServiceTeam
  };
};
export var debounce = function debounce(cb, delay) {
  var timeout;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    clearTimeout(timeout);
    timeout = setTimeout(function () {
      cb.apply(void 0, args);
    }, delay);
  };
};
export var handleFilterGroup = function handleFilterGroup(key) {
  if (key === 'a' || key === 'b' || key === 'c' || key === 'd' || key === 'e') {
    return 1;
  } else if (key === 'f' || key === 'g' || key === 'h' || key === 'j' || key === 'i') {
    return 2;
  } else if (key === 'k' || key === 'l' || key === 'm' || key === 'n' || key === 'o') {
    return 3;
  } else if (key === 'p' || key === 'q' || key === 'e' || key === 's' || key === 't') {
    return 4;
  } else if (key === 'u' || key === 'v' || key === 'w' || key === 'x' || key === 'z' || key === 'y') {
    return 5;
  }
};