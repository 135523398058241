import React from 'react';
import Trigger from '@magento/venia-ui/lib/components/Trigger';
import { useMergeCssClasses } from '../../Utils/utils';
import defaultClasses from './modalHeader.module.css';
import { CloseIcon } from '..';

var ModalHeader = function ModalHeader(_ref) {
  var title = _ref.title,
      children = _ref.children,
      propClasses = _ref.classes,
      closeModal = _ref.closeModal;
  var classes = useMergeCssClasses(defaultClasses, propClasses);
  return React.createElement("div", {
    className: classes.root
  }, title && React.createElement("p", null, title), React.createElement(Trigger, {
    classes: {
      root: classes.closeIcon
    },
    action: closeModal,
    "aria-label": "Close modal"
  }, React.createElement("span", {
    className: "sr-only"
  }, "Close modal"), React.createElement(CloseIcon, null)));
};

export default ModalHeader;