import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import { gql } from '@apollo/client';
import { CheckoutPageFragment } from '../CheckoutPage/checkoutPageFragments.gql';
export var GET_CUSTOMER = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query GetCustomerAfterSignIn {\n        # eslint-disable-next-line @graphql-eslint/require-id-when-available\n        customer {\n            email\n            firstname\n            lastname\n            is_subscribed\n            customer_flow_map_group\n            group_uid\n            customer_price_group\n            company_role\n            addresses {\n                firstname\n                lastname\n                street\n                city\n                region {\n                    region_code\n                    region\n                    region_id\n                }\n                postcode\n                country_code\n                telephone\n            }\n            role {\n                id\n                name\n                permissions {\n                    id\n                    text\n                    children {\n                        id\n                        text\n                        children {\n                            id\n                            text\n                            children {\n                                id\n                                text\n                                children {\n                                    id\n                                    text\n                                    children {\n                                        id\n                                        text\n                                    }\n                                }\n                            }\n                        }\n                    }\n                }\n            }\n        }\n    }\n"])));
export var SIGN_IN = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    mutation SignIn($email: String!, $password: String!) {\n        generateCustomerToken(email: $email, password: $password) {\n            token\n        }\n    }\n"])));
export var CREATE_CART = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    mutation CreateCartAfterSignIn {\n        cartId: createEmptyCart\n    }\n"])));
export var MERGE_CARTS = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    mutation MergeCartsAfterSignIn($sourceCartId: String!, $destinationCartId: String!) {\n        mergeCarts(source_cart_id: $sourceCartId, destination_cart_id: $destinationCartId) {\n            id\n            # eslint-disable-next-line @graphql-eslint/require-id-when-available\n            items {\n                uid\n            }\n            ...CheckoutPageFragment\n        }\n    }\n    ", "\n"])), CheckoutPageFragment);
export default {
  createCartMutation: CREATE_CART,
  getCustomerQuery: GET_CUSTOMER,
  mergeCartsMutation: MERGE_CARTS,
  signInMutation: SIGN_IN
};