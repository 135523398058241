import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCT_COLLECTION_QUERY } from '../../overrides/peregrine/talons/RootComponents/Product/product.gql';
/**
 *
 * @param {*} props.operations GraphQL operations used by talons
 */

export var useGalleryItemCollection = function useGalleryItemCollection() {
  var _useLazyQuery = useLazyQuery(GET_PRODUCT_COLLECTION_QUERY, {
    fetchPolicy: 'no-cache' // nextFetchPolicy: 'no-cache'

  }),
      _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
      getCollection = _useLazyQuery2[0],
      _useLazyQuery2$ = _useLazyQuery2[1],
      loading = _useLazyQuery2$.loading,
      error = _useLazyQuery2$.error,
      data = _useLazyQuery2$.data;

  var handleSubmit = useCallback(function (sku) {
    getCollection({
      variables: {
        sku: sku
      }
    });
  }, []);
  return {
    loading: loading,
    error: error,
    collectionData: data ? data.products.items[0] : null,
    handleSubmit: handleSubmit
  };
};