import React from 'react';

var DownArrow = function DownArrow(_ref) {
  var classes = _ref.classes;
  return React.createElement("svg", {
    className: classes === null || classes === void 0 ? void 0 : classes.root,
    xmlns: "http://www.w3.org/2000/svg",
    width: "20",
    height: "12",
    viewBox: "0 0 20 12"
  }, React.createElement("path", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "#232323",
    strokeLinecap: "round",
    strokeWidth: "2",
    d: "M1 1l9 9 9-9"
  }));
};

export default DownArrow;