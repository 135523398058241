import _defineProperty from "@babel/runtime/helpers/defineProperty";
export var PERMISSIONSTXTS = {
  full_account_access: {
    heading: "FULL ACCOUNT ACCESS",
    text: "Give your new account user the same access as you have as an account administrator, including the ability to create more new account users.)"
  },
  all_purchasing_functions: {
    heading: "ALL PURCHASING FUNCTIONS",
    text: "Allow your new user to view your pricing, complete orders & check out & be able to place orders within your account using your specific payment terms."
  },
  pay_on_account: "Purchase using your saved, on account payment method (use pay on account method)",
  can_view_pricing_and_purchase: "Users are able to view pricing & purchase",
  view_all_order_history: {
    heading: "VIEW ORDER HISTORY",
    text: "This user will be able to see all orders related to this company account including orders of subordinate users."
  },
  view_payment_terms: {
    heading: "PAYMENT INFORMATION",
    text: "This user will will be able to view the payment terms in the company profile."
  },
  view_and_maintain_account_management_structure: {
    heading: "ACCOUNT MANAGEMENT STRUCTURE",
    text: "This user can view & maintain account management structure (including the ability to manage roles and permissions, and manage users and teams.)"
  }
};
export var permissions = {
  full_account_access: ["Magento_Company::index", "Magento_Sales::all", "Magento_Sales::place_order", "Magento_Sales::payment_account", "Jola_Permissions::can_view_pricing", "Magento_Sales::view_orders", "Magento_Sales::view_orders_sub", "Magento_NegotiableQuote::all", "Magento_NegotiableQuote::view_quotes", "Magento_NegotiableQuote::manage", "Magento_NegotiableQuote::checkout", "Magento_NegotiableQuote::view_quotes_sub", "Magento_Company::view", "Magento_Company::view_account", "Magento_Company::edit_account", "Magento_Company::view_address", "Magento_Company::edit_address", "Magento_Company::contacts", "Magento_Company::payment_information", "Magento_Company::user_management", "Magento_Company::roles_view", "Magento_Company::roles_edit", "Magento_Company::users_view", "Magento_Company::users_edit", "Magento_Company::credit", "Magento_Company::credit_history"],
  all_purchasing_functions: ["Magento_Sales::place_order", "Magento_Sales::payment_account", "Jola_Permissions::can_view_pricing", "Magento_Sales::all", "Magento_Company::index", "Magento_Company::view", "Magento_Company::view_account", "Magento_Company::view_address", "Magento_Company::contacts", "Magento_Company::credit", "Magento_Company::credit_history"],
  pay_on_account: ["Magento_Sales::place_order", "Magento_Sales::payment_account", "Magento_Sales::all", "Magento_Company::index", "Magento_Company::view", "Magento_Company::view_account", "Magento_Company::view_address", "Magento_Company::contacts", "Magento_Company::credit", "Magento_Company::credit_history"],
  can_view_pricing_and_purchase: ["Magento_Sales::place_order", "Jola_Permissions::can_view_pricing", "Magento_Sales::all", "Magento_Company::index", "Magento_Company::view", "Magento_Company::view_account", "Magento_Company::view_address", "Magento_Company::contacts"],
  view_all_order_history: ["Magento_Sales::all", "Magento_Company::index", "Magento_Company::view", "Magento_Company::view_account", "Magento_Company::view_address", "Magento_Company::contacts", "Magento_Sales::view_orders", "Magento_Sales::view_orders_sub"],
  view_payment_terms: ["Magento_Company::payment_information", "Magento_Company::index", "Magento_Company::view", "Magento_Company::view_account", "Magento_Company::view_address", "Magento_Company::contacts", "Magento_Company::credit", "Magento_Company::credit_history"],
  view_and_maintain_account_management_structure: ["Magento_Company::users_edit", "Magento_Company::users_view", "Magento_Company::user_management", "Magento_Company::index", "Magento_Company::view", "Magento_Company::view_account", "Magento_Company::roles_edit", "Magento_Company::roles_view", "Magento_Company::view_address", "Magento_Company::contacts"]
};
export var determineSelectedPermissionsBasedOnPermissionValues = function determineSelectedPermissionsBasedOnPermissionValues(currentSelection) {
  var _Object$keys;

  var selectedPermissions = (_Object$keys = Object.keys(permissions)) === null || _Object$keys === void 0 ? void 0 : _Object$keys.map(function (key) {
    var permission = permissions[key];
    var found = permission.every(function (value) {
      return currentSelection === null || currentSelection === void 0 ? void 0 : currentSelection.includes(value);
    });
    return _defineProperty({}, key, found);
  });
  var finalObject = {};
  selectedPermissions.forEach(function (value) {
    var ourNewKey = Object.keys(value)[0];
    finalObject[ourNewKey] = value[ourNewKey];
  });
  return finalObject;
};
export var recursiveStructureTreeKeys = function recursiveStructureTreeKeys(structureTree) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'key';
  var allKeys = [];

  var cb = function cb(e) {
    allKeys.push(e[key]);
    e.children && e.children.forEach(cb);
  };

  structureTree === null || structureTree === void 0 ? void 0 : structureTree.forEach(cb);
  return allKeys;
};