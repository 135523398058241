import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect } from 'react';
import { bool, shape, string } from 'prop-types';
import { useScrollLock } from '@magento/peregrine';
import defaultClasses from './main.module.css';
import { useUserContext } from '@magento/peregrine/lib/context/user';
import { useStyle } from '@magento/venia-ui/lib/classify';
import Footer from '../Footer/footer';
import Header from '../Header/header';

var Main = function Main(props) {
  var children = props.children,
      isMasked = props.isMasked;
  var classes = useStyle(defaultClasses, props.classes);

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      isSignedIn = _useUserContext2[0].isSignedIn;

  var rootClass = isMasked ? classes.root_masked : classes.root;
  var pageClass = isMasked ? classes.page_masked : classes.page;
  useScrollLock(isMasked);
  return React.createElement("main", {
    className: rootClass
  }, React.createElement(Header, null), React.createElement("div", {
    className: pageClass,
    id: "main"
  }, children), React.createElement(Footer, null));
};

export default Main;
Main.propTypes = {
  classes: shape({
    page: string,
    page_masked: string,
    root: string,
    root_masked: string
  }),
  isMasked: bool
};