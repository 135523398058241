import React from 'react';
import defaultClasses from './viewByCategoryLinks.module.css';
import ViewByCategoryLink from './viewByCategoryLink';

var ViewByCategoryLinks = function ViewByCategoryLinks(_ref) {
  var _item$children, _item$children2;

  var item = _ref.item,
      setSubmenuItems = _ref.setSubmenuItems;
  var subMenuItemClassname = item.isActive ? defaultClasses.subitemsWrapper_active : defaultClasses.subitemsWrapper;
  var findDecor = (_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.find(function (child) {
    return child.url_path.includes('decor');
  });
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: subMenuItemClassname
  }, (_item$children2 = item.children) === null || _item$children2 === void 0 ? void 0 : _item$children2.filter(function (child) {
    return child.uid !== (findDecor === null || findDecor === void 0 ? void 0 : findDecor.uid);
  }).map(function (item, idx) {
    return React.createElement(ViewByCategoryLink, {
      setSubmenuItems: setSubmenuItems,
      key: idx,
      item: item
    });
  })), React.createElement(ViewByCategoryLink, {
    setSubmenuItems: setSubmenuItems,
    key: findDecor === null || findDecor === void 0 ? void 0 : findDecor.uid,
    item: findDecor
  }));
};

export default ViewByCategoryLinks;