import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { isSupportedProductType as isSupported } from '@magento/peregrine/lib/util/isSupportedProductType';
import { useEventingContext } from '@magento/peregrine/lib/context/eventing';
import { useCallback, useEffect, useRef } from 'react';
import useIntersectionObserver from '@magento/peregrine/lib/hooks/useIntersectionObserver';
export var useGalleryItem = function useGalleryItem() {
  var _item$price_range, _item$price_range$max, _item$price_range$max2, _item$price_range2, _item$price_range2$ma, _item$price_range2$ma2, _item$price_range3, _item$price_range3$ma, _item$price_range3$ma2;

  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _useEventingContext = useEventingContext(),
      _useEventingContext2 = _slicedToArray(_useEventingContext, 2),
      dispatch = _useEventingContext2[1].dispatch;

  var intersectionObserver = useIntersectionObserver();
  var item = props.item,
      storeConfig = props.storeConfig;
  var finalPrice = item === null || item === void 0 ? void 0 : (_item$price_range = item.price_range) === null || _item$price_range === void 0 ? void 0 : (_item$price_range$max = _item$price_range.maximum_price) === null || _item$price_range$max === void 0 ? void 0 : (_item$price_range$max2 = _item$price_range$max.final_price) === null || _item$price_range$max2 === void 0 ? void 0 : _item$price_range$max2.value;
  var discountAmount = item === null || item === void 0 ? void 0 : (_item$price_range2 = item.price_range) === null || _item$price_range2 === void 0 ? void 0 : (_item$price_range2$ma = _item$price_range2.maximum_price) === null || _item$price_range2$ma === void 0 ? void 0 : (_item$price_range2$ma2 = _item$price_range2$ma.discount) === null || _item$price_range2$ma2 === void 0 ? void 0 : _item$price_range2$ma2.amount_off;
  var currencyCode = item === null || item === void 0 ? void 0 : (_item$price_range3 = item.price_range) === null || _item$price_range3 === void 0 ? void 0 : (_item$price_range3$ma = _item$price_range3.maximum_price) === null || _item$price_range3$ma === void 0 ? void 0 : (_item$price_range3$ma2 = _item$price_range3$ma.final_price) === null || _item$price_range3$ma2 === void 0 ? void 0 : _item$price_range3$ma2.currency;
  var handleLinkClick = useCallback(function () {
    dispatch({
      type: 'PRODUCT_CLICK',
      payload: {
        name: item.name,
        sku: item.sku,
        priceTotal: finalPrice,
        discountAmount: discountAmount,
        currencyCode: currencyCode,
        selectedOptions: null
      }
    });
  }, [currencyCode, discountAmount, dispatch, finalPrice, item]);
  var itemRef = useRef(null);
  var contextRef = useRef({
    dispatched: false,
    timeOutId: null
  });
  useEffect(function () {
    if (typeof intersectionObserver === 'undefined' || !item || contextRef.current.dispatched) {
      return;
    }

    var htmlElement = itemRef.current;

    var onIntersection = function onIntersection(entries) {
      if (entries[0].isIntersecting) {
        contextRef.current.timeOutId = setTimeout(function () {
          observer.unobserve(htmlElement);
          dispatch({
            type: 'PRODUCT_IMPRESSION',
            payload: {
              name: item.name,
              sku: item.sku,
              priceTotal: finalPrice,
              discountAmount: discountAmount,
              currencyCode: currencyCode,
              selectedOptions: null
            }
          });
          contextRef.current.dispatched = true;
        }, 500);
      } else {
        clearTimeout(contextRef.current.timeOutId);
      }
    };

    var observer = new intersectionObserver(onIntersection, {
      threshold: 0.9
    });

    if (htmlElement) {
      observer.observe(htmlElement);
    }

    return function () {
      if (htmlElement) {
        observer.unobserve(htmlElement);
      }
    };
  }, [currencyCode, discountAmount, dispatch, finalPrice, intersectionObserver, item]);
  var productType = item ? item.__typename : null;
  var isSupportedProductType = isSupported(productType);
  var wishlistButtonProps = storeConfig && storeConfig.magento_wishlist_general_is_enabled === '1' ? {
    item: {
      sku: item.sku,
      quantity: 1
    },
    storeConfig: storeConfig
  } : null;
  return _objectSpread(_objectSpread({}, props), {}, {
    itemRef: itemRef,
    handleLinkClick: handleLinkClick,
    wishlistButtonProps: wishlistButtonProps,
    isSupportedProductType: isSupportedProductType
  });
};