// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navHeader-title-jo- {\n    display: inline-flex;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "navHeader-title-jo- text-sm capitalize items-center"
};
export default ___CSS_LOADER_EXPORT___;
