import _extends from "@babel/runtime/helpers/extends";
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMegaMenuItem } from '@magento/peregrine/lib/talons/MegaMenu/useMegaMenuItem';
import { useStyle } from '@magento/venia-ui/lib/classify';
import defaultClasses from './megaMenuItem.module.css';
import Submenu from './Submenu/subMenu';
/**
 * The MegaMenuItem component displays mega menu item
 *
 * @param {MegaMenuCategory} props.category
 * @param {int} props.activeCategoryId - id of active category
 * @param {int} props.mainNavWidth - width of the main nav. It's used for setting min-width of the submenu
 * @param {function} props.onNavigate - function called when clicking on Link
 */

var MegaMenuItem = function MegaMenuItem(props) {
  var activeCategoryId = props.activeCategoryId,
      category = props.category,
      categoryUrlSuffix = props.categoryUrlSuffix,
      subMenuState = props.subMenuState,
      disableFocus = props.disableFocus,
      onNavigate = props.onNavigate;
  var classes = useStyle(defaultClasses, props.classes);
  var talonProps = useMegaMenuItem({
    category: category,
    activeCategoryId: activeCategoryId,
    subMenuState: subMenuState,
    disableFocus: disableFocus
  });
  var isFocused = talonProps.isFocused,
      isActive = talonProps.isActive,
      handleCloseSubMenu = talonProps.handleCloseSubMenu,
      isMenuActive = talonProps.isMenuActive,
      handleKeyDown = talonProps.handleKeyDown;
  var megaMenuItemClassname = isMenuActive ? classes.megaMenuItem_active : classes.megaMenuItem;
  var children = useMemo(function () {
    return category.children.length ? React.createElement(Submenu, {
      isFocused: isFocused,
      subMenuState: subMenuState,
      items: category,
      handleCloseSubMenu: handleCloseSubMenu,
      categoryUrlSuffix: categoryUrlSuffix,
      onNavigate: onNavigate
    }) : null;
  }, [category, isFocused, subMenuState, handleCloseSubMenu, categoryUrlSuffix, onNavigate]);
  var linkAttributes = category.children.length ? {
    'aria-label': "Category: ".concat(category.name, ". ").concat(category.children.length, " sub-categories")
  } : {};
  var categoryLink = '';
  if (category.name === 'Products') categoryLink = '/products';else if (category.name === 'Collections') categoryLink = '/collections';else if (category.name === 'Inspiration') categoryLink = '/inspiration/installations-residential-gallery';else if (category.name === 'For Professionals') categoryLink = '/for-professionals/dealers';else if (category.name === 'About') categoryLink = '/about/company-our-team';else if (category.name === 'Where To Buy') categoryLink = '/where-to-buy/buy-local';
  return React.createElement("div", {
    className: megaMenuItemClassname
  }, React.createElement(Link, _extends({
    to: categoryLink
  }, linkAttributes, {
    onKeyDown: handleKeyDown,
    className: isActive ? classes.megaMenuLinkActive : classes.megaMenuLink
  }), category.name), children);
};

export default MegaMenuItem;
MegaMenuItem.propTypes = {
  category: PropTypes.shape({
    children: PropTypes.array,
    id: PropTypes.number.isRequired,
    include_in_menu: PropTypes.number,
    isActive: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.array.isRequired,
    position: PropTypes.number.isRequired,
    url_path: PropTypes.string.isRequired
  }).isRequired,
  activeCategoryId: PropTypes.number,
  // mainNavWidth: PropTypes.number.isRequired,
  categoryUrlSuffix: PropTypes.string,
  onNavigate: PropTypes.func.isRequired
};