import React, { useMemo, useEffect } from 'react';
import { useStyle } from '@magento/venia-ui/lib/classify';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import defaultClasses from './quoteMiniCart.module.css';
import { QuotesIcon } from '../../';
import { GET_QUOTE_REQUEST } from './quoteMiniCart.gql';
import { setQuotes } from '../../../overrides/peregrine/store/actions/quotes/asyncActions';

var QuoteMiniCart = function QuoteMiniCart(props) {
  var dispatch = useDispatch();
  var quotesCount = useSelector(function (state) {
    return state.quotes.quotesCount;
  });

  var _useQuery = useQuery(GET_QUOTE_REQUEST, {
    fetchPolicy: 'no-cache'
  }),
      data = _useQuery.data,
      error = _useQuery.error,
      loading = _useQuery.loading;

  useEffect(function () {
    if (data) {
      dispatch(setQuotes(Number(data === null || data === void 0 ? void 0 : data.mpCurrentQuote.quote.items_count)));
    }
  }, [data, setQuotes]);
  var classes = useStyle(defaultClasses, props.classes);
  var itemCountDisplay = quotesCount > 99 ? '99+' : quotesCount;
  var maybeQuoteItemCounter = quotesCount ? React.createElement("span", {
    className: classes.counter
  }, itemCountDisplay) : null;
  return React.createElement(Link, {
    to: '/quote-request',
    className: "relative",
    "aria-label": "Go to Quote Request page"
  }, React.createElement(QuotesIcon, null), maybeQuoteItemCounter);
};

export default QuoteMiniCart;