import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React, { useEffect, useLayoutEffect, useState } from 'react';
import defaultClasses from './getCandid.module.css';
import tags from './tags';
import { useWindowSize as useWindowWidthCheck } from '@magento/peregrine';
import { useLocation } from 'react-router-dom';

var GetCandid = function GetCandid(props) {
  var location = useLocation();
  var commercialOrResidential = location.pathname.includes('residential') ? 'residential' : 'commercial';

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      loaded = _useState2[0],
      setLoaded = _useState2[1];

  var _useWindowSize = useWindowSize(),
      _useWindowSize2 = _slicedToArray(_useWindowSize, 2),
      width = _useWindowSize2[0],
      height = _useWindowSize2[1];

  var windowSize = useWindowWidthCheck();
  var isMobile = windowSize.innerWidth < 767;

  var _useState3 = useState(tags[commercialOrResidential][0]),
      _useState4 = _slicedToArray(_useState3, 2),
      activeTags = _useState4[0],
      setActiveTags = _useState4[1];

  var candidId = props.candidId,
      candidTheme = props.candidTheme,
      candidOverlayTheme = props.candidOverlayTheme,
      margin = props.margin,
      count = props.count,
      slideShow = props.slideShow,
      imageWidth = props.imageWidth,
      fullWidth = props.fullWidth,
      term = props.term,
      productUrl = props.productUrl,
      minHeight = props.minHeight,
      textAlign = props.textAlign,
      type = props.type,
      containerWidth = props.containerWidth,
      sortBy = props.sortBy,
      sortDirection = props.sortDirection,
      tagCondition = props.tagCondition,
      _props$showCommunityU = props.showCommunityUploadButton,
      showCommunityUploadButton = _props$showCommunityU === void 0 ? false : _props$showCommunityU,
      border = props.border,
      borderColor = props.borderColor,
      borderWidth = props.borderWidth,
      borderRadius = props.borderRadius,
      marginTop = props.marginTop,
      marginRight = props.marginRight,
      marginBottom = props.marginBottom,
      marginLeft = props.marginLeft,
      mediaQueries = props.mediaQueries,
      paddingTop = props.paddingTop,
      paddingRight = props.paddingRight,
      paddingBottom = props.paddingBottom,
      paddingLeft = props.paddingLeft,
      familly = props.familly,
      _props$cssClasses = props.cssClasses,
      cssClasses = _props$cssClasses === void 0 ? [] : _props$cssClasses,
      children = props.children,
      _props$isPdp = props.isPdp,
      isPdp = _props$isPdp === void 0 ? false : _props$isPdp;
  var wrapperStyles = {
    marginTop: marginTop,
    marginRight: marginRight,
    marginBottom: marginBottom,
    marginLeft: marginLeft,
    paddingTop: paddingTop,
    paddingRight: paddingRight,
    paddingBottom: paddingBottom,
    paddingLeft: paddingLeft,
    border: border,
    borderColor: borderColor,
    borderRadius: borderRadius,
    borderWidth: borderWidth
  };
  var contentStyles = {
    minHeight: minHeight,
    textAlign: textAlign
  };
  var candid = window.candid;
  var containerId = productUrl ? defaultClasses.getCandidProductPage : fullWidth || containerWidth === '100' ? defaultClasses.getCandidFullWidth : defaultClasses.getCandid;
  var slideNumber = isMobile ? 1 : count;
  var mobileWidth = windowSize.innerWidth / 100 * 80;
  var clusterId = '62ab8f2ea4372f2f81bc93bd-7082e3b90f5fe18bfdcb9e38c07dca6d8f33de0e8c0c51e2cbfadf20fa9150b1cf719a3e39d27aef2e020824edad4aaa6d8d9e2a7c68400613ca172e84b52a922c06add942875b50cd15f8a4791e4daa0aa09975dd74414744362e378a703d2a';
  useEffect(function () {
    // removed as it was inconsistent, moved widget js to load from template
    if (candidId && !candid) {// const script = document.createElement('script');
      // script.src = '//api.getcandid.com/scripts/widget.js';
      // script.async = true;
      // document.body.appendChild(script);
      // return () => {
      //     document.body.removeChild(script);
      // };
    }

    var scriptTagExists = document.getElementById('mediaTemplate');
    var filterTagExists = document.getElementById('filterTemplate');

    if (!filterTagExists) {
      var filterTemplate = document.createElement('script');
      filterTemplate.text = '<div></div>';
      filterTemplate.id = 'filterTemplate';
      filterTemplate.type = 'text/x-jsrender';
      document.body.appendChild(filterTemplate);
    }

    if (!scriptTagExists) {
      var script = document.createElement('script');
      script.text = "<div class='candid-wall-cell'>        <div class='candid-wall-overlay'></div><div class='candid-wall-overlay-text'><div> {{> Entry.Tags.ProductCount > 0 ? 'SHOP NOW' : 'VIEW NOW' }}</div></div><a class='media' data-media-index='{{> Index }}'><img data-original='{{> Media.Images.LowResolution.Url }}'alt='{{> Title }}' style='display:inline-block;' class='lazy'></a></div>";
      script.id = 'mediaTemplate';
      script.type = 'text/x-jsrender';
      document.body.appendChild(script);
    }
  }, [candidId]);
  useEffect(function () {
    if (candidId && candid) {
      var theme = candidTheme !== '' ? candidTheme : 'ct-minimal';
      var overlayTheme = candidOverlayTheme !== '' ? candidOverlayTheme : theme;

      if (type === 'feed' || !type) {
        candid.init({
          id: candidId,
          tag: familly ? familly === null || familly === void 0 ? void 0 : familly.replace(/ /g, '-').toLowerCase() : term ? term : '',
          cluster: clusterId,
          sort: sortBy !== null && sortBy !== void 0 ? sortBy : 'Date',
          sortDirection: sortDirection !== null && sortDirection !== void 0 ? sortDirection : 'Descending',
          margin: margin,
          width: isPdp ? imageWidth : imageWidth !== '0' ? isMobile ? mobileWidth : imageWidth : isMobile ? mobileWidth : width * 0.196875,
          containerId: containerId,
          count: count,
          pageSize: slideNumber,
          slideShow: slideShow ? slideShow : false,
          layoutMode: 'masonry',
          layout: 'standard',
          fillTag: 'homepage',
          delimiters: ['[[', ']]'],
          tagUrl: productUrl ? productUrl : null,
          tagsOp: tagCondition !== null && tagCondition !== void 0 ? tagCondition : 'OR',
          theme: theme,
          overlayTheme: overlayTheme
        });
      }

      if (type === 'wall' && !loaded) {
        var _ref;

        candid.wall("#".concat(containerId), {
          id: candidId,
          tag: term !== null && term !== void 0 ? term : '',
          cluster: clusterId,
          sort: sortBy !== null && sortBy !== void 0 ? sortBy : 'Date',
          sortDirection: sortDirection !== null && sortDirection !== void 0 ? sortDirection : 'Descending',
          margin: margin,
          type: 'Tag',
          width: (_ref = imageWidth !== '0') !== null && _ref !== void 0 ? _ref : width * 0.196875,
          containerId: containerId,
          count: count,
          pageSize: count,
          slideShow: slideShow ? slideShow : false,
          // layoutMode: 'masonry',
          layout: 'standard',
          fillTag: 'homepage',
          tagUrl: productUrl ? productUrl : null,
          cache: true,
          loadMoreHtml: null,
          delimiters: ['[[', ']]'],
          tagsOp: tagCondition !== null && tagCondition !== void 0 ? tagCondition : 'OR',
          theme: theme,
          overlayTheme: overlayTheme
        });
        setLoaded(true);
      }
    }
  }, [candid, width, candidId, candidTheme, slideNumber, loaded, clusterId]);
  if (!candidId) return React.createElement(React.Fragment, null);
  var mappedTags = type === 'wall' ? tags[commercialOrResidential].map(function (tag, index) {
    var formattedTag = tag.value === 'all' ? '' : tag.value;
    return React.createElement("a", {
      key: "filter-tag-".concat(index),
      onClick: function onClick() {
        setActiveTags(tag);
        var y = document.getElementById(containerId).getBoundingClientRect().top + window.scrollY - 80;
        window.scroll({
          top: y,
          behavior: 'smooth'
        });
      },
      className: "".concat(defaultClasses.tagHyperlink, " ").concat(tag === activeTags ? defaultClasses.tagActiveHyperlink : ''),
      href: "javascript:candid.wall('#" + containerId + "', { id: '" + candidId + "', host: 'api.getcandid.com', tag: '" + term + ',' + formattedTag + "', type: 'Tag', scroll: false, layout: 'standard', page: 1, tagsOp: 'AND' });",
      title: "Only show ".concat(tag.label)
    }, tag.label);
  }) : React.createElement(React.Fragment, null);
  return React.createElement("div", {
    style: wrapperStyles,
    className: defaultClasses.root
  }, React.createElement("div", {
    id: defaultClasses.filters,
    className: "filters"
  }, mappedTags), React.createElement("div", {
    id: containerId,
    style: _objectSpread({
      maxWidth: containerWidth
    }, contentStyles)
  }), React.createElement("div", {
    className: defaultClasses.footer
  }, showCommunityUploadButton ? React.createElement(React.Fragment, null, React.createElement("div", {
    className: defaultClasses.centerContent
  }, React.createElement("span", null, "@HUBBARDTONFORGE"), familly ? React.createElement("h4", {
    className: "font-bold text-black"
  }, "#", familly) : React.createElement("h4", {
    className: "font-bold text-black"
  }, "#hubbardtonforge")), React.createElement("a", {
    className: "font-bold",
    href: "javascript:candid.upload('".concat(candidId, "', ['").concat(term, "'").concat(activeTags ? ",'" + activeTags + "'" : '', "], 'api.getcandid.com', {terms: {enabled: true,checked: false,label: \"I agree to the <a href='/getcandid-terms' target='_blank' style='text-decoration: underline;'>terms</a>\"}});")
  }, "Upload Your HF Photo")) : React.createElement(React.Fragment, null)));
};

function useWindowSize() {
  var _useState5 = useState([0, 0]),
      _useState6 = _slicedToArray(_useState5, 2),
      size = _useState6[0],
      setSize = _useState6[1];

  useLayoutEffect(function () {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return function () {
      return window.removeEventListener('resize', updateSize);
    };
  }, []);
  return size;
}

export default GetCandid;