// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".megaMenu-megaMenu-rF9 {\n    display: none;\n}\n\n@media (min-width: 1024px) and (max-width: 1279px) {\n    .megaMenu-megaMenu-rF9 {\n        justify-content: flex-end;\n    }\n}\n\n@media (min-width: 1024px) {\n    .megaMenu-megaMenu-rF9 {\n        display: flex;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"megaMenu": "megaMenu-megaMenu-rF9 col-end-12 lg_col-end-11 xl_col-end-11 col-start-4 flex-grow justify-self-center"
};
export default ___CSS_LOADER_EXPORT___;
