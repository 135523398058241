import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _reducerMap;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { handleActions } from 'redux-actions';
import BrowserPersistence from '@magento/peregrine/lib/util/simplePersistence';
var storage = new BrowserPersistence();
import actions from '@magento/peregrine/lib/store/actions/user';
export var name = 'user';
var rawSignInToken = storage.getRawItem('signin_token');

var isSignedIn = function isSignedIn() {
  return !!rawSignInToken;
};

var getToken = function getToken() {
  if (!rawSignInToken) {
    return undefined;
  }

  var _JSON$parse = JSON.parse(rawSignInToken),
      value = _JSON$parse.value;

  return value;
};

var initialState = {
  currentUser: {
    email: '',
    firstname: '',
    lastname: '',
    customer_flow_map_group: 'Not logged-in',
    group_uid: 0,
    customer_price_group: null,
    role: null,
    companyType: null,
    addresses: null
  },
  getDetailsError: null,
  isGettingDetails: false,
  isResettingPassword: false,
  isSignedIn: isSignedIn(),
  resetPasswordError: null,
  token: getToken()
};
var reducerMap = (_reducerMap = {}, _defineProperty(_reducerMap, actions.setToken, function (state, _ref) {
  var payload = _ref.payload;
  return _objectSpread(_objectSpread({}, state), {}, {
    isSignedIn: true,
    token: payload
  });
}), _defineProperty(_reducerMap, actions.clearToken, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    isSignedIn: false,
    token: null
  });
}), _defineProperty(_reducerMap, actions.getDetails.request, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    getDetailsError: null,
    isGettingDetails: true
  });
}), _defineProperty(_reducerMap, actions.getDetails.receive, function (state, _ref2) {
  var payload = _ref2.payload,
      error = _ref2.error;

  if (error) {
    return _objectSpread(_objectSpread({}, state), {}, {
      getDetailsError: payload,
      isGettingDetails: false
    });
  }

  return _objectSpread(_objectSpread({}, state), {}, {
    currentUser: payload,
    getDetailsError: null,
    isGettingDetails: false
  });
}), _defineProperty(_reducerMap, actions.resetPassword.request, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    isResettingPassword: true
  });
}), _defineProperty(_reducerMap, actions.resetPassword.receive, function (state, _ref3) {
  var payload = _ref3.payload,
      error = _ref3.error;

  if (error) {
    return _objectSpread(_objectSpread({}, state), {}, {
      isResettingPassword: false,
      resetPasswordError: payload
    });
  }

  return _objectSpread(_objectSpread({}, state), {}, {
    isResettingPassword: false,
    resetPasswordError: null
  });
}), _defineProperty(_reducerMap, actions.reset, function () {
  return _objectSpread(_objectSpread({}, initialState), {}, {
    isSignedIn: false,
    token: null
  });
}), _reducerMap);
export default handleActions(reducerMap, initialState);