import React from 'react';
import { shape, string, bool } from 'prop-types';
import defaulClasses from './navButton.module.css';
import { NextIcon, PrevIcon } from '../Common/Icons';
import { useMergeCssClasses } from '../../Utils/utils';

var NavButton = function NavButton(_ref) {
  var active = _ref.active,
      buttonLabel = _ref.buttonLabel,
      onClick = _ref.onClick,
      isNext = _ref.isNext,
      propClasses = _ref.classes;
  var classes = useMergeCssClasses(defaulClasses, propClasses);
  var iconsContent = isNext ? React.createElement(NextIcon, null) : React.createElement(PrevIcon, null);
  var iconClasses = active ? classes.root : classes.icon_disabled;
  return React.createElement("button", {
    "aria-label": buttonLabel,
    className: iconClasses,
    disabled: !active,
    onClick: onClick
  }, iconsContent);
};

export default NavButton;
NavButton.propTypes = {
  classes: shape({
    icon: string,
    icon_disabled: string,
    root: string
  }),
  isNext: bool
};
NavButton.defaultProps = {
  buttonLabel: 'move to another page',
  isNext: false
};