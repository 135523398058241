// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wishlistLineItem-root-sde {\n    width: 100%;\n    text-align: left;\n\n    border-bottom: 1px solid rgb(var(--venia-global-color-gray-dark));\n    font-weight: var(--venia-global-fontWeight-semibold);\n    height: 3.5rem;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "wishlistLineItem-root-sde"
};
export default ___CSS_LOADER_EXPORT___;
