;globalThis.fetchRootComponent = (function () {
            const getKey = function(type, variant) {
    return 'RootCmp_' + type + '__' + (variant || 'default');
};
            const esModuleInterop = function(mod) {
    return mod.default || mod;
};
            const rootComponentsMap = {RootCmp_CMS_PAGE__default:function () { return import(/* webpackChunkName: "RootCmp_CMS_PAGE__default" */'./src/RootComponents/CMS/index.js')},
RootCmp_CATEGORY__default:function () { return import(/* webpackChunkName: "RootCmp_CATEGORY__default" */'./src/RootComponents/Category/index.js')},
RootCmp_PRODUCT__default:function () { return import(/* webpackChunkName: "RootCmp_PRODUCT__default" */'./node_modules/@magento/venia-ui/lib/RootComponents/Product/index.js')},
RootCmp_SEARCH__default:function () { return import(/* webpackChunkName: "RootCmp_SEARCH__default" */'./node_modules/@magento/venia-ui/lib/RootComponents/Search/index.js')}};
            return function importRootComponent(type, variant) {
            const importerKey = getKey(type, variant);
            return rootComponentsMap[importerKey]()
                .then(esModuleInterop);
        }
        })()