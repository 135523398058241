import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { gql } from '@apollo/client';
export var getFooterData = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query GetFooter {\n    getFooter {\n      id\n      name\n      row {\n        id\n        name\n        sort_order\n        url\n      }\n    }\n  }\n"])));
export var getCopyrightQuery = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query FooterBottom {\n    footerBottom {\n      id\n      name\n      row {\n        id\n        Name\n        row\n        url\n        sortorder\n        icon\n      }\n    }\n  }\n"])));
export default {
  getFooterData: getFooterData,
  getCopyrightQuery: getCopyrightQuery
};