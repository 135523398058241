import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from 'react';
import { useToasts } from '@magento/peregrine';
import { AlertCircle as AlertCircleIcon, Check as CheckIcon } from 'react-feather';
import { useIntl } from 'react-intl';
import Icon from '@magento/venia-ui/lib/components/Icon';
var ErrorIcon = React.createElement(Icon, {
  src: AlertCircleIcon,
  alt: "Error icon",
  attrs: {
    width: 18
  }
});
var SuccessIcon = React.createElement(Icon, {
  src: CheckIcon,
  alt: "Success icon",
  attrs: {
    width: 18
  }
});

function useToast() {
  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var _useToasts = useToasts(),
      _useToasts2 = _slicedToArray(_useToasts, 2),
      addToast = _useToasts2[1].addToast;

  var addMessage = function addMessage(type, message) {
    return addToast({
      type: type,
      icon: type === 'success' ? SuccessIcon : ErrorIcon,
      message: formatMessage({
        id: message,
        defaultMessage: message
      }),
      timeout: 3000
    });
  };

  return {
    addMessage: addMessage
  };
}

export default useToast;