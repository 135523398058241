// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../components/Dialog/dialog.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wishlistDialog-root-6DO {\n    overflow-y: auto;\n    height: 60vh;\n    width: 38rem;\n}\n\n.wishlistDialog-rootDialog-6up {\n}\n\n/* .rootDialog > div {\n    height: 821px;\n} */\n\n.wishlistDialog-cancelBtn-7Z1:hover {\n    background-color: var(--venia-global-color-urbane-bronze);\n    border-color: var(--venia-global-color-urbane-bronze);\n}\n\n.wishlistDialog-formErrors-eNP {\n    border-color: rgb(var(--venia-global-color-error));\n    border-style: solid;\n    border-width: 0 0 0 5px;\n    padding: 1rem 0 1rem 1rem;\n    display: grid;\n    grid-column: 1 / span 1;\n}\n\n.wishlistDialog-existingWishlists-T88 {\n}\n\n.wishlistDialog-cancelBtnWrapper-wvP {\n    width: 141px;\n}\n\n@media screen and (max-width: 991px) {\n    .wishlistDialog-root-6DO {\n        width: 100%;\n    }\n}\n\n.wishlistDialog-loadingIndicator-37P {\n    opacity: 0.5;\n}\n\n@supports (font: -apple-system-body) and (-webkit-appearance: none) {\n    .wishlistDialog-root-6DO {\n        max-height: calc(100% - 50px);\n        overflow-y: auto;\n    }\n\n    .wishlistDialog-listHolder-K2h {\n        max-height: 200px;\n        overflow-y: auto !important;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "wishlistDialog-root-6DO px-4 md_px-16",
	"rootDialog": "wishlistDialog-rootDialog-6up " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["form"] + "",
	"cancelBtn": "wishlistDialog-cancelBtn-7Z1",
	"formErrors": "wishlistDialog-formErrors-eNP",
	"existingWishlists": "wishlistDialog-existingWishlists-T88",
	"cancelBtnWrapper": "wishlistDialog-cancelBtnWrapper-wvP mr-5",
	"loadingIndicator": "wishlistDialog-loadingIndicator-37P",
	"listHolder": "wishlistDialog-listHolder-K2h"
};
export default ___CSS_LOADER_EXPORT___;
