import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["children", "classes", "priority", "negative", "disabled", "size", "onPress", "fullWidth"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React, { useRef } from 'react';
import { useButton } from 'react-aria';
import { oneOf, shape, string, bool } from 'prop-types';
import defaultClasses from './button.module.css';
import { useMergeCssClasses } from '../../../Utils/utils';

var getRootClassName = function getRootClassName(priority, negative) {
  return "root_".concat(priority, "Priority").concat(negative ? 'Negative' : '');
};
/**
 * A component for buttons.
 *
 * @typedef Button
 * @kind functional component
 *
 * @param {props} props React component props
 *
 * @returns {React.Element} A React component that displays a single button.
 */


var Button = function Button(props) {
  var children = props.children,
      propClasses = props.classes,
      priority = props.priority,
      negative = props.negative,
      disabled = props.disabled,
      size = props.size,
      onPress = props.onPress,
      fullWidth = props.fullWidth,
      restProps = _objectWithoutProperties(props, _excluded);

  var buttonRef = useRef();

  var _useButton = useButton(_objectSpread({
    isDisabled: disabled,
    onPress: onPress
  }, restProps), buttonRef),
      buttonProps = _useButton.buttonProps;

  var classes = useMergeCssClasses(defaultClasses, propClasses);
  var rootClassName = classes[getRootClassName(priority, negative)];
  var btnSize = classes[size];
  return React.createElement("button", _extends({
    ref: buttonRef,
    className: "".concat(rootClassName, " ").concat(classes.root, " ").concat(btnSize, " ").concat(fullWidth ? "".concat(classes.fullWidth) : '')
  }, buttonProps, restProps), React.createElement("span", {
    className: classes.content
  }, children));
};
/**
 * Props for {@link Button}
 *
 * @typedef props
 *
 * @property {Object} classes An object containing the class names for the
 * Button component.
 * @property {string} classes.content classes for the button content
 * @property {string} classes.root classes for root container
 * @property {string} classes.root_highPriority classes for Button if
 * high priority.
 * @property {string} classes.root_lowPriority classes for Button if
 * low priority.
 * @property {string} classes.root_normalPriority classes for Button if
 * normal priority.
 * @property {string} priority the priority/importance of the Button
 * @property {string} type the type of the Button
 * @property {string} size the size of the Button
 * @property {bool} negative whether the button should be displayed in red for a negative action
 * @property {bool} disabled is the button disabled
 */


Button.propTypes = {
  classes: shape({
    content: string,
    root: string,
    root_highPriority: string,
    root_lowPriority: string,
    root_normalPriority: string
  }),
  priority: oneOf(['high', 'low', 'normal']).isRequired,
  type: oneOf(['button', 'reset', 'submit']).isRequired,
  size: oneOf(['small', 'normal', 'large', 'regWidth']).isRequired,
  negative: bool,
  disabled: bool,
  fullWidth: bool
};
Button.defaultProps = {
  priority: 'high',
  type: 'button',
  negative: false,
  disabled: false,
  size: 'normal',
  fullWidth: false
};
export default Button;