import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import mergeOperations from '@magento/peregrine/lib/util/shallowMerge';
import { useAppContext } from '@magento/peregrine/lib/context/app';
import { useCatalogContext } from '@magento/peregrine/lib/context/catalog';
import { useUserContext } from '@magento/peregrine/lib/context/user';
import { useAwaitQuery } from '@magento/peregrine/lib/hooks/useAwaitQuery';
import useInternalLink from '@magento/peregrine/lib/hooks/useInternalLink';
import DEFAULT_OPERATIONS from './navigation.gql';
import { useResources } from '../../../../pages/Resources/useResources';
import { setResources } from '../../store/actions/resources/asyncActions';
var ancestors = {
  CREATE_ACCOUNT: 'SIGN_IN',
  FORGOT_PASSWORD: 'SIGN_IN',
  MY_ACCOUNT: 'MENU',
  SIGN_IN: 'MENU',
  MENU: null
};
export var useNavigation = function useNavigation() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  var getCustomerQuery = operations.getCustomerQuery,
      getRootCategoryId = operations.getRootCategoryId; // retrieve app state from context

  var _useAppContext = useAppContext(),
      _useAppContext2 = _slicedToArray(_useAppContext, 2),
      appState = _useAppContext2[0],
      closeDrawer = _useAppContext2[1].closeDrawer;

  var _useCatalogContext = useCatalogContext(),
      _useCatalogContext2 = _slicedToArray(_useCatalogContext, 2),
      catalogState = _useCatalogContext2[0],
      catalogActions = _useCatalogContext2[1].actions;

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 2),
      getUserDetails = _useUserContext2[1].getUserDetails;

  var fetchUserDetails = useAwaitQuery(getCustomerQuery);

  var _useResources = useResources(),
      resourcesAll = _useResources.resourcesAll;

  var dispatch = useDispatch(); // request data from server

  useEffect(function () {
    getUserDetails({
      fetchUserDetails: fetchUserDetails
    });
    dispatch(setResources(resourcesAll));
  }, [fetchUserDetails, getUserDetails, resourcesAll]);

  var _useQuery = useQuery(getRootCategoryId, {
    fetchPolicy: 'cache-and-network'
  }),
      getRootCategoryData = _useQuery.data;

  var rootCategoryId = useMemo(function () {
    if (getRootCategoryData) return getRootCategoryData.storeConfig.root_category_uid;
  }, [getRootCategoryData]); // extract relevant data from app state

  var drawer = appState.drawer;
  var isOpen = drawer === 'nav';
  var categories = catalogState.categories; // get local state

  var _useState = useState('MENU'),
      _useState2 = _slicedToArray(_useState, 2),
      view = _useState2[0],
      setView = _useState2[1];

  var _useState3 = useState(rootCategoryId),
      _useState4 = _slicedToArray(_useState3, 2),
      categoryId = _useState4[0],
      setCategoryId = _useState4[1];

  useEffect(function () {
    // On a fresh render with cold cache set the current category as root
    // once the root category query completes.
    if (rootCategoryId && !categoryId) setCategoryId(rootCategoryId);
  }, [categoryId, rootCategoryId]); // define local variables

  var category = categories[categoryId];
  var isTopLevel = categoryId === rootCategoryId;
  var hasModal = view !== 'MENU';
  useEffect(function () {
    return !isOpen && rootCategoryId !== categoryId && setCategoryId(rootCategoryId);
  }, [isOpen]); // define handlers

  var handleBack = useCallback(function () {
    var parent = ancestors[view];

    if (parent) {
      setView(parent);
    } else if (category && !isTopLevel) {
      setCategoryId(category.parentId);
    } else {
      closeDrawer();
    }
  }, [category, closeDrawer, isTopLevel, view]);

  var _useInternalLink = useInternalLink('category'),
      setShimmerType = _useInternalLink.setShimmerType;

  var handleClose = useCallback(function () {
    closeDrawer(); // Sets next root component to show proper loading effect

    setShimmerType();
  }, [closeDrawer, setShimmerType]); // create callbacks for local state

  var showCreateAccount = useCallback(function () {
    setView('CREATE_ACCOUNT');
  }, [setView]);
  var showForgotPassword = useCallback(function () {
    setView('FORGOT_PASSWORD');
  }, [setView]);
  var showMainMenu = useCallback(function () {
    setView('MENU');
  }, [setView]);
  var showMyAccount = useCallback(function () {
    setView('MY_ACCOUNT');
  }, [setView]);
  var showSignIn = useCallback(function () {
    setView('SIGN_IN');
  }, [setView]);
  return {
    catalogActions: catalogActions,
    categoryId: categoryId,
    handleBack: handleBack,
    handleClose: handleClose,
    hasModal: hasModal,
    isOpen: isOpen,
    isTopLevel: isTopLevel,
    setCategoryId: setCategoryId,
    showCreateAccount: showCreateAccount,
    showForgotPassword: showForgotPassword,
    showMainMenu: showMainMenu,
    showMyAccount: showMyAccount,
    showSignIn: showSignIn,
    view: view
  };
};