// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".spinner-root-s7b {\n    --stroke: rgb(var(--venia-global-color-text-hint));\n}\n\n.spinner-icon-yJJ {\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "spinner-root-s7b",
	"icon": "spinner-icon-yJJ animation-spin"
};
export default ___CSS_LOADER_EXPORT___;
