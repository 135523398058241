import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { bool, func, shape, string } from 'prop-types';
import { useStyle } from '@magento/venia-ui/lib/classify';
import AccountChip from '@magento/venia-ui/lib/components/AccountChip';
import Trigger from '@magento/venia-ui/lib/components/Trigger';
import { useNavigationHeader } from '@magento/peregrine/lib/talons/Navigation/useNavigationHeader';
import defaultClasses from './navHeader.module.css';
import Icon from '../Common/Icon/icon';
import arrow from 'src/assets/arrow.svg';

var NavHeader = function NavHeader(props) {
  var isTopLevel = props.isTopLevel,
      onBack = props.onBack,
      view = props.view;

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var talonProps = useNavigationHeader({
    isTopLevel: isTopLevel,
    onBack: onBack,
    view: view
  });
  var handleBack = talonProps.handleBack,
      isTopLevelMenu = talonProps.isTopLevelMenu;
  var classes = useStyle(defaultClasses, props.classes);
  var titles = {
    CREATE_ACCOUNT: formatMessage({
      id: 'navHeader.createAccountText',
      defaultMessage: 'Create Account'
    }),
    FORGOT_PASSWORD: formatMessage({
      id: 'navHeader.forgotPasswordText',
      defaultMessage: 'Forgot Password'
    }),
    MY_ACCOUNT: formatMessage({
      id: 'navHeader.myAccountText',
      defaultMessage: 'My Account'
    }),
    SIGN_IN: formatMessage({
      id: 'navHeader.signInText',
      defaultMessage: 'Sign In'
    }),
    MENU: formatMessage({
      id: 'navHeader.mainMenuText',
      defaultMessage: 'Main Menu'
    })
  };
  var backIcon = arrow;
  var titleElement;

  if (['MY_ACCOUNT', 'SIGN_IN'].includes(view)) {
    titleElement = React.createElement(AccountChip, {
      fallbackText: formatMessage({
        id: 'navHeader.accountText',
        defaultMessage: 'Account'
      })
    });
  } else {
    var title = titles[view] || titles.MENU;
    titleElement = React.createElement("span", null, title);
  }

  var backLink = !isTopLevel ? React.createElement(Fragment, null, React.createElement(Trigger, {
    key: "backButton",
    action: handleBack,
    "aria-label": "Go back"
  }, React.createElement("span", {
    className: "sr-only"
  }, "Go back"), React.createElement(Icon, {
    src: backIcon,
    alt: "Back icon"
  })), React.createElement("span", {
    key: "title",
    className: classes.title
  }, isTopLevel ? titleElement : 'Back')) : null;
  return backLink;
};

export default NavHeader;
NavHeader.propTypes = {
  classes: shape({
    title: string
  }),
  isTopLevel: bool,
  onBack: func.isRequired,
  view: string.isRequired
};