// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".radio-root-Eu0 {\n    /* composes: grid-flow-col from global; */\n    grid-template-areas: 'input label';\n    grid-template-columns: -webkit-min-content 1fr;\n    grid-template-columns: min-content 1fr;\n}\n\n.radio-input-xBC {\n    grid-area: input;\n}\n\n.radio-input-xBC:checked {\n    background-color: var(--venia-global-color-black) !important;\n}\n\n.radio-input-xBC:active,\n.radio-input-xBC:focus {\n    box-shadow: none !important;\n}\n\n.radio-icon-fHI {\n    grid-area: input;\n    border: 1px solid var(--venia-global-color-black);\n}\n\n.radio-icon-fHI:focus-visible {\n    outline: none;\n}\n\n/* TODO @TW: cannot compose */\n.radio-icon-fHI svg {\n    /* composes: stroke-gray-600 from global; */\n    stroke: rgb(var(--venia-global-color-gray-600));\n}\n\n.radio-label-F27 {\n    grid-area: label;\n}\n\n/* TODO @TW: cannot compose */\n.radio-input-xBC:checked + .radio-icon-fHI svg {\n    /* composes: stroke-brand-base from global; */\n    stroke: rgb(var(--venia-brand-color-1-700));\n}\n\n.radio-input_shimmer-UA8 {\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "radio-root-Eu0 gap-3 flex items-center justify-items-center leading-normal m-0 relative text-colorDefault",
	"input": "radio-input-xBC appearance-none bg-clip-content bg-transparent border-4 border-solid border-transparent h-[1.5rem] m-0 rounded-full w-[1.5rem] z-foreground absolute active_outline-none active_shadow-radioActive checked_bg-brand-dark focus_outline-none focus_shadow-radioFocus",
	"icon": "radio-icon-fHI h-[1.5rem] w-[1.5rem] z-surface rounded-full",
	"label": "radio-label-F27 justify-self-start text-sm",
	"input_shimmer": "radio-input_shimmer-UA8 h-[1.5rem] m-0 rounded-full w-[1.5rem] z-foreground"
};
export default ___CSS_LOADER_EXPORT___;
