import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["after", "before", "classes", "field", "message", "placeholder", "mode", "counter"];
import React, { Fragment, useEffect, useState } from 'react';
import { node, shape, string } from 'prop-types';
import { Text as InformedText } from 'informed';
import useFieldState from '@magento/peregrine/lib/hooks/hook-wrappers/useInformedFieldStateWrapper';
import { FieldIcons, Message } from '@magento/venia-ui/lib/components/Field';
import defaultClasses from './textInput.module.css';
import { useFieldTouched, useMergeCssClasses } from '../../../../Utils/utils';
import CharacterCounter from '../../../../components/CharacterCounter';

var TextInput = function TextInput(props) {
  var _fieldState$value$len, _fieldState$value2;

  var after = props.after,
      before = props.before,
      propClasses = props.classes,
      field = props.field,
      message = props.message,
      placeholder = props.placeholder,
      mode = props.mode,
      _props$counter = props.counter,
      counter = _props$counter === void 0 ? false : _props$counter,
      rest = _objectWithoutProperties(props, _excluded);

  var _useFieldTouched = useFieldTouched(),
      _useFieldTouched2 = _slicedToArray(_useFieldTouched, 2),
      isTouched = _useFieldTouched2[0],
      setIsTouched = _useFieldTouched2[1];

  var fieldState = useFieldState(field);
  var classes = useMergeCssClasses(defaultClasses, propClasses);
  var inputClass = fieldState.error ? classes.input_error : classes.input;

  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      count = _useState2[0],
      setCount = _useState2[1]; // active animation on input field when we focus input on tab


  useEffect(function () {
    return (fieldState.value || (props === null || props === void 0 ? void 0 : props.type) === 'date') && setIsTouched(true);
  }, [fieldState.value, props]);

  var handleBlur = function handleBlur(rest) {
    !fieldState.value && setIsTouched(false);

    if (typeof (rest === null || rest === void 0 ? void 0 : rest.onBlur) === 'function') {
      rest.onBlur();
    }
  };

  useEffect(function () {
    var _fieldState$value;

    return counter && setCount((_fieldState$value = fieldState.value) === null || _fieldState$value === void 0 ? void 0 : _fieldState$value.length);
  }, [fieldState.value, counter]);
  var modeClasses = mode === 'light' ? classes.mode_light : classes.mode_dark;
  var placeholderClasses = isTouched ? classes.placeholder_active : classes.placeholder;
  return React.createElement(Fragment, null, React.createElement(FieldIcons, {
    after: after,
    before: before,
    classes: {
      root: modeClasses,
      input: classes.fieldInput ? classes.fieldInput : ''
    }
  }, React.createElement(InformedText, _extends({}, rest, {
    className: inputClass,
    field: field,
    onBlur: function onBlur() {
      return handleBlur(rest);
    }
  })), React.createElement("span", {
    className: placeholderClasses
  }, placeholder), counter && React.createElement(CharacterCounter, {
    count: (_fieldState$value$len = (_fieldState$value2 = fieldState.value) === null || _fieldState$value2 === void 0 ? void 0 : _fieldState$value2.length) !== null && _fieldState$value$len !== void 0 ? _fieldState$value$len : count
  })), React.createElement(Message, {
    fieldState: fieldState
  }, message));
};

export default TextInput;
TextInput.propTypes = {
  after: node,
  before: node,
  classes: shape({
    input: string,
    placeholder: string
  }),
  field: string.isRequired,
  message: node,
  mode: string
};
TextInput.defaultProps = {
  mode: 'light'
};