import React from 'react';
import defaultClasses from './characterCounter.module.css';
import { useStyle } from '@magento/venia-ui/lib/classify';

var CharacterCounter = function CharacterCounter(_ref) {
  var count = _ref.count,
      _ref$max = _ref.max,
      max = _ref$max === void 0 ? 50 : _ref$max,
      propClasses = _ref.classes;
  var classes = useStyle(defaultClasses, propClasses);
  return React.createElement("p", {
    className: classes.limit
  }, "".concat(count || 0, "/").concat(max));
};

export default CharacterCounter;