import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { gql } from '@apollo/client';
export var GET_STORE_CONFIG_DATA = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query GetStoreConfigDataForGalleryAC {\n        # eslint-disable-next-line @graphql-eslint/require-id-when-available\n        storeConfig {\n            store_code\n            product_url_suffix\n            magento_wishlist_general_is_enabled\n            enable_multiple_wishlists\n        }\n    }\n"])));
export var EXISTS_IN_FAVORITES = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query existInFavorites($product_id_list: [String]) {\n        existInFavorites(input: { product_id_list: $product_id_list}) {\n            exist\n            product_id\n        }\n    }\n"])));
export default {
  getStoreConfigQuery: GET_STORE_CONFIG_DATA,
  existInFavorites: EXISTS_IN_FAVORITES
};