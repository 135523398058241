import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

export var DELIMITER = ',';
export var getSearchFromState = function getSearchFromState(initialValue, filterKeys, filterState) {
  // preserve all existing params
  var nextParams = new URLSearchParams(initialValue); // iterate over available filters

  var _iterator = _createForOfIteratorHelper(filterKeys),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var key = _step.value;
      // remove any existing filter values
      nextParams["delete"](key);
    } // we reset page to if filter is selected on a page that is not #1

  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  nextParams.set('page', 1); // iterate over the latest filter values

  var _iterator2 = _createForOfIteratorHelper(filterState),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var _step2$value = _slicedToArray(_step2.value, 2),
          group = _step2$value[0],
          items = _step2$value[1];

      var _iterator3 = _createForOfIteratorHelper(items),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var item = _step3.value;

          var _ref = item || {},
              title = _ref.title,
              value = _ref.value; // append the new values


          nextParams.append("".concat(group, "[filter]"), "".concat(title).concat(DELIMITER).concat(value));
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
    } // prepend `?` to the final string

  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  return "?".concat(nextParams.toString());
};
export var getStateFromSearch = function getStateFromSearch(initialValue, filterKeys, filterItems) {
  // preserve all existing params
  var params = new URLSearchParams(initialValue);
  var uniqueKeys = new Set(params.keys());
  var nextState = new Map(); // iterate over existing param keys

  var _iterator4 = _createForOfIteratorHelper(uniqueKeys),
      _step4;

  try {
    for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
      var key = _step4.value;

      // if a key matches a known filter, add its items to the next state
      if (filterKeys.has(key) && key.endsWith('[filter]')) {
        // derive the group by slicing off `[filter]`
        var group = key.slice(0, -8);
        var items = new Set();
        var groupItemsByValue = new Map(); // cache items by value to avoid inefficient lookups

        var _iterator5 = _createForOfIteratorHelper(filterItems.get(group)),
            _step5;

        try {
          for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
            var item = _step5.value;
            groupItemsByValue.set(item.value, item);
          } // map item values to items

        } catch (err) {
          _iterator5.e(err);
        } finally {
          _iterator5.f();
        }

        var _iterator6 = _createForOfIteratorHelper(params.getAll(key)),
            _step6;

        try {
          for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
            var value = _step6.value;
            var existingFilter = groupItemsByValue.get(getValueFromFilterString(value));

            if (existingFilter) {
              items.add(existingFilter);
            } else {
              console.warn("Existing filter ".concat(value, " not found in possible filters"));
            }
          } // add items to the next state, keyed by group

        } catch (err) {
          _iterator6.e(err);
        } finally {
          _iterator6.f();
        }

        nextState.set(group, items);
      }
    }
  } catch (err) {
    _iterator4.e(err);
  } finally {
    _iterator4.f();
  }

  return nextState;
};
/**
 * Looks for filter values within a search string and returns a map like
 * {
 *   "category_id": ["Bottoms,28", "Pants & Shorts,19"]
 * }
 * filter[category_id]=Bottoms,28&filter[category_id]=Pants & Shorts,19
 * @param {String} initialValue a search string, as in from location.search
 */

export var getFiltersFromSearch = function getFiltersFromSearch(initialValue) {
  // preserve all existing params
  var params = new URLSearchParams(initialValue);
  var uniqueKeys = new Set(params.keys());
  var filters = new Map(); // iterate over existing param keys

  var _iterator7 = _createForOfIteratorHelper(uniqueKeys),
      _step7;

  try {
    for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
      var key = _step7.value;

      // if a key matches a known filter, add its items to the next state
      if (key.endsWith('[filter]')) {
        // derive the group by slicing off `[filter]`
        var group = key.slice(0, -8);
        var items = new Set(); // map item values to items

        var _iterator8 = _createForOfIteratorHelper(params.getAll(key)),
            _step8;

        try {
          for (_iterator8.s(); !(_step8 = _iterator8.n()).done;) {
            var value = _step8.value;
            items.add(value);
          } // add items to the next state, keyed by group

        } catch (err) {
          _iterator8.e(err);
        } finally {
          _iterator8.f();
        }

        filters.set(group, items);
      }
    }
  } catch (err) {
    _iterator7.e(err);
  } finally {
    _iterator7.f();
  }

  return filters;
};
/**
 * Sort filters array
 * @param {Array} initialArray an array containing filters data
 */

export var sortFiltersArray = function sortFiltersArray(initialArray) {
  return initialArray.sort(function (a, b) {
    // Place Category filter first
    if (a['attribute_code'] === 'category_id' || a['attribute_code'] === 'category_uid') {
      return -1;
    }

    if (b['attribute_code'] === 'category_id' || b['attribute_code'] === 'category_uid') {
      return 1;
    } // Sort alphabetically if same position


    if (a['position'] === b['position']) {
      if (a['label'] < b['label']) {
        return -1;
      }

      if (a['label'] > b['label']) {
        return 1;
      }
    } // Sort by position


    return a['position'] - b['position'];
  });
};
export var stripHtml = function stripHtml(html) {
  return html.replace(/(<([^>]+)>)/gi, '');
};
/** GetFilterInput helpers below. */

var getValueFromFilterString = function getValueFromFilterString(keyValueString) {
  return keyValueString.split(DELIMITER)[1];
};
/**
 * Converts a set of values to a range filter
 * @param {Set} values
 */


var toRangeFilter = function toRangeFilter(values) {
  // Range should always only be a single string. In the event we received
  // multiple, just return the first.
  var rangeString = Array.from(values)[0];

  var _rangeString$split = rangeString.split('_'),
      _rangeString$split2 = _slicedToArray(_rangeString$split, 2),
      from = _rangeString$split2[0],
      to = _rangeString$split2[1];

  var rangeFilter = {
    from: from,
    to: to
  };

  if (rangeFilter.from === '*') {
    delete rangeFilter.from;
  }

  if (rangeFilter.to === '*') {
    delete rangeFilter.to;
  }

  return rangeFilter;
};
/**
 * Converts a set of values into an equals filter
 * @param {Set} values
 */


var toEqualFilter = function toEqualFilter(values) {
  if (values.size > 1) {
    return {
      "in": Array.from(values).map(getValueFromFilterString)
    };
  } else {
    return {
      eq: getValueFromFilterString(Array.from(values)[0])
    };
  }
};
/**
 * Converts a set of values into a match filter
 * @param {Set} values
 */


var toMatchFilter = function toMatchFilter(values) {
  return {
    match: getValueFromFilterString(Array.from(values)[0])
  };
};

var CONVERSION_FUNCTIONS = {
  FilterEqualTypeInput: toEqualFilter,
  FilterMatchTypeInput: toMatchFilter,
  FilterRangeTypeInput: toRangeFilter
};
/**
 * Returns a filter input object matching the type provided.
 *
 * @param values - A set of values to construct the result object from.
 * @param type - Any of the possible types of filter input types
 */

export var getFilterInput = function getFilterInput(values, type) {
  var conversionFunction = CONVERSION_FUNCTIONS[type];

  if (!conversionFunction) {
    throw TypeError("Unknown type ".concat(type));
  }

  return conversionFunction(values);
}; // for (const item of filterItems.get(group)) {
//                 groupItemsByValue.push({ value: item.value, item: item });
//             }
//             // map item values to items
//             for (const value of params.getAll(key)) {
//                 const valueFromString = getValueFromFilterString(value);
//                 const minValue = groupItemsByValue[0].value.split('_')[0]
//                 const maxValue = groupItemsByValue[0].value.split('_')[0]
//                 // const existingFilter = groupItemsByValue.get(getValueFromFilterString(value));
//                 // if (existingFilter) {
//                 //     items.add(existingFilter);
//                 // } else {
//                 //     console.warn(`Existing filter ${value} not found in possible filters`);
//                 // }
//             }