import React from 'react';
import { shape, string } from 'prop-types';
import Shimmer from '@magento/venia-ui/lib/components/Shimmer';
import classes from './productSort.shimmer.module.css';

var ProductSortShimmer = function ProductSortShimmer(props) {
  return React.createElement("div", {
    className: classes.root,
    "aria-live": "polite",
    "aria-busy": "true"
  }, React.createElement(Shimmer, {
    classes: {
      root_button: classes.sortButtonShimmer
    },
    type: "button"
  }));
};

ProductSortShimmer.propTypes = {
  classes: shape({
    root: string,
    sortButtonShimmer: string
  })
};
export default ProductSortShimmer;