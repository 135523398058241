// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subMenu-submenu-J8T {\n    display: none;\n    position: absolute;\n    padding: 21px 34px 20px;\n    top: 100%;\n    left: -50%;\n    box-shadow: 0 0 5px rgb(173 181 189 / 11%), 0 10px 50px rgb(108 117 125 / 14%);\n}\n\n.subMenu-submenuProduct-FQw {\n    left: -250%;\n}\n\n/* .submenu::before {\n  box-shadow: inset 0 2px rgb(var(--venia-global-color-gray-100));\n  content: '';\n  height: 10px;\n  left: 0;\n  pointer-events: none;\n  position: absolute;\n  right: 0;\n  top: 0;\n} */\n\n.subMenu-submenu_active-RTE {\n    display: flex;\n}\n\n.subMenu-submenuItemsWrapper-lXL {\n    display: flex;\n    flex-direction: column;\n    margin-right: 30px;\n    min-width: 200px;\n}\n\n.subMenu-imagePlaceholder-R3y {\n    margin-top: 20px;\n    height: 315px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submenu": "subMenu-submenu-J8T bg-white text-bronze",
	"submenuProduct": "subMenu-submenuProduct-FQw",
	"submenu_active": "subMenu-submenu_active-RTE subMenu-submenu-J8T bg-white text-bronze",
	"submenuItemsWrapper": "subMenu-submenuItemsWrapper-lXL",
	"imagePlaceholder": "subMenu-imagePlaceholder-R3y"
};
export default ___CSS_LOADER_EXPORT___;
