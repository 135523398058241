import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useRef, useState, useCallback, useEffect } from 'react';
import defaultClasses from './slider.module.css';
import { useMergeCssClasses } from '../../../Utils/utils';
import { PrevIcon, NextIcon } from '..';

var Slider = function Slider(_ref) {
  var propClasses = _ref.classes,
      children = _ref.children,
      _ref$elToScroll = _ref.elToScroll,
      elToScroll = _ref$elToScroll === void 0 ? 2 : _ref$elToScroll,
      _ref$hideArrows = _ref.hideArrows,
      hideArrows = _ref$hideArrows === void 0 ? false : _ref$hideArrows;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isDown = _useState2[0],
      setIsDown = _useState2[1];

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      startXPosition = _useState4[0],
      setStartXPosition = _useState4[1];

  var _useState5 = useState(null),
      _useState6 = _slicedToArray(_useState5, 2),
      scrollLeft = _useState6[0],
      setScrollLeft = _useState6[1];

  var classes = useMergeCssClasses(defaultClasses, propClasses);
  var carouselRef = useRef();
  var prevRef = useRef();
  var nextRef = useRef();
  var handleClickButtons = useCallback(function (type) {
    var itemWidth = carouselRef.current.childNodes[0].clientWidth;
    type === 'prev' ? carouselRef.current.scrollLeft -= elToScroll * itemWidth : carouselRef.current.scrollLeft += elToScroll * itemWidth;
  });
  useEffect(function () {
    handleScroll();
  }, []);
  var hideButton = useCallback(function (el) {
    return el.current.style.display = 'none';
  });
  var showButton = useCallback(function (el) {
    return el.current.style.display = 'block';
  });
  var handleScroll = useCallback(function () {
    carouselRef.current.offsetWidth + carouselRef.current.scrollLeft >= carouselRef.current.scrollWidth - 5 || hideArrows ? hideButton(nextRef) : showButton(nextRef);
    carouselRef.current.scrollLeft < 5 || hideArrows ? hideButton(prevRef) : showButton(prevRef);
  });
  return React.createElement("div", {
    className: classes.sliderWrapper
  }, React.createElement("button", {
    className: classes.prevBtn,
    ref: prevRef,
    onClick: function onClick() {
      return handleClickButtons('prev');
    },
    "aria-label": "Previous slider image"
  }, React.createElement("span", {
    className: "sr-only"
  }, "Previous slider image"), React.createElement(PrevIcon, null)), React.createElement("div", {
    className: classes.carousel,
    ref: carouselRef,
    onScroll: handleScroll,
    onMouseDown: function onMouseDown(e) {
      setIsDown(true);
      setStartXPosition(e.pageX - carouselRef.current.offsetLeft);
      setScrollLeft(carouselRef.current.scrollLeft);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsDown(false);
    },
    onMouseUp: function onMouseUp() {
      return setIsDown(false);
    },
    onMouseMove: function onMouseMove(e) {
      if (!isDown) return;
      e.preventDefault();
      var x = e.pageX - carouselRef.current.offsetLeft;
      var walk = (x - startXPosition) * 3; //scroll-fast

      carouselRef.current.scrollLeft = scrollLeft - walk;
    }
  }, children), React.createElement("button", {
    className: classes.nextBtn,
    ref: nextRef,
    onClick: function onClick() {
      return handleClickButtons('next');
    },
    "aria-label": "Next slider image"
  }, React.createElement("span", {
    className: "sr-only"
  }, "Next slider image"), React.createElement(NextIcon, null)));
};

export default Slider;