import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import { gql } from '@apollo/client';
export var ADD_ITEM_TO_DOWNLOAD = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    mutation addItemToDownload($input: addByCustomerIdInput!) {\n        addItemToDownload(input: $input) {\n            add_item_to_collection {\n                customer_id\n                id\n                product_id\n                selection_id\n                store_id\n            }\n        }\n    }\n"])));
export var ADD_MULTIPLE_ITEMS_TO_DOWNLOAD = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    mutation addMultipleItemsToDownload($input: addMultipleItemsToDownloadOutput!) {\n        addMultipleItemsToDownload(input: $input) {\n            add_multiple_items_to_download {\n                customer_id\n                id\n                product_id\n                selection_id\n                store_id\n            }\n        }\n    }\n"])));
export var GENERATE_DOWNLOAD_URL = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    mutation generateDownloadUrlM($input: [generateDownloadUrlByCustomerIdImagesSamples!]!) {\n        generateDownloadUrlM(input: $input) {\n            generated_download_url_output {\n                download_url\n                filename\n                status\n            }\n        }\n    }\n"])));
export var REMOVE_ITEM_FROM_DOWNLOAD = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    mutation removeItemFromDownload($input: addByCustomerIdInput!) {\n        removeItemFromDownload(input: $input) {\n            remove_item_from_collection {\n                customer_id\n                id\n                product_id\n                selection_id\n                store_id\n            }\n        }\n    }\n"])));
export var REMOVE_MULTIPLE_ITEMS_FROM_DOWNLOAD = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    mutation removeMultipleItemsFromDownload($input: [addByCustomerIdInput!]!) {\n        removeMultipleItemsFromDownload(input: $input) {\n            remove_multiple_items_from_download {\n                customer_id\n                id\n                product_id\n                selection_id\n                store_id\n            }\n        }\n    }\n"])));
export var DOWNLOAD_IMAGES = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    query downloadimages($pageSize: Int, $currentPage: Int, $filter: ImageDownloadsFilterInput) {\n        downloadimages(pageSize: $pageSize, currentPage: $currentPage, filter: $filter) {\n            customer_id\n            id\n            product {\n                url_key\n                uid\n                id\n                name\n                sku\n                small_image {\n                    url\n                    label\n                }\n                image {\n                    url\n                    label\n                }\n                media_gallery {\n                    url\n                    label\n                }\n\n                price_range {\n                    maximum_price {\n                        regular_price {\n                            currency\n                            value\n                        }\n                        final_price {\n                            value\n                            currency\n                        }\n                        regular_price {\n                            value\n                            currency\n                        }\n                    }\n                }\n\n                thumbnail {\n                    url\n                    label\n                }\n            }\n            product_id\n            selection_id\n            store_id\n        }\n    }\n"])));