import _extends from "@babel/runtime/helpers/extends";
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { bool, func, shape, string, object, node } from 'prop-types';
import { Form } from 'informed';
import { useScrollLock } from '@magento/peregrine';
import { Portal } from '@magento/venia-ui/lib/components/Portal';
import Trigger from '@magento/venia-ui/lib/components/Trigger';
import { useMergeCssClasses } from '../../Utils/utils';
import { Button, Image, CloseIcon } from '..';
import defaultClasses from './dialog.module.css';
/**
 * The Dialog component shows its children content in a dialog,
 * encoding the look-and-feel and behavior in one place for consistency across the app.
 *
 * @typedef Dialog
 * @kind functional component
 *
 * @param {Object}  props
 * @param {Object}  props.classes - A set of class overrides to apply to elements.
 * @param {String}  props.cancelText - The text to display on the Dialog cancel button.
 * @param {String}  props.cancelTranslationId - The id to assign for the cancel button translation.
 * @param {String}  props.confirmText - The text to display on the Dialog confirm button.
 * @param {String}  props.confirmTranslationId - The id to assign for the confirm button translation.
 * @param {Object}  props.formProps - Props to apply to the internal form. @see https://joepuzzo.github.io/informed/?path=/story/form--props.
 * @param {Boolean} props.isModal - Determines behavior of clicking on the mask. False cancels Dialog.
 * @param {Boolean} props.isOpen - Whether the Dialog is currently showing.
 * @param {Func}    props.onCancel - A function to call when the user cancels the Dialog.
 * @param {Func}    props.onConfirm - A function to call when the user confirms the Dialog.
 * @param {Boolean} props.shouldDisableAllButtons - A toggle for whether the buttons should be disabled.
 * @param {Boolean} props.shouldDisableConfirmButton - A toggle for whether the confirm button should be disabled.
 *                                                     The final value is OR'ed with shouldDisableAllButtons.
 * @param {Boolean} props.shouldShowButtons - A toggle for whether the cancel and confirm buttons are visible.
 * @param {Boolean} props.shouldUnmountOnHide - A boolean to unmount child components on hide
 * @param {String}  props.title - The title of the Dialog.
 */

var Dialog = function Dialog(props) {
  var cancelText = props.cancelText,
      cancelTranslationId = props.cancelTranslationId,
      children = props.children,
      confirmText = props.confirmText,
      confirmTranslationId = props.confirmTranslationId,
      formProps = props.formProps,
      isModal = props.isModal,
      isOpen = props.isOpen,
      onCancel = props.onCancel,
      onConfirm = props.onConfirm,
      shouldDisableAllButtons = props.shouldDisableAllButtons,
      shouldDisableConfirmButton = props.shouldDisableConfirmButton,
      _props$shouldShowButt = props.shouldShowButtons,
      shouldShowButtons = _props$shouldShowButt === void 0 ? true : _props$shouldShowButt,
      shouldUnmountOnHide = props.shouldUnmountOnHide,
      title = props.title,
      _props$editProject = props.editProject,
      editProject = _props$editProject === void 0 ? false : _props$editProject,
      _props$deleteProject = props.deleteProject,
      deleteProject = _props$deleteProject === void 0 ? function () {} : _props$deleteProject; // Prevent the page from scrolling in the background
  // when the Dialog is open.

  useScrollLock(isOpen);
  var classes = useMergeCssClasses(defaultClasses, props.classes);
  var rootClass = isOpen ? classes.root_open : classes.root;
  var isMaskDisabled = shouldDisableAllButtons || isModal;
  var confirmButtonDisabled = shouldDisableAllButtons || shouldDisableConfirmButton;
  var cancelButtonClasses = {
    root_lowPriority: classes.cancelButton
  };
  var confirmButtonClasses = {
    root_highPriority: classes.confirmButton
  };
  var maybeCloseXButton = React.createElement(Trigger, {
    id: "closeButton",
    disabled: shouldDisableAllButtons,
    action: onCancel,
    type: "reset",
    "aria-label": "Close modal"
  }, React.createElement("span", {
    className: "sr-only"
  }, "Close modal"), React.createElement(CloseIcon, null));
  var maybeButtons = shouldShowButtons ? React.createElement("div", {
    className: "".concat(classes.buttons, " ").concat(editProject ? 'grid-flow-row' : 'grid-flow-col')
  }, !editProject ? React.createElement(Button, {
    classes: confirmButtonClasses,
    disabled: shouldDisableAllButtons,
    onClick: onCancel,
    priority: 'normal',
    "aria-label": "Cancel"
  }, React.createElement(FormattedMessage, {
    id: 'cancel',
    defaultMessage: 'Cancel'
  })) : // <Button
  //     data-cy="Dialog-cancelButton"
  //     classes={cancelButtonClasses}
  //     disabled={shouldDisableAllButtons}
  //     onClick={onCancel}
  //     priority="low"
  //     type="reset"
  //     aria-label="Cancel"
  // >
  //     <FormattedMessage id={cancelTranslationId} defaultMessage={cancelText} />
  // </Button>
  false, editProject ? // <p className="underline  mt-4 mx-auto mb-8 cursor-pointer" onClick={deleteProject}>
  //     Delete project
  // </p>
  React.createElement(Button, {
    classes: confirmButtonClasses,
    disabled: confirmButtonDisabled,
    onClick: deleteProject,
    priority: 'normal',
    "aria-label": "Delete project"
  }, React.createElement(FormattedMessage, {
    id: 'deleteProject',
    defaultMessage: 'Delete Project'
  })) : null, React.createElement(Button, {
    classes: confirmButtonClasses,
    disabled: confirmButtonDisabled,
    priority: 'low',
    type: "submit",
    "aria-label": confirmText
  }, React.createElement(FormattedMessage, {
    id: confirmTranslationId,
    defaultMessage: confirmText
  }))) : null;
  var maybeForm = isOpen || !shouldUnmountOnHide ? React.createElement(Form, _extends({
    className: classes.form
  }, formProps, {
    onSubmit: onConfirm
  }), React.createElement("button", {
    className: classes.mask,
    disabled: isMaskDisabled,
    onClick: onCancel,
    type: "reset",
    "aria-label": "mask"
  }), React.createElement("div", {
    className: classes.dialog
  }, React.createElement("div", {
    className: classes.header
  }, React.createElement("span", {
    className: "text-22 mr-[8px]"
  }, title), maybeCloseXButton), React.createElement("div", {
    className: classes.body
  }, children, maybeButtons))) : null;
  return React.createElement(Portal, null, React.createElement("aside", {
    className: rootClass
  }, maybeForm));
};

export default Dialog;
Dialog.propTypes = {
  cancelText: string,
  cancelTranslationId: string,
  classes: shape({
    body: string,
    cancelButton: string,
    confirmButton: string,
    container: string,
    contents: string,
    header: string,
    headerText: string,
    headerButton: string,
    mask: string,
    root: string,
    root_open: string
  }),
  confirmText: string,
  confirmTranslationId: string,
  formProps: object,
  isModal: bool,
  isOpen: bool,
  onCancel: func,
  onConfirm: func,
  shouldDisableAllButtons: bool,
  shouldDisableSubmitButton: bool,
  shouldUnmountOnHide: bool,
  title: node
};
Dialog.defaultProps = {
  cancelText: 'Cancel',
  cancelTranslationId: 'global.cancelButton',
  confirmText: 'Confirm',
  confirmTranslationId: 'global.confirmButton',
  isModal: false,
  shouldUnmountOnHide: true
};