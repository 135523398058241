import React from 'react';

var FilledFavIcon = function FilledFavIcon() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    width: "26",
    height: "21",
    viewBox: "0 0 26 21"
  }, React.createElement("defs", null, React.createElement("path", {
    id: "path-1",
    d: "M0 0L26 0 26 21 0 21z"
  })), React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, React.createElement("g", null, React.createElement("mask", {
    id: "mask-2",
    fill: "#fff"
  }, React.createElement("use", {
    xlinkHref: "#path-1"
  })), React.createElement("path", {
    fill: "#000",
    d: "M26 6.56c0 1.81-.77 3.51-2.18 4.79L13.28 20.9a.42.42 0 01-.28.1c-.09 0-.2-.03-.27-.1L2.19 11.35C.78 10.07 0 8.37 0 6.56s.78-3.51 2.19-4.79C3.45.63 5.12 0 6.9 0c1.78 0 3.46.63 4.72 1.77L13 3.03l1.39-1.26C15.65.63 17.32 0 19.1 0c1.78 0 3.46.63 4.72 1.77C25.23 3.05 26 4.75 26 6.56",
    mask: "url(#mask-2)"
  }))));
};

export default FilledFavIcon;