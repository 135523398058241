// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".favouritesItem-image-syT {\n    height: 191px;\n    width: 191px;\n}\n\n@media (max-width: 768px) {\n    .favouritesItem-image-syT {\n        height: 80px;\n        width: 80px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "favouritesItem-image-syT"
};
export default ___CSS_LOADER_EXPORT___;
