import React from 'react';

var FavouriteIcon = function FavouriteIcon() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    width: "40",
    height: "32",
    viewBox: "0 0 40 32"
  }, React.createElement("defs", null, React.createElement("path", {
    id: "2xdxj2ziha",
    d: "M0 0h40v32H0z"
  })), React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("mask", {
    id: "u2ivke8tjb",
    fill: "#fff"
  }, React.createElement("use", {
    xlinkHref: "#2xdxj2ziha"
  })), React.createElement("path", {
    fill: "#000",
    d: "M35.784 16.479L20 30.644 4.217 16.48c-1.93-1.733-2.995-4.034-2.995-6.483 0-2.448 1.064-4.753 2.995-6.487 1.709-1.531 3.981-2.375 6.399-2.375 2.419 0 4.691.844 6.398 2.375l2.56 2.301a.648.648 0 00.85 0l2.56-2.3c1.708-1.532 3.982-2.376 6.4-2.376 2.419 0 4.691.844 6.4 2.375 1.93 1.734 2.993 4.039 2.993 6.487 0 2.45-1.062 4.75-2.993 6.483m.852-13.783C34.697.957 32.122 0 29.384 0c-2.738 0-5.312.957-7.252 2.696l-2.133 1.917-2.133-1.917C15.929.957 13.354 0 10.616 0c-2.739 0-5.312.957-7.25 2.696-2.172 1.949-3.367 4.542-3.367 7.3.001 2.758 1.197 5.349 3.366 7.298l16.21 14.546c.118.107.273.16.424.16a.634.634 0 00.426-.16l16.21-14.546c2.17-1.95 3.364-4.54 3.365-7.298 0-2.758-1.193-5.351-3.364-7.3",
    mask: "url(#u2ivke8tjb)"
  })));
};

export default FavouriteIcon;