import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useCallback } from 'react';
import { arrayOf, oneOf, string, bool } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useStyle } from '@magento/venia-ui/lib/classify';
import Button from '@magento/venia-ui/lib/components/Button/button';
import resolveLinkProps from '@magento/peregrine/lib/util/resolveLinkProps';
import defaultClasses from '@magento/pagebuilder/lib/ContentTypes/ButtonItem/buttonItem.module.css';
import { useUserContext } from '@magento/peregrine/lib/context/user';
/**
 * Page Builder ButtonItem component.
 *
 * This component is part of the Page Builder / PWA integration. It can be consumed without Page Builder.
 *
 * @typedef ButtonItem
 * @kind functional component
 *
 * @param {props} props React component props
 *
 * @returns {React.Element} A React component that displays a button.
 */

var ButtonItem = function ButtonItem(props) {
  var classes = useStyle(defaultClasses, props.classes);

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      isSignedIn = _useUserContext2[0].isSignedIn;

  var buttonType = props.buttonType,
      link = props.link,
      _props$openInNewTab = props.openInNewTab,
      openInNewTab = _props$openInNewTab === void 0 ? false : _props$openInNewTab,
      text = props.text,
      textAlign = props.textAlign,
      border = props.border,
      borderColor = props.borderColor,
      borderWidth = props.borderWidth,
      borderRadius = props.borderRadius,
      marginTop = props.marginTop,
      marginRight = props.marginRight,
      marginBottom = props.marginBottom,
      marginLeft = props.marginLeft,
      paddingTop = props.paddingTop,
      paddingRight = props.paddingRight,
      paddingBottom = props.paddingBottom,
      paddingLeft = props.paddingLeft,
      _props$cssClasses = props.cssClasses,
      cssClasses = _props$cssClasses === void 0 ? [] : _props$cssClasses;
  var dynamicInnerStyles = {
    textAlign: textAlign,
    border: border,
    borderColor: borderColor,
    borderWidth: borderWidth,
    borderRadius: borderRadius,
    marginTop: marginTop,
    marginRight: marginRight,
    marginBottom: marginBottom,
    marginLeft: marginLeft,
    paddingTop: paddingTop,
    paddingRight: paddingRight,
    paddingBottom: paddingBottom,
    paddingLeft: paddingLeft
  };
  var history = useHistory();
  var linkProps = {};
  var url = '';

  if (typeof link === 'string') {
    linkProps = resolveLinkProps(link);
    url = (linkProps.to ? linkProps.to : linkProps.href).trim();
  }

  var typeToPriorityMapping = {
    primary: 'high',
    secondary: 'normal',
    link: 'low'
  };
  var handleClick = useCallback(function () {
    if (!url) {
      return;
    }

    if (openInNewTab && globalThis.open) {
      globalThis.open(url, '_blank');
    } else if (linkProps.to) {
      history.push(url);
    } else {
      globalThis.location.assign(url);
    }
  }, [openInNewTab, url, linkProps.to]); // eslint-disable-line react-hooks/exhaustive-deps

  var justifyMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  if (textAlign) {
    dynamicInnerStyles.justifyContent = justifyMap[textAlign] || 'center';
    dynamicInnerStyles.textAlign = textAlign;
  }

  if (cssClasses && cssClasses !== null && cssClasses !== void 0 && cssClasses.includes('login-btn') && isSignedIn) {
    return React.createElement(React.Fragment, null);
  }

  var buttonProps = {
    onClick: handleClick,
    priority: typeToPriorityMapping[buttonType],
    style: dynamicInnerStyles,
    type: 'button'
  }; // Custom style link type until PWA-937 adds link styled buttons

  if (buttonType === 'link') {
    buttonProps.className = classes.linkButton;
  }

  return React.createElement("div", {
    className: cssClasses.length ? cssClasses.join(' ') : undefined
  }, React.createElement(Button, buttonProps, text));
};
/**
 * Props for {@link ButtonItem}
 *
 * @typedef props
 *
 * @property {String} buttonType Sets button type option
 * @property {String} link Url to the page opened when button clicked
 * @property {String} linkType Type of the linked page
 * @property {String} openInNewTab Toggles the option to open linked page in the new tab
 * @property {String} text Button text
 * @property {String} textAlign Button text align
 * @property {String} border CSS border property
 * @property {String} borderColor CSS border color property
 * @property {String} borderWidth CSS border width property
 * @property {String} borderRadius CSS border radius property
 * @property {String} marginTop CSS margin top property
 * @property {String} marginRight CSS margin right property
 * @property {String} marginBottom CSS margin bottom property
 * @property {String} marginLeft CSS margin left property
 * @property {String} paddingTop CSS padding top property
 * @property {String} paddingRight CSS padding right property
 * @property {String} paddingBottom CSS padding bottom property
 * @property {String} paddingLeft CSS padding left property
 * @property {Array} cssClasses List of CSS classes to be applied to the component
 */


ButtonItem.propTypes = {
  buttonType: oneOf(['primary', 'secondary', 'link']),
  link: string,
  linkType: oneOf(['default', 'category', 'product', 'page']),
  openInNewTab: bool,
  text: string,
  textAlign: string,
  border: string,
  borderColor: string,
  borderWidth: string,
  borderRadius: string,
  marginTop: string,
  marginRight: string,
  marginBottom: string,
  marginLeft: string,
  paddingTop: string,
  paddingRight: string,
  paddingBottom: string,
  paddingLeft: string,
  cssClasses: arrayOf(string)
};
export default ButtonItem;