// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".resourcesTabs-resourcesTab-15n {\n    position: absolute;\n    padding: 21px 34px 20px;\n    top: 28px;\n    left: calc(100% - 10rem);\n    box-shadow: 0 0 5px rgb(173 181 189 / 11%), 0 10px 50px rgb(108 117 125 / 14%);\n    width: 250px;\n    background-color: white;\n}\n\n.resourcesTabs-resourcesTab-15n a {\n    display: block;\n}\n\n.resourcesTabs-resourcesTab-15n a:hover:before {\n    position: absolute;\n    top: 0px;\n    height: 100%;\n    content: '';\n    left: -34px;\n    width: 10px;\n    background-color: var(--venia-global-color-worldly-gray);\n}\n\n.resourcesTabs-resourcesTab-15n a:hover {\n    font-weight: bold;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resourcesTab": "resourcesTabs-resourcesTab-15n"
};
export default ___CSS_LOADER_EXPORT___;
