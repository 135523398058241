import React from 'react';
import classes from './loadingIcon.module.css';

var LoadingIcon = function LoadingIcon() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    x: "0",
    y: "0",
    enableBackground: "new -3 -3 236.1 244.2",
    viewBox: "-3 -3 236.1 244.2",
    className: classes.mainIcon
  }, React.createElement("g", null, React.createElement("g", null, React.createElement("path", {
    d: "M153 89.9c-.2 0-.6.2-.8.2-1.8.2-3.8.2-5.7.2-5.3 0-10.5.2-15.8 0-1.8 0-3.8-.2-5.7-.2-.6 0-1.6 0-1.8.2-.4.4 0 1 .2 1.2s1.2.2 1.4.2c1.6 0 3 .2 3.6 1.2.8 1.2.8 3.8.8 5.8.2 3.8 0 8.2 0 12.3v.8h-.6c-1 0-1.8 0-2.8-.2-.4 0-.8 0-1-.2-.2 0-.2-.4-.2-.6-.2-.6-.2-1.8-.6-2-.2-.2-1.2 0-1.6 0-1.8 0-3.6-.2-5.1-.2H96.1V97.7c0-2 0-3.6.6-4.8.6-.8 1.6-1.2 2.8-1.2.4 0 .8 0 1.2-.2s.4-.4.4-.6c0-.6-1.2-.6-2-.6h-2.4c-1.6 0-3 .2-4.7.2-2.4 0-4.7-.2-7.1-.2-.6 0-1.8-.2-2 .4-.2.8.6.8 1.4.8 1 0 2 .2 2.6.4 2.2.6 2.2 3 2.4 5.6v36c0 2.4-.2 5.2-.6 7.4-.2 1.2-.4 2.2-1.2 2.8-.6.4-1.6.4-2.4.6-.2 0-.6 0-.8.2s-.4.8 0 1 1.6.2 2.4.2c2.6 0 4.7-.2 7.1-.2 2.4.2 4.7.2 7.3.2.8 0 2 0 2-.6.2-.8-.6-.8-1.2-.8-1 0-2.4-.2-3.2-.4-1.4-.2-2-1-2.2-2.6-.2-1.4-.4-2.8-.4-4.4-.2-7.2-.2-14.9-.2-22.3v-1.2s.4.2.6.2c1.4.4 3 .8 4.2 1.2 3.2 1 6.1 2.2 7.7 4.4.6.8 1.2 1.8 1.2 3.4v1.4c0 .2-.4.2-.4.2-.8.4-1.2 1.2-1.6 2 0 .2-.4 1-.4 1h16.4v-.2c0-.4 0-.6-.2-1 0-.2 0-1-.2-1.2s-.6-.4-1-.6-.8-.2-1.2-.4c-1-.6-1-2.4-.2-3.4 1.4-1.6 4.7-1.4 7.5-1.8.2 0 .6-.2.8-.2v15.3c0 2.4-.2 5.2-.4 7.2-.2 1.2-.6 2-1.4 2.4-.4.2-1 .4-1.4.4s-1.4 0-1.6.2c-.2.2-.2.4-.2.8.2.4 1.4.4 2.2.4 2.6 0 4.9-.2 7.3-.2 2.2 0 4.9.2 7.5.2.8 0 2 .2 2-.6s-1.2-.6-2-.6c-2.2-.2-3.8-.4-4.4-2-.4-1-.4-2.4-.4-3.8-.4-6.4-.2-13.5-.2-20.3v-1s.4-.2.6-.2c3-1.2 5.7-2.4 7.9-4.2.2-.2 1-.6.8-1-.2-.2-.8-.2-1-.2-2.6 0-4.7 0-7.1.2-.2 0-1.2.2-1.2 0v-1.2c0-4.4-.2-8.8 0-13.1 0-1 0-2.2.4-3.2 0-.2.2-.8.4-.8h.6c2.6 0 4.9.2 7.5.2h2.8c1.6.2 3 .8 3.8 1.8.6.6 1 2 1 3.2 0 .6 0 1 .4 1 .8.2.8-1.2.8-2 .2-1.8.2-3.6.4-5.2.2-.6.8-2.6-.4-2.4-.5-.2-.7 0-.8.2",
    className: classes.st0
  }), React.createElement("path", {
    d: "M153.8 89.5c1.2-.2.4 1.8.4 2.4-.2 1.6-.4 3.4-.4 5.2 0 .8 0 2.2-.8 2-.4 0-.4-.4-.4-1 0-1.2-.4-2.6-1-3.2-.8-1-2.2-1.6-3.8-1.8H145c-2.6 0-4.9 0-7.5-.2h-.5c-.2 0-.2.6-.4.8-.2 1-.2 2.2-.4 3.2-.2 4.2 0 8.7 0 13.1v1.2c0 .2.8 0 1.2 0 2.4 0 4.6-.2 7.1-.2.4 0 1 0 1 .2.2.4-.6.8-.8 1-2.4 1.6-4.9 3-7.9 4.2-.2 0-.6.2-.6.2v1c0 6.8-.2 13.9.2 20.3 0 1.4.2 2.8.4 3.8.6 1.8 2.2 1.8 4.4 2 .8 0 2-.2 2 .6s-1.2.6-2 .6c-2.4 0-5.1-.2-7.5-.2-2.6 0-4.7.2-7.3.2-.8 0-2 .2-2.2-.4-.2-.2 0-.6.2-.8s1.2-.2 1.6-.2c.6 0 1-.2 1.4-.4.8-.4 1.2-1.4 1.4-2.4.4-2 .4-4.8.4-7.2v-15.3s-.6 0-.8.2c-2.8.4-5.9.2-7.5 1.8-.8 1-.8 2.8.2 3.4.4.2.8.4 1.2.4.4.2 1 .4 1 .6s.2.8.2 1.2 0 .6.2 1v.2h-16.4c-.2 0 .2-1 .4-1 .4-1 .8-1.6 1.6-2l.4-.2c.2-.2.2-1 0-1.4 0-1.4-.6-2.6-1.2-3.4-1.8-2.2-4.7-3.4-7.7-4.4-1.2-.4-2.8-.8-4.2-1.2-.2 0-.6-.2-.6-.2v1.2c0 7.4-.2 15.1.2 22.3 0 1.6.2 3 .4 4.4s.8 2.2 2.2 2.6c.8.2 2.2.4 3.2.4.6 0 1.2 0 1.2.8 0 .6-1.2.6-2 .6-2.6 0-4.9-.2-7.3-.2s-4.7.2-7.1.2c-.6 0-1.8.2-2.4-.2-.4-.2-.2-.8 0-1s.6 0 .8-.2c.8 0 1.8-.2 2.4-.6.8-.6 1-1.6 1.2-2.8.4-2.2.6-5 .6-7.4v-36c0-2.8 0-5-2.4-5.6-.8-.2-1.6-.2-2.6-.4-.6 0-1.4 0-1.4-.8 0-.6 1.2-.4 2-.4 2.4 0 4.7 0 7.1.2 1.8 0 3.2-.2 4.7-.2h2.4c.8 0 2-.2 2 .6 0 .2 0 .6-.4.6-.4.2-1 0-1.2.2-1.2.2-2.2.4-2.8 1.2-.8 1.2-.6 2.8-.6 4.8-.2 3.2 0 6.8 0 10.3v.6h21.2c1.8 0 3.4 0 5.1.2h1.6c.4.2.4 1.4.6 2 0 .2.2.4.2.6.2.2.6.2 1 .2.8 0 1.8.2 2.8.2h.6V98.5c0-2 0-4.6-.8-5.8-.6-1-2.2-1.2-3.6-1.2-.4 0-1.2 0-1.4-.2s-.4-.8-.2-1.2c.2-.2 1.2-.2 1.8-.2 2 0 4 0 5.7.2 5.3.2 10.3 0 15.8 0 2 0 3.9 0 5.7-.2.2 0 .6 0 .8-.2.1 0 .3-.2.7-.2",
    className: classes.st0
  }))), React.createElement("path", {
    d: "M64.4 66.5L164.6 66.5 164.6 64.5 64.4 64.5z",
    className: classes.st0
  }), React.createElement("path", {
    d: "M64.4 168.5L164.6 168.5 164.6 166.5 64.4 166.5z",
    className: classes.st0
  }), React.createElement("ellipse", {
    cx: "114.8",
    cy: "116.9",
    className: classes.st1,
    rx: "111.7",
    ry: "113.7"
  }), React.createElement("path", {
    d: "M114.7 233.1C51.4 233.1 0 180.8 0 116.5S51.4 0 114.7 0C142.9 0 170 10.5 191 29.5l-4 4.4c-19.9-18-45.6-28-72.3-28C54.7 6 6 55.5 6 116.5s48.8 110.7 108.7 110.7v5.9z",
    className: classes.st2
  }, React.createElement("animateTransform", {
    attributeName: "transform",
    dur: "4s",
    from: "0 114.8 116.9",
    repeatCount: "indefinite",
    to: "360 114.8 116.9",
    type: "rotate"
  })));
};

export default LoadingIcon;
{
  /* <style type="text/css">
   
  </style> */
}