import { useCallback, useEffect, useRef } from 'react';
import { useFormApi } from 'informed';
import useFieldState from '@magento/peregrine/lib/hooks/hook-wrappers/useInformedFieldStateWrapper';
import { getSearchParam } from '@magento/peregrine/lib/hooks/useSearchParam';
/**
 * Returns props necessary to render a SearchField component.
 */

export var useSearchField = function useSearchField(props) {
  var isSearchOpen = props.isSearchOpen;
  var inputRef = useRef();

  var _useFieldState = useFieldState('search_query'),
      value = _useFieldState.value;

  var formApi = useFormApi();
  var resetForm = useCallback(function () {
    formApi.reset();
  }, [formApi]); // When the search field is opened focus on the input.

  useEffect(function () {
    if (isSearchOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchOpen]); // Pre-populate the search field with the search term from the URL.
  // We purposefully only ever run this effect on initial mount.

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(function () {
    var urlTerm = getSearchParam('query', location);

    if (!formApi || !urlTerm) {
      return;
    }

    formApi.setValue('search_query', urlTerm);
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return {
    inputRef: inputRef,
    resetForm: resetForm,
    value: value,
    formApi: formApi
  };
};