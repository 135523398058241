// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".field-root-bjW {\n  color: rgb(var(--venia-global-color-text));\n  display: grid;\n  align-content: start;\n}\n\n.field-label-PV2 {\n}\n\n.field-input-3rT {\n  flex: 0 0 100%;\n  margin: 0;\n  max-width: 100%;\n  width: 100%;\n  -webkit-appearance: none;\n}\n\n.field-input-3rT:focus {\n  outline: none;\n  background-color: var(--venia-global-color-snowbound);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "field-root-bjW",
	"label": "field-label-PV2 sr-only",
	"input": "field-input-3rT"
};
export default ___CSS_LOADER_EXPORT___;
