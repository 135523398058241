import React from 'react';
import { func, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useCategoryLeaf } from '@magento/peregrine/lib/talons/CategoryTree';
import resourceUrl from '@magento/peregrine/lib/util/makeUrl';
import { useStyle } from '@magento/venia-ui/lib/classify';
import defaultClasses from './categoryLeaf.module.css';

var Leaf = function Leaf(props) {
  var category = props.category,
      onNavigate = props.onNavigate,
      categoryUrlSuffix = props.categoryUrlSuffix;
  var name = category.name,
      url_path = category.url_path,
      children = category.children;
  var classes = useStyle(defaultClasses, props.classes);

  var _useCategoryLeaf = useCategoryLeaf({
    onNavigate: onNavigate
  }),
      handleClick = _useCategoryLeaf.handleClick;

  var destination = resourceUrl("/".concat(url_path).concat(categoryUrlSuffix || ''));
  var resourcesAllLinks = useSelector(function (state) {
    return state.resources.resourcesLinks;
  });
  var isExternalLink = false;

  var handleExternalLinkClick = function handleExternalLinkClick(destination) {
    return function (e) {
      e.preventDefault();
      window.open(destination, '_blank', 'noopener, noreferrer');
      handleClick();
    };
  };

  var leafLabel = children && children.length ? React.createElement(FormattedMessage, {
    id: 'categoryLeaf.allLabel',
    defaultMessage: 'All {name}',
    values: {
      name: name
    }
  }) : name;

  if (leafLabel === 'Find Sales Rep - Residential') {
    destination = '/find-a-rep/residential';
  }

  if (leafLabel === 'Find Sales Rep - Commercial') {
    destination = '/find-a-rep/commercial';
  }

  if (leafLabel === 'Professional Resources') {
    var _resourcesAllLinks$;

    destination = "/resources?tab=".concat(resourcesAllLinks === null || resourcesAllLinks === void 0 ? void 0 : (_resourcesAllLinks$ = resourcesAllLinks[0]) === null || _resourcesAllLinks$ === void 0 ? void 0 : _resourcesAllLinks$.name);
  }

  if (leafLabel === 'Blog') {
    destination = 'https://blog.hubbardtonforge.com/';
    isExternalLink = true;
  }

  return React.createElement("li", {
    className: classes.root
  }, isExternalLink ? React.createElement(Link, {
    className: classes.target,
    onClick: handleExternalLinkClick(destination),
    "aria-label": "Go to ".concat(leafLabel),
    target: "_blank"
  }, React.createElement("span", {
    className: classes.text
  }, leafLabel)) : React.createElement(Link, {
    className: classes.target,
    to: destination,
    onClick: handleClick,
    "aria-label": "Go to ".concat(leafLabel)
  }, React.createElement("span", {
    className: classes.text
  }, leafLabel)));
};

export default Leaf;
Leaf.propTypes = {
  category: shape({
    name: string.isRequired,
    url_path: string.isRequired
  }).isRequired,
  classes: shape({
    root: string,
    target: string,
    text: string
  }),
  onNavigate: func.isRequired,
  categoryUrlSuffix: string
};