// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footerIconLink-linkItem-95a {\n    height: 125px;\n    width: 125px;\n}\n\n.footerIconLink-linkItem-95a:hover {\n    background-color: var(--venia-global-color-snowbound);\n}\n\n.footerIconLink-linkItem-95a:last-of-type {\n    width: 220px;\n}\n\n.footerIconLink-linkWraper-0Zp {\n}\n\n.footerIconLink-iconWraper-PaZ {\n}\n\n.footerIconLink-textWrapper-mu0 {\n    /* composes: pt-[12px] from global; */\n}\n\n@media (max-width: 768px) {\n    .footerIconLink-textWrapper-mu0 {\n        display: none !important;\n    }\n}\n\n@media (max-width: 767px) {\n    .footerIconLink-linkItem-95a:last-of-type {\n        width: 125px;\n    }\n}\n\n@media (max-width: 640px) {\n    .footerIconLink-iconChat-Fiu svg {\n        width: 54px;\n        height: 46px;\n    }\n    .footerIconLink-iconCallUs-yU6 svg {\n        width: 29px;\n        height: 52px;\n    }\n    .footerIconLink-iconEmail-kEQ svg {\n        width: 49px;\n        height: 34px;\n    }\n    .footerIconLink-iconInstallation-EBM svg {\n        width: 27px;\n        height: 51px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkItem": "footerIconLink-linkItem-95a flex flex-col justify-between py-5",
	"linkWraper": "footerIconLink-linkWraper-0Zp w-full flex justify-center",
	"iconWraper": "footerIconLink-iconWraper-PaZ h-full items-center",
	"textWrapper": "footerIconLink-textWrapper-mu0",
	"iconChat": "footerIconLink-iconChat-Fiu",
	"iconCallUs": "footerIconLink-iconCallUs-yU6",
	"iconEmail": "footerIconLink-iconEmail-kEQ",
	"iconInstallation": "footerIconLink-iconInstallation-EBM"
};
export default ___CSS_LOADER_EXPORT___;
