import React from 'react';
import { useIntl } from 'react-intl';
import { node, number, oneOfType, shape, string } from 'prop-types';
import defaultClasses from './message.module.css';
import { useStyle } from '@magento/venia-ui/lib/classify';

var Message = function Message(props) {
  var children = props.children,
      propClasses = props.classes,
      fieldState = props.fieldState;

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var error = fieldState.error;
  var classes = useStyle(defaultClasses, propClasses);
  var className = error ? classes.root_error : classes.root;
  var translatedErrorMessage;

  if (error) {
    translatedErrorMessage = formatMessage({
      id: error.id,
      defaultMessage: error.defaultMessage
    }, {
      value: error.value
    });
  }

  return React.createElement("p", {
    className: className
  }, translatedErrorMessage || children);
};

export default Message;
Message.defaultProps = {
  fieldState: {}
};
Message.propTypes = {
  children: node,
  classes: shape({
    root: string,
    root_error: string
  }),
  fieldState: shape({
    error: shape({
      id: string,
      defaultMessage: string,
      value: oneOfType([number, string])
    })
  })
};