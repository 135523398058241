// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".productsLayout-productsWrapper-EMZ {\n}\n\n@media (max-width: 1023px) {\n    .productsLayout-productsWrapper-EMZ {\n        padding: 0 15px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productsWrapper": "productsLayout-productsWrapper-EMZ lg_px-14"
};
export default ___CSS_LOADER_EXPORT___;
