// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".addToListButton-normalHeight-O1W {\n    outline: none;\n}\n\n.addToListButton-normalHeight-O1W svg {\n    height: 32px;\n    width: 40px;\n}\n\n.addToListButton-smallHeight-nOg svg {\n    width: 26px;\n    height: 21px;\n}\n\n.addToListButton-normalHeight-O1W:focus-visible,\n.addToListButton-smallHeight-nOg:focus-visible {\n    outline: none;\n} \n\n@media (max-width: 768px) {\n    .addToListButton-smallHeight-nOg svg {\n        height: 12px;\n        width: 15px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"normalHeight": "addToListButton-normalHeight-O1W gap-x-2xs inline-flex items-center",
	"smallHeight": "addToListButton-smallHeight-nOg"
};
export default ___CSS_LOADER_EXPORT___;
