function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

/**
 * @fileoverview This file houses functions that can be used for
 * validation of form fields.
 *
 * Note that these functions should return a string error message
 * when they fail, and `undefined` when they pass.
 */
var SUCCESS = undefined;
export var hasLengthAtLeast = function hasLengthAtLeast(value, values, minimumLength) {
  var message = {
    id: 'validation.hasLengthAtLeast',
    defaultMessage: 'Must contain more characters',
    value: minimumLength
  };

  if (!value || value.length < minimumLength) {
    return message;
  }

  return SUCCESS;
};
export var hasLengthAtMost = function hasLengthAtMost(value, values, maximumLength) {
  if (value && value.length > maximumLength) {
    var message = {
      id: 'validation.hasLengthAtMost',
      defaultMessage: 'Must have less characters',
      value: maximumLength
    };
    return message;
  }

  return SUCCESS;
};
export var hasLengthExactly = function hasLengthExactly(value, values, length) {
  if (value && value.length !== length) {
    var message = {
      id: 'validation.hasLengthExactly',
      defaultMessage: 'Does not have exact number of characters',
      value: length
    };
    return message;
  }

  return SUCCESS;
};
/**
 * isRequired is provided here for convenience but it is inherently ambiguous and therefore we don't recommend using it.
 * Consider using more specific validators such as `hasLengthAtLeast` or `mustBeChecked`.
 */

export var isRequired = function isRequired(value) {
  var FAILURE = {
    id: 'validation.isRequired',
    defaultMessage: 'Is required.'
  }; // The field must have a value (no null or undefined) and
  // if it's a boolean, it must be `true`.

  if (!value) return FAILURE; // If it is a number or string, it must have at least one character of input (after trim).

  var stringValue = String(value).trim();
  var measureResult = hasLengthAtLeast(stringValue, null, 1);
  if (measureResult) return FAILURE;
  return SUCCESS;
};
export var mustBeChecked = function mustBeChecked(value) {
  var message = {
    id: 'validation.mustBeChecked',
    defaultMessage: 'Must be checked.'
  };
  if (!value) return message;
  return SUCCESS;
};
export var validateRegionCode = function validateRegionCode(value, values, countries) {
  var countryCode = DEFAULT_COUNTRY_CODE;
  var country = countries.find(function (_ref) {
    var id = _ref.id;
    return id === countryCode;
  });

  if (!country) {
    var invalidCountry = {
      id: 'validation.invalidCountry',
      defaultMessage: "Country \"".concat(countryCode, "\" is not an available country."),
      value: countryCode
    };
    return invalidCountry;
  }

  var regions = country.available_regions;

  if (!(Array.isArray(regions) && regions.length)) {
    var invalidRegions = {
      id: 'validation.invalidRegions',
      defaultMessage: "Country \"".concat(countryCode, "\" does not contain any available regions."),
      value: countryCode
    };
    return invalidRegions;
  }

  var region = regions.find(function (_ref2) {
    var code = _ref2.code;
    return code === value;
  });

  if (!region) {
    var invalidAbbrev = {
      id: 'validation.invalidAbbreviation',
      defaultMessage: 'That is not a valid state abbreviation.',
      value: value
    };
    return invalidAbbrev;
  }

  return SUCCESS;
};
export var validatePassword = function validatePassword(value) {
  var count = {
    lower: 0,
    upper: 0,
    digit: 0,
    special: 0
  };

  var _iterator = _createForOfIteratorHelper(value),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _char = _step.value;
      if (/[a-z]/.test(_char)) count.lower++;else if (/[A-Z]/.test(_char)) count.upper++;else if (/\d/.test(_char)) count.digit++;else if (/\S/.test(_char)) count.special++;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  if (Object.values(count).filter(Boolean).length < 3) {
    var message = {
      id: 'validation.validatePassword',
      defaultMessage: 'A password must contain at least 3 of the following: lowercase, uppercase, digits, special characters.'
    };
    return message;
  }

  return SUCCESS;
};
export var isEqualToField = function isEqualToField(value, values, fieldKey) {
  var message = {
    id: 'validation.isEqualToField',
    defaultMessage: 'Fields must match',
    value: fieldKey
  };
  return value === values[fieldKey] ? SUCCESS : message;
};
export var isNotEqualToField = function isNotEqualToField(value, values, fieldKey) {
  var message = {
    id: 'validation.isNotEqualToField',
    defaultMessage: 'Fields must be different',
    value: fieldKey
  };
  return value !== values[fieldKey] ? SUCCESS : message;
};
export var isEmailValid = function isEmailValid(email) {
  var message = {
    id: 'validation.isNotValidEmail',
    defaultMessage: 'Not valid email.'
  };
  var isValid = /\S+@\S+\.\S+/.test(email);
  return isValid ? SUCCESS : message;
};
export var isPhoneNumberValid = function isPhoneNumberValid(phone) {
  var message = {
    id: 'validation.isNotValidPhoneNumber',
    defaultMessage: 'Not valid phone number format.'
  }; // const regExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  var isValid = (phone === null || phone === void 0 ? void 0 : phone.length) === 17;
  return isValid ? SUCCESS : message;
};