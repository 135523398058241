import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { Fragment, lazy, useMemo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Relevant } from 'informed';
import { useQuery } from '@apollo/client';
import { useWishlistDialog } from '@magento/peregrine/lib/talons/Wishlist/WishlistDialog/useWishlistDialog';
import FormError from '@magento/venia-ui/lib/components/FormError';
import CreateWishlistForm from '@magento/venia-ui/lib/components/Wishlist/WishlistDialog/CreateWishlistForm';
import WishlistLineItem from '@magento/venia-ui/lib/components/Wishlist/WishlistDialog/WishlistLineItem';
import defaultClasses from '@magento/venia-ui/lib/components/Wishlist/WishlistDialog/wishlistDialog.module.css';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { useMergeCssClasses } from 'src/Utils/utils';
import FavouritesItem from 'src/components/Favorites/SignInModal/favouritesItem';
import Dialog from 'src/components/Dialog';
import { Button } from 'src/components'; // import { useWishlist } from '@magento/peregrine/lib/talons/WishlistPage/useWishlist';
// import { useWishlistPage } from '@magento/peregrine/lib/talons/WishlistPage/useWishlistPage';

import { Spinner } from '@magento/venia-ui/lib/components/LoadingIndicator';
import { useWindowSize } from '@magento/peregrine';
import { useAppContext } from '@magento/peregrine/lib/context/app';
import getCustomerGroupData from '../../../../../hooks/getCustomerGroupData';
import { addScrollToBody, removeScrollFromBody } from '../../../../../hooks/utils';
import { EXIST_IN_WISHLISTS } from '@magento/peregrine/lib/talons/Wishlist/AddToListButton/addToListButton.gql';
var ProjectSuccessModal = lazy(function () {
  return import('./ProjectSuccessModal');
});

var findListItem = function findListItem(wishlist, selectedItem, selectedOptions) {
  var _wishlist$items_v2$it, _wishlist$items_v, _wishlist$items_v$ite;

  return (_wishlist$items_v2$it = wishlist === null || wishlist === void 0 ? void 0 : (_wishlist$items_v = wishlist.items_v2) === null || _wishlist$items_v === void 0 ? void 0 : (_wishlist$items_v$ite = _wishlist$items_v.items) === null || _wishlist$items_v$ite === void 0 ? void 0 : _wishlist$items_v$ite.find(function (wItem) {
    var _wItem$product;

    if (((_wItem$product = wItem.product) === null || _wItem$product === void 0 ? void 0 : _wItem$product.__typename) == selectedItem.__typename) {
      var _wItem$product2, _wItem$product3, _wItem$product4;

      if (['SimpleProduct', 'GroupedProduct'].includes((_wItem$product2 = wItem.product) === null || _wItem$product2 === void 0 ? void 0 : _wItem$product2.__typename) && ((_wItem$product3 = wItem.product) === null || _wItem$product3 === void 0 ? void 0 : _wItem$product3.uid) === (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.uid)) {
        return true;
      } else if (((_wItem$product4 = wItem.product) === null || _wItem$product4 === void 0 ? void 0 : _wItem$product4.__typename) == 'ConfigurableProduct') {
        var _wItem$product5, _wItem$configurable_o, _wItem$configurable_o2;

        if (!wItem.configured_variant && !selectedOptions && ((_wItem$product5 = wItem.product) === null || _wItem$product5 === void 0 ? void 0 : _wItem$product5.uid) === (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.uid)) return true;
        return ((_wItem$configurable_o = wItem.configurable_options) === null || _wItem$configurable_o === void 0 ? void 0 : _wItem$configurable_o.length) === (selectedOptions === null || selectedOptions === void 0 ? void 0 : selectedOptions.length) && !((_wItem$configurable_o2 = wItem.configurable_options) !== null && _wItem$configurable_o2 !== void 0 && _wItem$configurable_o2.filter(function (configOption) {
          return !selectedOptions.includes(configOption.configurable_product_option_value_uid);
        }).length);
      }
    } else if (selectedItem !== null && selectedItem !== void 0 && selectedItem.klevu_category) {
      var _wItem$product6, _wItem$product7;

      // means we're on PLP with klevu data
      if (((_wItem$product6 = wItem.product) === null || _wItem$product6 === void 0 ? void 0 : _wItem$product6.sku) == selectedItem.sku && ((_wItem$product7 = wItem.product) === null || _wItem$product7 === void 0 ? void 0 : _wItem$product7.__typename) == 'ConfigurableProduct' && !wItem.configured_variant) {
        return true;
      }
    } else {
      return false;
    }
  })) !== null && _wishlist$items_v2$it !== void 0 ? _wishlist$items_v2$it : null;
};

var WishlistDialog = function WishlistDialog(props) {
  var _selectedItem$id, _wishlistsData$custom, _wishlistsData$custom2, _wishlistsData$custom3, _wishlistsData$custom4, _wishlistsData$custom5, _wishlistsData$custom6, _wishlistsData$custom7;

  var isOpen = props.isOpen,
      itemOptions = props.itemOptions,
      onClose = props.onClose,
      onSuccess = props.onSuccess,
      selectedItem = props.selectedItem,
      selectedOptions = props.selectedOptions,
      selectedOptionsArray = props.selectedOptionsArray,
      _props$isPlp = props.isPlp,
      isPlp = _props$isPlp === void 0 ? false : _props$isPlp,
      _props$refetchFavorit = props.refetchFavorites,
      refetchFavorites = _props$refetchFavorit === void 0 ? function () {} : _props$refetchFavorit,
      configuredVariant = props.configuredVariant;
  var talonProps = useWishlistDialog({
    isLoading: props.isLoading,
    itemOptions: itemOptions,
    onClose: onClose,
    onSuccess: onSuccess
  });
  var canCreateWishlist = talonProps.canCreateWishlist,
      formErrors = talonProps.formErrors,
      handleAddToWishlist = talonProps.handleAddToWishlist,
      handleCancel = talonProps.handleCancel,
      handleCancelNewList = talonProps.handleCancelNewList,
      isLoading = talonProps.isLoading,
      wishlistsData = talonProps.wishlistsData,
      removeProductFromWishlist = talonProps.removeProductFromWishlist,
      _talonProps$loadingRe = talonProps.loadingRemoveFromWishlist,
      loadingRemoveFromWishlist = _talonProps$loadingRe === void 0 ? false : _talonProps$loadingRe,
      _talonProps$loadingAd = talonProps.loadingAddingtoWishlist,
      loadingAddingtoWishlist = _talonProps$loadingAd === void 0 ? false : _talonProps$loadingAd;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      project = _useState2[0],
      setProject = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      projectSuccessModal = _useState4[0],
      setProjectSuccessModal = _useState4[1];

  var _getCustomerGroupData = getCustomerGroupData(),
      customer_flow_map_group = _getCustomerGroupData.customer_flow_map_group;

  var windowSize = useWindowSize();
  var isMobile = windowSize.innerWidth <= 768;
  var classes = useMergeCssClasses(defaultClasses, props.classes);
  var productIdStr = selectedItem === null || selectedItem === void 0 ? void 0 : (_selectedItem$id = selectedItem.id) === null || _selectedItem$id === void 0 ? void 0 : _selectedItem$id.toString();
  var productId = !!isPlp ? productIdStr.substring(0, productIdStr.indexOf('-')) || productIdStr : (configuredVariant === null || configuredVariant === void 0 ? void 0 : configuredVariant.id) || (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.id);

  var _useQuery = useQuery(EXIST_IN_WISHLISTS, {
    variables: {
      product_id: productId
    },
    fetchPolicy: 'no-cache'
  }),
      existInWishlistsData = _useQuery.data,
      loading = _useQuery.loading,
      refetch = _useQuery.refetch;

  var hasProjects = useMemo(function () {
    return customer_flow_map_group !== 'Consumer' && customer_flow_map_group !== 'HF Employee' && customer_flow_map_group !== 'RES Reps' && customer_flow_map_group !== 'COM Reps';
  }, [customer_flow_map_group]);

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  useEffect(function () {
    if (refetch) refetch();
  }, [wishlistsData]);
  useEffect(function () {
    if (isOpen) removeScrollFromBody(false, 'listHolder');else addScrollToBody(false);
    return function () {
      return addScrollToBody(false);
    };
  }, [isOpen]);
  useEffect(function () {
    return project && setProjectSuccessModal(true);
  }, [project]);
  var myFavorites = (_wishlistsData$custom = wishlistsData === null || wishlistsData === void 0 ? void 0 : (_wishlistsData$custom2 = wishlistsData.customer) === null || _wishlistsData$custom2 === void 0 ? void 0 : (_wishlistsData$custom3 = _wishlistsData$custom2.wishlists) === null || _wishlistsData$custom3 === void 0 ? void 0 : _wishlistsData$custom3.find(function (wItem) {
    return ['Wish List', 'Favorites', 'My Favorites'].includes(wItem.name);
  })) !== null && _wishlistsData$custom !== void 0 ? _wishlistsData$custom : wishlistsData === null || wishlistsData === void 0 ? void 0 : (_wishlistsData$custom4 = wishlistsData.customer) === null || _wishlistsData$custom4 === void 0 ? void 0 : (_wishlistsData$custom5 = _wishlistsData$custom4.wishlists) === null || _wishlistsData$custom5 === void 0 ? void 0 : _wishlistsData$custom5[0];
  var derivedSelectedItem = myFavorites ? findListItem(myFavorites, selectedItem, selectedOptions || selectedOptionsArray) : null;
  var wishlistLines = useMemo(function () {
    if (wishlistsData) {
      var wishlistArray = hasProjects ? wishlistsData.customer.wishlists : myFavorites;
      var wishlists = wishlistArray.map(function (wishlist) {
        var _existInWishlistsData, _existInWishlistsData2, _existInWishlistsData3, _existInWishlistsData4;

        var checked = existInWishlistsData === null || existInWishlistsData === void 0 ? void 0 : (_existInWishlistsData = existInWishlistsData.existInWishlists) === null || _existInWishlistsData === void 0 ? void 0 : (_existInWishlistsData2 = _existInWishlistsData.find(function (wd) {
          return wd.wishlist_id == wishlist.id;
        })) === null || _existInWishlistsData2 === void 0 ? void 0 : _existInWishlistsData2.exist;
        var itemWishlistId = existInWishlistsData === null || existInWishlistsData === void 0 ? void 0 : (_existInWishlistsData3 = existInWishlistsData.existInWishlists) === null || _existInWishlistsData3 === void 0 ? void 0 : (_existInWishlistsData4 = _existInWishlistsData3.find(function (wd) {
          return wd.wishlist_id == wishlist.id;
        })) === null || _existInWishlistsData4 === void 0 ? void 0 : _existInWishlistsData4.wishlist_item_id;
        return React.createElement("li", {
          key: wishlist.id
        }, React.createElement(WishlistLineItem, {
          id: wishlist.id,
          isDisabled: isLoading,
          onClick: handleAddToWishlist,
          wishlist: wishlist,
          checked: checked,
          setProject: setProject,
          removeProductFromWishlist: removeProductFromWishlist,
          loadingRemoveFromWishlist: loadingRemoveFromWishlist,
          refetchWishlist: refetch,
          myFavoritesId: myFavorites === null || myFavorites === void 0 ? void 0 : myFavorites.id,
          removingProduct: loadingRemoveFromWishlist || loadingAddingtoWishlist || isLoading,
          itemWishlistId: itemWishlistId,
          refetchFavorites: refetchFavorites
        }, wishlist.name === 'Wish List' ? 'My Favorites' : wishlist.name));
      });
      return React.createElement("ul", {
        className: classes.existingWishlists
      }, wishlists);
    } else {
      return null;
    }
  }, [classes.existingWishlists, handleAddToWishlist, isLoading, wishlistsData, selectedOptions, selectedItem, itemOptions === null || itemOptions === void 0 ? void 0 : itemOptions.selected_options, setProject, selectedOptionsArray, existInWishlistsData]); // const shouldRenderForm = useCallback(() => !!isFormOpen, [isFormOpen]);

  var newWishlistForm = canCreateWishlist ? React.createElement(Fragment, null, React.createElement(CreateWishlistForm, {
    onCreateList: handleAddToWishlist,
    isAddLoading: isLoading,
    onCancel: handleCancelNewList
  })) : null;
  return React.createElement(Dialog, {
    isOpen: isOpen,
    onCancel: handleCancel,
    shouldShowButtons: false,
    classes: {
      form: defaultClasses.rootDialog
    },
    title: formatMessage({
      id: 'wishlistDialog.favoritesText',
      defaultMessage: "Select your preferred list(s)"
    })
  }, React.createElement(React.Fragment, null, React.createElement("div", {
    className: "".concat(classes.root, " ").concat(loadingRemoveFromWishlist || loadingAddingtoWishlist || loading ? ' ' + classes.loadingIndicator : '', " mb-10")
  }, React.createElement(FormError, {
    classes: {
      root: classes.formErrors
    },
    errors: formErrors
  }), React.createElement("div", {
    className: "flex items-center"
  }, React.createElement(FavouritesItem, {
    item: selectedItem,
    derivedSelectedItem: derivedSelectedItem
  })), isMobile ? React.createElement("div", {
    className: "border border-[#979797] mt-6"
  }) : null, newWishlistForm, React.createElement("div", {
    className: "".concat(classes.listHolder, " mt-12"),
    id: "listHolder"
  }, !(wishlistsData !== null && wishlistsData !== void 0 && (_wishlistsData$custom6 = wishlistsData.customer) !== null && _wishlistsData$custom6 !== void 0 && (_wishlistsData$custom7 = _wishlistsData$custom6.wishlists) !== null && _wishlistsData$custom7 !== void 0 && _wishlistsData$custom7.length) ? React.createElement(Spinner, null) : wishlistLines)), projectSuccessModal ? React.createElement(ProjectSuccessModal, {
    project: project,
    setProject: setProject,
    setProjectSuccessModal: setProjectSuccessModal
  }) : null));
};

export default WishlistDialog;
WishlistDialog.propTypes = {
  classes: shape({}),
  isOpen: bool,
  isLoading: bool,
  itemOptions: shape({
    entered_options: arrayOf(shape({
      uid: number.isRequired,
      value: string.isRequired
    })),
    parent_sku: string,
    sku: string.isRequired,
    selected_options: arrayOf(string),
    quantity: number.isRequired
  }),
  onClose: func,
  onSuccess: func
};