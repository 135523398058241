import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["type"];
import { BrowserPersistence } from '@magento/peregrine/lib/util';
var storage = new BrowserPersistence(); // If the root template supplies the backend URL at runtime, use it directly

var _ref = globalThis.document || {},
    htmlElement = _ref.documentElement;

var _ref2 = htmlElement ? htmlElement.dataset : {},
    imageOptimizingOrigin = _ref2.imageOptimizingOrigin;

var alreadyOptimized = imageOptimizingOrigin === 'backend'; // Protect against potential falsy values for `mediaBackend`.

var storeCode = storage.getItem('store_view_code') || STORE_VIEW_CODE;
var storeSecureBaseMediaUrl = {}; // Fallback to global secure_base_media_url set at build time

AVAILABLE_STORE_VIEWS.forEach(function (store) {
  storeSecureBaseMediaUrl[store.store_code] = store.secure_base_media_url;
});
var mediaBackend = storage.getItem('store_view_secure_base_media_url') || storeSecureBaseMediaUrl[storeCode];

if (!mediaBackend) {
  console.warn('A media backend URL should be defined in your config.');
  mediaBackend = 'https://backend.test/media/';
} // Tests if a URL begins with `http:` or `https:` or `data:`


var absoluteUrl = /^(data|http|https)?:/i; // Simple path joiner that guarantees one and only one slash between segments

var joinUrls = function joinUrls(base, url) {
  return (base.endsWith('/') ? base.slice(0, -1) : base) + '/' + (url.startsWith('/') ? url.slice(1) : url);
};

var mediaBases = new Map().set('image-product', 'catalog/product/').set('image-category', 'catalog/category/').set('image-swatch', 'attribute/swatch/');

var getFileType = function getFileType(url) {
  var fileName = url.pathname.split('/').reverse()[0];
  var fileType = fileName.split('.').reverse()[0];
  return fileType;
};
/**
 * Creates an "optimized" url for a provided relative url based on
 * requested media type and width. Any image URLs (whose type begins with
 * "image-" will also be optimized.)
 *
 * If a `type` is provided the `path` will be joined with the associated media
 * base.
 *  - `catalog/product/path/to/img.jpg`
 *
 * If a `width` is provided, "resize parameters" are added to the URL for
 * middlewares (either onboard or backend) to return using the desired width
 * and original media url.
 *  - `catalog/product/path/to/img.jpg?width=500&auto=webp&format=pjpg
 *
 * If only `path` is provided it is returned unaltered.
 *
 * @param {string} path - absolute or relative url to resource.
 * @param {Object} props - properties describing desired optimizations
 * @param {string} props.type - "image-product" or "image-category"
 * @param {number} props.width - the desired resize width of the image
 * @param {number} props.height - the desired resize height of the image
 * @param {number} props.quality - the desired quality of the image
 * @param {bool} props.crop - should the image be cropped
 * @param {string} props.fit - how should the image be fit with the dimensions: bounds, cover, crop
 */


var makeOptimizedUrl = function makeOptimizedUrl(path) {
  var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      type = _ref3.type,
      opts = _objectWithoutProperties(_ref3, _excluded);

  if (!path) {
    return path;
  } // Immediate return if there's no image optimization to attempt


  if (!type || !type.startsWith('image-')) {
    return path;
  }

  var _ref4 = globalThis.location || {},
      origin = _ref4.origin;

  var isAbsolute = absoluteUrl.test(path);
  var magentoBackendURL = process.env.MAGENTO_BACKEND_URL;
  var baseURL = new URL(path, mediaBackend); // If URL is relative and has a supported type, prepend media base onto path

  if (!isAbsolute && mediaBases.has(type)) {
    var mediaBase = mediaBases.get(type);

    if (!baseURL.pathname.includes(mediaBase)) {
      baseURL = new URL(joinUrls(mediaBase, path), mediaBackend);
    }
  }

  if (baseURL.href.startsWith(magentoBackendURL) && !alreadyOptimized) {
    // Replace URL base so optimization middleware can handle request
    baseURL = new URL(baseURL.href.slice(magentoBackendURL.length), origin);
  } // Append image optimization parameters


  var params = new URLSearchParams(baseURL.search);
  params.set('auto', 'webp'); // Use the webp format if available

  var imageFileType = getFileType(baseURL);

  if (imageFileType === 'png') {
    params.set('format', 'png'); // use png if webp is not available
  } else {
    params.set('format', 'pjpg'); // Use progressive JPG if webp is not available
  }

  Object.entries(opts).forEach(function (_ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
        key = _ref6[0],
        value = _ref6[1];

    if (value !== undefined && value !== null) {
      params.set(key, value);
    }
  });
  baseURL.search = params.toString();

  if (baseURL.origin === origin) {
    if (process.env.CLOUD_FLARE_IMAGE_OPTIMIZATION == 'true') {
      var _pathNameNoCache = baseURL.pathname.replace(/cache\/[^\/]*\//, '');

      baseURL.cf_href = '/cdn-cgi/image/width=' + (opts !== null && opts !== void 0 && opts.width ? opts.width : '') + ',height=' + (opts !== null && opts !== void 0 && opts.height ? opts.height : '') + ',quality=' + (opts !== null && opts !== void 0 && opts.quality ? opts.quality : '85') + ',format=auto' + _pathNameNoCache + baseURL.search;
      return baseURL.cf_href;
    }

    return baseURL.href.slice(baseURL.origin.length);
  }

  var pathNameNoCache = baseURL.pathname.replace(/cache\/[^\/]*\//, '');
  baseURL.cf_href = baseURL.origin + '/cdn-cgi/image/width=' + (opts !== null && opts !== void 0 && opts.width ? opts.width : '') + ',height=' + (opts !== null && opts !== void 0 && opts.height ? opts.height : '') + ',quality=' + (opts !== null && opts !== void 0 && opts.quality ? opts.quality : '85') + ',format=auto' + pathNameNoCache + baseURL.search;

  if (process.env.CLOUD_FLARE_IMAGE_OPTIMIZATION == 'true') {
    return baseURL.cf_href;
  }

  return baseURL.href;
};

export default makeOptimizedUrl;