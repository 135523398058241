// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".customCheckbox-round-mKz {\n    position: relative;\n}\n\n.customCheckbox-checkboxWrapper-sMo {\n    display: flex;\n    align-items: center;\n}\n\n.customCheckbox-root-AKg {\n    width: 30px;\n    height: 30px;\n}\n\n.customCheckbox-root_checked-d1H {\n    background-color: var(--venia-global-color-black) !important;\n}\n\n.customCheckbox-root_checked-d1H svg {\n    height: 15px;\n    width: 21px;\n}\n.customCheckbox-labelText-vYu {\n    position: relative;\n    left: 30px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"round": "customCheckbox-round-mKz",
	"checkboxWrapper": "customCheckbox-checkboxWrapper-sMo",
	"root": "customCheckbox-root-AKg border border solid border-bronze bg-snowbound flex items-center justify-center cursor-pointer",
	"root_checked": "customCheckbox-root_checked-d1H customCheckbox-root-AKg border border solid border-bronze bg-snowbound flex items-center justify-center cursor-pointer",
	"labelText": "customCheckbox-labelText-vYu"
};
export default ___CSS_LOADER_EXPORT___;
