import React, { Fragment, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';
import { useBreadcrumbs } from '@magento/peregrine/lib/talons/Breadcrumbs/useBreadcrumbs';
import resourceUrl from '@magento/peregrine/lib/util/makeUrl';
import Shimmer from './breadcrumbs.shimmer';
import defaultClasses from './breadcrumbs.module.css';
import { useMergeCssClasses } from '../../../../Utils/utils';
var DELIMITER = '|';
/**
 * Breadcrumbs! Generates a sorted display of category links.
 *
 * @param {String} props.categoryId the uid of the category for which to generate breadcrumbs
 * @param {String} props.currentProduct the name of the product we're currently on, if any.
 */

var Breadcrumbs = function Breadcrumbs(props) {
  var classes = useMergeCssClasses(defaultClasses, props.classes);
  var categoryId = props.categoryId,
      currentProduct = props.currentProduct,
      _props$text = props.text,
      text = _props$text === void 0 ? undefined : _props$text,
      _props$search = props.search,
      search = _props$search === void 0 ? undefined : _props$search;
  var talonProps = useBreadcrumbs({
    categoryId: categoryId
  });
  var currentCategory = talonProps.currentCategory,
      currentCategoryPath = talonProps.currentCategoryPath,
      hasError = talonProps.hasError,
      isLoading = talonProps.isLoading,
      normalizedData = talonProps.normalizedData,
      handleClick = talonProps.handleClick; // For all links generate a fragment like "/ Text"

  var links = useMemo(function () {
    return normalizedData.map(function (_ref) {
      var text = _ref.text,
          path = _ref.path;
      return React.createElement(Fragment, {
        key: text
      }, React.createElement("span", {
        className: classes.divider
      }, DELIMITER), React.createElement(Link, {
        className: classes.link,
        to: resourceUrl(path),
        onClick: handleClick
      }, text));
    });
  }, [classes.divider, classes.link, handleClick, normalizedData]);

  if (text) {
    return React.createElement("div", {
      className: classes.root,
      "aria-live": "polite",
      "aria-busy": "false"
    }, React.createElement(Link, {
      className: classes.link,
      to: "/",
      "aria-label": "Go to Home page"
    }, React.createElement(FormattedMessage, {
      id: 'global.home',
      defaultMessage: 'Home'
    })), links, React.createElement("span", {
      className: classes.divider
    }, DELIMITER), React.createElement("span", null, " Search "), React.createElement("span", {
      className: classes.divider
    }, DELIMITER), React.createElement(Link, {
      className: classes.link,
      to: {
        pathname: window.location.pathname,
        search: window.location.search
      },
      style: {
        textTransform: 'uppercase'
      },
      "aria-label": "Search"
    }, search), !currentProduct && React.createElement("h3", {
      className: classes.currentCategoryTitle
    }, text !== null && text !== void 0 ? text : currentCategory));
  }

  if (isLoading && !currentCategory) {
    return React.createElement(Shimmer, null);
  } // Don't display anything but the empty, static height div when there's an error.


  if (hasError) {
    return React.createElement("div", {
      className: classes.root,
      "aria-live": "polite",
      "aria-busy": "false"
    });
  } // If we have a "currentProduct" it means we're on a PDP so we want the last
  // category text to be a link. If we don't have a "currentProduct" we're on
  // a category page so it should be regular text.


  var currentCategoryLink = currentProduct ? React.createElement(Link, {
    className: classes.link,
    to: resourceUrl(currentCategoryPath),
    onClick: handleClick,
    "aria-label": "Go to ".concat(currentCategory)
  }, currentCategory) : React.createElement("span", {
    className: classes.currentCategory
  }, currentCategory);
  var currentProductNode = currentProduct ? React.createElement(Fragment, null, React.createElement("span", {
    className: classes.divider
  }, DELIMITER), React.createElement("span", {
    className: classes.text
  }, currentProduct)) : null;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: classes.root,
    "aria-live": "polite",
    "aria-busy": "false"
  }, React.createElement(Link, {
    className: classes.link,
    to: "/",
    "aria-label": "Go to Home page"
  }, React.createElement(FormattedMessage, {
    id: 'global.home',
    defaultMessage: 'Home'
  })), links, React.createElement("span", {
    className: classes.divider
  }, DELIMITER), currentCategoryLink, currentProductNode), !currentProduct && React.createElement("h1", {
    className: "h3 ".concat(classes.currentCategoryTitle)
  }, text !== null && text !== void 0 ? text : currentCategory));
};

export default Breadcrumbs;
Breadcrumbs.propTypes = {
  categoryId: string,
  currentProduct: string
};