// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".appBodyLayout-body-vwc {\n    /* max-width: 1536px; */\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "appBodyLayout-body-vwc container lg_pb-0 pb-[30px]"
};
export default ___CSS_LOADER_EXPORT___;
