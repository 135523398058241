import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Trigger from '@magento/venia-ui/lib/components/Trigger';
import { InstallationIcon, EmailsIcon, CallUsIcon } from '../Common';
import defaultClasses from './footerIconLink.module.css';
import CallUsModal from './Modals/callUsModal';
import InstallationSupportModal from './Modals/installationSupportModal';
import EmailUsModal from './Modals/emailUsModal';
import { addScrollToBody, removeScrollFromBody } from '../../hooks/utils';

var FooterIconLinks = function FooterIconLinks() {
  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      footerModalData = _useState2[0],
      setFooterModalData = _useState2[1];

  var handleModalClose = useCallback(function () {
    return setFooterModalData(null);
  }, []);
  var isCallModalOpen = footerModalData === 'CALL_MODAL';
  var isEmailModalOpen = footerModalData === 'EMAIL_MODAL';
  var isInstallationModalOpen = footerModalData === 'INSTALLATION_MODAL';
  useEffect(function () {
    return isCallModalOpen || isEmailModalOpen || isInstallationModalOpen ? removeScrollFromBody(false) : addScrollToBody(false);
  }, [isCallModalOpen, isEmailModalOpen, isInstallationModalOpen]);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: defaultClasses.linkItem
  }, React.createElement(Trigger, {
    action: function action() {
      return setFooterModalData('CALL_MODAL');
    },
    classes: {
      root: "".concat(defaultClasses.linkWraper, " ").concat(defaultClasses.iconWraper, " ").concat(defaultClasses.iconCallUs)
    },
    "aria-label": "Open Call Us modal"
  }, React.createElement("span", {
    className: "sr-only"
  }, React.createElement(FormattedMessage, {
    id: 'CALL US',
    defaultMessage: 'CALL US'
  })), React.createElement(CallUsIcon, null)), React.createElement("div", {
    className: "".concat(defaultClasses.linkWraper, " ").concat(defaultClasses.textWrapper)
  }, React.createElement(FormattedMessage, {
    id: 'CALL US',
    defaultMessage: 'CALL US'
  }))), React.createElement("div", {
    className: defaultClasses.linkItem
  }, React.createElement(Trigger, {
    action: function action() {
      return setFooterModalData('EMAIL_MODAL');
    },
    classes: {
      root: "".concat(defaultClasses.linkWraper, " ").concat(defaultClasses.iconWraper, " ").concat(defaultClasses.iconEmail)
    },
    "aria-label": "Open Email US modal"
  }, React.createElement("span", {
    className: "sr-only"
  }, React.createElement(FormattedMessage, {
    id: 'EMAIL US',
    defaultMessage: 'EMAIL US'
  })), React.createElement(EmailsIcon, null)), React.createElement("div", {
    className: "".concat(defaultClasses.linkWraper, " ").concat(defaultClasses.textWrapper)
  }, React.createElement(FormattedMessage, {
    id: 'EMAIL US',
    defaultMessage: 'EMAIL US'
  }))), React.createElement("div", {
    className: defaultClasses.linkItem
  }, React.createElement(Trigger, {
    action: function action() {
      return setFooterModalData('INSTALLATION_MODAL');
    },
    classes: {
      root: "".concat(defaultClasses.linkWraper, " ").concat(defaultClasses.iconWraper, " ").concat(defaultClasses.iconInstallation)
    },
    "aria-label": "Open Installation Support modal"
  }, React.createElement("span", {
    className: "sr-only"
  }, React.createElement(FormattedMessage, {
    id: 'INSTALLATION SUPPORT',
    defaultMessage: 'INSTALLATION SUPPORT'
  })), React.createElement(InstallationIcon, null)), React.createElement("div", {
    className: "".concat(defaultClasses.linkWraper, " ").concat(defaultClasses.textWrapper)
  }, React.createElement(FormattedMessage, {
    id: 'INSTALLATION SUPPORT',
    defaultMessage: 'INSTALLATION SUPPORT'
  }))), isCallModalOpen && React.createElement(CallUsModal, {
    isOpen: isCallModalOpen,
    closeModal: handleModalClose
  }), isEmailModalOpen && React.createElement(EmailUsModal, {
    isOpen: isEmailModalOpen,
    closeModal: handleModalClose
  }), isInstallationModalOpen && React.createElement(InstallationSupportModal, {
    isOpen: isInstallationModalOpen,
    closeModal: handleModalClose
  }));
};

export default FooterIconLinks;