// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../overrides/venia-ui/components/TextInput/textInput.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".accessRequestModal-root-Vk0 {\n    width: 650px;\n}\n\n.accessRequestModal-placeholder-S18 {\n}\n\n.accessRequestModal-closeBtn-e9X {\n    top: 50px;\n    right: 50px;\n}\n\n.accessRequestModal-closeBtn-e9X svg {\n    width: 44px;\n    height: 44px;\n}\n\n.accessRequestModal-closeBtn-e9X path {\n    stroke: var(--venia-global-color-white);\n    stroke-width: 1px;\n}\n\n.accessRequestModal-input-28u {\n    border-color: var(--venia-global-color-white);\n}\n\n.accessRequestModal-input-28u:focus {\n    background-color: transparent;\n}\n\n.accessRequestModal-inputPlaceholder-U8m {\n    font-size: 1rem;\n    color: white !important;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "accessRequestModal-root-Vk0 text-white text-center",
	"placeholder": "accessRequestModal-placeholder-S18 text-base",
	"closeBtn": "accessRequestModal-closeBtn-e9X absolute",
	"input": "accessRequestModal-input-28u",
	"inputPlaceholder": "accessRequestModal-inputPlaceholder-U8m " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["placeholder"] + ""
};
export default ___CSS_LOADER_EXPORT___;
