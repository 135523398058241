import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useCallback, useMemo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useApolloClient } from '@apollo/client';
import { useUserContext } from '@magento/peregrine/lib/context/user';
import { GET_PRODUCTS_IN_WISHLISTS } from '@magento/peregrine/lib/talons/Wishlist/AddToListButton/addToListButton.gql';
import { useSingleWishlist } from '@magento/peregrine/lib/talons/Wishlist/AddToListButton/helpers/useSingleWishlist';
export var useAddToListButton = function useAddToListButton(props) {
  var afterAdd = props.afterAdd,
      beforeAdd = props.beforeAdd,
      item = props.item,
      storeConfig = props.storeConfig,
      isFavorite = props.isFavorite,
      _props$isWishlistDial = props.isWishlistDialogOpen,
      isWishlistDialogOpen = _props$isWishlistDial === void 0 ? false : _props$isWishlistDial,
      _props$setActiveItem = props.setActiveItem,
      setActiveItem = _props$setActiveItem === void 0 ? function () {} : _props$setActiveItem,
      _props$setIsWishlistD = props.setIsWishlistDialogOpen,
      setIsWishlistDialogOpen = _props$setIsWishlistD === void 0 ? function () {} : _props$setIsWishlistD,
      _props$isPdp = props.isPdp,
      isPdp = _props$isPdp === void 0 ? false : _props$isPdp,
      setWishlistChanged = props.setWishlistChanged,
      _props$wishlists = props.wishlists,
      wishlists = _props$wishlists === void 0 ? [] : _props$wishlists,
      refetchFavorites = props.refetchFavorites;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isModalOpen = _useState2[0],
      setIsModalOpen = _useState2[1];

  var _useState3 = useState(),
      _useState4 = _slicedToArray(_useState3, 2),
      successToastName = _useState4[0],
      setSuccessToastName = _useState4[1];

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      isSignedIn = _useUserContext2[0].isSignedIn;

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var singleWishlistProps = useSingleWishlist(_objectSpread(_objectSpread({}, props), {}, {
    setIsModalOpen: setIsModalOpen,
    isFavorite: isFavorite,
    setActiveItem: setActiveItem,
    setWishlistChanged: setWishlistChanged,
    wishlists: wishlists,
    refetchFavorites: refetchFavorites
  }));
  var apolloClient = useApolloClient();
  useEffect(function () {
    return isWishlistDialogOpen && setIsModalOpen(true);
  }, [isWishlistDialogOpen]);
  var buttonProps = useMemo(function () {
    var singleButtonProps = singleWishlistProps.buttonProps;

    if (storeConfig.enable_multiple_wishlists === '1' && isSignedIn && isFavorite && isWishlistDialogOpen) {
      return _objectSpread(_objectSpread({}, singleButtonProps), {}, {
        'aria-haspopup': 'dialog',
        onPress: function onPress() {
          setIsModalOpen(true);
          if (beforeAdd) beforeAdd();
        }
      });
    }

    return singleButtonProps;
  }, [singleWishlistProps.buttonProps, storeConfig.enable_multiple_wishlists, isSignedIn, beforeAdd, isWishlistDialogOpen, isFavorite]);
  var handleModalClose = useCallback(function (success, additionalData) {
    setIsModalOpen(false);
    setActiveItem(false);
    setIsWishlistDialogOpen(false); // only set item added true if someone calls handleModalClose(true)

    if (success === true) {
      apolloClient.writeQuery({
        query: GET_PRODUCTS_IN_WISHLISTS,
        data: {
          customerWishlistProducts: [].concat(_toConsumableArray(singleWishlistProps.customerWishlistProducts), [item.sku])
        }
      });
      setSuccessToastName(additionalData.wishlistName);

      if (afterAdd) {
        afterAdd();
      }
    }
  }, [afterAdd, apolloClient, item.sku, singleWishlistProps.customerWishlistProducts]);
  var modalProps = useMemo(function () {
    if (storeConfig.enable_multiple_wishlists === '1' && isSignedIn) {
      return {
        isOpen: isModalOpen,
        itemOptions: item,
        onClose: handleModalClose
      };
    }

    return null;
  }, [handleModalClose, isModalOpen, isSignedIn, item, storeConfig.enable_multiple_wishlists]);
  var successToastProps = useMemo(function () {
    if (successToastName) {
      return {
        type: 'success',
        message: formatMessage({
          id: 'wishlist.galleryButton.successMessageNamed',
          defaultMessage: 'Item successfully added to the "{wishlistName}" list.'
        }, {
          wishlistName: successToastName
        }),
        timeout: 5000
      };
    }

    return singleWishlistProps.successToastProps;
  }, [singleWishlistProps.successToastProps, formatMessage, successToastName]);
  return _objectSpread(_objectSpread({}, singleWishlistProps), {}, {
    buttonProps: buttonProps,
    modalProps: modalProps,
    successToastProps: successToastProps,
    isModalOpen: isModalOpen
  });
};