import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { gql } from '@apollo/client';
export var SUBSCRIBE_TO_NEWSLETTER = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation subscribeToNewsletter(\n    $email: String!\n    $first_name: String!\n    $what_describes_you: String!\n  ) {\n    subscriber(email: $email, first_name: $first_name, what_describes_you: $what_describes_you)\n  }\n"])));
export var SUBSCRIBE_SELECT_OPTIONS = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query SubscribeOptions {\n    subscribeOptions {\n      subscribeoptions_id\n      option\n    }\n  }\n"])));
export default {
  subscribeMutation: SUBSCRIBE_TO_NEWSLETTER,
  subscribeOptions: SUBSCRIBE_SELECT_OPTIONS
};