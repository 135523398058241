import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { gql } from '@apollo/client';
export var SIGN_OUT = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    mutation SignOutFromMenu {\n        revokeCustomerToken {\n            result\n        }\n    }\n"])));
export var COMPANY_CHECK = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query EpicorCompanyCheck($epicor_id: Int!, $email: String!) {\n        epicorCompanyCheck(epicor_id: $epicor_id, email: $email) {\n            company_id_found_on_website\n            webadmin_email_matched\n            email_found_on_website\n            company_id_found_on_epicor\n        }\n    }\n"])));
export var COMPANY_ACCESS = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    query AccessRequest(\n        $jobTitle: String!\n        $firstName: String!\n        $lastName: String!\n        $email: String!\n        $phone: String!\n        $epicorCompanyId: Int!\n    ) {\n        AccessRequest(\n            jobTitle: $jobTitle\n            firstName: $firstName\n            lastName: $lastName\n            email: $email\n            phone: $phone\n            epicorCompanyId: $epicorCompanyId\n        ) {\n            notified\n        }\n    }\n"])));
export default {
  signOutMutation: SIGN_OUT,
  checkCompanyExist: COMPANY_CHECK,
  accessRequest: COMPANY_ACCESS
};