import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { gql } from '@apollo/client';
export var ADD_TO_WISHLIST = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    mutation addProductToWishlist(\n        $wishlistId: ID!\n        $itemOptions: WishlistItemInput!\n    ) {\n        addProductsToWishlist(\n            wishlistId: $wishlistId\n            wishlistItems: [$itemOptions]\n        ) {\n            user_errors {\n                code\n                message\n            }\n            wishlist {\n                id\n                name\n            }\n        }\n    }\n"])));
export var GET_WISHLISTS = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query getWishlistsDialogData {\n        # eslint-disable-next-line @graphql-eslint/require-id-when-available\n        storeConfig {\n            store_code\n            enable_multiple_wishlists\n            maximum_number_of_wishlists\n        }\n        # eslint-disable-next-line @graphql-eslint/require-id-when-available\n        customer {\n            wishlists {\n                id\n                name\n                items_count\n            }\n        }\n    }\n"])));
export default {
  addProductToWishlistMutation: ADD_TO_WISHLIST,
  getWishlistsQuery: GET_WISHLISTS
};