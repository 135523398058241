import React from 'react';
import defaultClasses from './spinner.module.css';
import { useMergeCssClasses } from '../../../../Utils/utils';
import { LoadingIcon } from '../../../../components';

var Spinner = function Spinner(props) {
  var classes = useMergeCssClasses(defaultClasses, props.classes);
  return React.createElement("div", {
    className: 'flex justify-center'
  }, React.createElement(LoadingIcon, null));
};

export default Spinner;