// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".middleDialog-centered-cEa {\n    left: 50%;\n    bottom: 10%;\n    transform: translate(-50%, 0);\n    z-index: 1000;\n}\n\n.middleDialog-centered-cEa a::after,\n.middleDialog-centered-cEa > div p::after {\n    content: ' ';\n    position: absolute;\n    left: 0px;\n    width: 100%;\n    height: 0.1rem;\n    background-color: #3e3e3e;\n    bottom: -0.2rem;\n}\n\n@media (max-width: 768px) {\n    .middleDialog-centered-cEa {\n        width: 95%;\n        left: 2.5%;\n        transform: translate(0%, 0%);\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centered": "middleDialog-centered-cEa bg-snowbound fixed pl-14 pr-10 py-8 border border-solid border-black"
};
export default ___CSS_LOADER_EXPORT___;
