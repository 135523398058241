// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../../node_modules/postcss-loader/dist/cjs.js!../../clickable.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".accountTrigger-root-j63 {\n    align-items: center;\n    display: grid;\n    /* The full height of the site header minus (box shadow height * 2). */\n    height: calc(5rem - 8px);\n}\n\n.accountTrigger-root_open-Z6r {\n    box-shadow: 0 4px rgb(var(--venia-brand-color-1-700));\n}\n\n.accountTrigger-trigger-6zr {\n    /* height: 3rem; */\n    z-index: 1;\n}\n\n.accountTrigger-trigger_active-grU {\n}\n\n.accountTrigger-trigger_active-grU::after {\n    content: '';\n    position: absolute;\n    bottom: -27px;\n    background-color: var(--venia-global-color-red);\n    width: 100%;\n    height: 4px;\n}\n\n@media(max-width: 767px) {\n    .accountTrigger-trigger_active-grU::after {\n        bottom: -16px;\n    }\n}\n\n@media (max-width: 640px) {\n    .accountTrigger-trigger-6zr {\n        width: 3rem;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "accountTrigger-root-j63",
	"root_open": "accountTrigger-root_open-Z6r accountTrigger-root-j63",
	"trigger": "accountTrigger-trigger-6zr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["root"] + " h-full px-1",
	"trigger_active": "accountTrigger-trigger_active-grU accountTrigger-trigger-6zr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["root"] + " h-full px-1 relative"
};
export default ___CSS_LOADER_EXPORT___;
