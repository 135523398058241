import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { useAdapter } from '@magento/peregrine/lib/talons/Adapter/useAdapter';
import { AppContextProvider } from '@magento/venia-ui/lib/components/App';
import App from '../App';
import StoreCodeRoute from '@magento/venia-ui/lib/components/StoreCodeRoute';

var Adapter = function Adapter(props) {
  var talonProps = useAdapter(props);
  var apolloProps = talonProps.apolloProps,
      initialized = talonProps.initialized,
      reduxProps = talonProps.reduxProps,
      routerProps = talonProps.routerProps,
      urlHasStoreCode = talonProps.urlHasStoreCode; // TODO: Replace with app skeleton. See PWA-547.

  if (!initialized) {
    return null;
  }

  var children = props.children || React.createElement(App, null);
  var storeCodeRouteHandler = urlHasStoreCode ? React.createElement(StoreCodeRoute, null) : null;
  return React.createElement(ApolloProvider, apolloProps, React.createElement(ReduxProvider, reduxProps, React.createElement(BrowserRouter, routerProps, storeCodeRouteHandler, React.createElement(AppContextProvider, null, children))));
};

export default Adapter;