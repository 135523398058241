import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { getAdvanced, getBackgroundImages, getVerticalAlignment, getMediaQueries, getBorder } from '@magento/pagebuilder/lib/utils';
export default (function (node) {
  var childNode = node.children[0];
  var theme = childNode.getAttribute('data-candid-theme');
  var overlayTheme = childNode.getAttribute('data-candid-overlay-theme');
  var id = childNode.getAttribute('data-candid-id');
  var margin = childNode.getAttribute('data-candid-margin');
  var count = childNode.getAttribute('data-candid-count');
  var slideShow = childNode.getAttribute('data-candid-slideshow');
  var imageWidth = childNode.getAttribute('data-candid-width');
  var term = childNode.getAttribute('data-candid-tag');
  var type = childNode.getAttribute('data-candid-type');
  var containerWidth = childNode.getAttribute('data-candid-container-width');
  var sortBy = childNode.getAttribute('data-candid-sort');
  var sortDirection = childNode.getAttribute('data-candid-sort-order');
  var tagOperation = childNode.getAttribute('data-candid-tag-operation');
  var showCommunityUploadButton = childNode.getAttribute('data-candid-show-community-upload');
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
    minHeight: node.style.minHeight ? node.style.minHeight : null,
    display: node.style.display,
    width: node.style.width,
    backgroundColor: node.style.backgroundColor,
    candidId: id,
    candidTheme: theme,
    candidOverlayTheme: overlayTheme,
    margin: margin,
    count: count,
    slideShow: slideShow,
    imageWidth: imageWidth,
    term: term,
    type: type,
    containerWidth: containerWidth,
    sortBy: sortBy,
    sortDirection: sortDirection,
    tagOperation: tagOperation,
    showCommunityUploadButton: showCommunityUploadButton === 'true' ? true : false
  }, getBorder(node)), getMediaQueries(node)), getAdvanced(node)), getBackgroundImages(node)), getVerticalAlignment(node));
});