// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button-root-mpw:focus-visible {\n    outline: none;\n}\n.button-root-mpw {\n    letter-spacing: 0.0625rem;\n}\n.button-root-mpw:disabled,\n.button-root-mpw:hover:disabled {\n    pointer-events: none;\n    cursor: no-drop;\n}\n\n.button-content-u28 {\n    align-items: center;\n    display: inline-grid;\n    gap: 0.35rem;\n    grid-auto-flow: column;\n    justify-content: center;\n    justify-items: center;\n    width: 100%;\n    outline: none;\n}\n\n/* derived classes */\n.button-root_lowPriority-tyj {\n}\n.button-root_lowPriority-tyj:hover {\n    background-color: rgba(18, 17, 17, 0.2);\n    color: var(--venia-global-color-urbane-bronze);\n    border: solid 2px var(--venia-global-color-urbane-bronze);\n}\n\n.button-root_highPriority-F4x {\n    background-color: rgba(255, 255, 255, 0.25);\n}\n\n.button-root_highPriority-F4x:hover {\n    background-color: var(--venia-global-color-white);\n    color: var(--venia-global-color-black);\n}\n\n.button-normal-WWc {\n    width: 246px;\n    height: 60px;\n    max-width: 100%;\n}\n\n.button-small-mI9 {\n    width: 234px;\n    height: 50px;\n    max-width: 100%;\n}\n\n.button-large-X0n {\n    width: 380px;\n}\n\n.button-fullWidth-nuR {\n    width: 100%;\n}\n\n/* normal priorty is secondary in back  */\n.button-root_normalPriority-3Ew {\n    background-color: rgba(18, 17, 17, 0.2);\n}\n\n.button-root_normalPriority-3Ew:disabled {\n    background-color: var(--venia-global-color-worldly-gray);\n    border: 2px solid var(--venia-global-color-worldly-gray);\n    color: white;\n}\n\n.button-root_normalPriority-3Ew:hover {\n    background-color: var(--venia-global-color-black);\n    color: var(--venia-global-color-white);\n}\n\n@media (max-width: 640px) {\n    .button-regWidth-wVb {\n        width: 160px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "button-root-mpw",
	"content": "button-content-u28",
	"root_lowPriority": "button-root_lowPriority-tyj button-root-mpw bg-bronze text-white border-solid border-2 border-bronze",
	"root_highPriority": "button-root_highPriority-F4x button-root-mpw text-white border-2 border-solid border-white",
	"normal": "button-normal-WWc",
	"small": "button-small-mI9",
	"large": "button-large-X0n button-normal-WWc",
	"fullWidth": "button-fullWidth-nuR",
	"root_normalPriority": "button-root_normalPriority-3Ew button-root-mpw text-bronze border-2 border-solid border-black",
	"regWidth": "button-regWidth-wVb button-normal-WWc"
};
export default ___CSS_LOADER_EXPORT___;
