// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".image-root-vpl {\n    /*\n   * For customization, we provide an empty root.\n   * These styles will be applied to the image container.\n   */\n}\n\n.image-container-UW5 {\n    position: relative;\n}\n\n.image-image--IH {\n    /*\n   * For customization, we provide an empty image class.\n   * These styles will be applied directly to the image itself.\n   */\n}\n\n.image-loaded-v6N {\n    position: absolute;\n    top: 0;\n    left: 0;\n    visibility: visible;\n}\n\n.image-notLoaded-oiu {\n    visibility: hidden;\n}\n\n.image-placeholder-TtD {\n    background-color: var(--venia-global-color-gray);\n    position: relative;\n    top: 0;\n    left: 0;\n    height: inherit;\n}\n\n.image-placeholder_layoutOnly-LYE {\n    background-color: unset;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "image-root-vpl",
	"container": "image-container-UW5",
	"image": "image-image--IH",
	"loaded": "image-loaded-v6N",
	"notLoaded": "image-notLoaded-oiu image-loaded-v6N",
	"placeholder": "image-placeholder-TtD",
	"placeholder_layoutOnly": "image-placeholder_layoutOnly-LYE image-placeholder-TtD"
};
export default ___CSS_LOADER_EXPORT___;
