// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cmsBlock-root-bQz {\n    margin: 0rem;\n}\n.cmsBlock-content-CGB h2 {\n    margin-bottom: 2rem;\n    text-align: center;\n    text-transform: uppercase;\n}\n.cmsBlock-content-CGB img {\n    display: block;\n    /* TODO - image in page bulider block */\n    height: auto;\n    max-height: 100%;\n    max-width: 100%;\n    width: auto;\n}\n\n@media screen and (max-width: 991px) {\n    .cmsBlock-content-CGB img {\n        height: auto;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "cmsBlock-root-bQz",
	"content": "cmsBlock-content-CGB"
};
export default ___CSS_LOADER_EXPORT___;
