import React from 'react';

var CheckIcon = function CheckIcon(_ref) {
  var color = _ref.color;
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "17",
    height: "14",
    viewBox: "0 0 17 14"
  }, React.createElement("path", {
    fill: "none",
    fillRule: "evenodd",
    stroke: color ? color : '#FFF',
    strokeWidth: "2",
    strokeLinecap: "round",
    d: "M.667 8.045L5.122 12.5l11-11"
  }));
};

export default CheckIcon;