import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { gql } from '@apollo/client';
export var ADD_TO_WISHLIST = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    mutation AddProductToWishlistFromGallery(\n        $wishlistId: ID!\n        $itemOptions: WishlistItemInput!\n    ) {\n        addProductsToWishlist(\n            wishlistId: $wishlistId\n            wishlistItems: [$itemOptions]\n        ) {\n            user_errors {\n                code\n                message\n            }\n        }\n    }\n"])));
export var GET_PRODUCTS_IN_WISHLISTS = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query GetProductsInWishlistsForGallery {\n        customerWishlistProducts @client\n    }\n"])));
export var EXIST_IN_WISHLISTS = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    query ExistsInWIshlists($product_id: String!) {\n        existInWishlists(product_id: $product_id) {\n            exist\n            number_of_items\n            wishlist_id\n            wishlist_item_id\n            wishlist_item_image\n            wishlist_name\n        }\n    }\n"])));
export default {
  addProductToWishlistMutation: ADD_TO_WISHLIST,
  getProductsInWishlistsQuery: GET_PRODUCTS_IN_WISHLISTS
};