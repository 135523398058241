// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../node_modules/postcss-loader/dist/cjs.js!../Common/Button/button.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".newsletter-fullWidthButton-wS0 {\n    width: 100%;\n    font-weight: 700;\n}\n\n.newsletter-inputField-bX3 {\n}\n\n@media (max-width: 640px) {\n    .newsletter-inputField-bX3 {\n        padding-bottom: 10px !important;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidthButton": "newsletter-fullWidthButton-wS0 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["root_lowPriority"] + "",
	"inputField": "newsletter-inputField-bX3 text-xs py-3"
};
export default ___CSS_LOADER_EXPORT___;
