// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cmsVideoSection-cmsVideoSection-arT img {\n    width: 114px !important;\n    height: 114px !important;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    cursor: pointer;\n}\n\n.cmsVideoSection-closeIcon-sP- {\n}\n.cmsVideoSection-closeIcon-sP- svg {\n    width: 44px;\n    height: 44px;\n}\n.cmsVideoSection-closeIcon-sP- svg path {\n    stroke-width: 1px;\n}\n\n@media (max-width: 768px) {\n    .cmsVideoSection-closeIcon-sP- svg {\n        width: 24px;\n        height: 24px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cmsVideoSection": "cmsVideoSection-cmsVideoSection-arT",
	"closeIcon": "cmsVideoSection-closeIcon-sP- ml-auto"
};
export default ___CSS_LOADER_EXPORT___;
