// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rowItem-root-YFL {\n\n    grid-template-areas:\n        'item item item'\n        'collection collection collection';\n    grid-template-columns: 1fr 1fr 1fr;\n    -moz-column-gap: 1.25rem;\n         column-gap: 1.25rem;\n}\n\n@media screen and (max-width: 881px) {\n    .rowItem-root-YFL {\n        grid-template-columns: 1fr 1fr;\n        grid-template-areas:\n            'item item'\n            'collection collection';\n    }\n}\n\n/* @media screen and (max-width: 480px) {\n    .root {\n        grid-template-columns: 1fr;\n        grid-template-areas:\n            'item'\n            'collection';\n    }\n} */\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "rowItem-root-YFL grid"
};
export default ___CSS_LOADER_EXPORT___;
