// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".clickable-root-I82 {\n  align-items: center;\n  cursor: pointer;\n  display: inline-flex;\n  justify-content: center;\n  line-height: 1;\n  pointer-events: auto;\n  text-align: center;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "clickable-root-I82"
};
export default ___CSS_LOADER_EXPORT___;
