import React from 'react';
import { Link } from 'react-router-dom';
import classes from './viewByCategoryLink.module.css';

var ViewByCategoryLink = function ViewByCategoryLink(_ref) {
  var item = _ref.item,
      setSubmenuItems = _ref.setSubmenuItems;
  return React.createElement("div", {
    className: classes.submenuItem
  }, React.createElement(Link, {
    to: "/".concat(item.url_path),
    onClick: function onClick() {
      return setSubmenuItems(null);
    },
    "aria-label": "Go to ".concat(item.name, " category")
  }, React.createElement("span", {
    className: classes.submenuItemName,
    style: {
      color: item.title_color
    }
  }, item.name)), item.children.length > 0 && React.createElement("ul", null, item.children.map(function (item, idx) {
    return React.createElement("li", {
      className: classes.submenuItemChild,
      key: "".concat(item, "+").concat(idx),
      style: {
        color: item.title_color
      }
    }, React.createElement(Link, {
      to: "/".concat(item.url_path),
      onClick: function onClick() {
        return setSubmenuItems(null);
      },
      "aria-label": "Go to ".concat(item.name)
    }, item.name));
  })));
};

export default ViewByCategoryLink;