// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".indicator-root--zy {\n    height: calc(100% - 6rem);\n}\n\n.indicator-global-0xC {\n    z-index: 1000;\n}\n\n.indicator-message-MH2 {\n    letter-spacing: 0.25em;\n}\n\n.indicator-indicator-QMz {\n    -webkit-animation-direction: alternate;\n            animation-direction: alternate;\n    -webkit-animation-duration: 1.5s;\n            animation-duration: 1.5s;\n    -webkit-animation-iteration-count: infinite;\n            animation-iteration-count: infinite;\n    -webkit-animation-name: indicator-pulse-KJI;\n            animation-name: indicator-pulse-KJI;\n    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n            animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n}\n\n@-webkit-keyframes indicator-pulse-KJI {\n    0% {\n        filter: grayscale(1);\n        transform: scale(0.75) rotate(-540deg);\n    }\n    100% {\n        filter: grayscale(0);\n        transform: scale(1) rotate(0deg);\n    }\n}\n\n@keyframes indicator-pulse-KJI {\n    0% {\n        filter: grayscale(1);\n        transform: scale(0.75) rotate(-540deg);\n    }\n    100% {\n        filter: grayscale(0);\n        transform: scale(1) rotate(0deg);\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "indicator-root--zy content-center gap-lg grid items-center justify-center justify-items-center mx-0 w-full",
	"global": "indicator-global-0xC indicator-root--zy content-center gap-lg grid items-center justify-center justify-items-center mx-0 w-full fixed h-full left-0 m-0 top-0 w-full bg-white",
	"message": "indicator-message-MH2 text-colorDefault text-subtle uppercase",
	"indicator": "indicator-indicator-QMz",
	"pulse": "indicator-pulse-KJI"
};
export default ___CSS_LOADER_EXPORT___;
