import React from 'react';
import { bool } from 'prop-types';

var CloseIconNew = function CloseIconNew(_ref) {
  var isWhite = _ref.isWhite;
  return React.createElement("svg", {
    width: "20",
    height: "20",
    viewBox: "0 0 20 20",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("g", {
    stroke: "#3E3E3E",
    "stroke-width": "2",
    fill: "none",
    "fill-rule": "evenodd",
    "stroke-linecap": "square"
  }, React.createElement("path", {
    d: "m5.122 5.327 9.756 9.757M14.878 5.327l-9.756 9.757"
  })));
};

export default CloseIconNew;
CloseIconNew.propTypes = {
  isWhite: bool
};
CloseIconNew.defaultProps = {
  isWhite: false
};