import React from 'react';
import { FormattedMessage } from 'react-intl';
import { bool, node, shape, string } from 'prop-types';
import { useStyle } from '@magento/venia-ui/lib/classify';
import defaultClasses from './field.module.css';

var Field = function Field(props) {
  var children = props.children,
      id = props.id,
      label = props.label,
      optional = props.optional;
  var classes = useStyle(defaultClasses, props.classes);
  var optionalSymbol = optional ? React.createElement("span", {
    className: classes.optional
  }, React.createElement(FormattedMessage, {
    id: 'field.optional',
    defaultMessage: 'Optional'
  })) : null;
  return React.createElement("div", {
    className: classes.root
  }, React.createElement("label", {
    className: classes.label,
    htmlFor: id
  }, label, optionalSymbol), children);
};

Field.propTypes = {
  children: node,
  classes: shape({
    label: string,
    root: string
  }),
  id: string,
  label: node,
  optional: bool
};
export default Field;