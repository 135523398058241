// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-root-bGs {\n  display: flex;\n  flex-direction: column;\n  word-wrap: break-word;\n}\n\n.text-expanded-gkV {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: -webkit-box;\n    -webkit-line-clamp: auto; /* number of lines to show */\n    -webkit-box-orient: vertical;\n}\n\n.text-notExpanded-FxY p {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: -webkit-box;\n    -webkit-line-clamp: 2; /* number of lines to show */\n    -webkit-box-orient: vertical;\n}\n\n.text-readMoreBtn-i-n {\n  cursor: pointer;\n}\n\n@media(max-width: 640px) {\n    .text-expanded-gkV, .text-notExpanded-FxY {\n        line-height: 24px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "text-root-bGs",
	"expanded": "text-expanded-gkV",
	"notExpanded": "text-notExpanded-FxY",
	"readMoreBtn": "text-readMoreBtn-i-n font-bold mt-2"
};
export default ___CSS_LOADER_EXPORT___;
