import React from 'react';

var ShopingIcon = function ShopingIcon() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "42",
    height: "42",
    viewBox: "0 0 42 42"
  }, React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("circle", {
    cx: "21",
    cy: "21",
    r: "20.5",
    stroke: "#121111"
  }), React.createElement("path", {
    fill: "#fff",
    fillRule: "nonzero",
    d: "M31.107 31.95l-1.775-15.76c-.038-.33-.336-.58-.692-.58h-3.338v-.71c0-2.211-1.93-4.01-4.302-4.01s-4.302 1.799-4.302 4.01v.71H13.36c-.356 0-.654.25-.692.58l-1.775 15.76a.621.621 0 00.175.501.72.72 0 00.516.215h18.832a.719.719 0 00.516-.215.62.62 0 00.175-.501zM18.09 14.9c0-1.496 1.306-2.714 2.911-2.714s2.911 1.218 2.911 2.714v.71H18.09v-.71zm-5.733 16.47l1.63-14.466h2.71v1.429c0 .358.312.648.696.648.384 0 .696-.29.696-.648v-1.429h5.822v1.429c0 .358.312.648.696.648.384 0 .695-.29.695-.648v-1.429h2.712l1.63 14.466H12.356z"
  })));
};

export default ShopingIcon;