import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import React, { useMemo } from 'react';
import Trigger from '@magento/venia-ui/lib/components/Trigger';
import classes from './currentFilters.module.css';
import { SidebarIcon, CloseIconWhite } from '..';
import CurrentFilterItem from './currentFilterItem';
import { rangeSliderFilters, useQuery } from '../../overrides/venia-ui/components/SearchPage/useKlevuSearchResults';

var CurrentFilters = function CurrentFilters(_ref) {
  var isFilterSToggled = _ref.isFilterSToggled,
      handleToggleDropdown = _ref.handleToggleDropdown,
      filterState = _ref.filterState,
      filterApi = _ref.filterApi,
      onRemove = _ref.onRemove,
      handleReset = _ref.handleReset,
      klevuFilterManager = _ref.klevuFilterManager;
  var removeItem = filterApi.removeItem;
  var selectedQuery = useQuery(); // create elements and params at the same time for efficiency

  var filterElements = useMemo(function () {
    var elements = [];

    var _iterator = _createForOfIteratorHelper(filterState),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = _slicedToArray(_step.value, 2),
            group = _step$value[0],
            items = _step$value[1];

        if (rangeSliderFilters.includes(group)) {
          var _data$;

          var data = selectedQuery === null || selectedQuery === void 0 ? void 0 : selectedQuery.getAll(group + '[filter]');
          var minMax = (_data$ = data[0]) === null || _data$ === void 0 ? void 0 : _data$.split('_');
          var key = "".concat(group, "::").concat(minMax[0], ",").concat(minMax[1]);
          var item = {
            title: group === 'klevu_price' ? "$".concat(minMax[0], " - $").concat(minMax[1]) : "".concat(minMax[0], "\" - ").concat(minMax[1], "\""),
            value: "".concat(minMax[0], ",").concat(minMax[1]),
            swatchData: {},
            type: 'range'
          };
          elements.push(React.createElement("li", {
            key: key,
            className: classes.item
          }, React.createElement(CurrentFilterItem, {
            group: group,
            item: item,
            removeItem: removeItem,
            onRemove: onRemove,
            klevuFilterManager: klevuFilterManager
          })));
        } else {
          var _iterator2 = _createForOfIteratorHelper(items),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var _item = _step2.value;

              var _ref2 = _item || {},
                  title = _ref2.title,
                  value = _ref2.value;

              var _key = "".concat(group, "::").concat(title, "_").concat(value);

              elements.push(React.createElement("li", {
                key: _key,
                className: classes.item
              }, React.createElement(CurrentFilterItem, {
                group: group,
                item: _item,
                removeItem: removeItem,
                onRemove: onRemove,
                klevuFilterManager: klevuFilterManager
              })));
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return elements;
  }, [classes.item, filterState, removeItem, onRemove]);
  var filterStateSize = 0;
  filterState.forEach(function (key) {
    return filterStateSize += key.size;
  });
  rangeSliderFilters.forEach(function (value, index) {
    var _selectedQuery$getAll;

    var hasFilter = selectedQuery === null || selectedQuery === void 0 ? void 0 : (_selectedQuery$getAll = selectedQuery.getAll("".concat(value, "[filter]"))) === null || _selectedQuery$getAll === void 0 ? void 0 : _selectedQuery$getAll.length;

    if (hasFilter) {
      filterStateSize++;
    }
  });
  var filterDropdownClasses = isFilterSToggled ? classes.filterDropdown_active : classes.filterDropdown;
  return React.createElement("div", {
    className: filterDropdownClasses
  }, React.createElement("div", {
    onClick: handleToggleDropdown,
    className: "flex items-center cursor-pointer",
    role: "button"
  }, !isFilterSToggled ? React.createElement(SidebarIcon, null) : React.createElement("div", {
    className: "pl-2"
  }, React.createElement(CloseIconWhite, null)), React.createElement("h6", {
    className: "leading-160 ml-3 text-black"
  }, filterStateSize, " Filters")), React.createElement(Trigger, {
    action: handleReset,
    classes: {
      root: classes.triggerRoot
    },
    "aria-label": "Clear filters"
  }, "Clear Filters"), isFilterSToggled && React.createElement("div", {
    className: classes.optionsWrapper
  }, filterElements));
};

export default CurrentFilters;