import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../components/Common/Icon/icon';
import NextRedIcon from '../../assets/next-red.svg';
import NextIcon from '../../assets/next.svg';
import classes from './category.module.css';

var SubCategoryBox = function SubCategoryBox(_ref) {
  var subcategory = _ref.subcategory;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isHovered = _useState2[0],
      setIsHovered = _useState2[1];

  var handleIsHovered = function handleIsHovered() {
    return setIsHovered(!isHovered);
  };

  return React.createElement("div", {
    className: classes.outletCategoryLink,
    onMouseEnter: handleIsHovered,
    onMouseLeave: handleIsHovered
  }, React.createElement("div", {
    className: classes.outletCategoryName
  }, React.createElement("h3", null, subcategory.name)), React.createElement("div", {
    className: classes.shopOutlet + ' my-8 items-center grid grid-cols-2'
  }, React.createElement("h5", null, "Shop Outlet"), React.createElement("span", null, React.createElement(Link, {
    to: '/' + subcategory.url_path,
    className: "flex w-[36px] h-[36px] md_w-[48px] md_h-[48px]",
    "aria-label": "Go to ".concat(subcategory.name, " Outlet page")
  }, React.createElement(Icon, {
    src: isHovered ? NextIcon : NextRedIcon,
    alt: 'Shop ' + subcategory.name
  })))));
};

export default SubCategoryBox;