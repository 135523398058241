import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from 'react';
import { useErrorContext } from '@magento/peregrine/lib/context/unhandledErrors';
import App from './app';
import { useErrorBoundary } from '@magento/venia-ui/lib/components/App/useErrorBoundary';

var AppContainer = function AppContainer() {
  var ErrorBoundary = useErrorBoundary(App);

  var _useErrorContext = useErrorContext(),
      _useErrorContext2 = _slicedToArray(_useErrorContext, 2),
      unhandledErrors = _useErrorContext2[0],
      errorApi = _useErrorContext2[1];

  return React.createElement(ErrorBoundary, _extends({
    unhandledErrors: unhandledErrors
  }, errorApi));
};

export default AppContainer;