import React from 'react';
import { Link } from 'react-router-dom';
import { generateUrl } from '@magento/peregrine/lib/util/imageUtils';
import Price from '@magento/venia-ui/lib/components/Price';
import placeholder from '../../../assets/product-16.png';
import classes from './productSliderItem.module.css';
import { Image } from '..';
var SMALL_IMAGE_WIDTH = 400;

var ProductSliderItem = function ProductSliderItem(_ref) {
  var _item$small_image;

  var item = _ref.item;
  var imgPath = item === null || item === void 0 ? void 0 : (_item$small_image = item.small_image) === null || _item$small_image === void 0 ? void 0 : _item$small_image.url;
  var imgUrl = imgPath !== null && imgPath !== void 0 ? imgPath : false; // ? generateUrl(imgPath, 'image-product')(SMALL_IMAGE_WIDTH) : false;

  return React.createElement(Link, {
    to: "/".concat(item.url_key),
    className: "border border-solid border-snowbound h-full flex flex-col ",
    "aria-label": "Go to ".concat(item.name)
  }, React.createElement(Image, {
    resource: imgUrl || placeholder,
    alt: "".concat(item.name, " product"),
    width: SMALL_IMAGE_WIDTH,
    classes: {
      image: classes.image
    }
  }), React.createElement("div", {
    className: "px-3 py-5 flex flex-col flex-1 justify-between"
  }, React.createElement("p", {
    className: "text-black font-semibold leading-129"
  }, item.name), React.createElement("div", {
    className: classes.skuDimensionsWrapper
  }, React.createElement("p", {
    className: "md_whitespace-nowrap mr-2.5"
  }, "Base Item ", item.sku), React.createElement("p", {
    className: "md_whitespace-nowrap"
  }, item.hf_width && "".concat(item.hf_width, "\""), ' ', item.hf_width && item.hf_height && 'x ', item.hf_height && "".concat(item.hf_height, "\""), ' ', item.hf_depth_length && item.hf_height && 'x ', item.hf_depth_length && "".concat(item.hf_depth_length, "\""))), React.createElement(Price, {
    value: item.price.regularPrice.amount.value,
    currencyCode: item.price.regularPrice.amount.currency,
    classes: {
      root: 'text-base leading-156 font-semibold text-bronze'
    }
  })));
};

export default ProductSliderItem;