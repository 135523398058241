// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".signInModal-signInToFavoriteItemWrapper--jv {\n    margin-top: 2.25rem;\n    margin-bottom: 2.25rem;\n    display: flex;\n    column-gap: 1rem;\n}\n\n@media (min-width: 1024px) {\n\n    .signInModal-signInToFavoriteItemWrapper--jv {\n        margin-top: 3.5rem;\n        margin-bottom: 3.5rem;\n    }\n\n    .signInModal-signInToFavoriteItemWrapper--jv {\n        margin-left: 2rem;\n        margin-right: 2rem;\n    }\n\n    .signInModal-signInToFavoriteItemWrapper--jv {\n        column-gap: 3rem;\n    }\n}\n\n.signInModal-signInToFavoriteItemInfo-G7n {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.signInModal-bottom-part-ZBg {\n    background-color: #f2f2f2;\n    width: 57rem;\n    margin-left: -3rem;\n}\n\n.signInModal-continueButton-FLl {\n    margin: 2.5rem 0 2rem;\n}\n\n.signInModal-modalWrapper-hvR {\n    overflow: hidden;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signInToFavoriteItemWrapper": "signInModal-signInToFavoriteItemWrapper--jv",
	"signInToFavoriteItemInfo": "signInModal-signInToFavoriteItemInfo-G7n",
	"bottom-part": "signInModal-bottom-part-ZBg",
	"continueButton": "signInModal-continueButton-FLl",
	"modalWrapper": "signInModal-modalWrapper-hvR"
};
export default ___CSS_LOADER_EXPORT___;
