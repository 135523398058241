// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".row-root-lq2 {\n    --leftViewportElement: 0;\n    --scrollBarWidth: var(--global-scrollbar-width, 0px);\n}\n\n.row-contained-SUO {\n    margin-left: auto !important;\n    margin-right: auto !important;\n}\n\n@media (max-width: 1024px) {\n    .row-contained-SUO {\n        max-width: 100% !important;\n        padding: 0 1rem;\n    }\n}\n\n@media (min-width: 1024px) {\n    .row-contained-SUO {\n        max-width: 1024px;\n        padding: 0 2rem;\n    }\n}\n\n@media (min-width: 1280px) {\n    .row-contained-SUO {\n        max-width: 1280px;\n        padding: 0 2rem;\n    }\n}\n\n@media (min-width: 1536px) {\n    .row-contained-SUO {\n        max-width: var(--venia-global-maxWidth);\n        padding: 0 2rem;\n    }\n}\n\n.row-fullBleed-D0b {\n    width: calc(100vw);\n    transform: translateX(-50%);\n    margin-left: 50%;\n}\n\n.row-videoOverlay-aUx {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: -99;\n    -webkit-transform: translate3d(0, 0, 0);\n}\n\n.row-root-lq2 [id*='jarallax-container'] video,\n.row-root-lq2 [id*='jarallax-container'] iframe {\n    visibility: hidden;\n}\n\n@media only screen and (max-width: 768px) {\n    .row-root-lq2 {\n        background-attachment: scroll !important;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "row-root-lq2",
	"contained": "row-contained-SUO row-root-lq2",
	"fullBleed": "row-fullBleed-D0b row-root-lq2",
	"videoOverlay": "row-videoOverlay-aUx"
};
export default ___CSS_LOADER_EXPORT___;
