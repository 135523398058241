// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../node_modules/postcss-loader/dist/cjs.js!../clickable.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navTrigger-root-yIv {\n    background-color: var(--venia-global-color-worldly-gray);\n    min-height: 5rem;\n    width: 65px;\n}\n\n@media (max-width: 760px) {\n    .navTrigger-root-yIv {\n        min-height: 56px;\n    }\n}\n\n@media (min-width: 1024px) {\n    .navTrigger-root-yIv {\n        display: none;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "navTrigger-root-yIv " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["root"] + ""
};
export default ___CSS_LOADER_EXPORT___;
