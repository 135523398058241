import React from 'react';
import { shape, string } from 'prop-types';
import defaultClasses from './fieldIcons.module.css'; // import { useMergeCssClasses } from '../../../Utils/utils';

import { useMergeCssClasses } from '../../../../Utils/utils';

var FieldIcons = function FieldIcons(props) {
  var after = props.after,
      before = props.before,
      children = props.children;
  var classes = useMergeCssClasses(defaultClasses, props.classes);
  var style = {
    '--iconsBefore': before ? 1 : 0,
    '--iconsAfter': after ? 1 : 0
  };
  return React.createElement("span", {
    className: classes.root,
    style: style
  }, React.createElement("span", {
    className: classes.input
  }, children), React.createElement("span", {
    className: classes.before
  }, before), React.createElement("span", {
    className: classes.after
  }, after));
};

FieldIcons.propTypes = {
  classes: shape({
    after: string,
    before: string,
    root: string
  })
};
export default FieldIcons;