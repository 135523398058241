// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../node_modules/postcss-loader/dist/cjs.js!../Dropdown/dropdown.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".productSort-root-E8V {\n}\n\n.productSort-dropdownLabel-hZH {\n}\n\n.productSort-menu--Za {\n  z-index: 100 !important;\n}\n\n.productSort-desktopText-2S9 {\n}\n.productSort-icon-nVW {\n  transform: rotate(0deg);\n  transition: all 0.3s ease-in-out;\n}\n\n.productSort-iconActive-Vwy {\n  transform: rotate(180deg);\n  transition: all 0.3s ease-in-out;\n}\n\n.productSort-sortText-ajn {\n}\n\n@media (min-width: 1024px) {\n  .productSort-mobileText-1u3 {\n    display: none;\n  }\n\n  .productSort-desktopText-2S9 {\n    display: inline-flex;\n  }\n}\n\n@media (max-width: 1023px) {\n  .productSort-desktopText-2S9 {\n    display: none;\n  }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "productSort-root-E8V relative",
	"dropdownLabel": "productSort-dropdownLabel-hZH " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["dropdownLabel"] + "",
	"menu": "productSort-menu--Za " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["dropdownOptions"] + "",
	"desktopText": "productSort-desktopText-2S9 items-center justify-between w-full",
	"icon": "productSort-icon-nVW",
	"iconActive": "productSort-iconActive-Vwy",
	"sortText": "productSort-sortText-ajn leading-171",
	"mobileText": "productSort-mobileText-1u3"
};
export default ___CSS_LOADER_EXPORT___;
