import React, { useCallback } from 'react';
import { func, number, shape, string, bool } from 'prop-types';
import { useIntl } from 'react-intl';
import { usePagination } from '@magento/peregrine/lib/talons/Pagination/usePagination';
import Shimmer from '@magento/venia-ui/lib/components/Shimmer';
import { FormattedMessage } from 'react-intl';
import classes from './pagination.module.css';
import NavButton from './navButton';
import Dropdown from '../Dropdown/dropdown';

var Pagination = function Pagination(_ref) {
  var _ref$pageControl = _ref.pageControl,
      currentPage = _ref$pageControl.currentPage,
      setPage = _ref$pageControl.setPage,
      totalPages = _ref$pageControl.totalPages,
      _ref$bottomPagination = _ref.bottomPagination,
      bottomPagination = _ref$bottomPagination === void 0 ? false : _ref$bottomPagination,
      totalCount = _ref.totalCount,
      isLoading = _ref.isLoading,
      pageSize = _ref.pageSize,
      setPageSize = _ref.setPageSize,
      pageSizeValues = _ref.pageSizeValues;

  var _useIntl = useIntl(),
      formatMessage = _useIntl.formatMessage;

  var _usePagination = usePagination({
    currentPage: currentPage,
    setPage: setPage,
    totalPages: totalPages,
    tileBuffer: totalPages
  }),
      handleNavBack = _usePagination.handleNavBack,
      handleNavForward = _usePagination.handleNavForward,
      isActiveLeft = _usePagination.isActiveLeft,
      isActiveRight = _usePagination.isActiveRight,
      tiles = _usePagination.tiles;

  var categoryResultsHeading = totalCount > 0 ? React.createElement(FormattedMessage, {
    id: 'categoryContent.resultCount',
    values: {
      count: totalCount
    },
    defaultMessage: '{count} Results'
  }) : isLoading ? React.createElement(Shimmer, {
    width: 5
  }) : null;
  var derivedPageSizeValues = pageSizeValues !== null && pageSizeValues !== void 0 && pageSizeValues.length ? pageSizeValues : [12, 24, 48, 96];
  var paginationOptions = tiles.map(function (item) {
    return {
      label: item,
      value: item
    };
  });
  var pageSizeOptions = derivedPageSizeValues === null || derivedPageSizeValues === void 0 ? void 0 : derivedPageSizeValues.map(function (item) {
    return {
      label: item,
      value: item
    };
  });
  var onPageSizeChangeResetPage = useCallback(function (value) {
    setPage(1);
    setPageSize(value);
  }, [setPageSize, setPage]);
  return React.createElement("div", {
    className: classes.root
  }, React.createElement("div", {
    className: classes.paginationWrap
  }, React.createElement("div", null, bottomPagination && (isActiveLeft || isActiveRight) && React.createElement("div", {
    className: "flex justify-center flex-1"
  }, React.createElement(NavButton, {
    active: isActiveLeft,
    onClick: handleNavBack,
    classes: {
      root: 'mr-3',
      icon_disabled: 'mr-3'
    }
  }), React.createElement(NavButton, {
    active: isActiveRight,
    onClick: handleNavForward,
    classes: {
      root: 'ml-3',
      icon_disabled: 'ml-3'
    },
    isNext: true
  }))), React.createElement("div", null, React.createElement("div", {
    className: classes.dropdownWrapper
  }, !bottomPagination && categoryResultsHeading, React.createElement(Dropdown, {
    classes: {
      dropdown: classes.dropdownWidthPageSize,
      dropdownOptions: classes.dropdownOptions
    },
    title: "Show ".concat(pageSize),
    options: pageSizeOptions,
    handleOptionClick: onPageSizeChangeResetPage
  }), React.createElement(Dropdown, {
    classes: {
      dropdown: classes.dropdownWidth,
      dropdownOptions: classes.dropdownOptions
    },
    title: currentPage,
    options: paginationOptions,
    handleOptionClick: setPage
  }), React.createElement("span", {
    className: "ml-2 xl_ml-5"
  }, "of ", totalPages == 'NaN' ? '' : totalPages)))));
};

Pagination.propTypes = {
  classes: shape({
    root: string
  }),
  pageControl: shape({
    currentPage: number,
    setPage: func,
    totalPages: number
  }).isRequired,
  bottomPagination: bool
};
export default Pagination;