import { VALID_SERVICE_WORKER_ENVIRONMENT, handleMessageFromSW } from '@magento/peregrine/lib/util/swUtils';
export var registerSW = function registerSW() {
  if (VALID_SERVICE_WORKER_ENVIRONMENT && globalThis.navigator) {
    window.navigator.serviceWorker.register('/sw.js').then(function () {
      console.log('SW Registered');
    })["catch"](function () {
      /**
       * console.* statements are removed by webpack
       * in production mode. window.console.* are not.
       */
      window.console.warn('Failed to register SW.');
    });
    navigator.serviceWorker.addEventListener('message', function (e) {
      var _e$data = e.data,
          type = _e$data.type,
          payload = _e$data.payload;
      handleMessageFromSW(type, payload, e);
    });
  }
};