import React from 'react';

function RoundSearchIcon() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    width: "35",
    height: "35",
    viewBox: "0 0 35 35"
  }, React.createElement("defs", null, React.createElement("path", {
    id: "ds1xz2kboa",
    d: "M0 0h27v27H0z"
  })), React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("circle", {
    cx: "17.5",
    cy: "17.5",
    r: "17.5",
    fill: "#3E3E3E"
  }), React.createElement("g", {
    transform: "translate(5 3)"
  }, React.createElement("mask", {
    id: "l70bcwnp5b",
    fill: "#fff"
  }, React.createElement("use", {
    xlinkHref: "#ds1xz2kboa"
  })), React.createElement("path", {
    fill: "#FFF",
    stroke: "#FFF",
    d: "M6.448 16.668c-2.533-2.54-2.533-6.672 0-9.213a6.448 6.448 0 014.594-1.907 6.45 6.45 0 014.594 1.907c2.533 2.54 2.533 6.674 0 9.213a6.449 6.449 0 01-4.594 1.908 6.447 6.447 0 01-4.594-1.908m15.468 5.86l-5.783-5.587c2.635-2.761 2.6-7.156-.108-9.874A6.994 6.994 0 0011.042 5a6.993 6.993 0 00-4.983 2.067c-2.745 2.755-2.745 7.236 0 9.988a6.991 6.991 0 004.983 2.07 6.986 6.986 0 004.698-1.8l5.795 5.598a.274.274 0 10.381-.394",
    mask: "url(#l70bcwnp5b)"
  }))));
}

export default RoundSearchIcon;