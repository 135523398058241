// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".column-root-eGm {\n}\n\n@media only screen and (max-width: 768px) {\n    .column-root-eGm {\n        background-attachment: scroll !important;\n        flex-basis: 100%;\n    }\n}\n\n.column-paddingRightXl-liu {\n    padding-right: 8rem;\n}\n.column-paddingLeftXl-c76 {\n    padding-left: 8rem;\n}\n\n.column-socialMedia-FjH {\n    flex-direction: row;\n    justify-content: flex-end;\n}\n\n.column-socialMedia-FjH .column-socialLinks-SWO {\n    display: flex;\n    align-self: flex-end;\n    gap: 0.5rem;\n}\n\n.column-privacyPoliceLinks-9fs {\n    gap: 1rem;\n}\n\n@media (max-width: 768px) {\n    .column-socialMedia-FjH {\n        order: 1;\n    }\n    .column-privacyPoliceLinks-9fs {\n        order: 2;\n    }\n    .column-copyrightContent-z6y {\n        order: 3;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "column-root-eGm",
	"paddingRightXl": "column-paddingRightXl-liu",
	"paddingLeftXl": "column-paddingLeftXl-c76",
	"socialMedia": "column-socialMedia-FjH",
	"socialLinks": "column-socialLinks-SWO",
	"privacyPoliceLinks": "column-privacyPoliceLinks-9fs",
	"copyrightContent": "column-copyrightContent-z6y"
};
export default ___CSS_LOADER_EXPORT___;
