import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useMemo } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import mergeOperations from '@magento/peregrine/lib/util/shallowMerge';
import DEFAULT_OPERATIONS from './categoryTree.gql';
/**
 * @typedef {object} CategoryNode
 * @prop {object} category - category data
 * @prop {boolean} isLeaf - true if the category has no children
 */

/**
 * @typedef { import("graphql").DocumentNode } DocumentNode
 */

/**
 * Returns props necessary to render a CategoryTree component.
 *
 * @param {object} props
 * @param {number} props.categoryId - category id for this node
 * @param {DocumentNode} props.query - GraphQL query
 * @param {function} props.updateCategories - bound action creator
 * @return {{ childCategories: Map<number, CategoryNode> }}
 */

export var useCategoryTree = function useCategoryTree(props) {
  var categoryId = props.categoryId,
      updateCategories = props.updateCategories;
  var operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  var getNavigationMenuQuery = operations.getNavigationMenuQuery,
      getCategoryUrlSuffixQuery = operations.getCategoryUrlSuffixQuery;

  var _useLazyQuery = useLazyQuery(getNavigationMenuQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  }),
      _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
      runQuery = _useLazyQuery2[0],
      queryResult = _useLazyQuery2[1];

  var data = queryResult.data;

  var _useQuery = useQuery(getCategoryUrlSuffixQuery, {
    fetchPolicy: 'cache-and-network'
  }),
      categoryUrlData = _useQuery.data;

  var categoryUrlSuffix = useMemo(function () {
    if (categoryUrlData) {
      return categoryUrlData.storeConfig.category_url_suffix;
    }
  }, [categoryUrlData]); // fetch categories

  useEffect(function () {
    if (categoryId != null) {
      runQuery({
        variables: {
          id: categoryId
        }
      });
    }
  }, [categoryId, runQuery]); // update redux with fetched categories

  useEffect(function () {
    if (data && data.categories.items[0]) {
      updateCategories(data.categories.items[0]);
    }
  }, [data, updateCategories]);
  var rootCategory = data && data.categories.items[0];

  var _ref = rootCategory || {},
      _ref$children = _ref.children,
      children = _ref$children === void 0 ? [] : _ref$children;

  var childCategories = useMemo(function () {
    var childCategories = new Map();
    children.map(function (category) {
      if (category.include_in_menu) {
        var isLeaf = !parseInt(category.children_count);
        childCategories.set(category.uid, {
          category: category,
          isLeaf: isLeaf
        });
      }
    });
    return childCategories;
  }, [children, rootCategory]);
  return {
    childCategories: childCategories,
    data: data,
    categoryUrlSuffix: categoryUrlSuffix
  };
};